import { Button } from '../../../Components/Base';
import VisibilityOutlinedIcon from '../../../Components/Icons/VisibilityOutlinedIcon';
import useDisclosure from '../../../Utils/useDisclosure';

import QuestionDetailsModal, { QuestionDetailsProps } from './QuestionDetailsModal';

type PreviewQuestionDetailsButtonProps = { question: QuestionDetailsProps };

const PreviewQuestionDetailsButton = ({ question }: PreviewQuestionDetailsButtonProps) => {
  const { isOpen, open, close } = useDisclosure();
  return (
    <>
      <Button
        data-testid="watch-question-button"
        color="neutral"
        variant={'outlined'}
        onClick={open}
        size={'small'}
        startIcon={<VisibilityOutlinedIcon />}
        sx={{ minWidth: 90 }}
      >
        ดูโจทย์
      </Button>
      <QuestionDetailsModal open={isOpen} onClose={close} question={question} />
    </>
  );
};

export type { PreviewQuestionDetailsButtonProps };
export default PreviewQuestionDetailsButton;
