import { useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { Helmet } from 'react-helmet';
import { ApolloError } from '@apollo/client';

import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Text,
} from '../../Components/Base';
import { ExaminationInstruction } from '../../Components/ExaminationPrint';
import { useConfig } from '../../Contexts/ConfigProvider';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';
import { ResponseType } from '../../Domains/ExaminationManagement/constant';

import ExaminationHeader from './Containers/ExaminationHeader';
import ExaminationQuestionList from './Containers/ExaminationQuestionList';

declare global {
  interface Window {
    PagedConfig: Record<string, unknown>;
  }
}

const Title = styled(Text)({
  '@media print': {
    display: 'none',
  },
});

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ไม่พบชุดข้อสอบที่ต้องการ ข้อมูลอาจถูกลบจากผู้ใช้อื่น',
  [ResponseType.UNEXPECTED]: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
};

const getErrorMessage = (error: ApolloError) => {
  if (error.message.includes('Not found Exam')) {
    return RESPONSE_MESSAGE.NOT_FOUND;
  } else if (error.message === 'Failed to fetch') {
    return RESPONSE_MESSAGE.NETWORK_ERROR;
  }

  return RESPONSE_MESSAGE.UNEXPECTED;
};

const ExaminationPrint = () => {
  const { exam: examConfig } = useConfig();
  const watermarkConfig = examConfig?.examination?.printWatermark ?? '';
  const { examId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const watermarkText = searchParams.get('embed') ?? watermarkConfig;
  const typeKey = searchParams.get('type') ?? 'teacher';
  const isStudent = typeKey === 'student';
  const {
    data: examination,
    loading: examinationLoading,
    error: examinationError,
  } = useGetExamination({
    variables: { id: examId },
    fetchPolicy: 'network-only',
    onError: (error) => console.error(error),
    onCompleted: async () => {
      // config will be read on pagedjs loaded also auto transpile begin automatically
      // prevent mui styled has been removed on production build
      window.PagedConfig = { stylesheets: [] };
      // Ref pagedjs by polyfill
      // example: https://codesandbox.io/s/kpbbp
      // example: https://gitlab.coko.foundation/pagedjs/pagedjs
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import('pagedjs/dist/paged.polyfill')
        .then(async ({ default: Previewer }) => {
          document.body.style.display = 'grid';
          document.body.style.placeItems = 'center';
          document.body.style.padding = '0';
          document.body.style.margin = '0';

          const printingStyle = `
            @page {
              margin: 0.75cm;
              size: A4;
              @top-right {
                font-size: 12px;
                content: 'หน้า ' counter(page);
              }
            }
            #examination-print-page::after {
              position: absolute;
              width: 100%;
              top: 50%;
              left: 50%;
              content: "${watermarkText}" !important;
              font-size: 60px;
              text-align: center;
              opacity: 0.05;
              font-weight: 600;
              transform: translate(-50%,-50%) rotate(-30deg);
            }
          `;
          await Previewer.polisher.add({ 'polisher.css': printingStyle });

          Previewer.hooks.afterPreview.trigger = () => {
            // this not work cause TypeError: Can only call window.reload on instances of Window
            // window.onfocus=window.close
            window.onfocus = () => window.close();
            window.print();
          };
        })
        .catch((err) => console.log(err));
    },
  });

  if (examinationLoading) return null;

  if (examinationError && !examination) {
    const message = getErrorMessage(examinationError);
    return (
      <Modal open>
        <ModalTitle sx={{ p: 3, pb: 4 }}>{'เกิดข้อผิดพลาดในการพิมพ์'}</ModalTitle>
        <ModalContent sx={{ pb: 0 }}>{message}</ModalContent>
        <ModalFooter sx={{ justifyContent: 'flex-end', p: 3, pt: 4 }}>
          <Button
            onClick={window.close}
            variant={'contained'}
            sx={{ width: { xs: 1, sm: 'auto' } }}
            size="large"
          >
            {'ตกลง'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const examName = examination?.qmsExam?.title ?? '';
  return (
    <>
      <Helmet>
        <title>{`${examName} - ${isStudent ? 'นักเรียน' : 'คุณครู'}`}</title>
      </Helmet>
      <Box data-testid="examination-print-page" id="examination-print-page">
        <Title data-testid="title" variant={'h4'} px={2} mb={2}>
          {`ชุดข้อสอบ: ${examName}`}
        </Title>
        <ExaminationHeader />
        <ExaminationInstruction data-testid="instruction" my={2}>
          {'คำชี้แจง: ให้นักเรียนเลือกคำตอบที่ถูกต้อง'}
        </ExaminationInstruction>
        <ExaminationQuestionList />
      </Box>
    </>
  );
};

export default ExaminationPrint;
