import { styled, SvgIconProps } from '@mui/material';

import { Box, BoxProps, Text } from '../Base';
import DragIndicatorRoundedIcon from '../Icons/DragIndicatorRoundedIcon';

export type DragAndDropProps = {
  variant?: 'small' | 'medium';
  color?: 'text.primary' | 'text.secondary';
} & Omit<BoxProps, 'color'>;

const DragIcon = styled((props: SvgIconProps & { $variant: 'small' | 'medium' }) => (
  <DragIndicatorRoundedIcon {...props} />
))(({ $variant }) => ({
  fontSize: $variant === 'small' ? '20px' : '24px',
}));

const DragAndDrop = (props: DragAndDropProps) => {
  const { children, color = 'text.primary', variant = 'medium', ...restProps } = props;
  return (
    <Box
      display={'grid'}
      alignItems={'flex-start'}
      gridTemplateColumns={'minmax(0px, 24px) minmax(0px, 1fr)'}
      sx={{ cursor: 'grab' }}
      {...restProps}
    >
      <DragIcon $variant={variant} sx={{ color }} />
      {typeof children === 'string' ? (
        <Text
          variant={variant === 'medium' ? 'body1' : 'body2'}
          color={color}
          whiteSpace={'nowrap'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default DragAndDrop;
