import { FormControl, FormGroup } from '@mui/material';

import {
  Box,
  Flex,
  SelectionInput,
  Text,
  TextInput,
  SearchSelectionInput,
  SearchableMultipleSelectionInput,
} from '../../../../Components/Base';

const OPTIONS = [
  { label: 'จำนวนเต็ม', value: 'integer_number' },
  { label: 'การสร้างทางเรขาคณิต', value: 'geometry' },
  { label: 'เลขยกกำลัง', value: 'exponent' },
  { label: 'จำนวนเชิงซ้อน', value: 'complex_number' },
];

const Input = () => {
  return (
    <form>
      <FormGroup>
        <FormControl>
          <Flex flex={'1 0 auto'} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box width={1}>
              <Text variant="h6">Text Input</Text>
              <TextInput label={'Text Input'} placeholder="enter your name here" required />
              <Box mt={1} />
              <TextInput
                label={'Text Input Error'}
                placeholder="enter your name here"
                error
                helperText="required"
              />
              <TextInput
                label={'Text Input Disabled'}
                placeholder="enter your name here"
                disabled
              />
            </Box>
            <Box pl={2} />
            <Box width={1}>
              <Text variant="h6">Selection</Text>
              <SelectionInput label={'Selection'} placeholder="select option" options={OPTIONS} />
              <Box mt={1} />
              <SelectionInput
                label={'Selection Error'}
                placeholder="select option"
                options={OPTIONS}
                error
                helperText="required"
              />
              <SelectionInput
                label={'Selection Disabled'}
                placeholder="select option"
                options={OPTIONS}
                disabled
              />
            </Box>
            <Box pl={2} />
            <Box width={1}>
              <Text variant="h6">Search Selection</Text>
              <SearchSelectionInput
                label={'Search Selection'}
                options={OPTIONS}
                InputProps={{ placeholder: 'เลือกหน่วยการเรียนรู้' }}
              />
              <Box mt={1} />
              <SearchSelectionInput
                label={'Search Selection Error'}
                options={OPTIONS}
                InputProps={{ placeholder: 'เลือกหน่วยการเรียนรู้' }}
                error
                helperText="required"
              />
              <SearchSelectionInput
                label={'Search Selection Disabled'}
                options={OPTIONS}
                InputProps={{ placeholder: 'เลือกหน่วยการเรียนรู้' }}
                disabled
              />
            </Box>
            <Box pl={2} />
            <Box width={1}>
              <Text variant="h6">Search Multiple Selection</Text>
              <SearchableMultipleSelectionInput
                label={'Multiple Selection'}
                options={OPTIONS}
                InputProps={{ placeholder: 'Bloom' }}
              />
              <Box mt={1} />
              <SearchableMultipleSelectionInput
                label={'Multiple Selection Error'}
                options={OPTIONS}
                InputProps={{ placeholder: 'Bloom' }}
                error
                helperText="required"
              />
              <SearchableMultipleSelectionInput
                label={'Multiple Selection Disabled'}
                options={OPTIONS}
                InputProps={{ placeholder: 'Bloom' }}
                disabled
              />
            </Box>
          </Flex>
        </FormControl>
      </FormGroup>
    </form>
  );
};

export default Input;
