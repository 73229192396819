import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useEffect } from 'react';

import ExaminationInformationForm, {
  ExaminationInformationFormProps,
} from '../../../Components/ExaminationInformationForm';
import { Text, Box, Flex, Button } from '../../../Components/Base';
import ArrowForwardIcon from '../../../Components/Icons/ArrowForwardIcon';
import {
  EXAMINATION_EXAMINATION_QUESTIONS_PATH,
  ExaminationStatus,
} from '../../../Domains/ExaminationManagement/constant';
import { useExaminationInformation } from '../../../Contexts/ExaminationProvider';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';

const ExaminationSubmissionBlock: ExaminationInformationFormProps['submitComponent'] = (
  formContext,
) => {
  const { watch } = formContext;
  const [, setExaminationInformation] = useExaminationInformation();

  useEffect(() => {
    const subscription = watch((value) => setExaminationInformation(value));
    return () => subscription.unsubscribe();
  }, [watch, setExaminationInformation]);

  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Flex mt={2} justifyContent={'space-between'}>
        <Box pl={2} />
        <Button
          data-testid="saving-and-continue-button"
          type="submit"
          disabled={formContext.formState.isSubmitting || !formContext.formState.isValid}
          size="large"
          endIcon={<ArrowForwardIcon />}
        >
          {formContext.formState.isSubmitting ? 'กำลังบันทึก..' : 'ต่อไป'}
        </Button>
      </Flex>
    </>
  );
};

export default function ExaminationReformation() {
  const navigate = useNavigate();
  const { examId = '' } = useParams();
  const [examinationInformation, setExaminationInformation] = useExaminationInformation();
  const { examName = '', subject = '', grade = '' } = examinationInformation ?? {};
  const { data: examination } = useGetExamination({ variables: { id: examId } });

  return (
    <>
      <Text variant="h4">{'รายละเอียดชุดข้อสอบ'}</Text>
      <Box mt={2.5} />
      {examinationInformation && (
        <ExaminationInformationForm
          initialFormValues={{ examName: examName, subject: subject, grade: grade }}
          onSubmit={() => (formValues) => {
            setExaminationInformation(formValues);
            if (!examId) return;
            navigate(EXAMINATION_EXAMINATION_QUESTIONS_PATH.replace(':examId', examId));
          }}
          submitComponent={ExaminationSubmissionBlock}
          isDraftMode={examination?.qmsExam?.status === ExaminationStatus.Draft}
          gradeOptions={[{ label: grade, value: grade }]}
          subjectOptions={[{ label: subject, value: subject }]}
        />
      )}
    </>
  );
}
