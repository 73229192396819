import { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';

import { Flex, Box, BoxProps, Text, TablePagination } from '../../../../Components/Base';
import ExaminationQuestionGroups from '../../../../Components/ExaminationQuestionGroups';
import { useExaminationQuestion } from '../../../../Contexts/ExaminationProvider';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestions,
} from '../../../../Domains/ExaminationManagement/utils';
import { combineExaminationQuestionWithStore } from '../utils';
import usePagination from '../../../../Domains/Pagination';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';

interface ExaminationPointInput {
  [key: string]: string;
}

const QuestionList = (props: BoxProps) => {
  const { examId = '' } = useParams();
  const [questionSelected, setExaminationQuestionSelected] = useExaminationQuestion();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });
  const examItems = examination?.qmsExam.examItems ?? [];
  const questions = formatExaminationItemToQuestions(examItems);

  const { paging, rowsPerPage, changePage, changeRowsPerPage } = usePagination();

  const examinationQuestionList = combineExaminationQuestionWithStore(
    questions ?? [],
    questionSelected ?? [],
  );

  const splitQuestionList = useMemo(() => {
    const startQuestionListInPage = paging * rowsPerPage;
    const endQuestionListInPage = (paging + 1) * rowsPerPage;

    return examinationQuestionList.slice(startQuestionListInPage, endQuestionListInPage);
  }, [examinationQuestionList, paging, rowsPerPage]);

  const questionGroupBySection = groupQuestionBySection(splitQuestionList);

  const formContext = useForm<ExaminationPointInput>({
    mode: 'onTouched',
    defaultValues: {},
  });

  useEffect(() => {
    // FIXME: use debounce workaround for prevent render frequenly
    const subscription = formContext.watch(
      debounce((formValue, { name = '' }) => {
        const pointById = parseInt(formValue[name] ?? '');

        setExaminationQuestionSelected((prev) =>
          prev.map((item) => {
            if (item.id === name) return { ...item, point: pointById };
            return item;
          }),
        );
      }, 300),
    );

    return () => subscription.unsubscribe();
  }, [formContext, setExaminationQuestionSelected]);

  const handleDelete = useCallback(
    (id: string) => {
      setExaminationQuestionSelected((prev) => {
        const preRemoveQuestion = orderBy(prev, 'order') ?? [];
        const removeQuestion = preRemoveQuestion.filter((item) => item.id !== id);
        const orderedQuestion = removeQuestion.map((item, index) => ({
          ...item,
          order: index + 1,
        }));

        return orderedQuestion;
      });
    },
    [setExaminationQuestionSelected],
  );

  return (
    <Box data-testid="examination-question-list" {...props}>
      <FormProvider {...formContext}>
        <form>
          {questionGroupBySection.map((question, questionGroupIndex) => {
            return (
              <Box data-testid={`section-${questionGroupIndex + 1}`} key={question.section}>
                <Flex flexDirection={'row'}>
                  <Box
                    px={0.6}
                    py={2}
                    bgcolor={'primary.main'}
                    borderRadius={'8px 0px 0px 8px'}
                    boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
                  />
                  <Box
                    flexGrow={1}
                    px={3}
                    py={2}
                    bgcolor={'common.white'}
                    borderRadius={'0px 8px 8px 0px'}
                    boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
                  >
                    <Text data-testid="section-title" variant="h4">
                      {question.section}
                    </Text>
                  </Box>
                </Flex>
                {question.items.map((item, indicatorIndex) => {
                  const title = item.indicator.substring(0, 11).trim();
                  const description = item.indicator.substring(12).trim();
                  const questionItems = item.items;
                  return (
                    <ExaminationQuestionGroups
                      data-testid={`indicator-${indicatorIndex + 1}`}
                      key={item.indicator}
                      title={title}
                      description={description}
                      questionItems={questionItems}
                      onDelete={handleDelete}
                    />
                  );
                })}
              </Box>
            );
          })}
        </form>
      </FormProvider>
      <TablePagination
        count={examinationQuestionList.length}
        page={paging}
        onPageChange={changePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={changeRowsPerPage}
      />
    </Box>
  );
};

export default QuestionList;
