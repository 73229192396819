import { Text, Alert as BaseAlert, Box, Button } from '../../../../Components/Base';
import AddIcon from '../../../../Components/Icons/AddIcon';
import CallMadeIcon from '../../../../Components/Icons/CallMadeIcon';
import ErrorIcon from '../../../../Components/Icons/ErrorIcon';
import InfoIcon from '../../../../Components/Icons/InfoIcon';

const publishErrorsAlert = ['วันที่และเวลาสอบ', 'ชุดข้อสอบ', 'ผู้มีสิทธิ์เข้าสอบ'];

const Alert = () => {
  return (
    <Box>
      <BaseAlert>
        <Text component="span">{'เลือกคำถามด้านล่าง เพื่อเพิ่มไปยัง '}</Text>
        <Text component="span" fontWeight="600">
          “ชุดข้อสอบ”
        </Text>
      </BaseAlert>
      <Box mt={2} />
      <BaseAlert icon={<InfoIcon fontSize="small" sx={{ color: 'grey.700' }} />}>
        <Text component="span">{'เลือกคำถามด้านล่าง เพื่อเพิ่มไปยัง '}</Text>
        <Text component="span" fontWeight="600">
          “ชุดข้อสอบ”
        </Text>
      </BaseAlert>
      <Box mt={2} />
      <BaseAlert color="error" icon={<ErrorIcon fontSize="small" color="error" />}>
        <Text component="span" color="error">
          {'เลือกคำถามด้านล่าง เพื่อเพิ่มไปยัง '}
        </Text>
        <Text component="span" color="error" fontWeight="600">
          “ชุดข้อสอบ”
        </Text>
      </BaseAlert>
      <Box mt={2} />
      <BaseAlert color="error" icon={<ErrorIcon fontSize="small" color="error" />}>
        <Text color="error"> {'ไม่สามารถเผยแพร่ชุดข้อสอบได้ เนื่องจากขาดข้อมูลดังนี้:'}</Text>
        {publishErrorsAlert.map((item, index) => (
          <Text key={index} color="error">{`- ${item}`}</Text>
        ))}
      </BaseAlert>
      <Box mt={2} />
      <BaseAlert
        color="info"
        action={
          <Button
            size="small"
            onClick={() => {
              console.log('เพิ่มไปยังชุดข้อสอบ');
            }}
            startIcon={<AddIcon />}
          >
            {'เพิ่มไปยังชุดข้อสอบ'}
          </Button>
        }
      >
        {'มี 2 คำถามที่ถูกเลือก'}
      </BaseAlert>
      <Box mt={2} />
      <BaseAlert
        color="info"
        action={
          <Button
            size="small"
            onClick={() => {
              console.log('ไปที่ชุดข้อสอบ');
            }}
            startIcon={<CallMadeIcon />}
          >
            {'ไปที่ชุดข้อสอบ'}
          </Button>
        }
      >
        {'เพิ่ม 2 คำถามไปที่ “ชุดข้อสอบ” แล้ว'}
      </BaseAlert>
    </Box>
  );
};

export default Alert;
