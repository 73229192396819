import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchReportIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 180 180" sx={{ width: 180, height: 180 }} {...props}>
    <g clipPath="url(#clip0_2055_69338)">
      <path
        d="M90 160C128.66 160 160 128.66 160 90C160 51.3401 128.66 20 90 20C51.3401 20 20 51.3401 20 90C20 128.66 51.3401 160 90 160Z"
        fill="#E9ECEF"
      />
      <path
        d="M17.92 146.18C22.4929 146.18 26.2 142.473 26.2 137.9C26.2 133.327 22.4929 129.62 17.92 129.62C13.3471 129.62 9.64 133.327 9.64 137.9C9.64 142.473 13.3471 146.18 17.92 146.18Z"
        fill="#E9ECEF"
      />
      <path
        d="M156.47 36.85C159.8 36.85 162.5 34.1503 162.5 30.82C162.5 27.4898 159.8 24.79 156.47 24.79C153.14 24.79 150.44 27.4898 150.44 30.82C150.44 34.1503 153.14 36.85 156.47 36.85Z"
        fill="#E9ECEF"
      />
      <path
        d="M160 17.01C162.496 17.01 164.52 14.9863 164.52 12.49C164.52 9.99364 162.496 7.96997 160 7.96997C157.504 7.96997 155.48 9.99364 155.48 12.49C155.48 14.9863 157.504 17.01 160 17.01Z"
        fill="#E9ECEF"
      />
      <g filter="url(#filter0_dd_2055_69338)">
        <path
          d="M138.77 58.55V141.72C138.77 144.372 137.716 146.916 135.841 148.791C133.966 150.666 131.422 151.72 128.77 151.72H51.23C48.5778 151.72 46.0343 150.666 44.1589 148.791C42.2835 146.916 41.23 144.372 41.23 141.72V30C41.23 27.3478 42.2835 24.8043 44.1589 22.9289C46.0343 21.0536 48.5778 20 51.23 20H100.37C102.949 19.9996 105.422 21.0208 107.25 22.84L135.82 51.42C137.709 53.312 138.77 55.8764 138.77 58.55Z"
          fill="url(#paint0_linear_2055_69338)"
        />
        <path
          d="M138.43 55.99C138.176 55.5042 137.794 55.0973 137.325 54.8135C136.856 54.5297 136.318 54.3797 135.77 54.38H114.4C111.748 54.38 109.204 53.3264 107.329 51.4511C105.453 49.5757 104.4 47.0322 104.4 44.38V24C104.4 22.9391 103.978 21.9217 103.228 21.1716C102.478 20.4214 101.461 20 100.4 20C102.964 20.01 105.419 21.0347 107.23 22.85L135.8 51.43C137.068 52.6878 137.976 54.2624 138.43 55.99Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126 82.86H54.5298C53.7014 82.86 53.0298 83.5316 53.0298 84.36C53.0298 85.1884 53.7014 85.86 54.5298 85.86H126C126.828 85.86 127.5 85.1884 127.5 84.36C127.5 83.5316 126.828 82.86 126 82.86Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126 92.34H54.5298C53.7014 92.34 53.0298 93.0115 53.0298 93.84C53.0298 94.6684 53.7014 95.34 54.5298 95.34H126C126.828 95.34 127.5 94.6684 127.5 93.84C127.5 93.0115 126.828 92.34 126 92.34Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M97.7698 101.82H54.2098C53.5581 101.82 53.0298 102.348 53.0298 103V103.64C53.0298 104.292 53.5581 104.82 54.2098 104.82H97.7698C98.4215 104.82 98.9498 104.292 98.9498 103.64V103C98.9498 102.348 98.4215 101.82 97.7698 101.82Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126 49.86H93.05C92.2216 49.86 91.55 50.5316 91.55 51.36C91.55 52.1884 92.2216 52.86 93.05 52.86H126C126.828 52.86 127.5 52.1884 127.5 51.36C127.5 50.5316 126.828 49.86 126 49.86Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126 56.35H93.05C92.2216 56.35 91.55 57.0215 91.55 57.85C91.55 58.6784 92.2216 59.35 93.05 59.35H126C126.828 59.35 127.5 58.6784 127.5 57.85C127.5 57.0215 126.828 56.35 126 56.35Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M112.44 62.59H92.83C92.1231 62.59 91.55 63.163 91.55 63.87V64.31C91.55 65.0169 92.1231 65.59 92.83 65.59H112.44C113.147 65.59 113.72 65.0169 113.72 64.31V63.87C113.72 63.163 113.147 62.59 112.44 62.59Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M69.31 39.6699C66.091 39.668 62.9436 40.6203 60.2657 42.4067C57.5878 44.193 55.4996 46.7331 54.265 49.706C53.0304 52.6789 52.7048 55.951 53.3294 59.1088C53.9541 62.2667 55.5008 65.1685 57.7742 67.4475C60.0476 69.7265 62.9456 71.2803 66.1019 71.9127C69.2582 72.5451 72.5311 72.2275 75.507 71.0002C78.4829 69.7729 81.0281 67.691 82.8211 65.0175C84.614 62.344 85.5741 59.1989 85.58 55.9799C85.5839 53.8403 85.1661 51.7209 84.3503 49.7429C83.5346 47.7649 82.3369 45.9672 80.8259 44.4524C79.3148 42.9376 77.5199 41.7355 75.544 40.9149C73.568 40.0943 71.4496 39.6712 69.31 39.6699ZM69.31 66.6699C67.1816 66.6798 65.0983 66.0571 63.3244 64.8808C61.5506 63.7044 60.1663 62.0276 59.3472 60.0631C58.5281 58.0986 58.3112 55.935 58.7241 53.847C59.1369 51.759 60.1608 49.8407 61.6658 48.3357C63.1708 46.8307 65.0891 45.8068 67.1771 45.394C69.2651 44.9812 71.4287 45.1981 73.3932 46.0171C75.3577 46.8362 77.0345 48.2205 78.2108 49.9944C79.3871 51.7682 80.0099 53.8515 80 55.9799C80 58.8197 78.8754 61.5439 76.872 63.5566C74.8687 65.5693 72.1497 66.7067 69.31 66.7199V66.6699Z"
          fill="currentColor"
        />
        <path
          opacity="0.8"
          d="M85.5801 55.9799H80.0001C79.9974 53.1419 78.8716 50.4202 76.8686 48.4097C74.8655 46.3991 72.1481 45.2631 69.3101 45.2499V39.6699C71.4497 39.6712 73.568 40.0943 75.544 40.9149C77.52 41.7355 79.3148 42.9376 80.8259 44.4524C82.337 45.9671 83.5346 47.7649 84.3504 49.7429C85.1662 51.7209 85.584 53.8403 85.5801 55.9799Z"
          fill="currentColor"
        />
        <path
          d="M85.5801 55.98C85.5835 58.1183 85.1638 60.2362 84.3451 62.2116C83.5265 64.187 82.3251 65.9809 80.8101 67.49L76.8901 63.57C77.8831 62.5722 78.6691 61.388 79.2028 60.0854C79.7366 58.7829 80.0075 57.3876 80.0001 55.98H85.5801Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M58.55 131.67H63.45C63.8611 131.67 64.2553 131.743 64.546 131.874C64.8367 132.005 65 132.183 65 132.368V138.67H57V132.368C57 132.183 57.1633 132.005 57.454 131.874C57.7447 131.743 58.1389 131.67 58.55 131.67Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M73.8 117.67H78.2C78.6774 117.67 79.1352 117.859 79.4728 118.197C79.8104 118.534 80 118.991 80 119.468V138.67H72V119.468C72 118.991 72.1896 118.534 72.5272 118.197C72.8648 117.859 73.3226 117.67 73.8 117.67Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M89 123.67H93C93.5304 123.67 94.0391 123.792 94.4142 124.009C94.7893 124.226 95 124.52 95 124.827V138.67H87V124.827C87 124.52 87.2107 124.226 87.5858 124.009C87.9609 123.792 88.4696 123.67 89 123.67Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M104 117.67H108C108.53 117.67 109.039 117.841 109.414 118.145C109.789 118.448 110 118.861 110 119.29V138.67H102V119.29C102 118.861 102.211 118.448 102.586 118.145C102.961 117.841 103.47 117.67 104 117.67Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M119 112.67H123C123.53 112.67 124.039 112.881 124.414 113.256C124.789 113.631 125 114.139 125 114.67V138.59H117V114.67C117 114.139 117.211 113.631 117.586 113.256C117.961 112.881 118.47 112.67 119 112.67Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M127.5 138.95H53C52.8674 138.95 52.7402 138.897 52.6464 138.803C52.5527 138.71 52.5 138.583 52.5 138.45V114.67C52.5 114.537 52.5527 114.41 52.6464 114.316C52.7402 114.223 52.8674 114.17 53 114.17C53.1326 114.17 53.2598 114.223 53.3536 114.316C53.4473 114.41 53.5 114.537 53.5 114.67V137.97H127.5C127.633 137.97 127.76 138.023 127.854 138.116C127.947 138.21 128 138.337 128 138.47C127.995 138.599 127.94 138.721 127.847 138.811C127.753 138.9 127.629 138.95 127.5 138.95Z"
          fill="currentColor"
        />
      </g>
      <g filter="url(#filter1_d_2055_69338)">
        <path
          d="M157.11 161.496L130.42 134.806C135.215 128.301 137.521 120.294 136.922 112.235C136.322 104.176 132.855 96.5988 127.15 90.8756C122.582 86.3098 116.799 83.1512 110.489 81.7749C104.178 80.3986 97.6054 80.8625 91.5508 83.1115C85.4962 85.3604 80.2143 89.2999 76.3326 94.4621C72.4508 99.6242 70.1323 105.792 69.6524 112.233C69.1725 118.674 70.5513 125.117 73.6252 130.798C76.6991 136.478 81.3388 141.157 86.9932 144.278C92.6477 147.4 99.0792 148.832 105.524 148.407C111.969 147.981 118.156 145.714 123.35 141.876L150 168.566C150.938 169.503 152.209 170.03 153.535 170.03C154.861 170.03 156.133 169.503 157.07 168.566C158.013 167.633 158.547 166.365 158.554 165.039C158.562 163.713 158.042 162.439 157.11 161.496ZM86.4902 131.496C83.1691 128.174 80.9075 123.942 79.9913 119.335C79.0752 114.728 79.5457 109.953 81.3434 105.613C83.1411 101.274 86.1852 97.5649 90.0908 94.9554C93.9964 92.3458 98.5881 90.953 103.285 90.953C107.982 90.953 112.574 92.3458 116.48 94.9554C120.385 97.5649 123.429 101.274 125.227 105.613C127.025 109.953 127.495 114.728 126.579 119.335C125.663 123.942 123.401 128.174 120.08 131.496C115.628 135.948 109.591 138.453 103.294 138.461C96.9965 138.468 90.9535 135.978 86.4902 131.536V131.496Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_2055_69338"
        x="26.23"
        y="14"
        width="127.54"
        height="164.72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.835294 0 0 0 0 0.866667 0 0 0 0 0.917647 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69338" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.835294 0 0 0 0 0.866667 0 0 0 0 0.917647 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2055_69338"
          result="effect2_dropShadow_2055_69338"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2055_69338"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2055_69338"
        x="54.5591"
        y="78"
        width="118.995"
        height="119.03"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69338" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69338"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2055_69338"
        x1="8819.83"
        y1="3281.39"
        x2="8819.83"
        y2="20631.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_2055_69338">
        <rect width="180" height="180" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SearchReportIcon;
