import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import { styled, ListItemButton as MUIListItemButton, ListItemIcon } from '@mui/material';

import { Text, Flex } from '../../Components/Base';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  link: string;
};

const Link = styled(RouterLink)({
  flex: 1,
  textDecoration: 'none',
});

const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  padding: 0,
  borderRadius: '8px',
  '&:not(:first-child)': {
    marginTop: theme.spacing(1),
  },
}));

const MenuItem = (props: MenuItemProps) => {
  const { icon, link, label } = props;

  const target = useResolvedPath(link);
  // If not match with user's current or nested route, route's match object will be null.
  const targetPageMatch = useMatch({ path: `${target.pathname}` });
  const targetOrNestedPageMatch = useMatch({ path: `${target.pathname}/*` });

  const isAlreadyOnTargetPage = !!targetPageMatch;
  const isAlreadyOnTargetOrNestedPage = !!targetOrNestedPageMatch;

  const LabelIconItem = (
    <Flex flexDirection={'row'} alignItems={'center'} py={1.25} px={2}>
      <ListItemIcon
        sx={{
          color: isAlreadyOnTargetOrNestedPage ? 'primary.main' : 'grey.600',
          minWidth: 0,
          mr: 2,
        }}
      >
        {icon}
      </ListItemIcon>
      <Text
        variant={'body1'}
        fontWeight={isAlreadyOnTargetOrNestedPage ? 600 : 'inherit'}
        color={isAlreadyOnTargetOrNestedPage ? 'primary.main' : 'grey.600'}
        sx={{ textDecoration: 'none' }}
      >
        {label}
      </Text>
    </Flex>
  );

  return (
    <ListItemButton selected={isAlreadyOnTargetOrNestedPage}>
      {isAlreadyOnTargetPage ? LabelIconItem : <Link to={link}>{LabelIconItem}</Link>}
    </ListItemButton>
  );
};

export default MenuItem;
