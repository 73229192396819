import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { FlexboxProps } from '@mui/system';

import { Box, BoxProps } from '../Box';

type FlexProps = BoxProps & FlexboxProps;

const Flex = styled(forwardRef((props: FlexProps, ref) => <Box ref={ref} {...props} />))`
  display: flex;
`;

export { Flex };
export type { FlexProps };
