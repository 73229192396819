import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import {
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  DataGridProps,
  GridSortDirection,
  GridOverlay,
} from '@mui/x-data-grid';
import { styled } from '@mui/material';
import queryString from 'query-string';

import { Alert, Box, DataGrid as BaseDataGrid, Text } from '../../../../Components/Base';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import CheckCircleIcon from '../../../../Components/Icons/CheckCircleIcon';
import { QBTagCategory } from '../../../../Domains/ExaminationManagement/useGetQBTags';
import EmptySearchResult from '../../../../Components/EmptySearchResult';
import BaseHTMLView from '../../../../Components/HTMLView';
import {
  useExaminationQuestion,
  ExaminationContext,
  useExaminationContext,
} from '../../../../Contexts/ExaminationProvider';
import useGetPublishedQuestions, {
  Question,
  QuestionOrder,
  QBQuestion,
} from '../../Domains/useGetPublishedQuestions';
import useGetDifficultyTags from '../../../../Domains/ExaminationManagement/useGetQBTags/useGetQBDifficultyTags';
import useGetSubsectionTags from '../../../../Domains/ExaminationManagement/useGetQBTags/useGetQBSubsectionTags';
import PreviewQuestionDetailsButton from '../../Components/PreviewQuestionDetailsButton';
import ExaminationAddQuestionButton from '../ExaminationAddQuestionButton';
import GoToFinalizationPageButton from '../GoToFinalizationPageButton';
import { QueryParamsDataGrid } from '../../../../Domains/ExaminationManagement/constant';
import { QMSTagCategory } from '../../../../Domains/ExaminationManagement/useGetQMSTags';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import Loading, { LoadingState } from '../../../../Components/Base/Loading/Loading';
import { getSearchTags, updateQuestionSelected } from '../utils';

const HTMLView = styled(BaseHTMLView)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const DataGrid = styled(BaseDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    alignItems: 'baseline',
  },
  '& .MuiDataGrid-cellCheckbox': {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  '.question-cell': {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '.indicator-cell': {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '.bloom-cell': {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '.difficulty-cell': {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '& .MuiDataGrid-row.Mui-disabled': {
    background: theme.palette.grey[50],
    cursor: 'default',
  },
}));

const mapQuestionList = (questions: QBQuestion[]) => {
  const questionList = questions.map((question) => {
    const { tags } = question;
    const bloom = tags.find((tag) => tag.category === QBTagCategory.Bloom);
    const difficulty = tags.find((tag) => tag.category === QBTagCategory.Difficulty);
    const indicator = tags.find((tag) => tag.category === QBTagCategory.Indicator);

    return {
      ...question,
      bloom,
      difficulty,
      indicator,
    };
  });
  return questionList;
};

const QUESTIONS_COLUMN: GridColumns = [
  { field: 'id', hide: true },
  {
    field: 'question',
    headerName: 'คำถาม',
    minWidth: 200,
    flex: 1,
    hideable: false,
    cellClassName: 'question-cell',
    renderCell: (item: GridRenderCellParams) => {
      return <HTMLView rawContent={item.value} />;
    },
  },
  {
    field: 'indicator',
    headerName: 'ตัวชี้วัด',
    width: 110,
    sortable: false,
    cellClassName: 'indicator-cell',
    renderCell: (item: GridRenderCellParams) => {
      return (
        <Text variant={'body1'}>
          {item.value?.name ? item.value.name.substring(0, 11).trim() : '-'}
        </Text>
      );
    },
  },
  {
    field: 'bloom',
    headerName: 'Bloom',
    width: 115,
    sortable: false,
    cellClassName: 'bloom-cell',
    renderCell: (item: GridRenderCellParams) => {
      return <Text variant={'body1'}>{item.value?.name ? item.value.name : '-'}</Text>;
    },
  },
  {
    field: 'difficulty',
    headerName: 'ระดับความยาก',
    width: 130,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    cellClassName: 'difficulty-cell',
    renderCell: (item: GridRenderCellParams) => (
      <Text variant={'body1'}>{item.value?.name ? item.value.name : '-'}</Text>
    ),
  },
  {
    field: 'actions',
    type: 'actions',
    width: 140,
    getActions: (item: GridRowParams) => [<PreviewQuestionDetailsButton question={item.row} />],
  },
];

const ExaminationQuestionTable = () => {
  const { examId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const fieldKey = searchParams.get('field') ?? undefined;
  const sortKey = (searchParams.get('sort') ?? null) as GridSortDirection;
  const pageKey = searchParams.get('page') ?? 0;
  const pageSizeKey = searchParams.get('pageSize') ?? 10;

  const currentPageSize = Number(pageSizeKey);
  const currentPage = Number(pageKey);
  const currentSort = sortKey === 'asc' ? 'Asc' : 'Desc';

  const { data: difficultyTags } = useGetDifficultyTags();
  const { data: subsectionTags } = useGetSubsectionTags();

  const subsectionName = useMemo(() => {
    const subsectionId = searchParams.get('learningUnit');
    const subsectionData = subsectionTags?.tags?.find((tag) => tag.id === subsectionId);
    return subsectionData?.name;
  }, [searchParams, subsectionTags]);

  const { data: examination } = useGetExamination({ variables: { id: examId } });
  const { tags } = examination?.qmsExam ?? {};
  const subjectTag = tags?.find((value) => value.category === QMSTagCategory.Subject)?.id;
  const gradeTag = tags?.find((value) => value.category === QMSTagCategory.Grade)?.id;

  const searchTags = useMemo(
    () => getSearchTags(searchParams, difficultyTags?.tags ?? [], subjectTag ?? '', gradeTag ?? ''),
    [difficultyTags, searchParams, subjectTag, gradeTag],
  );

  const previousQuestions = useRef<QBQuestion[]>([]);
  const {
    data: questionListData,
    loading: questionListLoading,
    error: questionListError,
  } = useGetPublishedQuestions({
    onCompleted: (data) => {
      const questions = data.qbPublishedQuestions.questions;

      if (!questions) return;

      if (previousQuestions.current.length === 0) {
        previousQuestions.current.push(...questions);
        return;
      }

      const questionIds = previousQuestions.current.map((question) => question.id);
      const preUpdatedCache = questions.filter((question) => !questionIds.includes(question.id));
      previousQuestions.current.push(...preUpdatedCache);
    },
    variables: {
      search: {
        type: Question.MultipleChoice,
        tags: searchTags.data,
      },
      paging: {
        pageSize: currentPageSize,
        currentPage: currentPage,
      },
      order: fieldKey
        ? {
            field: fieldKey,
            type: QuestionOrder[currentSort],
          }
        : undefined,
    },
  });

  const questions = useMemo(
    () => mapQuestionList(questionListData?.qbPublishedQuestions.questions ?? []),
    [questionListData],
  );
  const questionTotal = questionListData?.qbPublishedQuestions.total ?? 0;

  const [questionSelected = [], setExaminationQuestionSelected, defaultStore] =
    useExaminationQuestion();
  const questionSelectedIds = questionSelected?.map((item) => item.id) ?? [];
  const prevQuestionSelected = defaultStore?.questionSelected ?? [];
  const preUpdateQuestions = questionSelected.filter(
    (selectedQuestion) =>
      !prevQuestionSelected.find((question) => question.id === selectedQuestion.id),
  );
  const updatedCountRef = useRef<number | null>(null);
  useEffect(
    () =>
      useExaminationContext.subscribe((state: ExaminationContext) => {
        const preUpdateCount = state?.questionSelected?.length ?? 0;
        const initialCount = state?.defaultState?.questionSelected?.length ?? 0;
        const diff = Math.abs(preUpdateCount - initialCount);
        if (diff === 0) return;
        updatedCountRef.current = diff;
      }),
    [],
  );

  const handleModelChange: DataGridProps['onSelectionModelChange'] = (selectionModel) => {
    updatedCountRef.current = null;

    const questionsSelected = updateQuestionSelected(
      prevQuestionSelected,
      previousQuestions.current,
      selectionModel as string[],
    );

    setExaminationQuestionSelected(questionsSelected);
  };

  const setSearchQueryParams = (dataQuery: QueryParamsDataGrid) => {
    const pageValue = dataQuery.currentPage;
    const pageSizeValue = dataQuery.currentPageSize;
    const fieldValue = dataQuery.sortField;
    const sortValue = dataQuery.sortOrder;

    const page = pageValue ?? currentPage;
    const pageSize = pageSizeValue ?? currentPageSize;
    const field = pageValue || pageValue === 0 || pageSizeValue ? fieldKey : fieldValue;
    const sort = pageValue || pageValue === 0 || pageSizeValue ? sortKey : sortValue;

    const stringify = queryString.stringify({ page, pageSize, field, sort });
    setSearchParams(searchTags.queryString + '&' + stringify);
  };

  if (questionListError) {
    return (
      <Alert
        data-testid="question-list-error-alert-notice"
        color={'error'}
        icon={<InfoIcon fontSize="small" sx={{ color: 'error.main' }} />}
      >
        <Text color={'error.darker'}>พบข้อผิดพลาดในการค้นหา</Text>
      </Alert>
    );
  }

  return (
    <>
      <Text data-testid="search-filter-result" variant={'h4'}>{`ผลการค้นหา${
        subsectionName ? ` "${subsectionName}" ` : ' '
      }(${questionTotal})`}</Text>
      <Box mt={2} />
      {preUpdateQuestions.length === 0 ? (
        updatedCountRef.current ? (
          <Alert
            data-testid="add-question-success-alert-notice"
            key={updatedCountRef.current}
            color={'success'}
            icon={<CheckCircleIcon fontSize="small" sx={{ color: 'success.main' }} />}
            action={<GoToFinalizationPageButton />}
          >
            <Text color={'success.darker'}>
              เพิ่ม {updatedCountRef.current} คำถามไปที่ “ชุดข้อสอบ” แล้ว
            </Text>
          </Alert>
        ) : (
          questionTotal > 0 && (
            <Alert
              data-testid="has-search-filter-alert-notice"
              color={'info'}
              icon={<InfoIcon fontSize="small" sx={{ color: 'info.main' }} />}
            >
              <Text color={'info.darker'}>
                {'เลือกคำถามด้านล่าง เพื่อเพิ่มไปยัง '}
                <Text component="span" fontWeight="600">
                  “ชุดข้อสอบ”
                </Text>
              </Text>
            </Alert>
          )
        )
      ) : (
        <Alert
          data-testid="question-selected-alert-notice"
          color="info"
          action={<ExaminationAddQuestionButton />}
        >
          <Text color={'info.darker'}>{`มี ${preUpdateQuestions.length} คำถามที่ถูกเลือก`}</Text>
        </Alert>
      )}
      <Box
        data-testid="examination-question-search-result-table"
        mt={2}
        height={questionTotal === 0 ? '450px' : 'auto'}
      >
        <DataGrid
          rows={questions}
          columns={QUESTIONS_COLUMN}
          rowHeight={152}
          checkboxSelection
          components={{
            NoRowsOverlay: () => (
              <EmptySearchResult
                title={'ยังไม่พบคำถามที่คุณค้นหา'}
                description={'ลองปรับเปลี่ยนตัวกรอง เพื่อค้นหาอีกครั้ง'}
              />
            ),
            LoadingOverlay: () => (
              <GridOverlay>
                <Loading variant={LoadingState.FLAT} mb={4} />
              </GridOverlay>
            ),
          }}
          autoHeight={questionTotal > 0}
          hideFooter={questionTotal === 0}
          initialState={{
            sorting: {
              sortModel: fieldKey ? [{ field: fieldKey ?? '', sort: sortKey }] : undefined,
            },
          }}
          selectionModel={questionSelectedIds}
          onSelectionModelChange={handleModelChange}
          isRowSelectable={(params) =>
            !prevQuestionSelected.find((question) => question.id === params.row.id)
          }
          getRowClassName={(params) =>
            prevQuestionSelected.find((question) => question.id === params.row.id)
              ? 'Mui-disabled'
              : ''
          }
          keepNonExistentRowsSelected
          loading={questionListLoading}
          disableColumnFilter
          paginationMode="server"
          rowCount={questionTotal}
          page={currentPage}
          pageSize={currentPageSize}
          onPageChange={(page) => setSearchQueryParams({ currentPage: page })}
          onPageSizeChange={(pageSize) => setSearchQueryParams({ currentPageSize: pageSize })}
          sortingMode="server"
          onSortModelChange={(sorting) => {
            const field = sorting[0]?.field;
            const sort = sorting[0]?.sort ?? undefined;
            setSearchQueryParams({ sortField: field, sortOrder: sort });
          }}
        />
      </Box>
    </>
  );
};

export default ExaminationQuestionTable;
