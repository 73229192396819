import { useController } from 'react-hook-form';

import SelectionInput from '../../Base/Input/SelectionInput';

import { FormSelectionInputProps, FieldValues } from './types';

const FormTextInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormSelectionInputProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <SelectionInput
      {...field}
      {...props}
      type={props?.type ?? 'text'}
      error={isTouched ? invalid : false}
      helperText={error?.message ?? null}
    />
  );
};

export type { FormSelectionInputProps };
export default FormTextInput;
