import { useParams } from 'react-router-dom';

import { Button, Modal, ModalTitle, ModalContent, ModalFooter } from '../../../Components/Base';
import { ResponseType } from '../../../Domains/ExaminationManagement/constant';
import usePublishExamination from '../../../Domains/ExaminationManagement/usePublishExamination';
import useDisclosure from '../../../Utils/useDisclosure';
import { useEditableExamination } from '../../../Contexts/ExaminationProvider';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';
import { isExaminationEqualWithStore } from '../utils';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการเผยแพร่',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'เผยแพร่เรียบร้อยแล้ว',
};

const PublishExamButton = () => {
  const { examId = '' } = useParams();
  const { open } = useSnackbar();
  const {
    isOpen: isConfirmModalOpen,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useDisclosure();
  const editableExamination = useEditableExamination();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
    skip: !examId,
  });
  const [publishExamMutation, { loading: publishExamLoading }] = usePublishExamination({
    onCompleted: () => {
      closeConfirmModal();
      open(RESPONSE_MESSAGE.SUCCESS, 'success');
    },
    onError: (error) => {
      closeConfirmModal();
      console.error(error);
      //TODO: handle all error message after connect learn auth
      let message = RESPONSE_MESSAGE.UNEXPECTED;
      if (error.message === 'Failed to fetch') message = RESPONSE_MESSAGE.NETWORK_ERROR;
      open(message, 'error');
    },
  });

  const handlePublishExam = async () => {
    if (!examId) return;
    await publishExamMutation({
      variables: {
        id: examId,
      },
    });
  };

  const remoteExamination = examination?.qmsExam;
  const isExaminationUpToDate = remoteExamination
    ? isExaminationEqualWithStore(remoteExamination, editableExamination)
    : true;
  const hasQuestions = (remoteExamination?.totalQuestions ?? 0) >= 1;

  return (
    <>
      <Button
        data-testid="publish-exam-button"
        color={'success'}
        onClick={openConfirmModal}
        disabled={!isExaminationUpToDate || !hasQuestions}
        sx={{ minWidth: '130px' }}
      >
        {'เผยแพร่'}
      </Button>
      <Modal open={isConfirmModalOpen}>
        <ModalTitle sx={{ p: 3, pb: 4 }}>{'เผยแพร่ชุดข้อสอบ'}</ModalTitle>
        <ModalContent sx={{ pb: 0 }}>
          {'หากกดเผยแพร่ชุดข้อสอบแล้ว จะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก'}
        </ModalContent>
        <ModalFooter sx={{ justifyContent: 'flex-end', p: 3, pt: 4 }}>
          <Button
            data-testid="cancel-button"
            onClick={closeConfirmModal}
            variant={'outlined'}
            color="neutral"
            sx={{ width: { xs: 1, sm: 'auto' } }}
            size="large"
          >
            {'ยกเลิก'}
          </Button>
          <Button
            data-testid="publish-button"
            onClick={handlePublishExam}
            sx={{ width: { xs: 1, sm: 'auto' }, ml: 2 }}
            size="large"
          >
            {publishExamLoading ? 'กำลังเผยแพร่...' : 'เผยแพร่'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PublishExamButton;
