import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { Box } from '../../Components/Base';
import { ExaminationNotFound } from '../../Components/Examination';
import ScrollToTop from '../../Containers/ScrollToTop';
import { EXAMINATION_EXAMINATION_QUESTIONS_PATH } from '../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';
import { useExaminationQuestion } from '../../Contexts/ExaminationProvider';

import EmptyQuestionList from './Components/EmptyQuestionList';
import QuestionList from './Containers/QuestionList';
import ExamSummary from './Containers/ExamSummary';

function ExaminationFinalization() {
  const { examId = '' } = useParams();
  const navigate = useNavigate();
  const [questionSelected = []] = useExaminationQuestion();
  const { data: examination, loading: examinationLoading } = useGetExamination({
    variables: { id: examId },
  });

  if (examinationLoading) {
    return <Skeleton variant="rectangular" height={400} sx={{ borderRadius: '8px' }} />;
  }

  if (!examination) {
    return <ExaminationNotFound mt={8} />;
  }

  const totalQuestions = examination?.qmsExam.totalQuestions ?? 0;
  if (totalQuestions === 0 || questionSelected.length === 0) {
    return (
      <EmptyQuestionList
        onGoBackExamArchive={() => {
          if (!examId) return;
          navigate(EXAMINATION_EXAMINATION_QUESTIONS_PATH.replace(':examId', examId));
        }}
      />
    );
  }

  return (
    <>
      <Box
        data-testid="examination-finalization-page"
        width={1}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'minmax(0px, 1fr)', md: '3fr minmax(250px, 1fr)' },
          gridTemplateAreas: {
            xs: `"exam-summary"
          "question-list"`,
            md: '"question-list exam-summary"',
          },
          gap: { xs: 0, md: 2 },
        }}
      >
        <QuestionList gridArea={'question-list'} mt={{ xs: 2, md: 0 }} />
        <ExamSummary gridArea={'exam-summary'} />
      </Box>
      <ScrollToTop />
    </>
  );
}

export default ExaminationFinalization;
