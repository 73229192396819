import { forwardRef, Ref } from 'react';
import { styled, Alert as MUIAlert, AlertProps as MUIAlertProps } from '@skooldio/qms-ui';

import { Text } from '../Text';

type AlertProps = {
  children?: React.ReactNode | string;
} & MUIAlertProps;

const StyledMUIAlert = styled(MUIAlert)(({ theme }) => ({
  '& .MuiAlert-message': {
    color: theme.palette.text.primary,
  },
  '& .MuiAlert-action': {
    paddingTop: theme.spacing(0.25),
  },
}));

const Alert = forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => {
  const { children, action, icon, color, sx, ...restProps } = props;

  return (
    <StyledMUIAlert
      ref={ref}
      sx={{
        backgroundColor: color ? `${color}.lighter` : 'grey.100',
        borderRadius: 2,
        ...sx,
      }}
      icon={icon || false}
      action={action}
      {...restProps}
    >
      {typeof children === 'string' ? (
        <Text variant="body1" fontWeight={'400'} color={color ? `${color}.darker` : 'text.primary'}>
          {children}
        </Text>
      ) : (
        children
      )}
    </StyledMUIAlert>
  );
});

export default Alert;
export type { AlertProps };
