import { useState } from 'react';

import { Tabs as BaseTabs, TabsProps, Tab } from '../../../../Components/Base';
import PeopleIcon from '../../../../Components/Icons/PeopleIcon';

const itemLists = [
  {
    label: 'Item one',
    value: 'one',
  },
  {
    label: 'Item two',
    value: 'two',
  },
  {
    label: 'Item three',
    value: 'three',
  },
];

const Tabs = () => {
  const [activeTab, setActiveTab] = useState<string>('one');

  const handleChange: TabsProps['onChange'] = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <BaseTabs onChange={handleChange} value={activeTab}>
      {itemLists?.map((item, index): JSX.Element => {
        return (
          <Tab
            key={index}
            value={item.value}
            label={item.label}
            icon={<PeopleIcon fontSize="small" />}
            iconPosition="start"
          />
        );
      })}
    </BaseTabs>
  );
};

export default Tabs;
