import {
  styled,
  TablePagination as MUITablePagination,
  TablePaginationProps as MUITablePaginationProps,
  TableCellProps,
} from '@mui/material';

import { Box } from '../Box';

type TablePaginationProps = MUITablePaginationProps & TableCellProps;

const StyledTablePagination = styled(MUITablePagination)(({ theme }) => ({
  borderBottom: 'none',
  '& .MuiTablePagination-select': {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: 14,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiTablePagination-actions': {
    fontSize: 32,
  },
}));

const defaultLabelDisplayedRows = (props: { from: number; to: number; count: number }) => {
  const { from, to, count } = props;
  return `${from}–${to} จาก ${count !== -1 ? count : `more than ${to}`}`;
};

const TablePagination = (props: TablePaginationProps) => {
  return (
    <StyledTablePagination
      component={Box}
      rowsPerPageOptions={[10, 20, 50, 100]}
      labelRowsPerPage={'แสดงแถว'}
      labelDisplayedRows={defaultLabelDisplayedRows}
      showFirstButton
      showLastButton
      {...props}
    />
  );
};

export default TablePagination;
export type { TablePaginationProps };
