import { GridRenderCellParams } from '@mui/x-data-grid';

import { DataGrid as BaseDataGrid, Text, Button } from '../../../../Components/Base';

const rows = [
  {
    id: 1,
    question: 'ข้อใดต่อไปนี้เป็นจริง',
    indicator: 'ค 1.1 ม.1/3',
    bloom: 'ความเข้าใจ',
    level: 'ง่าย',
  },
  {
    id: 2,
    question: 'อัตราส่วนในข้อใดเป็นอัตราส่วนที่เท่ากับอัตราส่วน 9 : 1',
    indicator: 'ค 1.1 ม.1/3',
    bloom: 'การนำไปใช้',
    level: 'ปานกลาง',
  },
];

const DataGridWithPagination = () => {
  const columns = [
    { field: 'id', hide: true },
    {
      field: 'question',
      headerName: 'คำถาม',
      flex: 1,
      renderCell: (item: GridRenderCellParams) => (
        <Text variant={'body1'} fontWeight={600}>
          {item.value}
        </Text>
      ),
    },
    { field: 'indicator', headerName: 'ตัวชี้วัด', width: 115 },
    { field: 'bloom', headerName: 'Bloom', width: 115 },
    { field: 'level', headerName: 'ระดับความยาก', width: 130 },
    {
      field: 'actions',
      type: 'actions',
      width: 120,
      getActions: () => [
        <Button variant={'outlined'} color="neutral">
          ดูโจทย์
        </Button>,
      ],
    },
  ];

  return (
    <>
      <Text variant="body2" fontWeight={600}>
        Data Grid With Pagination
      </Text>
      <BaseDataGrid rows={rows} columns={columns} checkboxSelection />
    </>
  );
};

export default DataGridWithPagination;
