import { Divider } from '@mui/material';

import { Box, BoxProps, Text } from '../../Base';

import ExaminationQuestionItem, { QuestionItemType, Variant } from './ExaminationQuestionItem';

type QuestionGroupProps = {
  questionItems: QuestionItemType[];
  title?: string;
  description?: string;
  variant?: Variant;
} & BoxProps;

const ExaminationQuestionGroup = ({
  questionItems,
  title,
  description,
  variant,
  ...restProps
}: QuestionGroupProps) => {
  return (
    <Box {...restProps}>
      {title && (
        <Text data-testid="title" variant="caption" component="p" fontWeight="600">
          {title}
        </Text>
      )}
      {description && (
        <Text data-testid="description" variant="caption" component="p" mt={title ? 1 : 0}>
          {description}
        </Text>
      )}
      {title && description && (
        <Divider sx={{ my: 2, borderColor: 'grey.400', borderBottomWidth: 'medium' }} />
      )}
      {questionItems.map((question, index) => (
        <ExaminationQuestionItem
          data-testid={`question-${index + 1}`}
          mt={index === 0 ? 0 : 2}
          key={question.order}
          sx={{ breakInside: 'avoid' }}
          questionItem={question}
          variant={variant}
        />
      ))}
    </Box>
  );
};

export { Variant };
export default ExaminationQuestionGroup;
