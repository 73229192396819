import { useNavigate } from 'react-router-dom';

import { IconButton, IconButtonProps } from '../../../Components/Base/Button';
import EditIcon from '../../../Components/Icons/EditIcon';
import { EXAMINATION_EXAMINATION_DETAIL_PATH } from '../../../Domains/ExaminationManagement/constant';

type EditExaminationButtonProps = {
  examId: string;
} & IconButtonProps;

const EditExaminationButton = (props: EditExaminationButtonProps) => {
  const { examId, ...buttonProps } = props;
  const navigate = useNavigate();

  const handleEditExamination = () => {
    if (!examId || typeof examId !== 'string') return;
    navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
  };

  return (
    <IconButton data-testid="edit-exam" {...buttonProps} onClick={handleEditExamination}>
      <EditIcon />
    </IconButton>
  );
};

export default EditExaminationButton;
