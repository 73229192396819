import React from 'react';
import { styled, Dialog as MUIDialog, DialogProps } from '@mui/material';

type ModalProps = {
  toggleComponent?: React.ReactNode;
} & DialogProps;

const Dialog = styled(MUIDialog)({
  '& .MuiDialog-paper': {
    maxWidth: '504px',
    width: '100%',
    borderRadius: '8px',
    boxShadow: 'none',
  },
});

const Modal = (props: ModalProps) => {
  const { children, open = false, toggleComponent, ...restProps } = props;
  return (
    <>
      {React.isValidElement(toggleComponent) && React.cloneElement(toggleComponent)}
      <Dialog disableEscapeKeyDown open={open} {...restProps}>
        {children}
      </Dialog>
    </>
  );
};

export default Modal;
export type { ModalProps };
