import orderBy from 'lodash/orderBy';

import { ExaminationContext } from '../../Contexts/ExaminationProvider';
import { Examination } from '../../Domains/ExaminationManagement/useGetExamination';

export const isExaminationEqualWithStore = (
  examinationDomain: Examination,
  examinationStore: Pick<ExaminationContext, 'examinationInformation' | 'questionSelected'>,
) => {
  const examNameDomain = examinationDomain.title;
  const examItems = orderBy(examinationDomain.examItems, 'order') ?? [];
  const questionSelectedDomain: ExaminationContext['questionSelected'] = [];
  examItems.forEach((item) => {
    const id = item.question.refQuestionId;
    const tags = item.question.tags;
    questionSelectedDomain.push({ id, tags, order: item.order ?? 1, point: item.score ?? 1 });
  });

  const examNameStore = examinationStore?.examinationInformation?.examName;
  const questionSelectedStore = orderBy(examinationStore.questionSelected, 'order') ?? [];

  const isEqualExamName = examNameDomain === examNameStore;
  const isEqualQuestionSelected = questionSelectedDomain.every(
    (questionDomain) =>
      !!questionSelectedStore.find(
        (questionStore) =>
          questionDomain.id === questionStore.id &&
          questionDomain.order === questionStore.order &&
          questionDomain.point === questionStore.point,
      ),
  );

  const isEqualExamination = isEqualExamName && isEqualQuestionSelected;

  return isEqualExamination;
};
