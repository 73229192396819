import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  logout: Scalars['Boolean'];
  qbCreateQuestion: QbQuestion;
  qbCreateTag: QbTag;
  qbDeleteQuestion: QbQuestion;
  qbDeprecateQuestion: QbQuestion;
  qbPublishQuestion: QbQuestion;
  qbUpdateQuestion: QbQuestion;
  qmsCreateExam: QmsExam;
  qmsCreateTag: QmsTag;
  qmsDeleteExam: QmsExam;
  qmsDeprecateExam: QmsExam;
  qmsDuplicateExam: QmsExam;
  qmsLeSyncSchoolGrades: QmsLeSchoolGradesResult;
  qmsLeSyncSubjects: QmsLeSubjectsResult;
  qmsPublishExam: QmsExam;
  qmsUpdateExam: QmsExam;
};

export type MutationQbCreateQuestionArgs = {
  input: QbQuestionCreateInput;
};

export type MutationQbCreateTagArgs = {
  input: QbTagCreateInput;
};

export type MutationQbDeleteQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationQbDeprecateQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationQbPublishQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationQbUpdateQuestionArgs = {
  id: Scalars['ID'];
  input: QbQuestionUpdateInput;
};

export type MutationQmsCreateExamArgs = {
  input: QmsExamCreateInput;
};

export type MutationQmsCreateTagArgs = {
  input: QmsTagCreateInput;
};

export type MutationQmsDeleteExamArgs = {
  id: Scalars['ID'];
};

export type MutationQmsDeprecateExamArgs = {
  id: Scalars['ID'];
};

export type MutationQmsDuplicateExamArgs = {
  id: Scalars['ID'];
};

export type MutationQmsLeSyncSchoolGradesArgs = {
  schoolCode: Scalars['ID'];
};

export type MutationQmsPublishExamArgs = {
  id: Scalars['ID'];
};

export type MutationQmsUpdateExamArgs = {
  id: Scalars['ID'];
  input: QmsExamUpdateInput;
};

export type QbOrderInput = {
  field: Scalars['String'];
  type: QbOrderType;
};

export enum QbOrderType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type QbPageInput = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QbPublishedQuestionsSearchInput = {
  question?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<QbQuestionSearchTagInput>>;
  type?: InputMaybe<QbQuestionType>;
};

export type QbQuestion = {
  __typename?: 'QBQuestion';
  correctAnswer?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  ownerCode?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  solution?: Maybe<Array<QbQuestionSolution>>;
  status: QbQuestionStatus;
  tags: Array<QbQuestionTag>;
  type: QbQuestionType;
};

export type QbQuestionCreateInput = {
  correctAnswer: Scalars['JSON'];
  metadata: Scalars['JSON'];
  ownerCode: Scalars['String'];
  question: Scalars['String'];
  solution: Array<QbQuestionSolutionInput>;
  tags: Array<QbQuestionTagInput>;
  type: QbQuestionType;
};

export type QbQuestionSearchTagInput = {
  category: QbTagCategory;
  id: Scalars['String'];
};

export type QbQuestionSolution = {
  __typename?: 'QBQuestionSolution';
  content: Scalars['String'];
  type: QbQuestionSolutionType;
};

export type QbQuestionSolutionInput = {
  content: Scalars['String'];
  type: QbQuestionSolutionType;
};

export enum QbQuestionSolutionType {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
  Youtube = 'YOUTUBE',
}

export enum QbQuestionStatus {
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type QbQuestionTag = {
  __typename?: 'QBQuestionTag';
  category: QbTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QbQuestionTagInput = {
  category: QbTagCategory;
  slug: Scalars['String'];
};

export enum QbQuestionType {
  FillInTheBlank = 'FILL_IN_THE_BLANK',
  FillNumber = 'FILL_NUMBER',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleSelect = 'MULTIPLE_SELECT',
}

export type QbQuestionUpdateInput = {
  correctAnswer?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  ownerCode?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  solution?: InputMaybe<Array<QbQuestionSolutionInput>>;
  tags?: InputMaybe<Array<QbQuestionTagInput>>;
  type?: InputMaybe<QbQuestionType>;
};

export type QbQuestionsResult = {
  __typename?: 'QBQuestionsResult';
  questions: Array<QbQuestion>;
  total: Scalars['Int'];
};

export type QbQuestionsSearchInput = {
  question?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<QbQuestionStatus>;
  tags?: InputMaybe<Array<QbQuestionSearchTagInput>>;
  type?: InputMaybe<QbQuestionType>;
};

export type QbTag = {
  __typename?: 'QBTag';
  category: QbTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum QbTagCategory {
  Bloom = 'BLOOM',
  CourseCode = 'COURSE_CODE',
  Difficulty = 'DIFFICULTY',
  ExamCampaign = 'EXAM_CAMPAIGN',
  ExamType = 'EXAM_TYPE',
  Grade = 'GRADE',
  Indicator = 'INDICATOR',
  Objective = 'OBJECTIVE',
  Owner = 'OWNER',
  QuestionExamType = 'QUESTION_EXAM_TYPE',
  Section = 'SECTION',
  Subject = 'SUBJECT',
  Subsection = 'SUBSECTION',
  Subtopic = 'SUBTOPIC',
  Topic = 'TOPIC',
}

export type QbTagCreateInput = {
  category: QbTagCategory;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QbTagQuestionInput = {
  category: QbTagCategory;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QbTagsResult = {
  __typename?: 'QBTagsResult';
  tags: Array<QbTag>;
  total: Scalars['Int'];
};

export type QbTagsSearchInput = {
  category?: InputMaybe<QbTagCategory>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum QmsDataSource {
  Qbank = 'QBANK',
  Qms = 'QMS',
}

export type QmsExam = {
  __typename?: 'QMSExam';
  createdAt: Scalars['DateTime'];
  defaultGeneratorType: QmsExamGeneratorType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  examItems: Array<QmsExamItem>;
  fullScore?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  ownerCode: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  status: QmsExamStatus;
  tags?: Maybe<Array<QmsExamTag>>;
  title: Scalars['String'];
  totalQuestions?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type QmsExamCreateInput = {
  defaultGeneratorType?: InputMaybe<QmsExamGeneratorType>;
  metadata?: InputMaybe<Scalars['JSON']>;
  tags?: InputMaybe<Array<QmsExamTagInput>>;
  title: Scalars['String'];
};

export enum QmsExamGeneratorType {
  Fixed = 'FIXED',
  FixedAll = 'FIXED_ALL',
  Random = 'RANDOM',
}

export type QmsExamItem = {
  __typename?: 'QMSExamItem';
  examId: Scalars['String'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  question: QmsQuestion;
  questionId: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
};

export type QmsExamResult = {
  __typename?: 'QMSExamResult';
  exams: Array<QmsExam>;
  total: Scalars['Int'];
};

export enum QmsExamStatus {
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type QmsExamTag = {
  __typename?: 'QMSExamTag';
  category: QmsTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QmsExamTagInput = {
  category: QmsTagCategory;
  slug: Scalars['String'];
};

export type QmsExamUpdateInput = {
  defaultGeneratorType?: InputMaybe<QmsExamGeneratorType>;
  metadata?: InputMaybe<Scalars['JSON']>;
  questions?: InputMaybe<Array<QmsExamUpdateQuestionInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type QmsExamUpdateQuestionInput = {
  refQuestionId: Scalars['String'];
  score: Scalars['Float'];
};

export type QmsExamsSearchInput = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<QmsExamStatus>;
  tagName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type QmsLeSchoolGrade = {
  __typename?: 'QMSLeSchoolGrade';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  schoolCode: Scalars['String'];
};

export type QmsLeSchoolGradesResult = {
  __typename?: 'QMSLeSchoolGradesResult';
  schoolGrades: Array<QmsLeSchoolGrade>;
  total: Scalars['Int'];
};

export type QmsLeSubject = {
  __typename?: 'QMSLeSubject';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type QmsLeSubjectsResult = {
  __typename?: 'QMSLeSubjectsResult';
  subjects: Array<QmsLeSubject>;
  total: Scalars['Int'];
};

export type QmsOrderInput = {
  field: Scalars['String'];
  type: QmsOrderType;
};

export enum QmsOrderType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type QmsPageInput = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QmsQuestion = {
  __typename?: 'QMSQuestion';
  correctAnswer?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  question: Scalars['String'];
  refQuestionId: Scalars['String'];
  refSource: Scalars['String'];
  solution?: Maybe<Array<QmsQuestionSolution>>;
  status: QmsQuestionStatus;
  tags: Array<QmsQuestionTag>;
  type: QmsQuestionType;
};

export type QmsQuestionSolution = {
  __typename?: 'QMSQuestionSolution';
  content: Scalars['String'];
  type: QmsQuestionSolutionType;
};

export enum QmsQuestionSolutionType {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
  Youtube = 'YOUTUBE',
}

export enum QmsQuestionStatus {
  Deprecated = 'DEPRECATED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type QmsQuestionTag = {
  __typename?: 'QMSQuestionTag';
  category: QmsTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum QmsQuestionType {
  FillInTheBlank = 'FILL_IN_THE_BLANK',
  FillNumber = 'FILL_NUMBER',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleSelect = 'MULTIPLE_SELECT',
}

export type QmsTag = {
  __typename?: 'QMSTag';
  category: QmsTagCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum QmsTagCategory {
  Bloom = 'BLOOM',
  CourseCode = 'COURSE_CODE',
  Difficulty = 'DIFFICULTY',
  ExamCampaign = 'EXAM_CAMPAIGN',
  ExamType = 'EXAM_TYPE',
  Grade = 'GRADE',
  Indicator = 'INDICATOR',
  Objective = 'OBJECTIVE',
  Owner = 'OWNER',
  QuestionExamType = 'QUESTION_EXAM_TYPE',
  Section = 'SECTION',
  Subject = 'SUBJECT',
  Subsection = 'SUBSECTION',
  Subtopic = 'SUBTOPIC',
  Topic = 'TOPIC',
}

export type QmsTagCreateInput = {
  category: QmsTagCategory;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QmsTagsResult = {
  __typename?: 'QMSTagsResult';
  tags: Array<QmsTag>;
  total: Scalars['Int'];
};

export type QmsTagsSearchInput = {
  category?: InputMaybe<QmsTagCategory>;
  name?: InputMaybe<Scalars['String']>;
  refSource?: InputMaybe<QmsDataSource>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getMyProfile: User;
  qbGetRemainTags: Array<QbTag>;
  qbPublishedQuestions: QbQuestionsResult;
  qbPublishedQuestionsByIds: Array<QbQuestion>;
  qbQuestion: QbQuestion;
  qbQuestions: QbQuestionsResult;
  qbQuestionsByIds: Array<QbQuestion>;
  qbTags: QbTagsResult;
  qmsExam: QmsExam;
  qmsExams: QmsExamResult;
  qmsLeSchoolGrades: QmsLeSchoolGradesResult;
  qmsLeSubjects: QmsLeSubjectsResult;
  qmsTags: QmsTagsResult;
};

export type QueryQbGetRemainTagsArgs = {
  search: QbTagQuestionInput;
};

export type QueryQbPublishedQuestionsArgs = {
  order?: InputMaybe<QbOrderInput>;
  paging?: InputMaybe<QbPageInput>;
  search?: InputMaybe<QbPublishedQuestionsSearchInput>;
};

export type QueryQbPublishedQuestionsByIdsArgs = {
  questionIds: Array<Scalars['ID']>;
};

export type QueryQbQuestionArgs = {
  id: Scalars['ID'];
};

export type QueryQbQuestionsArgs = {
  order?: InputMaybe<QbOrderInput>;
  paging?: InputMaybe<QbPageInput>;
  search?: InputMaybe<QbQuestionsSearchInput>;
};

export type QueryQbQuestionsByIdsArgs = {
  questionIds: Array<Scalars['ID']>;
};

export type QueryQbTagsArgs = {
  order?: InputMaybe<QbOrderInput>;
  paging?: InputMaybe<QbPageInput>;
  search?: InputMaybe<QbTagsSearchInput>;
};

export type QueryQmsExamArgs = {
  id: Scalars['ID'];
};

export type QueryQmsExamsArgs = {
  order?: InputMaybe<QmsOrderInput>;
  paging?: InputMaybe<QmsPageInput>;
  search?: InputMaybe<QmsExamsSearchInput>;
};

export type QueryQmsLeSchoolGradesArgs = {
  schoolCode: Scalars['ID'];
};

export type QueryQmsTagsArgs = {
  order?: InputMaybe<QmsOrderInput>;
  paging?: InputMaybe<QmsPageInput>;
  search?: InputMaybe<QmsTagsSearchInput>;
};

export type User = {
  __typename?: 'User';
  additional1?: Maybe<Scalars['String']>;
  additional2?: Maybe<Scalars['String']>;
  additional3?: Maybe<Scalars['String']>;
  additional4?: Maybe<Scalars['String']>;
  additional5?: Maybe<Scalars['String']>;
  additional6?: Maybe<Scalars['String']>;
  additional7?: Maybe<Scalars['String']>;
  additional8?: Maybe<Scalars['String']>;
  additional9?: Maybe<Scalars['String']>;
  additional10?: Maybe<Scalars['String']>;
  appPermissions: Array<Scalars['String']>;
  appRoles: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  /** If true email has been verified, can be null when email does not require verification eg. OAuth user */
  emailVerified?: Maybe<Scalars['Boolean']>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
  isPassportUser: Scalars['Boolean'];
  isPasswordCreatedByUser: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  newEmail?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  realm?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  teamIds: Array<Scalars['String']>;
  username: Scalars['String'];
};

export type CreateExamMutationVariables = Exact<{
  input: QmsExamCreateInput;
}>;

export type CreateExamMutation = {
  __typename?: 'Mutation';
  qmsCreateExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type UpdateExamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: QmsExamUpdateInput;
}>;

export type UpdateExamMutation = {
  __typename?: 'Mutation';
  qmsUpdateExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type PublishExamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishExamMutation = {
  __typename?: 'Mutation';
  qmsPublishExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type DeprecateExamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeprecateExamMutation = {
  __typename?: 'Mutation';
  qmsDeprecateExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type DeleteExamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteExamMutation = {
  __typename?: 'Mutation';
  qmsDeleteExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type DuplicateExamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DuplicateExamMutation = {
  __typename?: 'Mutation';
  qmsDuplicateExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type Exam_FragmentFragment = {
  __typename?: 'QMSExam';
  id: string;
  title: string;
  ownerCode: string;
  defaultGeneratorType: QmsExamGeneratorType;
  status: QmsExamStatus;
  totalQuestions?: number | null;
  fullScore?: number | null;
  metadata?: any | null;
  createdAt: any;
  updatedAt: any;
  deletedAt?: any | null;
  tags?: Array<{
    __typename?: 'QMSExamTag';
    id: string;
    name: string;
    slug: string;
    category: QmsTagCategory;
    description?: string | null;
  }> | null;
  examItems: Array<{
    __typename?: 'QMSExamItem';
    id: string;
    examId: string;
    questionId: string;
    score?: number | null;
    order?: number | null;
    question: {
      __typename?: 'QMSQuestion';
      id: string;
      question: string;
      type: QmsQuestionType;
      metadata?: any | null;
      correctAnswer?: any | null;
      status: QmsQuestionStatus;
      refSource: string;
      refQuestionId: string;
      solution?: Array<{
        __typename?: 'QMSQuestionSolution';
        type: QmsQuestionSolutionType;
        content: string;
      }> | null;
      tags: Array<{
        __typename?: 'QMSQuestionTag';
        id: string;
        name: string;
        slug: string;
        category: QmsTagCategory;
        description?: string | null;
      }>;
    };
  }>;
};

export type GetPublishedQuestionsQueryVariables = Exact<{
  search?: InputMaybe<QbPublishedQuestionsSearchInput>;
  paging?: InputMaybe<QbPageInput>;
  order?: InputMaybe<QbOrderInput>;
}>;

export type GetPublishedQuestionsQuery = {
  __typename?: 'Query';
  qbPublishedQuestions: {
    __typename?: 'QBQuestionsResult';
    total: number;
    questions: Array<{
      __typename?: 'QBQuestion';
      id: string;
      question: string;
      type: QbQuestionType;
      metadata?: any | null;
      correctAnswer?: any | null;
      status: QbQuestionStatus;
      solution?: Array<{
        __typename?: 'QBQuestionSolution';
        type: QbQuestionSolutionType;
        content: string;
      }> | null;
      tags: Array<{
        __typename?: 'QBQuestionTag';
        id: string;
        name: string;
        slug: string;
        category: QbTagCategory;
      }>;
    }>;
  };
};

export type GetQbTagsQueryVariables = Exact<{
  search?: InputMaybe<QbTagsSearchInput>;
  paging?: InputMaybe<QbPageInput>;
  order?: InputMaybe<QbOrderInput>;
}>;

export type GetQbTagsQuery = {
  __typename?: 'Query';
  qbTags: {
    __typename?: 'QBTagsResult';
    total: number;
    tags: Array<{
      __typename?: 'QBTag';
      id: string;
      name: string;
      slug: string;
      category: QbTagCategory;
      description?: string | null;
    }>;
  };
};

export type GetQbRemainTagsQueryVariables = Exact<{
  search: QbTagQuestionInput;
}>;

export type GetQbRemainTagsQuery = {
  __typename?: 'Query';
  qbGetRemainTags: Array<{
    __typename?: 'QBTag';
    id: string;
    name: string;
    slug: string;
    category: QbTagCategory;
    description?: string | null;
  }>;
};

export type GetQmsTagsQueryVariables = Exact<{
  search?: InputMaybe<QmsTagsSearchInput>;
  paging?: InputMaybe<QmsPageInput>;
  order?: InputMaybe<QmsOrderInput>;
}>;

export type GetQmsTagsQuery = {
  __typename?: 'Query';
  qmsTags: {
    __typename?: 'QMSTagsResult';
    total: number;
    tags: Array<{
      __typename?: 'QMSTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }>;
  };
};

export type GetExamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExamQuery = {
  __typename?: 'Query';
  qmsExam: {
    __typename?: 'QMSExam';
    id: string;
    title: string;
    ownerCode: string;
    defaultGeneratorType: QmsExamGeneratorType;
    status: QmsExamStatus;
    totalQuestions?: number | null;
    fullScore?: number | null;
    metadata?: any | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    tags?: Array<{
      __typename?: 'QMSExamTag';
      id: string;
      name: string;
      slug: string;
      category: QmsTagCategory;
      description?: string | null;
    }> | null;
    examItems: Array<{
      __typename?: 'QMSExamItem';
      id: string;
      examId: string;
      questionId: string;
      score?: number | null;
      order?: number | null;
      question: {
        __typename?: 'QMSQuestion';
        id: string;
        question: string;
        type: QmsQuestionType;
        metadata?: any | null;
        correctAnswer?: any | null;
        status: QmsQuestionStatus;
        refSource: string;
        refQuestionId: string;
        solution?: Array<{
          __typename?: 'QMSQuestionSolution';
          type: QmsQuestionSolutionType;
          content: string;
        }> | null;
        tags: Array<{
          __typename?: 'QMSQuestionTag';
          id: string;
          name: string;
          slug: string;
          category: QmsTagCategory;
          description?: string | null;
        }>;
      };
    }>;
  };
};

export type GetExamsQueryVariables = Exact<{
  search?: InputMaybe<QmsExamsSearchInput>;
  paging?: InputMaybe<QmsPageInput>;
  order?: InputMaybe<QmsOrderInput>;
}>;

export type GetExamsQuery = {
  __typename?: 'Query';
  qmsExams: {
    __typename?: 'QMSExamResult';
    total: number;
    exams: Array<{
      __typename?: 'QMSExam';
      id: string;
      title: string;
      ownerCode: string;
      defaultGeneratorType: QmsExamGeneratorType;
      status: QmsExamStatus;
      totalQuestions?: number | null;
      fullScore?: number | null;
      metadata?: any | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      tags?: Array<{
        __typename?: 'QMSExamTag';
        id: string;
        name: string;
        slug: string;
        category: QmsTagCategory;
        description?: string | null;
      }> | null;
      examItems: Array<{
        __typename?: 'QMSExamItem';
        id: string;
        examId: string;
        questionId: string;
        score?: number | null;
        order?: number | null;
        question: {
          __typename?: 'QMSQuestion';
          id: string;
          question: string;
          type: QmsQuestionType;
          metadata?: any | null;
          correctAnswer?: any | null;
          status: QmsQuestionStatus;
          refSource: string;
          refQuestionId: string;
          solution?: Array<{
            __typename?: 'QMSQuestionSolution';
            type: QmsQuestionSolutionType;
            content: string;
          }> | null;
          tags: Array<{
            __typename?: 'QMSQuestionTag';
            id: string;
            name: string;
            slug: string;
            category: QmsTagCategory;
            description?: string | null;
          }>;
        };
      }>;
    }>;
  };
};

export type GetQmsLeSchoolGradesQueryVariables = Exact<{
  schoolCode: Scalars['ID'];
}>;

export type GetQmsLeSchoolGradesQuery = {
  __typename?: 'Query';
  qmsLeSchoolGrades: {
    __typename?: 'QMSLeSchoolGradesResult';
    total: number;
    schoolGrades: Array<{
      __typename?: 'QMSLeSchoolGrade';
      id: string;
      name: string;
      code: string;
      schoolCode: string;
    }>;
  };
};

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileQuery = {
  __typename?: 'Query';
  getMyProfile: {
    __typename?: 'User';
    id: string;
    firstname: string;
    lastname: string;
    image?: string | null;
    realm?: string | null;
    username: string;
    email?: string | null;
    emailVerified?: boolean | null;
    newEmail?: string | null;
    isPassportUser: boolean;
    isPasswordCreatedByUser: boolean;
    isDisabled: boolean;
    isVerified: boolean;
    additional1?: string | null;
    additional2?: string | null;
    additional3?: string | null;
    additional4?: string | null;
    additional5?: string | null;
    additional6?: string | null;
    additional7?: string | null;
    additional8?: string | null;
    additional9?: string | null;
    additional10?: string | null;
    organizationId?: string | null;
    referenceId?: string | null;
    lastLoginAt?: string | null;
    createdAt: any;
    roles: Array<string>;
    appRoles: Array<string>;
    appPermissions: Array<string>;
    teamIds: Array<string>;
  };
};

export const Exam_FragmentFragmentDoc = gql`
  fragment EXAM_FRAGMENT on QMSExam {
    id
    title
    ownerCode
    defaultGeneratorType
    status
    totalQuestions
    fullScore
    metadata
    createdAt
    updatedAt
    deletedAt
    tags {
      id
      name
      slug
      category
      description
    }
    examItems {
      id
      examId
      questionId
      score
      order
      question {
        id
        question
        type
        metadata
        correctAnswer
        solution {
          type
          content
        }
        status
        refSource
        refQuestionId
        tags {
          id
          name
          slug
          category
          description
        }
      }
    }
  }
`;
export const CreateExamDocument = gql`
  mutation createExam($input: QMSExamCreateInput!) {
    qmsCreateExam(input: $input) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type CreateExamMutationFn = Apollo.MutationFunction<
  CreateExamMutation,
  CreateExamMutationVariables
>;

/**
 * __useCreateExamMutation__
 *
 * To run a mutation, you first call `useCreateExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExamMutation, { data, loading, error }] = useCreateExamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExamMutation, CreateExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExamMutation, CreateExamMutationVariables>(
    CreateExamDocument,
    options,
  );
}
export type CreateExamMutationHookResult = ReturnType<typeof useCreateExamMutation>;
export type CreateExamMutationResult = Apollo.MutationResult<CreateExamMutation>;
export type CreateExamMutationOptions = Apollo.BaseMutationOptions<
  CreateExamMutation,
  CreateExamMutationVariables
>;
export const UpdateExamDocument = gql`
  mutation updateExam($id: ID!, $input: QMSExamUpdateInput!) {
    qmsUpdateExam(id: $id, input: $input) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type UpdateExamMutationFn = Apollo.MutationFunction<
  UpdateExamMutation,
  UpdateExamMutationVariables
>;

/**
 * __useUpdateExamMutation__
 *
 * To run a mutation, you first call `useUpdateExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExamMutation, { data, loading, error }] = useUpdateExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExamMutation, UpdateExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExamMutation, UpdateExamMutationVariables>(
    UpdateExamDocument,
    options,
  );
}
export type UpdateExamMutationHookResult = ReturnType<typeof useUpdateExamMutation>;
export type UpdateExamMutationResult = Apollo.MutationResult<UpdateExamMutation>;
export type UpdateExamMutationOptions = Apollo.BaseMutationOptions<
  UpdateExamMutation,
  UpdateExamMutationVariables
>;
export const PublishExamDocument = gql`
  mutation publishExam($id: ID!) {
    qmsPublishExam(id: $id) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type PublishExamMutationFn = Apollo.MutationFunction<
  PublishExamMutation,
  PublishExamMutationVariables
>;

/**
 * __usePublishExamMutation__
 *
 * To run a mutation, you first call `usePublishExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishExamMutation, { data, loading, error }] = usePublishExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishExamMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishExamMutation, PublishExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishExamMutation, PublishExamMutationVariables>(
    PublishExamDocument,
    options,
  );
}
export type PublishExamMutationHookResult = ReturnType<typeof usePublishExamMutation>;
export type PublishExamMutationResult = Apollo.MutationResult<PublishExamMutation>;
export type PublishExamMutationOptions = Apollo.BaseMutationOptions<
  PublishExamMutation,
  PublishExamMutationVariables
>;
export const DeprecateExamDocument = gql`
  mutation deprecateExam($id: ID!) {
    qmsDeprecateExam(id: $id) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type DeprecateExamMutationFn = Apollo.MutationFunction<
  DeprecateExamMutation,
  DeprecateExamMutationVariables
>;

/**
 * __useDeprecateExamMutation__
 *
 * To run a mutation, you first call `useDeprecateExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprecateExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprecateExamMutation, { data, loading, error }] = useDeprecateExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeprecateExamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeprecateExamMutation, DeprecateExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeprecateExamMutation, DeprecateExamMutationVariables>(
    DeprecateExamDocument,
    options,
  );
}
export type DeprecateExamMutationHookResult = ReturnType<typeof useDeprecateExamMutation>;
export type DeprecateExamMutationResult = Apollo.MutationResult<DeprecateExamMutation>;
export type DeprecateExamMutationOptions = Apollo.BaseMutationOptions<
  DeprecateExamMutation,
  DeprecateExamMutationVariables
>;
export const DeleteExamDocument = gql`
  mutation deleteExam($id: ID!) {
    qmsDeleteExam(id: $id) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type DeleteExamMutationFn = Apollo.MutationFunction<
  DeleteExamMutation,
  DeleteExamMutationVariables
>;

/**
 * __useDeleteExamMutation__
 *
 * To run a mutation, you first call `useDeleteExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExamMutation, { data, loading, error }] = useDeleteExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExamMutation, DeleteExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExamMutation, DeleteExamMutationVariables>(
    DeleteExamDocument,
    options,
  );
}
export type DeleteExamMutationHookResult = ReturnType<typeof useDeleteExamMutation>;
export type DeleteExamMutationResult = Apollo.MutationResult<DeleteExamMutation>;
export type DeleteExamMutationOptions = Apollo.BaseMutationOptions<
  DeleteExamMutation,
  DeleteExamMutationVariables
>;
export const DuplicateExamDocument = gql`
  mutation duplicateExam($id: ID!) {
    qmsDuplicateExam(id: $id) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;
export type DuplicateExamMutationFn = Apollo.MutationFunction<
  DuplicateExamMutation,
  DuplicateExamMutationVariables
>;

/**
 * __useDuplicateExamMutation__
 *
 * To run a mutation, you first call `useDuplicateExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateExamMutation, { data, loading, error }] = useDuplicateExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateExamMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateExamMutation, DuplicateExamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateExamMutation, DuplicateExamMutationVariables>(
    DuplicateExamDocument,
    options,
  );
}
export type DuplicateExamMutationHookResult = ReturnType<typeof useDuplicateExamMutation>;
export type DuplicateExamMutationResult = Apollo.MutationResult<DuplicateExamMutation>;
export type DuplicateExamMutationOptions = Apollo.BaseMutationOptions<
  DuplicateExamMutation,
  DuplicateExamMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const GetPublishedQuestionsDocument = gql`
  query getPublishedQuestions(
    $search: QBPublishedQuestionsSearchInput
    $paging: QBPageInput
    $order: QBOrderInput
  ) {
    qbPublishedQuestions(search: $search, paging: $paging, order: $order) {
      questions {
        id
        question
        type
        metadata
        correctAnswer
        solution {
          type
          content
        }
        status
        tags {
          id
          name
          slug
          category
        }
      }
      total
    }
  }
`;

/**
 * __useGetPublishedQuestionsQuery__
 *
 * To run a query within a React component, call `useGetPublishedQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedQuestionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPublishedQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublishedQuestionsQuery,
    GetPublishedQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublishedQuestionsQuery, GetPublishedQuestionsQueryVariables>(
    GetPublishedQuestionsDocument,
    options,
  );
}
export function useGetPublishedQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublishedQuestionsQuery,
    GetPublishedQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublishedQuestionsQuery, GetPublishedQuestionsQueryVariables>(
    GetPublishedQuestionsDocument,
    options,
  );
}
export type GetPublishedQuestionsQueryHookResult = ReturnType<typeof useGetPublishedQuestionsQuery>;
export type GetPublishedQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetPublishedQuestionsLazyQuery
>;
export type GetPublishedQuestionsQueryResult = Apollo.QueryResult<
  GetPublishedQuestionsQuery,
  GetPublishedQuestionsQueryVariables
>;
export const GetQbTagsDocument = gql`
  query getQBTags($search: QBTagsSearchInput, $paging: QBPageInput, $order: QBOrderInput) {
    qbTags(search: $search, paging: $paging, order: $order) {
      tags {
        id
        name
        slug
        category
        description
      }
      total
    }
  }
`;

/**
 * __useGetQbTagsQuery__
 *
 * To run a query within a React component, call `useGetQbTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQbTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQbTagsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetQbTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetQbTagsQuery, GetQbTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQbTagsQuery, GetQbTagsQueryVariables>(GetQbTagsDocument, options);
}
export function useGetQbTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQbTagsQuery, GetQbTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQbTagsQuery, GetQbTagsQueryVariables>(GetQbTagsDocument, options);
}
export type GetQbTagsQueryHookResult = ReturnType<typeof useGetQbTagsQuery>;
export type GetQbTagsLazyQueryHookResult = ReturnType<typeof useGetQbTagsLazyQuery>;
export type GetQbTagsQueryResult = Apollo.QueryResult<GetQbTagsQuery, GetQbTagsQueryVariables>;
export const GetQbRemainTagsDocument = gql`
  query getQBRemainTags($search: QBTagQuestionInput!) {
    qbGetRemainTags(search: $search) {
      id
      name
      slug
      category
      description
    }
  }
`;

/**
 * __useGetQbRemainTagsQuery__
 *
 * To run a query within a React component, call `useGetQbRemainTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQbRemainTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQbRemainTagsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetQbRemainTagsQuery(
  baseOptions: Apollo.QueryHookOptions<GetQbRemainTagsQuery, GetQbRemainTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQbRemainTagsQuery, GetQbRemainTagsQueryVariables>(
    GetQbRemainTagsDocument,
    options,
  );
}
export function useGetQbRemainTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQbRemainTagsQuery, GetQbRemainTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQbRemainTagsQuery, GetQbRemainTagsQueryVariables>(
    GetQbRemainTagsDocument,
    options,
  );
}
export type GetQbRemainTagsQueryHookResult = ReturnType<typeof useGetQbRemainTagsQuery>;
export type GetQbRemainTagsLazyQueryHookResult = ReturnType<typeof useGetQbRemainTagsLazyQuery>;
export type GetQbRemainTagsQueryResult = Apollo.QueryResult<
  GetQbRemainTagsQuery,
  GetQbRemainTagsQueryVariables
>;
export const GetQmsTagsDocument = gql`
  query getQMSTags($search: QMSTagsSearchInput, $paging: QMSPageInput, $order: QMSOrderInput) {
    qmsTags(search: $search, paging: $paging, order: $order) {
      tags {
        id
        name
        slug
        category
        description
      }
      total
    }
  }
`;

/**
 * __useGetQmsTagsQuery__
 *
 * To run a query within a React component, call `useGetQmsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsTagsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetQmsTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetQmsTagsQuery, GetQmsTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsTagsQuery, GetQmsTagsQueryVariables>(GetQmsTagsDocument, options);
}
export function useGetQmsTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQmsTagsQuery, GetQmsTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsTagsQuery, GetQmsTagsQueryVariables>(
    GetQmsTagsDocument,
    options,
  );
}
export type GetQmsTagsQueryHookResult = ReturnType<typeof useGetQmsTagsQuery>;
export type GetQmsTagsLazyQueryHookResult = ReturnType<typeof useGetQmsTagsLazyQuery>;
export type GetQmsTagsQueryResult = Apollo.QueryResult<GetQmsTagsQuery, GetQmsTagsQueryVariables>;
export const GetExamDocument = gql`
  query getExam($id: ID!) {
    qmsExam(id: $id) {
      ...EXAM_FRAGMENT
    }
  }
  ${Exam_FragmentFragmentDoc}
`;

/**
 * __useGetExamQuery__
 *
 * To run a query within a React component, call `useGetExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExamQuery(
  baseOptions: Apollo.QueryHookOptions<GetExamQuery, GetExamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamQuery, GetExamQueryVariables>(GetExamDocument, options);
}
export function useGetExamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExamQuery, GetExamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamQuery, GetExamQueryVariables>(GetExamDocument, options);
}
export type GetExamQueryHookResult = ReturnType<typeof useGetExamQuery>;
export type GetExamLazyQueryHookResult = ReturnType<typeof useGetExamLazyQuery>;
export type GetExamQueryResult = Apollo.QueryResult<GetExamQuery, GetExamQueryVariables>;
export const GetExamsDocument = gql`
  query getExams($search: QMSExamsSearchInput, $paging: QMSPageInput, $order: QMSOrderInput) {
    qmsExams(search: $search, paging: $paging, order: $order) {
      exams {
        ...EXAM_FRAGMENT
      }
      total
    }
  }
  ${Exam_FragmentFragmentDoc}
`;

/**
 * __useGetExamsQuery__
 *
 * To run a query within a React component, call `useGetExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetExamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExamsQuery, GetExamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExamsQuery, GetExamsQueryVariables>(GetExamsDocument, options);
}
export function useGetExamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExamsQuery, GetExamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExamsQuery, GetExamsQueryVariables>(GetExamsDocument, options);
}
export type GetExamsQueryHookResult = ReturnType<typeof useGetExamsQuery>;
export type GetExamsLazyQueryHookResult = ReturnType<typeof useGetExamsLazyQuery>;
export type GetExamsQueryResult = Apollo.QueryResult<GetExamsQuery, GetExamsQueryVariables>;
export const GetQmsLeSchoolGradesDocument = gql`
  query getQMSLeSchoolGrades($schoolCode: ID!) {
    qmsLeSchoolGrades(schoolCode: $schoolCode) {
      schoolGrades {
        id
        name
        code
        schoolCode
      }
      total
    }
  }
`;

/**
 * __useGetQmsLeSchoolGradesQuery__
 *
 * To run a query within a React component, call `useGetQmsLeSchoolGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQmsLeSchoolGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQmsLeSchoolGradesQuery({
 *   variables: {
 *      schoolCode: // value for 'schoolCode'
 *   },
 * });
 */
export function useGetQmsLeSchoolGradesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQmsLeSchoolGradesQuery,
    GetQmsLeSchoolGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQmsLeSchoolGradesQuery, GetQmsLeSchoolGradesQueryVariables>(
    GetQmsLeSchoolGradesDocument,
    options,
  );
}
export function useGetQmsLeSchoolGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQmsLeSchoolGradesQuery,
    GetQmsLeSchoolGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQmsLeSchoolGradesQuery, GetQmsLeSchoolGradesQueryVariables>(
    GetQmsLeSchoolGradesDocument,
    options,
  );
}
export type GetQmsLeSchoolGradesQueryHookResult = ReturnType<typeof useGetQmsLeSchoolGradesQuery>;
export type GetQmsLeSchoolGradesLazyQueryHookResult = ReturnType<
  typeof useGetQmsLeSchoolGradesLazyQuery
>;
export type GetQmsLeSchoolGradesQueryResult = Apollo.QueryResult<
  GetQmsLeSchoolGradesQuery,
  GetQmsLeSchoolGradesQueryVariables
>;
export const GetMyProfileDocument = gql`
  query getMyProfile {
    getMyProfile {
      id
      firstname
      lastname
      image
      realm
      username
      email
      emailVerified
      newEmail
      isPassportUser
      isPasswordCreatedByUser
      isDisabled
      isVerified
      additional1
      additional2
      additional3
      additional4
      additional5
      additional6
      additional7
      additional8
      additional9
      additional10
      organizationId
      referenceId
      lastLoginAt
      createdAt
      roles
      appRoles
      appPermissions
      teamIds
    }
  }
`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  );
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  );
}
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<
  GetMyProfileQuery,
  GetMyProfileQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
