import { useState, useRef, useEffect } from 'react';

import {
  Box,
  Button,
  Flex,
  Step,
  StepLabel,
  Stepper as BaseStepper,
  Text,
} from '../../../../Components/Base';

const steps = [
  { label: 'กรอกรายละเอียด', optional: true },
  { label: 'เลือกคำถาม' },
  { label: 'จัดการชุดข้อสอบและเผยแพร่' },
];

const Stepper = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const stepRef = useRef<{ [k: number]: boolean }>({});

  const lastStepIndex = steps.length - 1;

  useEffect(() => {
    stepRef.current[activeStep] = true;
  }, [activeStep]);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNext = () => {
    if (activeStep < lastStepIndex) {
      setActiveStep(activeStep + 1);
      handleComplete();
    }
  };

  const handleFinish = () => {
    handleComplete();
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const handlePublish = () => {
    setIsPublish(true);
  };

  return (
    <Box>
      <Text variant={'h4'}>Base Stepper</Text>
      <BaseStepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const { label, optional = false } = step;
          return (
            <Step
              key={label}
              onClick={() => {
                if (stepRef.current[index]) {
                  handleStep(index);
                }
              }}
            >
              <StepLabel
                StepIconProps={{
                  icon: index + 1,
                  completed: isPublish || (activeStep === index ? false : completed[index]),
                }}
                optional={optional && <Text variant={'caption'}>(Optional)</Text>}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </BaseStepper>

      <Flex mt={2} justifyContent={'flex-end'}>
        <Button onClick={handlePrevious} disabled={activeStep === 0 || isPublish}>
          Back
        </Button>
        <Box pl={2} />
        <Button
          onClick={activeStep === lastStepIndex ? handleFinish : handleNext}
          disabled={isPublish}
        >
          {activeStep === lastStepIndex ? 'Finish' : 'Next'}
        </Button>
        <Box pl={2} />
        <Button color={'success'} onClick={handlePublish} disabled={isPublish}>
          Publish
        </Button>
      </Flex>
    </Box>
  );
};

export default Stepper;
