import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Box, Text, Button } from '../../../../Components/Base';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { QMSTagCategory } from '../../../../Domains/ExaminationManagement/useGetQMSTags';
import SearchIcon from '../../../../Components/Icons/SearchIcon';
import ExpandLessIcon from '../../../../Components/Icons/ExpandLessIcon';
import ExpandMoreIcon from '../../../../Components/Icons/ExpandMoreIcon';

import SearchExaminationQuestionForm from './SearchExaminationQuestionForm';

const ExaminationQuestionSearchFilter = () => {
  const { examId = '' } = useParams();
  const [isFormExpanded, setIsFormExpanded] = useState<boolean>(true);
  const { data: examination } = useGetExamination({ variables: { id: examId } });
  const { tags } = examination?.qmsExam ?? {};
  const grade = tags?.find((value) => value.category === QMSTagCategory.Grade)?.name;
  const subject = tags?.find((value) => value.category === QMSTagCategory.Subject)?.name;

  return (
    <Box data-testid="examination-question-search-filter">
      <Flex alignItems={'center'} alignContent={'center'} justifyContent={'space-between'}>
        <Text
          data-testid="title"
          variant="h4"
          whiteSpace={'nowrap'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          flex={1}
          mr={6}
        >
          {subject} {grade}
        </Text>
        <Button
          data-testid="expand-search-question-button"
          onClick={() => {
            setIsFormExpanded((prevState) => !prevState);
          }}
          size="large"
          color="neutral"
          startIcon={<SearchIcon />}
          endIcon={isFormExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {'ค้นหาข้อสอบ'}
        </Button>
      </Flex>
      {isFormExpanded && (
        <Box mt={2} py={2} px={3} borderRadius={2} border={'1px solid'} borderColor={'grey.300'}>
          <SearchExaminationQuestionForm />
        </Box>
      )}
    </Box>
  );
};

export default ExaminationQuestionSearchFilter;
