import { Box } from '../../Components/Base';

import ExaminationInformationCreation from './Containers/ExaminationInformationCreation';

export default function ExaminationCreation() {
  return (
    <Box
      data-testid="examination-creation-page"
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
      bgcolor={'common.white'}
    >
      <ExaminationInformationCreation />
    </Box>
  );
}
