import { useMemo } from 'react';
import { matchPath, useNavigate, useParams, useMatch } from 'react-router-dom';

import { Box, Flex, Text, Stepper, Step, StepLabel, StepProps } from '../../../Components/Base';
import { LinkButton } from '../../../Components/LinkButton';
import ArrowBackIcon from '../../../Components/Icons/ArrowBackIcon';
import {
  ExaminationManagementStep,
  EXAMINATION_EVENT_CREATION_PATH,
  EXAMINATION_EVENT_FINALIZATION_PATH,
  EXAMINATION_EVENT_MANAGEMENT_STEPS,
} from '../../../Domains/ExaminationManagement/constant';
import {
  useExamEventDetails,
  ExaminationEventStatus,
} from '../../../Domains/ExaminationManagement/useExamEventDetails';
import { useConfig } from '../../../Contexts/ConfigProvider';

import SaveAsDraftButton from './SaveAsDraftButton';
import PreviewButton from './PreviewButton';
import PublishExaminationEventButton from './PublishExaminationEventButton';

const ExaminationEventManagement = () => {
  const navigate = useNavigate();
  const examEventCreatePathMatch = useMatch({ path: EXAMINATION_EVENT_CREATION_PATH });
  const examEventFinalizeMatch = useMatch({ path: EXAMINATION_EVENT_FINALIZATION_PATH });

  const { eventId = '' } = useParams();

  const { data } = useExamEventDetails({ id: eventId });
  const isPublishMode = useMemo(() => data?.status === ExaminationEventStatus.PUBLISH, [data]);

  let currentRouteMatch: ReturnType<typeof matchPath> = null;

  let activeStep = -1;
  EXAMINATION_EVENT_MANAGEMENT_STEPS.find((step, index) => {
    currentRouteMatch = matchPath({ path: step.path, end: true }, location.pathname);
    activeStep = index;
    return !!currentRouteMatch;
  });

  const { exam: examConfig } = useConfig();
  const enablePreviewExamination = examConfig?.examinationEvent?.enablePreviewExamination ?? false;

  const goToStep =
    ({ path }: ExaminationManagementStep): StepProps['onClick'] =>
    () => {
      if (currentRouteMatch?.pathname === EXAMINATION_EVENT_CREATION_PATH) return;

      navigate(path.replace(':eventId', currentRouteMatch?.params.eventId ?? ''));
    };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <LinkButton color="neutral" to={'/exam/events'} startIcon={<ArrowBackIcon />}>
          {'ย้อนกลับ'}
        </LinkButton>
        {examEventFinalizeMatch && <PublishExaminationEventButton />}
      </Flex>
      <Box>
        <Flex alignItems={'center'} justifyContent={'space-between'} mt={2}>
          <Text variant="h4">{isPublishMode ? 'รายละเอียดการจัดสอบ' : 'สร้างการสอบ'}</Text>
          {!examEventCreatePathMatch && (
            <Flex>
              {enablePreviewExamination && <PreviewButton />}
              {!isPublishMode && (
                <Box ml={2}>
                  <SaveAsDraftButton />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
        {!isPublishMode && (
          <Flex
            mt={3}
            px={3}
            justifyContent={'center'}
            borderRadius={2}
            py={2}
            boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
            bgcolor={'common.white'}
          >
            <Stepper activeStep={activeStep} sx={{ width: 1, maxWidth: '1100px' }}>
              {EXAMINATION_EVENT_MANAGEMENT_STEPS.map((step, index) => {
                const { label, path } = step;
                return (
                  <Step key={label} onClick={goToStep(step)}>
                    <StepLabel
                      StepIconProps={{
                        icon: index + 1,
                        // TODO: Check complete by examination event data
                        completed: currentRouteMatch?.pattern.path === path ? false : true,
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default ExaminationEventManagement;
