import { useState, useCallback } from 'react';

interface UseDisclosureProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

/**
 * Assumption: if we send `isOpen` props into useDisclosure, this hook assume we always send `onClose` and `onOpen`
 */
const useDisclosure = ({
  isOpen: isOpenProps,
  onOpen: onOpenProps = () => {},
  onClose: onCloseProps = () => {},
}: UseDisclosureProps = {}) => {
  const [isOpenState, setIsOpen] = useState(isOpenProps || false);

  const isControlled = isOpenProps !== undefined;
  const isOpen = isControlled ? !!isOpenProps : isOpenState;

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onCloseProps();
  }, [isControlled, onCloseProps]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenProps();
  }, [isControlled, onOpenProps]);

  const toggle = useCallback(() => {
    const action = isOpen ? close : open;
    action();
  }, [isOpen, close, open]);

  return { isOpen, open, close, toggle };
};

export default useDisclosure;
