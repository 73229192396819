import React, { useMemo, forwardRef, Ref } from 'react';
import { styled, MenuItem as MUIMenuItem } from '@mui/material';

import KeyboardArrowDownIcon from '../../../Icons/KeyboardArrowDownIcon';
import { Text, TextProps } from '../../Text';
import TextInput, { TextInputProps } from '../TextInput';

import { SelectionInputProps } from './types';

const EllipsisText = styled(Text)<TextProps>({
  textOverflow: 'ellipsis',
});

const Selection = styled(TextInput)(({ theme }) => ({
  '.MuiInputLabel-root': {
    paddingRight: theme.spacing(3.5),
  },
  '.MuiSelect-root': {
    '.MuiInputBase-input': {
      paddingRight: theme.spacing(3.5),
    },
  },
  '.MuiSelect-icon': {
    color: theme.palette.text.primary,
  },
  '.Mui-disabled .MuiTypography-body1': {
    opacity: 0.5,
  },
}));

const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root:hover, &.MuiMenuItem-root:focus': {
    backgroundColor: theme.palette.primary.lighter,
  },
}));

const BaseSelectionInput = (
  { placeholder, options, renderItem, ...props }: SelectionInputProps,
  ref: Ref<HTMLElement>,
): JSX.Element => {
  const selectProps = useMemo<TextInputProps['SelectProps']>(
    () => ({
      displayEmpty: placeholder ? true : false,
      IconComponent: KeyboardArrowDownIcon,
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        marginThreshold: 0,
        MenuListProps: {
          disablePadding: true,
        },
      },
    }),
    [placeholder],
  );
  return (
    <Selection ref={ref} placeholder={placeholder} {...props} select SelectProps={selectProps}>
      {/* FIXME: If remove MenuItem then placeholder not show and use display `none` to hide text placeholder in menu list */}
      {placeholder && (
        <MenuItem value="" sx={{ display: 'none' }}>
          <Text color="grey.500">{placeholder}</Text>
        </MenuItem>
      )}
      {Array.isArray(options) && options.length
        ? options.map((option, index) => (
            <MenuItem {...option} key={`${index}-${option.value}`} value={option.value}>
              {typeof renderItem === 'function' ? (
                React.cloneElement(renderItem({ ...option, index }))
              ) : (
                <EllipsisText overflow="hidden">{option.label}</EllipsisText>
              )}
            </MenuItem>
          ))
        : null}
    </Selection>
  );
};

const SelectionInput = forwardRef(BaseSelectionInput);

export type { SelectionInputProps };
export default SelectionInput;
