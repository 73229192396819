import MUIFab, { FabProps } from '@mui/material/Fab';
import { styled } from '@mui/material';

import ArrowUpwardIcon from '../Icons/ArrowUpwardIcon';

const Fab = styled(MUIFab)({
  boxShadow: '0px 8px 16px hsla(210, 10%, 23%, 0.15)',
  '&:active': {
    boxShadow: '0px 8px 16px hsla(210, 10%, 23%, 0.15)',
  },
});

const BackToTop = (props: FabProps) => {
  return (
    <Fab data-testid="back-to-top-button" color="primary" {...props}>
      <ArrowUpwardIcon sx={{ fontSize: 24 }} />
    </Fab>
  );
};

export default BackToTop;
