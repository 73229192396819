import { styled, Snackbar as MUISnackbar, SnackbarProps as MUISnackbarProps } from '@mui/material';

type SnackbarProps = MUISnackbarProps;

const StyledMUISnackbar = styled(MUISnackbar)(({ theme }) => ({
  '&.MuiSnackbar-root .MuiPaper-root': {
    backgroundColor: theme.palette.grey[900],
  },
  '&.MuiSnackbar-root .MuiAlert-message .MuiTypography-root': {
    color: theme.palette.common.white,
    fontWeight: '600',
  },
}));

const Snackbar = (props: SnackbarProps) => {
  const { children, autoHideDuration, ...restProps } = props;

  return (
    <StyledMUISnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      {...restProps}
    >
      {children}
    </StyledMUISnackbar>
  );
};

export default Snackbar;
export type { SnackbarProps };
