import { useState, createContext, useContext, useEffect } from 'react';

import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  Button,
  Loading,
} from '../../Components/Base';
import firebase, { firebaseRemoteConfig } from '../../Libs/Firebase';
import { mergeConfigOverrides } from '../../Utils/config';

const defaultConfig = {
  appState: 'INITIALIZE' as 'INITIALIZE' | 'MAINTENANCE' | 'ONLINE',
  graphqlURL: 'https://api.dev.gateway.teacher.exam.mylearned.com/graphql',
  logoUrl: '/assets/logo/logo.svg',
  appShell: {
    sideNavMenuItems: [
      {
        label: 'ชุดข้อสอบ',
        url: '/exam/examinations',
        iconName: 'DESCRIPTION_ICON',
      },
    ],
  },
  exam: {
    examination: {
      enableDownloadExamination: false,
      printWatermark: 'LearnEducation',
    },
    examinationEvent: {
      enablePreviewExamination: false,
      enableRandomQuestions: false,
      enableRandomChoices: false,
    },
  },
  routing: {
    components: {
      enable: false,
    },
    examination: {
      enable: true,
    },
    examinationEvent: {
      enable: false,
    },
    examinationResult: {
      enable: false,
    },
    login: {
      enable: true,
      path: 'https://api.dev.user.teacher.exam.mylearned.com/user-api/login/learned',
    },
    landing: {
      enable: true,
      path: '/exam',
    },
  },
  gtm: {
    id: '',
    authToken: '',
    previewId: '',
  },
  theme: {} as Record<string, unknown>,
};

type Config = typeof defaultConfig;
type RequiredConfig = Pick<Config, 'appState' | 'graphqlURL' | 'routing'>;
type IConfigContext = Partial<Config> & RequiredConfig;

declare global {
  interface Window {
    appConfig: Record<string, unknown>;
  }
}

const ConfigContext = createContext<IConfigContext>(defaultConfig);

const remoteConfig = firebaseRemoteConfig.getRemoteConfig(firebase);
remoteConfig.settings.minimumFetchIntervalMillis =
  process.env.NODE_ENV === 'production' ? 3600000 : 3600;

const resolveValue = (remoteConfigValue: string) => {
  const remoteConfigValueAsNumber = Number(remoteConfigValue);
  // Check is number
  if (!Number.isNaN(remoteConfigValueAsNumber)) return remoteConfigValueAsNumber;

  try {
    const remoteConfigValueAsJSON = JSON.parse(remoteConfigValue);
    return remoteConfigValueAsJSON;
  } catch (e) {
    return remoteConfigValue;
  }
};

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState<IConfigContext>(
    mergeConfigOverrides(window?.appConfig, defaultConfig),
  );

  useEffect(() => {
    firebaseRemoteConfig
      .ensureInitialized(remoteConfig)
      .then(() => console.log('Firebase Remote Config is initialized'))
      .catch((err: Error) => console.error('Firebase Remote Config failed to initialize', err))
      .then(() =>
        firebaseRemoteConfig
          .fetchAndActivate(remoteConfig)
          .then(() => {
            const appConfigValue: Record<string, unknown> = {};
            const remoteConfigValues = firebaseRemoteConfig.getAll(remoteConfig);

            Object.entries(remoteConfigValues).forEach(([key, value]) => {
              const remoteConfigValue = (value as firebaseRemoteConfig.Value & { _value: string })
                ._value;
              const resolvedConfig = resolveValue(remoteConfigValue);
              appConfigValue[key] = resolvedConfig;
            });

            setConfig((prev) => mergeConfigOverrides(appConfigValue, prev));
          })
          .catch((err) => {
            console.error(err);
            setConfig((prev) => mergeConfigOverrides({ appState: 'MAINTENANCE' }, prev));
          }),
      );
  }, []);

  if (config.appState === 'MAINTENANCE') {
    return (
      <Modal open={true}>
        <ModalTitle sx={{ p: 3, pb: 4 }}>{'ขออภัย'}</ModalTitle>
        <ModalContent sx={{ pb: 0 }}>
          {'ขณะนี้ระบบยังไม่พร้อมให้บริการในขณะนี้ กรุณาลองใหม่อีกครั้ง'}
        </ModalContent>
        <ModalFooter sx={{ justifyContent: 'flex-end', p: 3, pt: 4 }}>
          <Button
            sx={{ width: { xs: 1, sm: 'auto' } }}
            size="large"
            // this not work cause TypeError: Can only call Location.reload on instances of Location
            // onClick={window.location.reload}
            onClick={() => window.location.reload()}
          >
            ลองอีกครั้ง
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  if (config.appState === 'INITIALIZE') return <Loading open />;

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within ConfigContext');
  }
  return context;
};

export { ConfigProvider, useConfig };
