import useExamEventDetails from './useExamEventDetails';
import {
  ExaminationEventStatus,
  ExaminationEventItemType,
  CandidateByClassType,
  CandidateExceptedType,
  CandidateListType,
} from './types';

export default useExamEventDetails;
export { useExamEventDetails, ExaminationEventStatus };
export type {
  ExaminationEventItemType,
  CandidateByClassType,
  CandidateExceptedType,
  CandidateListType,
};
