import { useNavigate } from 'react-router-dom';

import { IconButton, IconButtonProps } from '../../../Components/Base/Button';
import VisibilityOutlinedIcon from '../../../Components/Icons/VisibilityOutlinedIcon';
import { EXAMINATION_EXAMINATION_DETAIL_PATH } from '../../../Domains/ExaminationManagement/constant';

type ViewExaminationButtonProps = {
  examId: string;
} & IconButtonProps;

const ViewExaminationButton = (props: ViewExaminationButtonProps) => {
  const { examId, ...buttonProps } = props;
  const navigate = useNavigate();

  const handleViewExamination = () => {
    if (!examId || typeof examId !== 'string') return;
    navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
  };

  return (
    <IconButton data-testid="view-exam" {...buttonProps} onClick={handleViewExamination}>
      <VisibilityOutlinedIcon />
    </IconButton>
  );
};

export default ViewExaminationButton;
