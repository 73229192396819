import { useNavigate, useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { Button, ButtonProps } from '../../Components/Base';
import {
  EXAMINATION_EXAMINATION_DETAIL_PATH,
  ResponseType,
} from '../../Domains/ExaminationManagement/constant';
import {
  useGetExamination,
  Examination,
} from '../../Domains/ExaminationManagement/useGetExamination';
import useUpdateExamination from '../../Domains/ExaminationManagement/useUpdateExamination';
import { useSnackbar } from '../../Contexts/SnackbarProvider';
import { useEditableExamination } from '../../Contexts/ExaminationProvider';

import { isExaminationEqualWithStore } from './utils';

const RESPONSE_MESSAGE = {
  EXAM_NAME_IS_EMPTY: 'กรุณากรอกชื่อชุดข้อสอบ',
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการแก้ไข',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'บันทึกฉบับร่างเรียบร้อยแล้ว',
};

type SaveExaminationAsDraftButtonProps = ButtonProps & {
  onCompleted?: (data?: Examination) => void;
};

const SaveExaminationAsDraftButton = ({
  children,
  onCompleted,
  ...props
}: SaveExaminationAsDraftButtonProps) => {
  const { examId = '' } = useParams();
  const { open } = useSnackbar();
  const navigate = useNavigate();
  const editableExamination = useEditableExamination();
  const { data: examination } = useGetExamination({ variables: { id: examId }, skip: !examId });
  const [updateExamMutation, { loading: examMutationLoading }] = useUpdateExamination({
    onCompleted: (data) => {
      onCompleted?.(data.qmsUpdateExam);
      open(RESPONSE_MESSAGE.SUCCESS, 'success');
    },
    onError: (error) => {
      console.error(error);
      //TODO: handle all error message after connect learn auth
      let message = RESPONSE_MESSAGE.UNEXPECTED;
      if (error.message.includes('Not found Exam')) {
        message = RESPONSE_MESSAGE.NOT_FOUND;
      } else if (error.message === 'Failed to fetch') {
        message = RESPONSE_MESSAGE.NETWORK_ERROR;
      }
      open(message, 'error');
    },
  });

  const remoteExamination = examination?.qmsExam;

  const isExaminationUpToDated = remoteExamination
    ? isExaminationEqualWithStore(remoteExamination, editableExamination)
    : true;

  const handleSaveAsDraft = async () => {
    const { examinationInformation, questionSelected } = editableExamination;
    const orderedQuestionSelected = orderBy(questionSelected, 'order') ?? [];
    const examName = examinationInformation?.examName;
    if (examName) {
      await updateExamMutation({
        variables: {
          id: remoteExamination?.id ?? '',
          input: {
            title: examName,
            questions: orderedQuestionSelected.map((summaryItem) => ({
              refQuestionId: summaryItem.id,
              score: summaryItem.point ?? 1,
            })),
          },
        },
      });
    } else {
      open(RESPONSE_MESSAGE.EXAM_NAME_IS_EMPTY, 'warning');
      navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
    }
  };

  return (
    <Button
      data-testid="save-exam-as-draft-button"
      sx={{ minWidth: '130px' }}
      onClick={handleSaveAsDraft}
      disabled={examMutationLoading || isExaminationUpToDated}
      {...props}
    >
      {examMutationLoading ? 'กำลังบันทึก...' : children ?? 'บันทึกฉบับร่าง'}
    </Button>
  );
};

export type { SaveExaminationAsDraftButtonProps };
export default SaveExaminationAsDraftButton;
