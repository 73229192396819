import { SvgIconProps } from '@mui/material';
import { ReactNode } from 'react';

import { Alert, Snackbar as BaseSnackbar } from '../../Components/Base';
import CheckCircleIcon from '../../Components/Icons/CheckCircleIcon';
import ErrorIcon from '../../Components/Icons/ErrorIcon';
import InfoIcon from '../../Components/Icons/InfoIcon';
import WarningIcon from '../../Components/Icons/WarningIcon';

import { useSnackbar } from './SnackbarStoreProvider';

const SnackbarIcon = ({
  variant,
  ...props
}: Omit<SvgIconProps, 'sx' | 'color'> & { variant: 'success' | 'info' | 'error' | 'warning' }) => {
  switch (variant) {
    case 'success': {
      return <CheckCircleIcon sx={{ color: 'success.main' }} {...props} />;
    }
    case 'error': {
      return <ErrorIcon sx={{ color: 'error.main' }} {...props} />;
    }
    case 'warning': {
      return <WarningIcon sx={{ color: 'warning.main' }} {...props} />;
    }
    case 'info': {
      return <InfoIcon sx={{ color: 'info.main' }} {...props} />;
    }
  }
};

const getDataTestIdByVariant = (type?: 'success' | 'info' | 'error' | 'warning') => {
  switch (type) {
    case 'success': {
      return 'success-alert-notice';
    }
    case 'error': {
      return 'error-alert-notice';
    }
    case 'warning': {
      return 'warning-alert-notice';
    }
    case 'info': {
      return 'info-alert-notice';
    }
    default: {
      return 'alert-notice';
    }
  }
};

const SnackbarProvider = ({ children }: { children?: ReactNode }) => {
  const { show, snackbar, close } = useSnackbar();

  const { id, message, type, options } = snackbar ?? {};

  return (
    <>
      {children}
      <BaseSnackbar key={id} autoHideDuration={5000} open={show} onClose={close} {...options}>
        <Alert
          icon={type ? <SnackbarIcon variant={type} fontSize="small" /> : undefined}
          data-testid={getDataTestIdByVariant(type)}
        >
          {message}
        </Alert>
      </BaseSnackbar>
    </>
  );
};

export { useSnackbar };
export default SnackbarProvider;
