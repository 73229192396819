import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../../Components/Base';
import {
  useExaminationEventContext,
  ExaminationEvent,
} from '../../../Contexts/ExaminationEventStoreProvider';
import { useConfig } from '../../../Contexts/ConfigProvider';
import { EXAMINATION_EVENT_FINALIZATION_PATH } from '../../../Domains/ExaminationManagement/constant';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';

import { isExaminationEventEqualWithStore } from './utils';

const SaveAsDraftButton = () => {
  const navigate = useNavigate();
  const { eventId = '' } = useParams();
  const { exam: examConfig } = useConfig();
  const editableExaminationEvent = useExaminationEventContext();
  const { open } = useSnackbar();

  const { examinationEventInformation: examEventInformation, examinationParticipant } =
    editableExaminationEvent;

  const enableRandomQuestions = examConfig?.examinationEvent?.enableRandomQuestions ?? false;
  const enableRandomChoices = examConfig?.examinationEvent?.enableRandomChoices ?? false;

  // FIXME: Will be remove after api integration
  const localExaminationEvent = localStorage.getItem('examinationEvent');
  const examinationEvents = JSON.parse(localExaminationEvent ?? '{}');

  const isExaminationEventUpToDated = isExaminationEventEqualWithStore(
    examinationEvents,
    editableExaminationEvent,
  );

  const handleSaveAsDraft = () => {
    // TODO: Waiting integrate save as draft function
    const examinationEventInformation: ExaminationEvent['examinationEventInformation'] = {
      examEventName: examEventInformation?.examEventName,
      semester: examEventInformation?.semester,
      class: examEventInformation?.class,
      startTime: examEventInformation?.startTime,
      endTime: examEventInformation?.endTime,
      examination: examEventInformation?.examination,
      shouldShowPoints: examEventInformation?.shouldShowPoints,
      instruction: examEventInformation?.instruction,
    };

    // Enable randomQuestions and randomAnswer from config
    if (enableRandomQuestions) {
      examinationEventInformation.randomQuestion = examEventInformation?.randomQuestion;
    }
    if (enableRandomChoices) {
      examinationEventInformation.randomAnswer = examEventInformation?.randomAnswer;
    }

    const examinationEvent = {
      examinationEventInformation,
      examinationParticipant,
    };

    // FIXME: Will be remove after api integration
    // Save ExaminationEvent to localStorage
    console.log('SaveAsDraftButton', { examinationEvent });
    localStorage.setItem('examinationEvent', JSON.stringify(examinationEvent));
    open('บันทึกฉบับร่างเรียบร้อยแล้ว', 'success');

    // Force re-render by replace router for temporary until we got API Integration
    if (!eventId) return;
    navigate(EXAMINATION_EVENT_FINALIZATION_PATH.replace(':eventId', eventId));
  };

  return (
    <Button
      onClick={handleSaveAsDraft}
      sx={{ minWidth: '130px' }}
      disabled={isExaminationEventUpToDated}
    >
      {'บันทึกฉบับร่าง'}
    </Button>
  );
};

export default SaveAsDraftButton;
