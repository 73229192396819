import { Question } from '../../../../Contexts/ExaminationProvider';
import { QuestionItemType } from '../utils';

type QuestionGroupBySectionType = {
  section: string;
  items: {
    indicator: string;
    items: QuestionItemType[];
    questionCount: number;
    points: number;
  }[];
  allQuestionCount: number;
  points: number;
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  if (startIndex <= endIndex) {
    result.splice(endIndex - 1, 0, removed);
  } else {
    result.splice(endIndex, 0, removed);
  }

  return result;
};

export const reorderChildren = (
  children: any[],
  splitDropZonePath: string[],
  splitItemPath: string[],
) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    items: reorderChildren(nodeChildren.items, splitDropZoneChildrenPath, splitItemChildrenPath),
  };

  return updatedChildren;
};

export const moveWithinParent = (
  sectionGroup: QuestionGroupBySectionType[],
  splitDropZonePath: string[],
  splitItemPath: string[],
) => {
  const reorderQuestionGroup = reorderChildren(
    sectionGroup,
    splitDropZonePath,
    splitItemPath,
  ) as QuestionGroupBySectionType[];
  return reorderQuestionGroup;
};

export const mapQuestionOrder = (
  examQuestions: Question[],
  questionGroupBySection: QuestionGroupBySectionType[],
) => {
  let counter = 0;
  const updateQuestionList = [...examQuestions];

  questionGroupBySection.map((sectionGroup) => {
    const questionGroupByIndicator = sectionGroup.items;

    questionGroupByIndicator.map((indicator) => {
      const questionList = indicator.items;

      questionList.map((question) => {
        counter = counter + 1;
        const questionId = question.id;

        updateQuestionList.find((item) => {
          if (item.id === questionId) item.order = counter;
        });
      });
    });
  });

  return updateQuestionList;
};
