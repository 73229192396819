import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const paging = useMemo(() => {
    const page = searchParams.get('page') ?? '0';
    return parseInt(page);
  }, [searchParams]);

  const rowsPerPage = useMemo(() => {
    const rows = searchParams.get('rows') ?? '10';
    return parseInt(rows);
  }, [searchParams]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const query = queryString.stringify({ page: 0, rows: event.target.value });
    setSearchParams(query);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const query = queryString.stringify({ page: newPage, rows: rowsPerPage });
    setSearchParams(query);
  };

  return {
    paging,
    rowsPerPage,
    changePage: handleChangePage,
    changeRowsPerPage: handleChangeRowsPerPage,
  };
};

export default usePagination;
