import { forwardRef } from 'react';
import { styled, Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import BaseTextInput from '../TextInput';
import KeyboardArrowDownIcon from '../../../Icons/KeyboardArrowDownIcon';

import {
  OptionSearchableMultipleSelectionProps,
  SearchableMultipleSelectionInputProps,
} from './types';

const TextInput = styled(BaseTextInput)({
  '& .MuiOutlinedInput-root': {
    height: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },
});

const BaseSearchableMultipleSelectionInput = forwardRef(
  (props: SearchableMultipleSelectionInputProps, ref) => {
    const { label, error, helperText, options, InputProps, ...restProps } = props;
    return (
      <Autocomplete
        ref={ref}
        ListboxProps={{ style: { maxHeight: 268, overflow: 'auto' } }}
        noOptionsText="ไม่มีผลลัพธ์"
        multiple
        openOnFocus
        includeInputInList
        disableClearable
        limitTags={2}
        options={options ?? []}
        isOptionEqualToValue={(
          option: OptionSearchableMultipleSelectionProps,
          { value }: OptionSearchableMultipleSelectionProps,
        ) => value === option.value}
        getOptionLabel={(option: OptionSearchableMultipleSelectionProps) => option.label}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params: AutocompleteRenderInputParams) => {
          const { InputLabelProps, ...inputProps } = params;
          return (
            <TextInput
              {...inputProps}
              fullWidth={true}
              label={label}
              error={error}
              helperText={helperText}
              InputProps={{ ...inputProps?.InputProps, ...InputProps }}
            />
          );
        }}
        {...restProps}
      />
    );
  },
);

const SearchableMultipleSelectionInput = styled(BaseSearchableMultipleSelectionInput)(
  ({ theme }) => ({
    '.MuiAutocomplete-popupIndicator': {
      color: theme.palette.text.primary,
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiChip-root': {
        height: '24px',
        '.MuiChip-deleteIcon': {
          fontSize: '16px',
        },
      },
      '& .MuiChip-label': {
        fontSize: '14px',
        color: theme.palette.grey[800],
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      '.MuiOutlinedInput-input': {
        minHeight: '36px',
      },
    },
    '.MuiOutlinedInput-root.Mui-disabled': {
      WebkitTextFillColor: 'unset',
      backgroundColor: theme.palette.grey[100],
      '.MuiChip-root': {
        '.MuiChip-label': {
          color: theme.palette.grey[900],
        },
        '.MuiChip-deleteIcon': {
          color: theme.palette.grey[900],
        },
      },
      '.MuiSvgIcon-root': {
        color: theme.palette.grey[500],
      },
    },
    '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  }),
);

export type { SearchableMultipleSelectionInputProps, OptionSearchableMultipleSelectionProps };
export default SearchableMultipleSelectionInput;
