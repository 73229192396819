import { createTheme } from '@mui/material/styles';

import Button from './Components/Button';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    type?: string;
    neutral: PaletteOptions['primary'];
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }

  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      darker: 'hsla(202, 100%, 20%, 1)',
      dark: 'hsla(202, 100%, 30%, 1)',
      main: 'hsla(202, 100%, 38%, 1)',
      light: 'hsla(202, 100%, 90%, 1)',
      lighter: 'hsla(201, 100%, 97%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    error: {
      darker: 'hsla(360, 77%, 20%, 1)',
      dark: 'hsla(360, 77%, 40%, 1)',
      main: 'hsla(360, 77%, 48%, 1)',
      light: 'hsla(360, 77%, 90%, 1)',
      lighter: 'hsla(0, 77%, 97%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    warning: {
      darker: 'hsla(39, 100%, 20%, 1)',
      dark: 'hsla(39, 100%, 40%, 1)',
      main: 'hsla(39, 100%, 48%, 1)',
      light: 'hsla(39, 100%, 90%, 1)',
      lighter: 'hsla(39, 100%, 97%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    info: {
      darker: 'hsla(207, 86%, 20%, 1)',
      dark: 'hsla(207, 86%, 40%, 1)',
      main: 'hsla(207, 86%, 48%, 1)',
      light: 'hsla(207, 100%, 90%, 1)',
      lighter: 'hsla(207, 100%, 97%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    success: {
      darker: 'hsla(131, 53%, 20%, 1)',
      dark: 'hsla(131, 53%, 40%, 1)',
      main: 'hsla(131, 53%, 48%, 1)',
      light: 'hsla(131, 53%, 90%, 1)',
      lighter: 'hsla(131, 53%, 97%, 1)',
    },
    neutral: {
      darker: 'hsla(210, 11%, 15%, 1)',
      dark: 'hsla(210, 9%, 21%, 1)',
      main: 'hsla(210, 11%, 71%, 1)',
      light: 'hsla(210, 14%, 89%, 1)',
      lighter: 'hsla(210, 17%, 95%, 1)',
    },
    grey: {
      50: 'hsla(210, 17%, 98%, 1)',
      100: 'hsla(210, 17%, 95%, 1)',
      200: 'hsla(210, 16%, 93%, 1)',
      300: 'hsla(210, 14%, 89%, 1)',
      400: 'hsla(210, 14%, 83%, 1)',
      500: 'hsla(210, 11%, 71%, 1)',
      600: 'hsla(210, 7%, 56%, 1)',
      700: 'hsla(210, 9%, 31%, 1)',
      800: 'hsla(210, 10%, 23%, 1)',
      900: 'hsla(210, 11%, 15%, 1)',
    },
    text: {
      primary: 'hsla(210, 10%, 23%, 1)',
      secondary: 'hsla(210, 7%, 56%, 1)',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans Thai',
      'Noto Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: 34,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.42,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.42,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.42,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.42,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.42,
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.46,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.42,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minWidth: 768,
        },
      },
    },
    ...Button,
  },
});

export default theme;
