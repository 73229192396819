import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Text } from '../../../Components/Base';
import { ExaminationQuestionGroup, Variant } from '../../../Components/ExaminationPrint';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestions,
} from '../../../Domains/ExaminationManagement/utils';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';

const ExaminationQuestionList = () => {
  const { examId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });
  const examItems = examination?.qmsExam.examItems ?? [];
  const questions = formatExaminationItemToQuestions(examItems);

  const typeKey = searchParams.get('type');
  const isTeacher = typeKey !== 'student';

  const questionGroupBySection = groupQuestionBySection(questions);

  return (
    <Box data-testid="examination-question-list">
      {questionGroupBySection.map((question, questionGroupIndex) => (
        <Box data-testid={`section-${questionGroupIndex + 1}`} key={question.section}>
          {isTeacher && (
            <Text
              data-testid="section-title"
              variant="caption"
              fontWeight="600"
              component="p"
              my={2}
            >
              {question.section}
            </Text>
          )}
          {question.items.map((item, indicatorIndex) => {
            const title = item.indicator.substring(0, 11).trim();
            const description = item.indicator.substring(12).trim();
            const questionItems = item.items;
            return (
              <ExaminationQuestionGroup
                data-testid={`indicator-${indicatorIndex + 1}`}
                key={item.indicator}
                title={isTeacher ? title : ''}
                description={isTeacher ? description : ''}
                questionItems={questionItems}
                variant={isTeacher ? Variant.META_VISIBLE : Variant.META_NONE}
                mt={2}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default ExaminationQuestionList;
