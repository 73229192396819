import { useMatch, useParams, useResolvedPath } from 'react-router-dom';

import { HeaderTitle } from '../../Components/HeaderTitle';
import { EXAMINATION_EXAMINATION_CREATION_PATH } from '../../Domains/ExaminationManagement/constant';
import { Box } from '../../Components/Base';
import { useExaminationInformation } from '../../Contexts/ExaminationProvider';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';

const Header = () => {
  const resolved = useResolvedPath(EXAMINATION_EXAMINATION_CREATION_PATH);
  const match = useMatch({ path: resolved.pathname });
  const { examId = '' } = useParams();
  const [examinationInformation] = useExaminationInformation();
  const { data: examination } = useGetExamination({
    skip: !examId,
    variables: { id: examId },
  });

  if (match) {
    return <HeaderTitle>ชุดข้อสอบใหม่</HeaderTitle>;
  }

  const examName = examinationInformation?.examName ?? '';
  const variant = examination?.qmsExam?.status;
  return examName ? <HeaderTitle variant={variant}>{examName}</HeaderTitle> : <Box />;
};

export default Header;
