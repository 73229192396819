import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material';

const BaseExamPaperIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 180 180" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M90 160c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70Z"
        fill="#E9EEF9"
      />
      <g filter="url(#b)">
        <path
          d="M90.047 25.591 24.016 43.358a8.82 8.82 0 0 0-6.226 10.809l25.601 95.146a8.82 8.82 0 0 0 10.81 6.225l66.031-17.767a8.82 8.82 0 0 0 6.225-10.809l-25.601-95.146a8.82 8.82 0 0 0-10.809-6.225Z"
          fill="url(#c)"
        />
      </g>
      <g filter="url(#d)">
        <path
          d="M79.429 30.098 53.81 125.672a8.858 8.858 0 0 0 6.262 10.849l66.329 17.779a8.857 8.857 0 0 0 10.848-6.263l25.618-95.574a8.858 8.858 0 0 0-6.262-10.848l-66.329-17.78a8.857 8.857 0 0 0-10.848 6.263Z"
          fill="url(#e)"
        />
      </g>
      <g filter="url(#f)">
        <path
          d="M138.81 62.7v83.16a10.001 10.001 0 0 1-10 10H51.23a10.002 10.002 0 0 1-10-10V34.14a10 10 0 0 1 10-10h49.15a9.689 9.689 0 0 1 6.87 2.86l28.56 28.57a10.078 10.078 0 0 1 3 7.13Z"
          fill="url(#g)"
        />
        <path
          d="M138.43 60.13a3.003 3.003 0 0 0-2.62-1.61H114.4a10 10 0 0 1-10-10V28.14a4 4 0 0 0-4-4 9.7 9.7 0 0 1 6.85 2.86l28.56 28.57a10.006 10.006 0 0 1 2.62 4.56Z"
          className={'custom-color-icon'}
          fill="currentColor"
        />
        <path
          d="M95.65 48.84H54a1.5 1.5 0 0 0 0 3h41.65a1.5 1.5 0 0 0 0-3ZM83.45 57.13H54a1.5 1.5 0 0 0 0 3h29.45a1.5 1.5 0 0 0 0-3ZM126.03 73H70.19a1.47 1.47 0 0 0-1.47 1.47v.06c0 .812.658 1.47 1.47 1.47h55.84a1.47 1.47 0 0 0 1.47-1.47v-.06a1.47 1.47 0 0 0-1.47-1.47ZM59.98 79.5h-4a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3Zm-4-8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4ZM126.03 91.38H70.19a1.47 1.47 0 0 0-1.47 1.47v.06c0 .812.658 1.47 1.47 1.47h55.84a1.47 1.47 0 0 0 1.47-1.47v-.06a1.47 1.47 0 0 0-1.47-1.47ZM59.98 97.88h-4a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3Zm-4-8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4ZM126.03 109.75H70.19a1.47 1.47 0 0 0-1.47 1.47v.06c0 .812.658 1.47 1.47 1.47h55.84a1.47 1.47 0 0 0 1.47-1.47v-.06a1.47 1.47 0 0 0-1.47-1.47ZM59.98 116.25h-4a2.999 2.999 0 0 1-3-3v-4a2.998 2.998 0 0 1 3-3h4a2.999 2.999 0 0 1 3 3v4a2.998 2.998 0 0 1-3 3Zm-4-8a.998.998 0 0 0-1 1v4a.999.999 0 0 0 1 1h4a.998.998 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4ZM126.03 128.13H70.19a1.47 1.47 0 0 0-1.47 1.47v.06c0 .812.658 1.47 1.47 1.47h55.84a1.47 1.47 0 0 0 1.47-1.47v-.06a1.47 1.47 0 0 0-1.47-1.47ZM59.98 134.63h-4a2.999 2.999 0 0 1-3-3v-4a2.998 2.998 0 0 1 3-3h4a2.999 2.999 0 0 1 3 3v4a2.998 2.998 0 0 1-3 3Zm-4-8a.998.998 0 0 0-1 1v4a1 1 0 0 0 1 1h4a.998.998 0 0 0 1-1v-4a1 1 0 0 0-1-1h-4Z"
          className={'custom-color-icon'}
          fill="currentColor"
          fillOpacity={0.2}
        />
      </g>
      <path
        d="M17.92 146.18a8.28 8.28 0 1 0 0-16.56 8.28 8.28 0 0 0 0 16.56ZM156.47 36.85a6.03 6.03 0 1 0 0-12.06 6.03 6.03 0 0 0 0 12.06ZM160 17.01a4.52 4.52 0 1 0 0-9.04 4.52 4.52 0 0 0 0 9.04Z"
        fill="#EAEEF8"
      />
    </g>
    <defs>
      <linearGradient
        id="c"
        x1={57.211}
        y1={35.141}
        x2={87.107}
        y2={146.249}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFDFF" />
        <stop offset={1} stopColor="#EDF0F6" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={23115.2}
        y1={2832.73}
        x2={20541.9}
        y2={12433.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFDFF" />
        <stop offset={1} stopColor="#EDF0F6" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={32846.6}
        y1={3285.53}
        x2={32846.6}
        y2={20635.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFDFF" />
        <stop offset={1} stopColor="#EDF0F6" />
      </linearGradient>
      <filter
        id="b"
        x={2.485}
        y={22.286}
        width={139.277}
        height={160.557}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={12} />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.835294 0 0 0 0 0.866667 0 0 0 0 0.917647 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2055_69416" result="shape" />
      </filter>
      <filter
        id="d"
        x={38.506}
        y={20.532}
        width={139.666}
        height={161.072}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={12} />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.835294 0 0 0 0 0.866667 0 0 0 0 0.917647 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2055_69416" result="shape" />
      </filter>
      <filter
        id="f"
        x={26.23}
        y={21.14}
        width={127.58}
        height={161.72}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={12} />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.835294 0 0 0 0 0.866667 0 0 0 0 0.917647 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2055_69416" result="shape" />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h180v180H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

const ExamPaperIcon = styled(BaseExamPaperIcon)(({ theme }) => ({
  '.custom-color-icon': {
    fill: theme.palette.primary.main,
  },
  fontSize: 180,
}));

export { ExamPaperIcon };
