import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { Box } from '../../Components/Base';
import ScrollToTop from '../../Containers/ScrollToTop';
import { ExaminationStatus } from '../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';
import { ExaminationNotFound } from '../../Components/Examination';

import ExaminationReformation from './Containers/ExaminationReformation';
import ExaminationQuestionList from './Containers/ExaminationQuestionList';
import ExamDetails from './Containers/ExamDetails';
import ExamSummary from './Containers/ExamSummary';

function ExaminationDetail() {
  const { examId = '' } = useParams();
  const { data: examination, loading: examinationLoading } = useGetExamination({
    variables: { id: examId },
  });
  const totalQuestions = examination?.qmsExam.totalQuestions ?? 0;

  if (examinationLoading) {
    return <Skeleton variant="rectangular" height={400} sx={{ borderRadius: '8px' }} />;
  }

  if (!examination) {
    return <ExaminationNotFound mt={8} />;
  }

  if (examination?.qmsExam?.status !== ExaminationStatus.Draft) {
    return (
      <Box data-testid="examination-detail-page">
        <ExamDetails />
        {totalQuestions >= 1 && (
          <Box
            width={1}
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'minmax(0px, 1fr)', md: '3fr minmax(250px, 1fr)' },
              gridTemplateAreas: {
                xs: `"exam-summary"
              "examination-list"`,
                md: '"examination-list exam-summary"',
              },
              gap: { xs: 0, md: 2 },
            }}
          >
            <ExaminationQuestionList gridArea={'examination-list'} mt={{ xs: 2, md: 0 }} />
            <ExamSummary gridArea={'exam-summary'} />
          </Box>
        )}
        <ScrollToTop />
      </Box>
    );
  }

  return (
    <Box
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
      bgcolor={'common.white'}
      data-testid="examination-detail-edit-page"
    >
      <ExaminationReformation />
    </Box>
  );
}

export default ExaminationDetail;
