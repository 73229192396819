export const PARTICIPANT_TAB_LISTS = [
  {
    label: 'แบบรายห้อง',
    value: 'candidateByClassType',
    icon: 'PeopleIcon',
  },
  {
    label: 'แบบรายบุคคล',
    value: 'candidateType',
    icon: 'PersonIcon',
  },
];

export const ROOM_OPTIONS = [
  { label: 'ม.1/1 (30)', value: 'm1' },
  { label: 'ม.1/2 (32)', value: 'm2' },
  { label: 'ม.1/3 (30)', value: 'm3' },
];

export const CANDIDATE_ROOM_EXCEPT_OPTIONS = [
  { label: 'ศุภกร วงษ์ขจร (ม.1/1)', value: 'candidates1' },
  { label: 'วงษ์ขจร มากสุข (ม.1/2)', value: 'candidates2' },
  { label: 'ศุภกร สุขใจ (ม.1/3)', value: 'candidates3' },
];
