import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

import { Button } from '../../../Components/Base';
import DownloadRoundedIcon from '../../../Components/Icons/DownloadRoundedIcon';
import ExpandMoreIcon from '../../../Components/Icons/ExpandMoreIcon';
import { EXAMINATION_EXAMINATION_PRINT_PATH } from '../../../Domains/ExaminationManagement/constant';
import { useConfig } from '../../../Contexts/ConfigProvider';

type PrintExaminationType = 'teacher' | 'student';

const DownloadExamButton = () => {
  const { exam: examConfig } = useConfig();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDownloadMenu = !!anchorEl;
  const { examId = '' } = useParams();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPrintUrl = (type: PrintExaminationType) => {
    const watermarkConfig = examConfig?.examination?.printWatermark ?? '';
    const printPath = `${EXAMINATION_EXAMINATION_PRINT_PATH.replace(':examId', examId)}`;
    const userType = type ? `type=${type}` : '';
    const embed = watermarkConfig ? `embed=${watermarkConfig}` : '';
    const queryString = userType || embed ? `?${userType}&${embed}` : '';
    return printPath + queryString;
  };

  return (
    <>
      <Button
        data-testid="download-exam-button"
        onClick={handleClick}
        startIcon={<DownloadRoundedIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        {'ดาวน์โหลดข้อสอบ'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openDownloadMenu}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        autoFocus={false}
      >
        <MenuItem
          data-testid="pdf-for-teacher"
          component={Link}
          to={getPrintUrl('teacher')}
          target={'_blank'}
        >
          สำหรับคุณครู (มีเฉลย)
        </MenuItem>
        <MenuItem
          data-testid="pdf-for-student"
          component={Link}
          to={getPrintUrl('student')}
          target={'_blank'}
        >
          สำหรับนักเรียน (ไม่มีเฉลย)
        </MenuItem>
      </Menu>
    </>
  );
};

export default DownloadExamButton;
