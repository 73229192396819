import { forwardRef } from 'react';
import {
  styled,
  FormControlLabel as MUIFormControlLabel,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';

const FormControlLabel = styled(MUIFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label ,.MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.text.primary,
  },
}));

const StyledMUISwitch = styled(MUISwitch)(({ theme }) => ({
  width: 68,
  display: 'flex',
  alignItems: 'center',
  '& .MuiSwitch-switchBase': {
    paddingLeft: 14,
    '&.Mui-checked': {
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[50],
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.grey[50],
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  '& .MuiSwitch-track': {
    height: 24,
    borderRadius: 100,
    opacity: 1,
    backgroundColor: theme.palette.grey[300],
  },
}));

type SwitchProps = {
  label: string;
} & MUISwitchProps;

const Switch = forwardRef((props: SwitchProps, ref) => {
  const { label, value, ...restProps } = props;
  return (
    <FormControlLabel
      ref={ref}
      label={label}
      control={<StyledMUISwitch checked={!!value} value={value} {...restProps} />}
    />
  );
});

export type { SwitchProps };
export default Switch;
