import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import {
  GridActionsCellItem,
  GridRowParams,
  GridRenderCellParams,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import ExaminationResultEmpty from '../Components/ExaminationResultEmpty';
import SearchExaminationResult from '../Containers/SearchExaminationResult';
import { Box, Color, DataGrid, Label, Text } from '../../../Components/Base';
import DownloadRoundedIcon from '../../../Components/Icons/DownloadRoundedIcon';
import EmptySearchResult from '../../../Components/EmptySearchResult';
import { getDateTimeString } from '../../../Containers/ExaminationEventSummary/utils';
import {
  useExaminationResults,
  ExaminationResultStatus,
} from '../../../Domains/ExaminationManagement/useExaminationResults';

const TableWrapper = styled(Box)(({ theme }) => ({
  filter: 'drop-shadow(0px 2px 15px hsla(206, 13%, 25%, 0.1))',
  marginTop: theme.spacing(3),
}));

const SearchWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: 8,
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  backgroundColor: theme.palette.common.white,
}));

const ExaminationResultList = () => {
  const { data: remoteExaminationResults } = useExaminationResults();
  const [searchParams] = useSearchParams();
  const searchExamEventNameKey = searchParams.get('examEventName');
  const searchSemesterKey = searchParams.get('semester');

  const resultList = remoteExaminationResults.filter((result) => {
    if (searchExamEventNameKey && searchSemesterKey) {
      return (
        result.title.includes(searchExamEventNameKey) &&
        result.semester?.value === searchSemesterKey
      );
    }
    if (searchExamEventNameKey) return result.title.includes(searchExamEventNameKey);
    if (searchSemesterKey) return result.semester?.value === searchSemesterKey;
    return true;
  });

  const resultNumber = resultList.length;

  const getExamDateTime = (item: GridValueGetterParams) => {
    const startDate = item.row.startDate ?? '';
    const endDate = item.row.endDate ?? '';
    const examDateTime = getDateTimeString(startDate, endDate);
    return examDateTime;
  };

  const columnTable = useMemo<GridColumns>(
    () => [
      { field: 'id', hide: true },
      {
        field: 'title',
        headerName: 'ชื่อการสอบ',
        minWidth: 200,
        flex: 1,
        renderCell: (item: GridRenderCellParams) => (
          <Text
            variant={'body1'}
            fontWeight={600}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
          >
            {item.value}
          </Text>
        ),
      },
      {
        field: 'examDateTime',
        type: 'date',
        headerName: 'วันที่จัดสอบ',
        width: 230,
        valueGetter: getExamDateTime,
      },
      {
        field: 'semester',
        headerName: 'เทอม/ปีการศึกษา',
        width: 200,
        renderCell: (item: GridRenderCellParams) => (
          <Text
            variant={'body1'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
          >
            {item.value.label}
          </Text>
        ),
      },
      {
        field: 'status',
        headerName: 'สถานะ',
        width: 160,
        renderCell: (item: GridRenderCellParams) => (
          <Label
            color={item.value === ExaminationResultStatus.IN_PROGRESS ? Color.INFO : Color.SUCCESS}
          >
            {item.value === ExaminationResultStatus.IN_PROGRESS ? 'กำลังจัดสอบ' : 'การสอบเสร็จสิ้น'}
          </Label>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'left',
        width: 100,
        // TODO: Create handle function: download .csv file
        getActions: (item: GridRowParams) => [
          <GridActionsCellItem
            icon={<DownloadRoundedIcon sx={{ color: 'grey.700' }} />}
            label="Download"
            onClick={() => {
              console.log(item.id);
            }}
          />,
        ],
      },
    ],
    [],
  );

  return (
    <>
      <SearchWrapper>
        <SearchExaminationResult />
      </SearchWrapper>
      <TableWrapper height={resultNumber === 0 ? '450px' : 'auto'}>
        <DataGrid
          rows={resultList}
          columns={columnTable}
          components={{
            NoRowsOverlay: () =>
              remoteExaminationResults.length === 0 ? (
                <ExaminationResultEmpty
                  title="คุณยังไม่มีผลการสอบ"
                  description="หากมีการจัดสอบเกิดขึ้น ผลการสอบจะแสดงที่หน้านี้"
                />
              ) : (
                <EmptySearchResult
                  title="ไม่พบผลการสอบที่คุณค้นหา"
                  description="กรุณาค้นหาอีกครั้ง"
                />
              ),
          }}
          autoHeight={resultNumber > 0}
          hideFooter
          disableSelectionOnClick
        />
      </TableWrapper>
    </>
  );
};

export default ExaminationResultList;
