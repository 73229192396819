import { FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { createElement } from 'react';

import { Box, OptionSelectionProps } from '../Base';
import FormSelectionInput from '../Form/FormSelectionInput.tsx';
import FormTextInput from '../Form/FormTextInput';
export interface ExaminationInformationFormInput {
  examName: string;
  subject: string;
  grade: string;
}

type ExaminationInformationFormContext = UseFormReturn<ExaminationInformationFormInput>;

type ExaminationInformationFormSubmission = (
  formUtils: ExaminationInformationFormContext,
) => SubmitHandler<ExaminationInformationFormInput>;

export type ExaminationInformationFormProps = {
  initialFormValues: ExaminationInformationFormInput;
  onSubmit: ExaminationInformationFormSubmission;
  submitComponent?: (formContext: ExaminationInformationFormContext) => JSX.Element;
  isDraftMode?: boolean;
  subjectOptions: OptionSelectionProps[];
  gradeOptions: OptionSelectionProps[];
};

const ExaminationInformationForm = ({
  initialFormValues,
  onSubmit,
  submitComponent,
  isDraftMode = false,
  subjectOptions,
  gradeOptions,
}: ExaminationInformationFormProps) => {
  const formContext = useForm<ExaminationInformationFormInput>({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });
  return (
    <FormProvider {...formContext}>
      <Box
        data-testid="examination-information-form"
        component="form"
        onSubmit={formContext.handleSubmit(onSubmit(formContext))}
      >
        {/* TODO: for width will be improve in task */}
        <Box sx={{ width: 1, maxWidth: '500px' }}>
          <FormTextInput
            name="examName"
            label={'ชื่อชุดข้อสอบ'}
            placeholder="กรุณากรอกชื่อชุดข้อสอบ"
            rules={{
              maxLength: { value: 150, message: 'กรุณากรอกชื่อชุดข้อสอบไม่เกิน 150 ตัวอักษร' },
              required: true,
            }}
            required
          />
          <Box mt={3} />
          <FormSelectionInput
            name="subject"
            label={'วิชา'}
            InputProps={{ placeholder: 'เลือกหน่วยการเรียนรู้' }}
            options={subjectOptions}
            disabled={isDraftMode}
          />
          <Box mt={3} />
          <FormSelectionInput
            name="grade"
            label={'ระดับชั้น'}
            options={gradeOptions}
            disabled={isDraftMode}
          />
        </Box>
        {submitComponent && createElement(submitComponent, formContext)}
      </Box>
    </FormProvider>
  );
};

export default ExaminationInformationForm;
