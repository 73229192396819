import { Divider } from '@mui/material';

import { Box, BoxProps, Text } from '../Base';

import ExaminationQuestionItem, { QuestionItemType } from './ExaminationQuestionItem';

type QuestionGroupsProps = {
  questionItems: QuestionItemType[];
  title: string;
  description: string;
  onDelete?: (id: string) => void;
  readonly?: boolean;
} & BoxProps;

const ExaminationQuestionGroups = ({
  questionItems,
  title,
  description,
  onDelete,
  readonly,
  ...restProps
}: QuestionGroupsProps) => {
  return (
    <Box
      p={3}
      mt={2}
      mb={4}
      bgcolor={'common.white'}
      borderRadius={2}
      boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
      {...restProps}
    >
      <Text data-testid="title" variant="h5">
        {title}
      </Text>
      <Box mt={1} />
      <Text data-testid="description" variant="body1">
        {description}
      </Text>
      <Divider sx={{ mt: 3, borderColor: 'grey.400', borderBottomWidth: 'medium' }} />
      {questionItems.map((question, index) => (
        <ExaminationQuestionItem
          data-testid={`question-${index + 1}`}
          mt={5}
          key={question.id}
          onDelete={() => onDelete?.(question.id)}
          question={question}
          readonly={readonly}
        />
      ))}
    </Box>
  );
};

export default ExaminationQuestionGroups;
