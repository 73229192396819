import { styled, Tab as MUITab, Tabs, TabsProps, TabProps as MUITabProps } from '@mui/material';

import { ButtonProps } from '../Button';

type TabProps = MUITabProps & ButtonProps;

const Tab = styled(MUITab)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: '600',
  textTransform: 'none',
  minHeight: 'auto',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  color: '#495057',
}));

export { Tabs, Tab };
export type { TabsProps, TabProps };
