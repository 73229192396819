import { Flex, Text } from '../../../Components/Base';
import { LinkButton } from '../../../Components/LinkButton';
import AddIcon from '../../../Components/Icons/AddIcon';
import ExaminationEventEmptyIcon from '../../../Components/Icons/ExaminationEventEmptyIcon';

const ExaminationEventEmpty = ({ redirectUrl }: { redirectUrl: string }) => {
  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'60vh'}>
      <ExaminationEventEmptyIcon color="primary" />
      <Text variant={'h4'} mt={2}>
        คุณยังไม่มีการสอบ
      </Text>
      <Text mt={0.5}>
        คลิกที่{' '}
        <Text component={'span'} fontWeight={600}>
          “สร้างการสอบ”
        </Text>{' '}
        เพื่อสร้างการสอบแรกของคุณ
      </Text>
      <LinkButton to={redirectUrl} color="neutral" startIcon={<AddIcon />} sx={{ mt: 2 }}>
        สร้างการสอบ
      </LinkButton>
    </Flex>
  );
};

export default ExaminationEventEmpty;
