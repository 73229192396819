import { useContext, useEffect, useRef } from 'react';
import {
  UNSAFE_NavigationContext,
  Navigator as BaseNavigator,
  HistoryRouterProps,
  Location,
  useLocation,
} from 'react-router-dom';

export interface Transition {
  action: HistoryRouterProps['history']['action'];
  location: Location;
  retry(): void;
}
interface Navigator extends BaseNavigator {
  block: HistoryRouterProps['history']['block'];
}

type NavigationContextWithBlock = typeof UNSAFE_NavigationContext & Navigator;
export type PromptCallback = (
  location: Location,
  transition: Transition,
  unblock: () => void,
) => void;

// Now v.6 not support usePrompt, This modification is migrate form <Prompt/>(v.5) into hook following issue ref
// Ref: https://github.com/remix-run/react-router/issues/8139
export function usePrompt(when: boolean, message: PromptCallback): () => void {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as NavigationContextWithBlock;
  const location = useLocation();
  const unblockRef = useRef(() => {});

  const triggerNativePromptOnUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return ((e || window.event).returnValue = '');
  };

  useEffect(() => {
    const shouldShowPrompt = when ?? true;
    if (!shouldShowPrompt) return;

    const unblock = navigator?.block((nextTransition: Transition) => {
      const isTheSamePage = location.pathname === nextTransition?.location.pathname;
      const isTheSameSearch = location.search === nextTransition?.location.search;

      if (isTheSamePage && isTheSameSearch) return;
      if (isTheSamePage && !isTheSameSearch) {
        unblock();
        nextTransition.retry();
        return;
      }
      if (shouldShowPrompt) message(location, nextTransition, unblock);
    });

    unblockRef.current = unblock;
    window.addEventListener('beforeunload', triggerNativePromptOnUnload);
    return () => {
      unblock();
      window.removeEventListener('beforeunload', triggerNativePromptOnUnload);
    };
  }, [message, navigator, location, when]);

  return unblockRef.current;
}
