import { Box } from '../../Components/Base';

import ExaminationEventInformationSummary from './ExaminationEventInformationSummary';
import ExaminationEventParticipantSummary from './ExaminationEventParticipantSummary';

const ExaminationEventSummary = () => {
  return (
    <>
      <ExaminationEventInformationSummary />
      <Box mt={4} />
      <ExaminationEventParticipantSummary />
    </>
  );
};

export default ExaminationEventSummary;
