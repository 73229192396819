import {
  Box,
  Button,
  Modal as BaseModal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '../../../../Components/Base';
import useDisclosure from '../../../../Utils/useDisclosure';

const Modal = () => {
  const { isOpen, open: onOpen, close: onClose } = useDisclosure();

  return (
    <Box>
      <Button onClick={onOpen} variant={'outlined'}>
        Open Modal
      </Button>

      <BaseModal open={isOpen}>
        <ModalTitle sx={{ pb: 0, mb: 3 }}>{'แก้ไขวิชา'}</ModalTitle>
        <ModalContent sx={{ pb: 0, mb: 3 }}>
          {'การแก้ไขจะทำให้คุณไม่สามารถใช้ข้อมูลชุดข้อสอบก่อนหน้านี้ได้'}
        </ModalContent>
        <ModalFooter sx={{ justifyContent: 'flex-end', px: 3, mb: 2 }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color="neutral"
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            {'ยกเลิก'}
          </Button>
          <Box pl={2} />
          <Button onClick={onClose} sx={{ width: { xs: 1, sm: 'auto' } }}>
            {'บันทึก'}
          </Button>
        </ModalFooter>
      </BaseModal>
    </Box>
  );
};

export default Modal;
