import { Flex, FlexProps } from '../../Flex';

type ModalFooterProps = {
  children?: React.ReactNode;
} & FlexProps;

const ModalFooter = (props: ModalFooterProps) => {
  const { children, ...restProps } = props;
  return (
    <Flex justifyContent={'center'} alignItems={'center'} {...restProps}>
      {children}
    </Flex>
  );
};

export default ModalFooter;
export type { ModalFooterProps };
