import { Divider, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Button } from '../../Components/Base';
import ArrowForwardIcon from '../../Components/Icons/ArrowForwardIcon';
import ArrowBackIcon from '../../Components/Icons/ArrowBackIcon';
import {
  EXAMINATION_EXAMINATION_DETAIL_PATH,
  EXAMINATION_EXAMINATION_FINALIZATION_PATH,
} from '../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';
import { ExaminationNotFound } from '../../Components/Examination';

import ExaminationQuestionSearchFilter from './Containers/ExaminationQuestionSearchFilter';
import ExaminationQuestionTable from './Containers/ExaminationQuestionTable';

function ExaminationQuestions() {
  const navigate = useNavigate();
  const { examId = '' } = useParams();
  const { data: examination, loading: examinationLoading } = useGetExamination({
    variables: { id: examId },
  });

  if (examinationLoading) {
    return <Skeleton variant="rectangular" height={400} sx={{ borderRadius: '8px' }} />;
  }

  if (!examination) {
    return <ExaminationNotFound mt={8} />;
  }

  return (
    <Box
      data-testid="examination-question"
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
      bgcolor={'common.white'}
    >
      <ExaminationQuestionSearchFilter />
      <Box mt={2} />
      <ExaminationQuestionTable />
      <Divider sx={{ mb: 4 }} />
      <Flex mt={2} justifyContent={'space-between'}>
        <Button
          data-testid="back-to-exam-detail-button"
          variant={'outlined'}
          color="neutral"
          onClick={() => {
            if (!examId) return;
            navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
          }}
          size="large"
          startIcon={<ArrowBackIcon />}
        >
          ก่อนหน้า
        </Button>
        <Box pl={2} />
        <Button
          data-testid="forward-to-exam-finalization-button"
          onClick={() => {
            if (!examId) return;
            navigate(EXAMINATION_EXAMINATION_FINALIZATION_PATH.replace(':examId', examId));
          }}
          size="large"
          endIcon={<ArrowForwardIcon />}
        >
          ต่อไป
        </Button>
      </Flex>
    </Box>
  );
}

export default ExaminationQuestions;
