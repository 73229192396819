import { styled } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Box, Text, TextProps } from '../../../../Components/Base';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { QMSTagCategory } from '../../../../Domains/ExaminationManagement/useGetQMSTags';

const ExamDetailsWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  boxShadow: '0px 2px 15px hsla(206, 13%, 25%, 0.1)',
  borderRadius: '8px',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gap: theme.spacing(2),
}));

const Title = (props: TextProps) => <Text color={'text.secondary'} {...props} />;
const Description = (props: TextProps) => <Text fontWeight={'600'} {...props} />;

const ExamDetails = () => {
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({ variables: { id: examId } });
  const { title, tags } = examination?.qmsExam ?? {};
  const grade = tags?.find((value) => value.category === QMSTagCategory.Grade)?.name;
  const subject = tags?.find((value) => value.category === QMSTagCategory.Subject)?.name;

  return (
    <ExamDetailsWrapper data-testid="examination-details">
      <ContentWrapper>
        <Title>ชื่อชุดข้อสอบ</Title>
        <Description data-testid="title">{title}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2}>
        <Title>วิชา</Title>
        <Description data-testid="subject">{subject}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2}>
        <Title>ระดับชั้น</Title>
        <Description data-testid="grade">{grade}</Description>
      </ContentWrapper>
    </ExamDetailsWrapper>
  );
};

export default ExamDetails;
