import {
  QmsExamStatus as ExaminationStatus,
  QmsOrderType as ExaminationOrder,
  QmsOrderInput as ExaminationOrderInput,
  QmsPageInput as ExaminationPageInput,
  QmsExamGeneratorType as ExaminationGenerator,
  GetExamsQuery as ExaminationQueryResult,
} from '../../generated/graphql';

export { ExaminationStatus, ExaminationOrder, ExaminationGenerator };
export type { ExaminationOrderInput, ExaminationPageInput, ExaminationQueryResult };

export const LOGIN_PATH = '/login';

export const EXAMINATION_BASE_PATH = '/exam';

export const EXAMINATION_EXAMINATIONS_PATH = `${EXAMINATION_BASE_PATH}/examinations`;

export const EXAMINATION_EXAMINATION_CREATION_PATH = `${EXAMINATION_EXAMINATIONS_PATH}/create`;
export const EXAMINATION_EXAMINATION_DETAIL_PATH = `${EXAMINATION_EXAMINATIONS_PATH}/:examId`;

export const EXAMINATION_EXAMINATION_QUESTIONS_PATH = `${EXAMINATION_EXAMINATION_DETAIL_PATH}/questions`;
export const EXAMINATION_EXAMINATION_FINALIZATION_PATH = `${EXAMINATION_EXAMINATION_DETAIL_PATH}/finalize`;
export const EXAMINATION_EXAMINATION_PRINT_PATH = `${EXAMINATION_EXAMINATION_DETAIL_PATH}/print`;

export const EXAMINATION_EVENTS_PATH = `${EXAMINATION_BASE_PATH}/events`;
export const EXAMINATION_EVENT_CREATION_PATH = `${EXAMINATION_EVENTS_PATH}/create`;
export const EXAMINATION_EVENT_DETAIL_PATH = `${EXAMINATION_EVENTS_PATH}/:eventId`;
export const EXAMINATION_EVENT_POLICIES_PATH = `${EXAMINATION_EVENT_DETAIL_PATH}/policies`;
export const EXAMINATION_EVENT_FINALIZATION_PATH = `${EXAMINATION_EVENT_DETAIL_PATH}/finalize`;

export const EXAMINATION_MANAGEMENT_STEPS = [
  { label: 'กรอกรายละเอียด', path: EXAMINATION_EXAMINATION_DETAIL_PATH },
  { label: 'เลือกคำถาม', path: EXAMINATION_EXAMINATION_QUESTIONS_PATH },
  { label: 'จัดการชุดข้อสอบและเผยแพร่', path: EXAMINATION_EXAMINATION_FINALIZATION_PATH },
];

export const EXAMINATION_EVENT_MANAGEMENT_STEPS = [
  { label: 'ตั้งค่าการสอบ', path: EXAMINATION_EVENT_DETAIL_PATH },
  { label: 'กำหนดผู้มีสิทธิ์เข้าสอบ', path: EXAMINATION_EVENT_POLICIES_PATH },
  { label: 'สรุปและเผยแพร่', path: EXAMINATION_EVENT_FINALIZATION_PATH },
];

export type ExaminationManagementStep = {
  label: string;
  path: string;
};

export const QUESTION_CHOICES = ['ก.', 'ข.', 'ค.', 'ง.', 'จ.', 'ฉ.', 'ช.', 'ซ.'];

export enum ResponseType {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  UNEXPECTED = 'UNEXPECTED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  SUCCESS = 'SUCCESS',
}

export type QuestionItemType = {
  id: string;
  bloom: string;
  choices: string[];
  correctAnswer: number;
  indicator: string;
  level: string;
  order: number;
  question: string;
  section: string;
  point: number;
};

export type QueryParamsDataGrid = {
  currentPage?: number;
  currentPageSize?: number;
  sortField?: string;
  sortOrder?: string;
};
