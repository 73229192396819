import {
  Alert,
  Box,
  Button,
  Flex,
  Snackbar as BaseSnackbar,
  Text,
} from '../../../../Components/Base';
import CheckCircleIcon from '../../../../Components/Icons/CheckCircleIcon';
import { useSnackbar } from '../../../../Contexts/SnackbarProvider';
import useDisclosure from '../../../../Utils/useDisclosure';

const Snackbar = () => {
  const {
    isOpen: isDefaultSnackbarOpen,
    open: onDefaultSnackbarOpen,
    close: onDefaultSnackbarClose,
  } = useDisclosure();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: onSuccessSnackbarOpen,
    close: onSuccessSnackbarClose,
  } = useDisclosure();
  const { open } = useSnackbar();

  return (
    <>
      <Flex>
        <Box>
          <Text variant="body2" fontWeight={600}>
            Normal Snackbar
          </Text>
          <Button variant="outlined" onClick={onDefaultSnackbarOpen} sx={{ mt: 2 }}>
            Open Snackbar
          </Button>
          <BaseSnackbar
            autoHideDuration={3000}
            open={isDefaultSnackbarOpen}
            onClose={onDefaultSnackbarClose}
          >
            <Alert>This is a default message!</Alert>
          </BaseSnackbar>
        </Box>
        <Box pl={2} />
        <Box>
          <Text variant="body2" fontWeight={600}>
            Success Snackbar
          </Text>
          <Button variant="outlined" color="success" onClick={onSuccessSnackbarOpen} sx={{ mt: 2 }}>
            Open Snackbar
          </Button>
          <BaseSnackbar
            autoHideDuration={3000}
            open={isSuccessSnackbarOpen}
            onClose={onSuccessSnackbarClose}
          >
            <Alert icon={<CheckCircleIcon fontSize="small" sx={{ color: 'success.main' }} />}>
              <Text component="span">{'This is a success message!'}</Text>
            </Alert>
          </BaseSnackbar>
        </Box>
      </Flex>
      <Text variant="body2" fontWeight={600} my={2}>
        Snackbar Provider
      </Text>
      <Flex>
        <Button onClick={() => open('Default')} sx={{ mr: 1 }}>
          Default
        </Button>
        <Button color="success" onClick={() => open('Success', 'success')} sx={{ mr: 1 }}>
          Success
        </Button>
        <Button color="info" onClick={() => open('Info', 'info')} sx={{ mr: 1 }}>
          Info
        </Button>
        <Button color="warning" onClick={() => open('Warning', 'warning')} sx={{ mr: 1 }}>
          Warning
        </Button>
        <Button color="error" onClick={() => open('Error', 'error')} sx={{ mr: 1 }}>
          Error
        </Button>
      </Flex>
    </>
  );
};

export default Snackbar;
