import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../../../Components/Base';
import CallMadeIcon from '../../../../Components/Icons/CallMadeIcon';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { EXAMINATION_EXAMINATION_FINALIZATION_PATH } from '../../../../Domains/ExaminationManagement/constant';

function GoToFinalizationPageButton() {
  const navigate = useNavigate();
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });

  return (
    <Button
      data-testid="go-to-finalization-page-button"
      variant="text"
      size="large"
      color="success"
      sx={{ height: 32, py: 0, alignItems: 'center' }}
      onClick={() => {
        if (!examId) return;
        navigate(EXAMINATION_EXAMINATION_FINALIZATION_PATH.replace(':examId', examId));
      }}
      disabled={examination?.qmsExam.examItems.length === 0}
      startIcon={<CallMadeIcon />}
    >
      ไปที่ชุดข้อสอบ
    </Button>
  );
}

export default GoToFinalizationPageButton;
