import { useNavigate, matchPath, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { Box, Text, Flex, Stepper, Step, StepProps, StepLabel } from '../../../Components/Base';
import { LinkButton } from '../../../Components/LinkButton';
import ArrowBackIcon from '../../../Components/Icons/ArrowBackIcon';
import {
  EXAMINATION_EXAMINATION_CREATION_PATH,
  EXAMINATION_EXAMINATION_FINALIZATION_PATH,
  EXAMINATION_EXAMINATIONS_PATH,
  EXAMINATION_MANAGEMENT_STEPS,
  ExaminationManagementStep,
  ExaminationStatus,
} from '../../../Domains/ExaminationManagement/constant';
import { useConfig } from '../../../Contexts/ConfigProvider';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';
import { useExaminationInformation } from '../../../Contexts/ExaminationProvider';
import SaveExaminationAsDraftButton from '../SaveExaminationAsDraftButton';

import DownloadExamButton from './DownloadExamButton';
import PublishExamButton from './PublishExamButton';

function ExaminationManagement() {
  const { exam: examConfig } = useConfig();
  const enableDownloadExamination = examConfig?.examination?.enableDownloadExamination ?? false;

  const navigate = useNavigate();
  const creationMatch = matchPath(EXAMINATION_EXAMINATION_CREATION_PATH, location.pathname);
  const finalizationMatch = matchPath(EXAMINATION_EXAMINATION_FINALIZATION_PATH, location.pathname);

  const { examId = '' } = useParams();
  const [examinationStore] = useExaminationInformation();
  const { data, loading } = useGetExamination({
    skip: !examId,
    variables: { id: examId },
  });
  const examination = data?.qmsExam;
  const isPublishMode = examination && examination?.status !== ExaminationStatus.Draft;

  let currentRouteMatch: ReturnType<typeof matchPath> = null;
  EXAMINATION_MANAGEMENT_STEPS.find((step) => {
    currentRouteMatch = matchPath({ path: step.path, end: true }, location.pathname);
    return !!currentRouteMatch;
  });

  const goToStep =
    ({ path }: ExaminationManagementStep): StepProps['onClick'] =>
    () => {
      if (currentRouteMatch?.pathname === EXAMINATION_EXAMINATION_CREATION_PATH) return;

      navigate(path.replace(':examId', currentRouteMatch?.params.examId ?? ''));
    };

  return (
    <Box data-testid="examination-management">
      <Flex justifyContent={'space-between'}>
        <LinkButton
          data-testid="back-to-examination-list-button"
          color="neutral"
          startIcon={<ArrowBackIcon />}
          to={EXAMINATION_EXAMINATIONS_PATH}
        >
          ย้อนกลับ
        </LinkButton>
        {finalizationMatch && examination && <PublishExamButton />}
      </Flex>
      {/**
       * This minimum height is used to prevent pushing of the button height.
       * value of "38px" is a height of download button and save as draft button
       */}
      {loading ? (
        <>
          <Skeleton variant="text" height={40} width={200} sx={{ borderRadius: '8px', mt: 2 }} />
          <Skeleton variant="rectangular" height={60} sx={{ borderRadius: '8px', mt: 2 }} />
        </>
      ) : (
        <Box data-testid={isPublishMode ? 'examination' : 'examination-creation'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={2} minHeight={'38px'}>
            <Text data-testid="title" variant="h4">
              {isPublishMode ? 'รายละเอียดชุดข้อสอบ' : 'จัดชุดข้อสอบ'}
            </Text>
            <Flex>
              {isPublishMode && enableDownloadExamination && examination && <DownloadExamButton />}
              {!isPublishMode && finalizationMatch && examination && (
                <SaveExaminationAsDraftButton />
              )}
            </Flex>
          </Flex>
          {(creationMatch || !isPublishMode) && (
            <Flex
              mt={2}
              px={3}
              justifyContent={'center'}
              borderRadius={2}
              py={2}
              boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
              bgcolor={'common.white'}
            >
              <Stepper sx={{ width: 1, maxWidth: '1100px' }}>
                {EXAMINATION_MANAGEMENT_STEPS.map((step, index) => {
                  const { label, path } = step;
                  const currentPathPattern = currentRouteMatch?.pattern.path;
                  const isMatchPathPattern = path === currentPathPattern;
                  const onStepClick = goToStep(step);
                  let active = false;
                  let completed = false;

                  const hasAnyQuestions = (examination?.examItems?.length ?? 0) > 0;

                  if (index === 0) {
                    active = true;
                    completed = !!examinationStore?.examName;
                  }

                  if (index === 1) {
                    completed =
                      hasAnyQuestions ||
                      EXAMINATION_EXAMINATION_FINALIZATION_PATH === currentPathPattern;
                  }

                  if (index === 2) {
                    active = hasAnyQuestions;
                    completed = hasAnyQuestions;
                  }

                  return (
                    <Step
                      key={label}
                      onClick={onStepClick}
                      /**
                       *  Do check complete and active by examination data and current path
                       *  Ref: https://www.figma.com/file/2aMCD1I8BkaI2vIyAOITLS/%5BQMS%5D-Question-Management-System?node-id=8920%3A104352
                       */
                      active={isMatchPathPattern ? true : active}
                      completed={isMatchPathPattern ? false : completed}
                      // Props disabled is check mouse cursor pointer
                      disabled={!!creationMatch}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ExaminationManagement;
