import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';

import { Flex, Box, Button } from '../../../Components/Base';
import ExaminationParticipantForm, {
  ExaminationParticipantFormProps,
  ParticipantTab,
} from '../Components/ExaminationParticipantForm';
import ArrowBackIcon from '../../../Components/Icons/ArrowBackIcon';
import ArrowForwardIcon from '../../../Components/Icons/ArrowForwardIcon';
import {
  EXAMINATION_EVENT_DETAIL_PATH,
  EXAMINATION_EVENT_FINALIZATION_PATH,
} from '../../../Domains/ExaminationManagement/constant';
import { useExaminationParticipant } from '../../../Contexts/ExaminationEventStoreProvider';

const ExaminationEventSubmissionBlock: ExaminationParticipantFormProps['submitComponent'] = (
  formContext,
) => {
  const navigate = useNavigate();
  const { eventId = '' } = useParams();
  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Flex mt={2} justifyContent={'space-between'}>
        {eventId && !location.pathname.endsWith(eventId) && (
          <Button
            variant={'outlined'}
            color="neutral"
            onClick={() => {
              if (!eventId) return;
              navigate(EXAMINATION_EVENT_DETAIL_PATH.replace(':eventId', eventId));
            }}
            size="large"
            startIcon={<ArrowBackIcon />}
          >
            {'ก่อนหน้า'}
          </Button>
        )}
        <Box pl={2} />
        <Button
          type="submit"
          disabled={formContext.formState.isSubmitting || !formContext.formState.isValid}
          size="large"
          endIcon={<ArrowForwardIcon />}
        >
          {formContext.formState.isSubmitting ? 'กำลังบันทึก..' : 'ต่อไป'}
        </Button>
      </Flex>
    </>
  );
};

const formatFormValuesToPolicies = (
  data: ExaminationParticipantFormProps['initialFormValues'],
  participantTab: string,
) => {
  const tabTypeValue = participantTab;
  const roomValues = data?.rooms;
  const exceptCandidateValues = data?.exceptCandidates;
  const candidateListValues = data?.candidateList;

  const isCandidateByClassType = tabTypeValue === ParticipantTab.CANDIDATE_BY_CLASS_TYPE;
  const isCandidateType = tabTypeValue === ParticipantTab.CANDIDATETYPE_TYPE;

  const rooms = isCandidateByClassType ? roomValues : [];
  const exceptCandidates = isCandidateByClassType ? exceptCandidateValues : [];
  const candidateList = isCandidateType ? candidateListValues : [];

  const participantForm = {
    rooms: rooms ?? [],
    exceptCandidates: exceptCandidates ?? [],
    candidateList: candidateList ?? [],
  };

  return participantForm;
};

const ExaminationParticipant = () => {
  const navigate = useNavigate();
  const { eventId = '' } = useParams();
  const [examinationParticipant, setExaminationParticipant] = useExaminationParticipant();
  return (
    <>
      <ExaminationParticipantForm
        initialFormValues={{
          candidateList: examinationParticipant?.candidateList,
          exceptCandidates: examinationParticipant?.exceptCandidates,
          rooms: examinationParticipant?.rooms,
        }}
        onSubmit={(formContext, participantTab) => (formValues) => {
          const participantForm = formatFormValuesToPolicies(formValues, participantTab);
          setExaminationParticipant(participantForm);
          if (!eventId) return;
          navigate(EXAMINATION_EVENT_FINALIZATION_PATH.replace(':eventId', eventId));
        }}
        submitComponent={ExaminationEventSubmissionBlock}
      />
    </>
  );
};

export default ExaminationParticipant;
