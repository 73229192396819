import { useState } from 'react';
import { DataGrid as MUIDataGrid, DataGridProps } from '@mui/x-data-grid';
import { styled } from '@mui/material';

const StyledDataGrid = styled(MUIDataGrid)(({ theme }) => ({
  '&.MuiDataGrid-root': {
    border: 'none',
    overflow: 'hidden',
    background: theme.palette.background.default,
  },
  '& .MuiDataGrid-columnHeaders': {
    background: theme.palette.grey[200],
  },
  '& .MuiDataGrid-main': {
    borderRadius: '8px',
  },
  '& .MuiDataGrid-row': {
    background: theme.palette.common.white,
    cursor: 'pointer',
    '[data-field="actions"]': {
      cursor: 'default',
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    background: theme.palette.primary.lighter,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 16,
    fontWeight: 600,
  },
  '& .MuiDataGrid-cell': {
    fontSize: 16,
    padding: theme.spacing(2),
  },
  '& .MuiInputBase-root': {
    marginRight: theme.spacing(1),
    minWidth: '65px',
  },
  '& .MuiTablePagination-select': {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: 14,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiTablePagination-actions': {
    fontSize: 32,
  },
  '& .MuiToolbar-root': {
    height: '64px',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: `0 ${theme.spacing(2)}`,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-actionsCell': {
    gridGap: theme.spacing(0.5),
  },
}));

const defaultLabelDisplayedRows = (props: { from: number; to: number; count: number }) => {
  const { from, to, count } = props;
  return `${from}–${to} จาก ${count !== -1 ? count : `more than ${to}`}`;
};

const DataGrid = (props: DataGridProps) => {
  const { hideFooter = false, ...restProps } = props;
  const [pageSize, setPageSize] = useState<number>(10);

  return (
    <StyledDataGrid
      headerHeight={48}
      rowHeight={60}
      pageSize={hideFooter ? 100 : pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50, 100]}
      hideFooter={hideFooter}
      hideFooterSelectedRowCount
      autoHeight
      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: 'แสดงแถว',
          labelDisplayedRows: defaultLabelDisplayedRows,
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      {...restProps}
    />
  );
};

export default DataGrid;
export type { DataGridProps };
