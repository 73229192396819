import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useExamEventDetails } from '../../../Domains/ExaminationManagement/useExamEventDetails';

import { formatInitialExaminationEventValues } from './utils';

const ExaminationEventProvider = ({ children }: { children?: React.ReactNode }) => {
  const { eventId = '' } = useParams();
  const { data: remoteExaminationEvent } = useExamEventDetails({ id: eventId });

  const initialExaminationEventValues = useMemo(
    () => formatInitialExaminationEventValues(remoteExaminationEvent),
    [remoteExaminationEvent],
  );

  // FIXME: Will be remove after api integration
  // Set default value from remoteExaminationEvent on localStorage examinationEvent
  useEffect(() => {
    localStorage.setItem('examinationEvent', JSON.stringify(initialExaminationEventValues));
  }, [initialExaminationEventValues]);

  return <>{children}</>;
};

export default ExaminationEventProvider;
