import { mockUpEmptyData, mockUpDraftData, mockUpPublishData } from './mockUpData';
import { ExaminationEventItemType } from './types';

type ExamEventDetailsType = {
  id: string;
};

const useExamEventDetails = (props: ExamEventDetailsType) => {
  const { id } = props;
  const examEventList = [mockUpEmptyData, mockUpDraftData, mockUpPublishData];

  const filterDataById = examEventList.find((event) => event.id === id);

  let data;

  if (filterDataById) {
    data = filterDataById as ExaminationEventItemType;
  } else {
    data = examEventList[0] as ExaminationEventItemType;
  }

  return { data };
};

export default useExamEventDetails;
