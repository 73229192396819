import { useFormContext } from 'react-hook-form';

import { Text, Box } from '../../../../Components/Base';
import FormCheckbox from '../../../../Components/Form/FormCheckbox';
import useGetQBDifficultyTags from '../../../../Domains/ExaminationManagement/useGetQBTags/useGetQBDifficultyTags';
import { getTagOptions } from '../utils';

const getCheckboxName = (value: string) => {
  switch (value) {
    case 'ง่าย':
      return 'easy';
    case 'ปานกลาง':
      return 'medium';
    case 'ยาก':
      return 'hard';
    default:
      return;
  }
};

const DifficultyCheckbox = () => {
  const formContext = useFormContext();
  const { data } = useGetQBDifficultyTags();

  const learningUnit = formContext.watch('learningUnit.value');
  const isLearningUnitEmptyState = !learningUnit;

  const difficultyTags = data?.tags ?? [];
  const difficultyOptions = getTagOptions(difficultyTags);

  return (
    <Box>
      <Text component={'span'} variant={'body2'} fontWeight={'600'}>
        {'ระดับความยาก'}
      </Text>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0.5 }}>
        {difficultyOptions.map((option) => {
          const checkboxName = getCheckboxName(option.label);
          return (
            <FormCheckbox
              key={`level.${checkboxName}`}
              name={`level.${checkboxName}`}
              label={option.label}
              disabled={isLearningUnitEmptyState}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default DifficultyCheckbox;
