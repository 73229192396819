import { Navigate, useLocation } from 'react-router-dom';

import { useAuthentication, LoginState } from '../../Contexts/AuthenticationProvider';
import { Loading } from '../../Components/Base';
import { LOGIN_PATH } from '../../Domains/ExaminationManagement/constant';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { loginState } = useAuthentication();

  if (loginState === LoginState.LOADING) {
    return <Loading open />;
  }

  if (loginState === LoginState.LOGGED_IN) return children;

  return <Navigate to={LOGIN_PATH} state={{ from: location }} />;
}

export default ProtectedRoute;
