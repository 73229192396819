import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExamPaperIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 180 180" sx={{ width: 180, height: 180 }} {...props}>
    <g clipPath="url(#clip0_2055_69416)">
      <path
        d="M90 160C128.66 160 160 128.66 160 90C160 51.3401 128.66 20 90 20C51.3401 20 20 51.3401 20 90C20 128.66 51.3401 160 90 160Z"
        fill="#E9ECEF"
      />
      <g filter="url(#filter0_d_2055_69416)">
        <path
          d="M90.0471 25.591L24.0156 43.3582C19.3118 44.6239 16.5246 49.4631 17.7902 54.167L43.3913 149.313C44.657 154.017 49.4963 156.804 54.2001 155.538L120.232 137.771C124.935 136.505 127.723 131.666 126.457 126.962L100.856 31.8164C99.5901 27.1125 94.7509 24.3253 90.0471 25.591Z"
          fill="url(#paint0_linear_2055_69416)"
        />
      </g>
      <g filter="url(#filter1_d_2055_69416)">
        <path
          d="M79.4286 30.0981L53.8108 125.672C52.5443 130.397 55.348 135.254 60.073 136.521L126.402 154.3C131.127 155.566 135.984 152.762 137.25 148.037L162.868 52.4633C164.134 47.7383 161.331 42.8812 156.606 41.6147L90.2772 23.8359C85.5522 22.5694 80.6951 25.3731 79.4286 30.0981Z"
          fill="url(#paint1_linear_2055_69416)"
        />
      </g>
      <g filter="url(#filter2_d_2055_69416)">
        <path
          d="M138.81 62.6999V145.86C138.81 148.512 137.756 151.056 135.881 152.931C134.006 154.806 131.462 155.86 128.81 155.86H51.23C48.5778 155.86 46.0343 154.806 44.1589 152.931C42.2835 151.056 41.23 148.512 41.23 145.86V34.1399C41.23 31.4877 42.2835 28.9442 44.1589 27.0688C46.0343 25.1935 48.5778 24.1399 51.23 24.1399H100.38C101.657 24.1404 102.921 24.3933 104.1 24.8841C105.279 25.3749 106.35 26.094 107.25 26.9999L135.81 55.5699C136.755 56.5019 137.507 57.6117 138.022 58.8353C138.537 60.059 138.805 61.3724 138.81 62.6999Z"
          fill="url(#paint2_linear_2055_69416)"
        />
        <path
          d="M138.43 60.1299C138.179 59.6501 137.803 59.2471 137.342 58.9637C136.881 58.6802 136.351 58.5269 135.81 58.5199H114.4C111.748 58.5199 109.204 57.4663 107.329 55.591C105.453 53.7156 104.4 51.1721 104.4 48.5199V28.1399C104.4 27.079 103.978 26.0616 103.228 25.3115C102.478 24.5613 101.461 24.1399 100.4 24.1399C102.972 24.1473 105.436 25.176 107.25 26.9999L135.81 55.5699C137.074 56.8289 137.979 58.4034 138.43 60.1299Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M95.65 48.8401H54C53.1716 48.8401 52.5 49.5117 52.5 50.3401C52.5 51.1685 53.1716 51.8401 54 51.8401H95.65C96.4784 51.8401 97.15 51.1685 97.15 50.3401C97.15 49.5117 96.4784 48.8401 95.65 48.8401Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M83.45 57.1299H54C53.1716 57.1299 52.5 57.8015 52.5 58.6299C52.5 59.4583 53.1716 60.1299 54 60.1299H83.45C84.2784 60.1299 84.95 59.4583 84.95 58.6299C84.95 57.8015 84.2784 57.1299 83.45 57.1299Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 73H70.1902C69.3784 73 68.7202 73.6581 68.7202 74.47V74.53C68.7202 75.3419 69.3784 76 70.1902 76H126.03C126.842 76 127.5 75.3419 127.5 74.53V74.47C127.5 73.6581 126.842 73 126.03 73Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M59.98 79.5H55.98C55.1843 79.5 54.4213 79.1839 53.8586 78.6213C53.296 78.0587 52.98 77.2956 52.98 76.5V72.5C52.98 71.7044 53.296 70.9413 53.8586 70.3787C54.4213 69.8161 55.1843 69.5 55.98 69.5H59.98C60.7756 69.5 61.5387 69.8161 62.1013 70.3787C62.6639 70.9413 62.98 71.7044 62.98 72.5V76.5C62.98 77.2956 62.6639 78.0587 62.1013 78.6213C61.5387 79.1839 60.7756 79.5 59.98 79.5ZM55.98 71.5C55.7148 71.5 55.4604 71.6054 55.2729 71.7929C55.0853 71.9804 54.98 72.2348 54.98 72.5V76.5C54.98 76.7652 55.0853 77.0196 55.2729 77.2071C55.4604 77.3946 55.7148 77.5 55.98 77.5H59.98C60.2452 77.5 60.4995 77.3946 60.6871 77.2071C60.8746 77.0196 60.98 76.7652 60.98 76.5V72.5C60.98 72.2348 60.8746 71.9804 60.6871 71.7929C60.4995 71.6054 60.2452 71.5 59.98 71.5H55.98Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 91.3799H70.1902C69.3784 91.3799 68.7202 92.038 68.7202 92.8499V92.9099C68.7202 93.7217 69.3784 94.3799 70.1902 94.3799H126.03C126.842 94.3799 127.5 93.7217 127.5 92.9099V92.8499C127.5 92.038 126.842 91.3799 126.03 91.3799Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M59.98 97.8799H55.98C55.1843 97.8799 54.4213 97.5638 53.8586 97.0012C53.296 96.4386 52.98 95.6755 52.98 94.8799V90.8799C52.98 90.0842 53.296 89.3212 53.8586 88.7586C54.4213 88.196 55.1843 87.8799 55.98 87.8799H59.98C60.7756 87.8799 61.5387 88.196 62.1013 88.7586C62.6639 89.3212 62.98 90.0842 62.98 90.8799V94.8799C62.98 95.6755 62.6639 96.4386 62.1013 97.0012C61.5387 97.5638 60.7756 97.8799 59.98 97.8799ZM55.98 89.8799C55.7148 89.8799 55.4604 89.9852 55.2729 90.1728C55.0853 90.3603 54.98 90.6147 54.98 90.8799V94.8799C54.98 95.1451 55.0853 95.3995 55.2729 95.587C55.4604 95.7745 55.7148 95.8799 55.98 95.8799H59.98C60.2452 95.8799 60.4995 95.7745 60.6871 95.587C60.8746 95.3995 60.98 95.1451 60.98 94.8799V90.8799C60.98 90.6147 60.8746 90.3603 60.6871 90.1728C60.4995 89.9852 60.2452 89.8799 59.98 89.8799H55.98Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 109.75H70.1902C69.3784 109.75 68.7202 110.408 68.7202 111.22V111.28C68.7202 112.092 69.3784 112.75 70.1902 112.75H126.03C126.842 112.75 127.5 112.092 127.5 111.28V111.22C127.5 110.408 126.842 109.75 126.03 109.75Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M59.98 116.25H55.98C55.1843 116.25 54.4213 115.934 53.8586 115.371C53.296 114.809 52.98 114.046 52.98 113.25V109.25C52.98 108.454 53.296 107.691 53.8586 107.129C54.4213 106.566 55.1843 106.25 55.98 106.25H59.98C60.7756 106.25 61.5387 106.566 62.1013 107.129C62.6639 107.691 62.98 108.454 62.98 109.25V113.25C62.98 114.046 62.6639 114.809 62.1013 115.371C61.5387 115.934 60.7756 116.25 59.98 116.25ZM55.98 108.25C55.7148 108.25 55.4604 108.355 55.2729 108.543C55.0853 108.73 54.98 108.985 54.98 109.25V113.25C54.98 113.515 55.0853 113.77 55.2729 113.957C55.4604 114.145 55.7148 114.25 55.98 114.25H59.98C60.2452 114.25 60.4995 114.145 60.6871 113.957C60.8746 113.77 60.98 113.515 60.98 113.25V109.25C60.98 108.985 60.8746 108.73 60.6871 108.543C60.4995 108.355 60.2452 108.25 59.98 108.25H55.98Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 128.13H70.1902C69.3784 128.13 68.7202 128.788 68.7202 129.6V129.66C68.7202 130.472 69.3784 131.13 70.1902 131.13H126.03C126.842 131.13 127.5 130.472 127.5 129.66V129.6C127.5 128.788 126.842 128.13 126.03 128.13Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M59.98 134.63H55.98C55.1843 134.63 54.4213 134.314 53.8586 133.751C53.296 133.189 52.98 132.426 52.98 131.63V127.63C52.98 126.834 53.296 126.071 53.8586 125.509C54.4213 124.946 55.1843 124.63 55.98 124.63H59.98C60.7756 124.63 61.5387 124.946 62.1013 125.509C62.6639 126.071 62.98 126.834 62.98 127.63V131.63C62.98 132.426 62.6639 133.189 62.1013 133.751C61.5387 134.314 60.7756 134.63 59.98 134.63ZM55.98 126.63C55.7148 126.63 55.4604 126.735 55.2729 126.923C55.0853 127.11 54.98 127.365 54.98 127.63V131.63C54.98 131.895 55.0853 132.149 55.2729 132.337C55.4604 132.525 55.7148 132.63 55.98 132.63H59.98C60.2452 132.63 60.4995 132.525 60.6871 132.337C60.8746 132.149 60.98 131.895 60.98 131.63V127.63C60.98 127.365 60.8746 127.11 60.6871 126.923C60.4995 126.735 60.2452 126.63 59.98 126.63H55.98Z"
          fill="currentColor"
        />
      </g>
      <path
        d="M17.9201 146.18C22.4931 146.18 26.2001 142.473 26.2001 137.9C26.2001 133.327 22.4931 129.62 17.9201 129.62C13.3472 129.62 9.64014 133.327 9.64014 137.9C9.64014 142.473 13.3472 146.18 17.9201 146.18Z"
        fill="#E9ECEF"
      />
      <path
        d="M156.47 36.85C159.8 36.85 162.5 34.1503 162.5 30.82C162.5 27.4898 159.8 24.79 156.47 24.79C153.14 24.79 150.44 27.4898 150.44 30.82C150.44 34.1503 153.14 36.85 156.47 36.85Z"
        fill="#E9ECEF"
      />
      <path
        d="M160 17.01C162.496 17.01 164.52 14.9863 164.52 12.49C164.52 9.99364 162.496 7.96997 160 7.96997C157.504 7.96997 155.48 9.99364 155.48 12.49C155.48 14.9863 157.504 17.01 160 17.01Z"
        fill="#E9ECEF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2055_69416"
        x="2.48535"
        y="22.2859"
        width="139.277"
        height="160.557"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69416"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2055_69416"
        x="38.5063"
        y="20.5317"
        width="139.666"
        height="161.072"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69416"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2055_69416"
        x="26.23"
        y="21.1399"
        width="127.58"
        height="161.72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69416" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69416"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2055_69416"
        x1="8238.77"
        y1="811.673"
        x2="12214.7"
        y2="15588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F7F9" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2055_69416"
        x1="4835.85"
        y1="-9453.65"
        x2="15826.5"
        y2="-6507.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F7F9" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2055_69416"
        x1="8823.43"
        y1="3285.53"
        x2="8823.43"
        y2="20635.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_2055_69416">
        <rect width="180" height="180" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ExamPaperIcon;
