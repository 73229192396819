import { createContext, useContext, ReactNode } from 'react';

import useGetMyProfile from '../../Domains/User/useGetMyProfile';
import useLogout from '../../Domains/User/Logout';

import { UserProfile } from './constant';
import { formatUserProfile } from './utils';

export enum LoginState {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

export type Authentication = {
  userProfile: UserProfile | null;
  loginState: LoginState;
  logout: () => void;
};

const AuthenticationContext = createContext<Authentication | undefined>(undefined);

const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  let loginState = LoginState.LOADING;
  const { data, loading, client } = useGetMyProfile({
    onError: (error) => console.error(error),
  });

  const [logout] = useLogout({
    onCompleted: () => client.cache.modify({ fields: { getMyProfile: () => null } }),
    onError: (error) => console.error(error),
  });

  const myProfile = data?.getMyProfile;
  const userProfile = myProfile ? formatUserProfile(myProfile) : null;

  if (!loading) {
    if (userProfile) loginState = LoginState.LOGGED_IN;
    else loginState = LoginState.NOT_LOGGED_IN;
  }

  return (
    <AuthenticationContext.Provider value={{ userProfile, loginState, logout }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = (): Authentication => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuthentication must be used within AuthenticationProvider');
  }
  return context;
};

export default AuthenticationProvider;
export { AuthenticationContext, useAuthentication };
