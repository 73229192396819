import { useMemo } from 'react';
import { useNavigate, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import {
  GridActionsCellItem,
  GridRowParams,
  GridRenderCellParams,
  GridColumns,
} from '@mui/x-data-grid';

import { Box, Color, DataGrid, Label, Text } from '../../../Components/Base';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import EditIcon from '../../../Components/Icons/EditIcon';
import EmptySearchResult from '../../../Components/EmptySearchResult';
import ExaminationEventEmpty from '../Components/ExaminationEventEmpty';
import {
  EXAMINATION_EVENTS_PATH,
  EXAMINATION_EVENT_CREATION_PATH,
  EXAMINATION_EVENT_DETAIL_PATH,
} from '../../../Domains/ExaminationManagement/constant';
import {
  useExaminationEvents,
  ExaminationEventStatus,
} from '../../../Domains/ExaminationManagement/useExaminationEvents';
import SearchBox from '../../../Containers/SearchBox';

const TableWrapper = styled(Box)(({ theme }) => ({
  filter: 'drop-shadow(0px 2px 15px hsla(206, 13%, 25%, 0.1))',
  marginTop: theme.spacing(3),
}));

const Link = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

const ExaminationEventList = () => {
  const { data } = useExaminationEvents();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchKey = searchParams.get('q') ?? '';

  const eventList = data.filter((event) => event.title.includes(searchKey));
  const resultNumber = eventList.length;

  const columnTable = useMemo<GridColumns>(
    () => [
      { field: 'id', hide: true },
      {
        field: 'title',
        headerName: 'ชื่อการสอบ',
        minWidth: 200,
        flex: 1,
        renderCell: (item: GridRenderCellParams) => (
          <Link to={`${EXAMINATION_EVENTS_PATH}/${item.id}`}>
            <Text
              variant={'body1'}
              fontWeight={600}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {item.value}
            </Text>
          </Link>
        ),
      },
      { field: 'examDate', type: 'date', headerName: 'วันที่จัดสอบ', width: 230 },
      {
        field: 'status',
        headerName: 'สถานะ',
        width: 120,
        renderCell: (item: GridRenderCellParams) => (
          <Label color={item.value === ExaminationEventStatus.DRAFT ? Color.INFO : Color.SUCCESS}>
            {item.value === ExaminationEventStatus.DRAFT ? 'ฉบับร่าง' : 'เผยแพร่'}
          </Label>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'left',
        width: 120,
        // TODO: Create handle function: edit and delete
        getActions: (item: GridRowParams) =>
          item.row.status === 'DRAFT'
            ? [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={() => {
                    if (!item.id || typeof item.id !== 'string') return;
                    navigate(EXAMINATION_EVENT_DETAIL_PATH.replace(':eventId', item.id));
                  }}
                />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {}} />,
              ]
            : [],
      },
    ],
    [navigate],
  );

  if (data.length === 0) {
    return <ExaminationEventEmpty redirectUrl={EXAMINATION_EVENT_CREATION_PATH} />;
  }

  return (
    <>
      <SearchBox placeholder="ค้นหาชื่อการสอบ" />
      <TableWrapper height={resultNumber === 0 ? '450px' : 'auto'}>
        <DataGrid
          rows={eventList}
          columns={columnTable}
          components={{
            NoRowsOverlay: () => (
              <EmptySearchResult title="ไม่พบการสอบที่คุณค้นหา" description="กรุณาค้นหาอีกครั้ง" />
            ),
          }}
          autoHeight={resultNumber > 0}
          disableSelectionOnClick
        />
      </TableWrapper>
    </>
  );
};

export default ExaminationEventList;
