import { Flex, FlexProps, Text } from '../Base';
import ArrowBackIcon from '../Icons/ArrowBackIcon';
import ExamPaperIcon from '../Icons/ExamPaperIcon';
import { LinkButton } from '../LinkButton';

const ExaminationNotFound = (props: FlexProps) => {
  return (
    <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} {...props}>
      <ExamPaperIcon color="primary" />
      <Text variant={'h4'} mt={2}>
        ไม่พบข้อมูล
      </Text>
      <Text mt={0.5}>ไม่พบชุดข้อสอบที่ต้องการแก้ไข ข้อมูลอาจถูกลบจากผู้ใช้อื่น</Text>
      <LinkButton
        to={'/exam'}
        color="neutral"
        size={'large'}
        sx={{ mt: 2 }}
        startIcon={<ArrowBackIcon />}
      >
        กลับหน้าแรก
      </LinkButton>
    </Flex>
  );
};

export default ExaminationNotFound;
