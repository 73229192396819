import { Box, Text } from '../../Components/Base';

import ExaminationParticipant from './Containers/ExaminationParticipant';

const ExaminationEventPolicies = () => {
  return (
    <Box
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px hsla(206, 13%, 25%, 0.1)'}
      bgcolor={'common.white'}
    >
      <Text variant="h4">{'ผู้มีสิทธิ์เข้าสอบ'}</Text>
      <Box mt={3} />
      <ExaminationParticipant />
    </Box>
  );
};

export default ExaminationEventPolicies;
