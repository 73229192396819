import { styled, Divider } from '@mui/material';

import { Box, Text, TextProps } from '../../Components/Base';
import { useExaminationEventInformation } from '../../Contexts/ExaminationEventStoreProvider';
import { useConfig } from '../../Contexts/ConfigProvider';

import { getDateTimeString, formatDisplayExaminationEventInformation } from './utils';

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 3.5fr',
  gap: theme.spacing(2),
}));

const Title = (props: TextProps) => <Text color={'text.secondary'} fontWeight={'600'} {...props} />;
const Description = (props: TextProps) => <Text fontWeight={'600'} {...props} />;

const ExaminationEventInformationSummary = () => {
  const [examinationEventInformation] = useExaminationEventInformation();

  const {
    examEventName,
    semester,
    grade,
    examination,
    startTime,
    endTime,
    randomQuestion,
    randomAnswer,
    shouldShowPoints,
    instruction,
  } = formatDisplayExaminationEventInformation(examinationEventInformation);

  const dateTime = getDateTimeString(startTime, endTime);

  const { exam: examConfig } = useConfig();
  const enableRandomQuestions = examConfig?.examinationEvent?.enableRandomQuestions ?? false;
  const enableRandomChoices = examConfig?.examinationEvent?.enableRandomChoices ?? false;

  return (
    <>
      <Text variant="subtitle1" mb={2}>
        ข้อมูลการจัดสอบ
      </Text>
      <Divider sx={{ mt: 2 }} />
      <ContentWrapper mt={2.5}>
        <Title>ชื่อการสอบ</Title>
        <Description>{examEventName}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>เทอม/ปีการศึกษา</Title>
        <Description>{semester}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>ระดับชั้น</Title>
        <Description>{grade}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>วันที่และเวลาสอบ</Title>
        <Description>{dateTime}</Description>
      </ContentWrapper>

      <Text variant="subtitle1" mt={4}>
        การตั้งค่าชุดข้อสอบ
      </Text>
      <Divider sx={{ mt: 2 }} />
      <ContentWrapper mt={2.5}>
        <Title>ชื่อชุดข้อสอบ</Title>
        <Description>{examination}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>วิชา</Title>
        <Description>{'คณิตศาสตร์'}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>จำนวน</Title>
        <Description>{'20 ข้อ'}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>คะแนนรวม</Title>
        <Description>{'20 คะแนน'}</Description>
      </ContentWrapper>

      {enableRandomQuestions && (
        <ContentWrapper mt={2.5}>
          <Title>การสุ่มลำดับของคำถาม</Title>
          <Description>{randomQuestion}</Description>
        </ContentWrapper>
      )}
      {enableRandomChoices && (
        <ContentWrapper mt={2.5}>
          <Title>การสุ่มลำดับของตัวเลือก</Title>
          <Description>{randomAnswer}</Description>
        </ContentWrapper>
      )}

      <ContentWrapper mt={2.5}>
        <Title>ผู้เข้าสอบเห็นคะแนนรายข้อ</Title>
        <Description>{shouldShowPoints}</Description>
      </ContentWrapper>
      <ContentWrapper mt={2.5}>
        <Title>คำชี้แจงในการทำข้อสอบ</Title>
        <Description>{instruction}</Description>
      </ContentWrapper>
    </>
  );
};

export default ExaminationEventInformationSummary;
