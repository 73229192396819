import DateRangePicker from '@mui/lab/DateRangePicker';
import { styled } from '@mui/material';
import { useController } from 'react-hook-form';

import { Box, Flex, TextInput } from '../../Base';
import CalendarTodayRoundedIcon from '../../Icons/CalendarTodayRoundedIcon';

import { FormDateRangePickerProps, FieldValues } from './types';

const CalendarIcon = styled(CalendarTodayRoundedIcon)(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.text.secondary,
}));

const FormDateRangePicker = <TFieldValues extends FieldValues = FieldValues>(
  props: FormDateRangePickerProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });

  return (
    <DateRangePicker
      {...field}
      calendars={1}
      renderInput={(startProps, endProps) => (
        <Flex width={1}>
          <TextInput
            {...startProps}
            label={'วันที่เริ่มต้น'}
            error={isTouched ? invalid : false}
            helperText={error?.message ?? null}
            InputProps={{
              endAdornment: <CalendarIcon />,
            }}
          />
          <Box ml={2} />
          <TextInput
            {...endProps}
            label={'วันที่สิ้นสุด'}
            error={isTouched ? invalid : false}
            helperText={error?.message ?? null}
            InputProps={{
              endAdornment: <CalendarIcon />,
            }}
          />
        </Flex>
      )}
      inputFormat={'dd/MM/yyyy'}
      {...props}
    />
  );
};

export default FormDateRangePicker;
