import { styled, Grid, GridProps } from '@mui/material';

import { Box, Text, TextProps, BoxProps } from '../../Base';

const ExaminationUserInfoWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '6px',
}));

const Title = (props: TextProps) => (
  <Text fontWeight={'600'} whiteSpace={'nowrap'} variant="caption" {...props} />
);
const Description = (props: TextProps) => (
  <Text
    variant="caption"
    whiteSpace={'nowrap'}
    overflow={'hidden'}
    textOverflow={'ellipsis'}
    {...props}
  />
);
const GridItem = (props: GridProps) => <Grid display="flex" item {...props} />;
const char = '.';

const ExaminationUserInfo = (props: BoxProps) => {
  return (
    <ExaminationUserInfoWrapper data-testid="examination-user-info" {...props}>
      <Grid container spacing={2}>
        <GridItem xs={6}>
          <Title mr={1.25}>ชื่อ-นามสกุล</Title>
          <Description>{char.repeat(200)}</Description>
        </GridItem>
        <GridItem xs={3}>
          <Title mr={1.25}>ระดับชั้น</Title>
          <Description>{char.repeat(100)}</Description>
        </GridItem>
        <GridItem xs={3}>
          <Title mr={1.25}>เลขที่</Title>
          <Description>{char.repeat(100)}</Description>
        </GridItem>
      </Grid>
    </ExaminationUserInfoWrapper>
  );
};

export default ExaminationUserInfo;
