import { useController } from 'react-hook-form';

import Checkbox from '../../Base/Input/Checkbox';

import { FormCheckboxProps, FieldValues } from './types';

const FormCheckbox = <TFieldValues extends FieldValues = FieldValues>(
  props: FormCheckboxProps<TFieldValues>,
): JSX.Element => {
  const { field } = useController({
    ...props,
    name: props.name,
  });
  return <Checkbox {...props} {...field} />;
};

export type { FormCheckboxProps };
export default FormCheckbox;
