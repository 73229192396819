import { Divider, styled } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Box, Flex, Text } from '../../../../Components/Base';
import BaseHTMLView from '../../../../Components/HTMLView';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestions,
} from '../../../../Domains/ExaminationManagement/utils';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';

const EllipsisText = styled(Text)({
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const HTMLView = styled(BaseHTMLView)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  position: 'relative',
}));

const adjustImageSpacingInQuestion = (question: string) => {
  return question.replaceAll('<figure>', '<figure style="margin: 8px 0px;">');
};

const insertQuestionOrder = (questionOrder: number, question: string) => {
  return `<p style="display: inline-grid"><span style="padding-right: 4px">${questionOrder}.</span> ${question}</p>`;
};

const ExaminationQuestionSummaryList = () => {
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });
  const examItems = examination?.qmsExam.examItems ?? [];
  const questions = formatExaminationItemToQuestions(examItems);
  const questionGroupBySection = groupQuestionBySection(questions ?? []);

  return (
    <Box data-testid="examination-question-summary-list">
      {questionGroupBySection.map((sectionGroup, sectionIndex) => {
        return (
          <Box
            data-testid={`section-${sectionIndex + 1}`}
            mt={sectionIndex === 0 ? 0 : 3}
            key={`section-${sectionIndex + 1}`}
          >
            <Flex justifyContent={'space-between'} width={1}>
              <EllipsisText data-testid="section-title" variant={'body1'} fontWeight={600}>
                {sectionGroup.section}
              </EllipsisText>
              <Text
                data-testid="question-count"
                variant={'body1'}
                fontWeight={600}
                ml={1}
                flex={'0 0 auto'}
              >
                {`${sectionGroup.allQuestionCount} ข้อ`}
              </Text>
            </Flex>

            {sectionGroup.items.map((indicatorGroup, indicatorIndex) => {
              return (
                <Box
                  data-testid={`indicator-${indicatorIndex + 1}`}
                  key={`indicator-${indicatorIndex + 1}`}
                >
                  <Flex
                    justifyContent={'space-between'}
                    width={1}
                    mt={indicatorIndex === 0 ? 1 : 2}
                  >
                    <Text data-testid="title" variant={'body2'} fontWeight={600}>
                      {indicatorGroup.indicator.substring(0, 11).trim()}
                    </Text>
                    <Text data-testid="question-count" variant={'body2'} fontWeight={600}>
                      {`${indicatorGroup.questionCount} ข้อ`}
                    </Text>
                  </Flex>
                  <Divider sx={{ my: 1 }} />
                  {indicatorGroup.items.map((item, index) => {
                    const questionOrder = item.order;
                    let formattedQuestion = item.question;
                    formattedQuestion = adjustImageSpacingInQuestion(formattedQuestion);
                    formattedQuestion = insertQuestionOrder(questionOrder, formattedQuestion);
                    return (
                      <HTMLView
                        data-testid={`question-${index + 1}`}
                        minHeight={40}
                        maxHeight={120}
                        my={3}
                        rawContent={formattedQuestion}
                        key={`question-${index + 1}`}
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default ExaminationQuestionSummaryList;
