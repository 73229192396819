import { IconButton, IconButtonProps } from '../../../Components/Base/Button';
import ContentCopyRoundedIcon from '../../../Components/Icons/ContentCopyRoundedIcon';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';
import { ResponseType } from '../../../Domains/ExaminationManagement/constant';
import useDuplicateExamination from '../../../Domains/ExaminationManagement/useDuplicateExamination';

type DuplicateExaminationButtonProps = {
  examId: string;
  onCompleted?: (() => void) | (() => Promise<void>);
} & IconButtonProps;

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการคัดลอก',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'คัดลอกชุดข้อสอบเรียบร้อยแล้ว',
};

const DuplicateExaminationButton = (props: DuplicateExaminationButtonProps) => {
  const { examId, onCompleted, ...buttonProps } = props;
  const { open: openSnackbar } = useSnackbar();
  const [duplicateExamMutation] = useDuplicateExamination();

  const handleDuplicateExamination = async () => {
    await duplicateExamMutation({
      variables: { id: examId },
      onCompleted: () => {
        openSnackbar(RESPONSE_MESSAGE.SUCCESS, 'success');
        onCompleted?.();
      },
      onError: (error) => {
        console.error(error);
        //TODO: handle all error message after connect learn auth
        let message = RESPONSE_MESSAGE.UNEXPECTED;
        if (error.message.includes('Not found Exam')) {
          message = RESPONSE_MESSAGE.NOT_FOUND;
        } else if (error.message === 'Failed to fetch') {
          message = RESPONSE_MESSAGE.NETWORK_ERROR;
        }
        openSnackbar(message, 'error');
      },
    });
  };

  return (
    <IconButton data-testid="duplicate-exam" {...buttonProps} onClick={handleDuplicateExamination}>
      <ContentCopyRoundedIcon />
    </IconButton>
  );
};

export default DuplicateExaminationButton;
