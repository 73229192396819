export const mockUpEmptyData = {
  id: '33c6fe9d-72c6-4066-8ba1-d8ee87df0088',
  title: 'ภาษาไทย ม.1 (ข้อสอบกลางภาคเรียนที่ 2/2564)',
  examDate: '23-03-2022 14:30-15:30',
  status: 'DRAFT',
  semester: { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
  grade: { label: 'ม.1', value: 'm1' },
  startDate: undefined,
  endDate: undefined,
  examination: undefined,
  randomQuestion: false,
  randomAnswer: false,
  shouldShowPoints: false,
  instruction: '',
  policies: {
    candidateByClass: undefined,
    candidateExcepted: undefined,
    candidateList: undefined,
  },
};

export const mockUpDraftData = {
  id: '20254095-03d4-4505-8f11-128872ed04a0',
  title: 'คณิตศาสตร์ ม.1 (ข้อสอบกลางภาคเรียนที่ 2/2564)',
  examDate: '24-01-2022 13:00-14:00',
  status: 'DRAFT',
  semester: { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
  grade: { label: 'ม.1', value: 'm1' },
  startDate: 'Fri, 24 Jun 2022 02:00:00 GMT',
  endDate: 'Fri, 24 Jun 2022 11:00:00 GMT',
  examination: {
    label: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
    value: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
  },
  randomQuestion: true,
  randomAnswer: false,
  shouldShowPoints: false,
  instruction:
    'This is draft instruction. Vivamus eget lacinia risus. Morbi luctus ante nec orci finibus lobortis. Donec tristique erat sed arcu congue mollis. Vestibulum sollicitudin massa vitae imperdiet posuere. Duis elementum, lorem nec mattis dictum, elit neque accumsan sem, nec finibus velit sapien a lorem. Nullam eget metus luctus, dapibus lectus dictum, tincidunt mauris. Nunc vehicula rutrum lacus, vel pharetra libero lacinia posuere. Sed a dui nisi. Morbi turpis leo, egestas sit amet erat id, porta rhoncus nibh. Etiam erat libero, vehicula quis purus et, eleifend molestie turpis. Etiam tristique sagittis congue. In non ipsum sagittis, pulvinar diam eget, sagittis sapien. Nam sit amet sem in enim gravida dignissim vel vel odio. Sed maximus lectus diam, et laoreet quam tincidunt at. Suspendisse potenti.',
  policies: {
    candidateByClass: [
      { id: 'm1', class: 'ม.1/1', students: 30 },
      { id: 'm2', class: 'ม.1/2', students: 32 },
      { id: 'm3', class: 'ม.1/3', students: 30 },
    ],
    candidateExcepted: [
      { id: 'candidates1', firstName: 'ศุภกร', lastName: 'วงษ์ขจร', class: 'ม.1/1' },
    ],
    candidateList: undefined,
  },
};

export const mockUpPublishData = {
  id: 'eff0bde4-a98a-4a75-ae01-28ed58b9a913',
  title: 'วิทยาศาสตร์ ม.1 (ข้อสอบกลางภาคภาคเรียนที่ 2/2564)',
  examDate: '24-01-2022 13:00-14:00',
  status: 'PUBLISH',
  semester: { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
  grade: { label: 'ม.1', value: 'm1' },
  startDate: 'Sat, 12 Mar 2022 02:00:00 GMT',
  endDate: 'Sat, 12 Mar 2022 05:00:00 GMT',
  examination: {
    label: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
    value: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
  },
  randomQuestion: true,
  randomAnswer: false,
  shouldShowPoints: false,
  instruction:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris volutpat metus ut sapien volutpat luctus. Etiam interdum at metus et condimentum. Quisque accumsan lacus id mi accumsan volutpat. Donec non pellentesque nunc, in porta urna. Nunc condimentum volutpat hendrerit. Etiam dignissim quam eleifend tristique sagittis. Nam in hendrerit ligula. Sed blandit lacinia nisl at iaculis. Integer consequat, diam in dignissim egestas, sem turpis luctus magna, in viverra elit magna sit amet arcu. Nulla nunc nibh, congue a porta eget, ultricies sit amet felis.',
  policies: {
    candidateByClass: [
      { id: 'm1', class: 'ม.1/1', students: 30 },
      { id: 'm2', class: 'ม.1/2', students: 32 },
      { id: 'm3', class: 'ม.1/3', students: 30 },
    ],
    candidateExcepted: [
      { id: 'candidates1', firstName: 'ศุภกร', lastName: 'วงษ์ขจร', class: 'ม.1/1' },
    ],
    candidateList: undefined,
  },
};
