export enum ExaminationResultStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type ExaminationResultItemType = {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  semester: { label: string; value: string };
  status: ExaminationResultStatus;
};

const mockUpData = [
  {
    id: '33c6fe9d-72c6-4066-8ba1-d8ee87df0088',
    title: 'ภาษาไทย ม.1 (ข้อสอบกลางภาคเรียนที่ 2/2564)',
    startDate: '2022-03-23T07:30:00.000Z',
    endDate: '2022-03-23T08:30:00.000Z',
    semester: { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
    status: 'IN_PROGRESS',
  },
  {
    id: 'eff0bde4-a98a-4a75-ae01-28ed58b9a913',
    title: 'วิทยาศาสตร์ ม.1 (ข้อสอบกลางภาคภาคเรียนที่ 1/2564)',
    startDate: '2022-06-24T02:00:00.000Z',
    endDate: '2022-06-25T11:00:00.000Z',
    semester: { label: 'เทอม 1 ปีการศึกษา 2564', value: 'semester1' },
    status: 'COMPLETED',
  },
  {
    id: '20254095-03d4-4505-8f11-128872ed04a0',
    title: 'คณิตศาสตร์ ม.1 (ข้อสอบกลางภาคเรียนที่ 1/2564)',
    startDate: '2022-06-24T02:00:00.000Z',
    endDate: '2022-06-24T03:00:00.000Z',
    semester: { label: 'เทอม 1 ปีการศึกษา 2564', value: 'semester1' },
    status: 'COMPLETED',
  },
];

const useExaminationResults = () => {
  const data = mockUpData as ExaminationResultItemType[];

  return { data };
};

export default useExaminationResults;
