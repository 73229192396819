import { Flex, Text, Button } from '../../../Components/Base';
import CallMadeIcon from '../../../Components/Icons/CallMadeIcon';
import ExamPaperIcon from '../../../Components/Icons/ExamPaperIcon';

const EmptyQuestionList = ({
  onGoBackExamArchive = () => {},
}: {
  onGoBackExamArchive?: () => void;
}) => {
  return (
    <Flex
      data-testid="examination-empty-question-list"
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
    >
      <ExamPaperIcon color="primary" />
      <Text variant={'h4'} mt={2}>
        ไม่มีคำถามในหน้านี้
      </Text>
      <Text mt={0.5}>
        คลิกที่{' '}
        <Text component={'span'} fontWeight={600}>
          “เลือกคำถาม”{' '}
        </Text>
        เพื่อค้นหาคำถามจากคลังข้อสอบ
      </Text>
      <Button
        data-testid="back-to-exam-archive-button"
        sx={{ mt: 2 }}
        color="neutral"
        onClick={onGoBackExamArchive}
        startIcon={<CallMadeIcon />}
      >
        {'เลือกคำถาม'}
      </Button>
    </Flex>
  );
};

export default EmptyQuestionList;
