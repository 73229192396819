import { Button } from '../../../Components/Base';
import VisibilityOutlinedIcon from '../../../Components/Icons/VisibilityOutlinedIcon';

const PreviewButton = () => {
  const handlePreviewExamination = () => {
    console.log('Click Preview Mode');
  };

  return (
    <Button
      color="neutral"
      variant={'outlined'}
      onClick={handlePreviewExamination}
      sx={{ minWidth: '130px' }}
      startIcon={<VisibilityOutlinedIcon />}
    >
      {'ดูตัวอย่าง'}
    </Button>
  );
};

export default PreviewButton;
