import { QueryHookOptions } from '@apollo/client';

import {
  useGetQBTags,
  QBTagCategory,
  QBTagsResult,
  QBTagQueryArgs,
  QBTagOrder,
} from './useGetQBTags';

type BloomTagsVariables = {
  order?: QBTagQueryArgs['order'];
} & QueryHookOptions;

const useGetQBSubsectionTags = (props?: BloomTagsVariables) => {
  const { order, ...apolloProps } = props ?? {};

  const { data, loading, error, ...apolloUtils } = useGetQBTags({
    variables: {
      search: {
        category: QBTagCategory.Subsection,
      },
      paging: {
        pageSize: 100,
        currentPage: 0,
      },
      order: {
        field: 'name',
        type: QBTagOrder.Asc,
        ...order,
      },
    },
    ...apolloProps,
  });

  return { data: data?.qbTags as QBTagsResult, loading, error, ...apolloUtils };
};

export default useGetQBSubsectionTags;
