import { forwardRef } from 'react';
import { styled, Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import KeyboardArrowDownIcon from '../../../Icons/KeyboardArrowDownIcon';
import TextInput from '../TextInput';

import { OptionSearchSelectionProps, SearchSelectionInputProps } from './types';

const BaseSearchSelectionInput = forwardRef((props: SearchSelectionInputProps, ref) => {
  const { label, error, helperText, options, InputProps, ...restProps } = props;
  return (
    <Autocomplete
      ref={ref}
      ListboxProps={{ style: { maxHeight: 268, overflow: 'auto' } }}
      noOptionsText="ไม่มีผลลัพธ์"
      openOnFocus
      includeInputInList
      options={options ?? []}
      isOptionEqualToValue={(
        option: OptionSearchSelectionProps,
        { value }: OptionSearchSelectionProps,
      ) => value === option.value}
      getOptionLabel={(option: OptionSearchSelectionProps) => option.label}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const { InputLabelProps, ...inputProps } = params;
        return (
          <TextInput
            {...inputProps}
            fullWidth={true}
            label={label}
            error={error}
            helperText={helperText}
            InputProps={{ ...inputProps?.InputProps, ...InputProps }}
          />
        );
      }}
      {...restProps}
    />
  );
});

const SearchSelectionInput = styled(BaseSearchSelectionInput)(({ theme, InputProps }) => ({
  '.MuiAutocomplete-popupIndicator': {
    color: theme.palette.text.primary,
    padding: 0,
  },
  '.MuiAutocomplete-inputRoot': InputProps?.startAdornment
    ? {
        paddingLeft: theme.spacing(4),
        '.MuiAutocomplete-input': {
          padding: `0 ${theme.spacing(3)}`,
        },
      }
    : { padding: 0 },
  '.MuiOutlinedInput-root .MuiAutocomplete-input': {
    paddingLeft: theme.spacing(2),
  },
  '.MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.grey['100'],
  },
  '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey['500'],
  },
}));

export type { SearchSelectionInputProps, OptionSearchSelectionProps };
export default SearchSelectionInput;
