import { useController } from 'react-hook-form';

import SearchableMultipleSelectionInput from '../../Base/Input/SearchableMultipleSelectionInput';

import { FormSearchSelectionInputProps, FieldValues } from './types';

const FormSearchableMultipleSelectionInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormSearchSelectionInputProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <SearchableMultipleSelectionInput
      {...field}
      error={isTouched ? invalid : false}
      helperText={error?.message ?? null}
      onChange={(event, item) => field.onChange(item ?? null)}
      onBlur={field.onBlur}
      {...props}
    />
  );
};

export type { FormSearchSelectionInputProps };
export default FormSearchableMultipleSelectionInput;
