import { FormProvider, useForm, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { createElement } from 'react';

import { Box, Flex, OptionSelectionProps } from '../../../Components/Base';
import FormSelectionInput from '../../../Components/Form/FormSelectionInput.tsx';
import FormTextInput from '../../../Components/Form/FormTextInput';

type SearchExaminationResultFormInput = {
  examEventName?: string;
  semester?: string;
};

type SearchExaminationResultFormContext = UseFormReturn<SearchExaminationResultFormInput>;
type SearchExaminationResultFormSubmission = (
  formUtils: SearchExaminationResultFormContext,
) => SubmitHandler<SearchExaminationResultFormInput>;

export type SearchExaminationResultFormProps = {
  initialFormValues: SearchExaminationResultFormInput;
  onSubmit: SearchExaminationResultFormSubmission;
  semesterList: OptionSelectionProps[];
  submitComponent?: (formContext: SearchExaminationResultFormContext) => JSX.Element;
};

const SearchExaminationResultForm = ({
  initialFormValues,
  semesterList,
  onSubmit,
  submitComponent,
}: SearchExaminationResultFormProps) => {
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit(formContext))}>
        <Flex flexDirection={{ xs: 'column', md: 'row' }} alignItems={'end'}>
          <Flex
            flexGrow={1}
            flexDirection={{ xs: 'column', md: 'row' }}
            width={{ xs: 1, md: 'auto' }}
          >
            <FormTextInput name="examEventName" label="ชื่อการสอบ" placeholder="ค้นหาชื่อการสอบ" />
            <Box pl={2} mt={{ xs: 2, md: 0 }} />
            <FormSelectionInput
              name="semester"
              label="เทอม/ปีการศึกษา"
              placeholder="เทอม/ปีการศึกษาทั้งหมด"
              options={semesterList}
            />
          </Flex>
          {submitComponent && createElement(submitComponent, formContext)}
        </Flex>
      </form>
    </FormProvider>
  );
};

export default SearchExaminationResultForm;
