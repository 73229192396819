import { Text } from '../../Components/Base';

import ExaminationResultList from './Containers/ExaminationResultList';

const ExaminationResults = () => {
  return (
    <>
      <Text variant={'h2'} mb={3}>
        {'ผลการสอบ'}
      </Text>
      <ExaminationResultList />
    </>
  );
};

export default ExaminationResults;
