import { Divider } from '@mui/material';

import { Box, Image, Text } from '../../Components/Base';

import { DataGrid, DataGridWithPagination } from './Containers/DataGrid';
import Alert from './Containers/Alert';
import Button from './Containers/Button';
import DragAndDrops from './Containers/DragAndDrops';
import Form from './Containers/Form';
import Input from './Containers/Input';
import Label from './Containers/Label';
import Modal from './Containers/Modal';
import QuestionGroups from './Containers/QuestionGroups';
import Snackbar from './Containers/Snackbar';
import Stepper from './Containers/Stepper';
import Tabs from './Containers/Tabs';

const TestUI = () => {
  return (
    <Box display="grid" gridTemplateColumns="minmax(0,1fr)">
      <Text variant="h1">
        Hello I'm from <code>shared</code> UI
      </Text>
      <Divider sx={{ my: 2 }} />

      {/* Button */}
      <Text variant="h6">Button</Text>
      <Button />
      <Divider sx={{ my: 2 }} />

      {/* Image */}
      <Text variant="h6">Image</Text>
      <Image src={'https://picsum.photos/1003/1181'} alt={'test image tag'} width={150} />
      <Divider sx={{ my: 2 }} />

      {/* Label */}
      <Text variant="h6">Label</Text>
      <Label />
      <Divider sx={{ my: 2 }} />

      {/* Tabs */}
      <Text variant="h6">Tab</Text>
      <Tabs />
      <Divider sx={{ my: 2 }} />

      {/* Input */}
      <Input />
      <Divider sx={{ my: 2 }} />

      {/* Stepper */}
      <Text variant="h6">Stepper</Text>
      <Stepper />
      <Divider sx={{ my: 2 }} />

      {/* Alert */}
      <Text variant="h6">Alert</Text>
      <Alert />
      <Divider sx={{ my: 2 }} />

      {/* Snackbar */}
      <Text variant="h6">Snackbar</Text>
      <Snackbar />
      <Divider sx={{ my: 2 }} />

      {/* Modal */}
      <Text variant="h6">Modal</Text>
      <Modal />
      <Divider sx={{ my: 2 }} />

      {/* Data Grid */}
      <Text variant="h6">Data Grid</Text>
      <DataGrid />
      <Box mt={2} />
      <DataGridWithPagination />
      <Divider sx={{ my: 2 }} />

      {/* Question Groups */}
      <QuestionGroups />
      <Divider sx={{ my: 2 }} />

      {/* Drag And Drop */}
      <DragAndDrops />
      <Divider sx={{ my: 2 }} />

      {/* Input Form */}
      <Form />
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default TestUI;
