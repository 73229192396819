import { Flex, Text, Box } from '../../Components/Base';
import ArrowBackIcon from '../../Components/Icons/ArrowBackIcon';
import ExamEmptyIcon from '../../Components/Icons/ExamEmptyIcon';
import { LinkButton } from '../../Components/LinkButton';

const NotFound = () => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} height={'50vh'}>
      <Box mr={8}>
        <Text fontSize={36} fontWeight="600" color="primary.main">
          Oops!!
        </Text>
        <Text fontSize={36} fontWeight="600" color="primary.main" mt={2}>
          ไม่พบหน้าที่คุณกำลังค้นหาอยู่
        </Text>
        <Text fontSize={24} fontWeight={400} mt={2}>
          กรุณาตรวจสอบลิงก์อีกครั้ง
        </Text>
        <LinkButton to={'/exam'} variant={'contained'} size="large" sx={{ mt: 8 }}>
          <ArrowBackIcon sx={{ fontSize: 24 }} />
          <Text ml={0.5} fontWeight={600}>
            กลับสู่หน้าหลัก
          </Text>
        </LinkButton>
      </Box>
      <ExamEmptyIcon color={'primary'} />
    </Flex>
  );
};

export default NotFound;
