import { OptionSelectionProps } from '../../Base';
import FormSelectionInput from '../../Form/FormSelectionInput.tsx';
import FormTextInput from '../../Form/FormTextInput';

export type ExaminationEventInformationProps = {
  classList: OptionSelectionProps[];
  semesterList: OptionSelectionProps[];
};

const ExaminationEventInformationFields = (props: ExaminationEventInformationProps) => {
  const { classList = [], semesterList = [] } = props;
  return (
    <>
      <FormTextInput
        name="examEventName"
        label="ชื่อการสอบ"
        placeholder="กรุณากรอกชื่อการสอบ"
        rules={{
          maxLength: { value: 150, message: 'กรุณากรอกชื่อชุดข้อสอบไม่เกิน 150 ตัวอักษร' },
          required: true,
        }}
        required
      />
      <FormSelectionInput
        name="semester"
        label="เทอม/ปีการศึกษา"
        placeholder="เลือกเทอม/ปีการศึกษา"
        options={semesterList}
        rules={{
          required: true,
        }}
        required
        sx={{ mt: 2 }}
      />
      <FormSelectionInput
        name="class"
        label="ระดับชั้น"
        placeholder="เลือกระดับชั้น"
        options={classList}
        rules={{
          required: true,
        }}
        required
        sx={{ mt: 2 }}
      />
    </>
  );
};

export default ExaminationEventInformationFields;
