import { Divider } from '@mui/material';

import { Box, BoxProps, Flex, Text, Label, LabelProps } from '../../Base';
import HTMLView from '../../../Components/HTMLView';
import CheckRoundedIcon from '../../Icons/CheckRoundedIcon';
import { QUESTION_CHOICES } from '../../../Domains/ExaminationManagement/constant';

export type QuestionItemType = {
  question: string;
  order: number;
  choices: string[];
  correctAnswer: number;
  bloom: string;
  point: number;
};

export enum Variant {
  META_NONE,
  META_VISIBLE,
}

type QuestionItemProps = {
  questionItem: QuestionItemType;
  variant?: Variant;
} & BoxProps;

const ExaminationQuestionItem = (props: QuestionItemProps) => {
  const { questionItem, variant = Variant.META_NONE, ...restProps } = props;
  const { question, order, choices, correctAnswer, bloom, point } = questionItem;

  const shouldShowBloom = [Variant.META_VISIBLE].includes(variant);
  const shouldShowPoint = [Variant.META_VISIBLE].includes(variant);
  const shouldShowAnswer = [Variant.META_VISIBLE].includes(variant);

  return (
    <Box data-testid="question" {...restProps}>
      <Flex alignItems={'center'} justifyContent={'space-between'} minHeight={'30px'}>
        <Text data-testid="order" sx={{ flexGrow: 1 }} variant="caption" fontWeight="600">
          ข้อที่ {order}
        </Text>
        {shouldShowBloom && (
          <Label flex={'0 0 auto'} variant={'capsule' as LabelProps['variant']} py={0.25}>
            <Text data-testid="bloom" variant="caption" fontWeight="600">
              {bloom}
            </Text>
          </Label>
        )}
        {shouldShowPoint && (
          <Flex mx={2} alignItems={'center'}>
            <Text data-testid="point" variant="caption">
              {point}
            </Text>
            <Text ml={1} variant="caption" fontWeight={'600'}>
              {'คะแนน'}
            </Text>
          </Flex>
        )}
      </Flex>
      <Divider sx={{ mt: 1.5, mb: 2 }} />
      <Text data-testid="label" variant="caption">
        <HTMLView rawContent={question} />
      </Text>
      <Box mt={2} />
      {choices.map((choice, index) => {
        return (
          <Flex
            sx={{ breakInside: 'avoid' }}
            data-testid={`choice-${index + 1}`}
            mt={0.25}
            px={1.5}
            py={1}
            key={`${index}-${choice}`}
            bgcolor={
              shouldShowAnswer && correctAnswer === index + 1 ? 'success.lighter' : 'transparent'
            }
          >
            {shouldShowAnswer && correctAnswer === index + 1 && (
              <CheckRoundedIcon
                data-testid="correct-answer"
                color={'success'}
                sx={{ fontSize: 18, position: 'absolute' }}
              />
            )}
            <Flex>
              <Text data-testid="badge" ml={5} mr={index < 8 ? '1ch' : 'unset'} variant="caption">
                {QUESTION_CHOICES[index]}
              </Text>
              <Text data-testid="label" variant="caption">
                <HTMLView rawContent={choice} />
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default ExaminationQuestionItem;
