import { URLSearchParams } from 'url';

import queryString from 'query-string';

import { OptionSearchSelectionProps } from '../../../Components/Base';
import { Question } from '../../../Contexts/ExaminationProvider';
import { QuestionItemType } from '../../../Domains/ExaminationManagement/constant';
import {
  QBTagsResult,
  QBTag,
  QBTagCategory,
} from '../../../Domains/ExaminationManagement/useGetQBTags';
import { QMSTagCategory } from '../../../Domains/ExaminationManagement/useGetQMSTags';
import { ExaminationTag } from '../../../Domains/ExaminationManagement/useGetExamination';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestionSelected,
} from '../../../Domains/ExaminationManagement/utils';
import { QuestionSearchTagInput, QBQuestion } from '../Domains/useGetPublishedQuestions';

export const getTagOptions = (tags: QBTagsResult['tags']) => {
  const tagOptions: OptionSearchSelectionProps[] = tags?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return tagOptions;
};

export const getIndicatorOptions = (tags: QBTagsResult['tags']) => {
  const indicatorOptions: OptionSearchSelectionProps[] = tags?.map(({ id, name, description }) => ({
    label: `${name} ${description}`,
    value: id,
  }));

  return indicatorOptions;
};

export const formatQuestionSelectedGroupBySection = (questionList: QuestionItemType[]) => {
  const questionGroupBySection = groupQuestionBySection(questionList);
  const questionGroup = questionGroupBySection.map((section) =>
    section.items.map((indicator) =>
      indicator.items.map((question) => ({
        refQuestionId: question.id,
        score: question.point,
      })),
    ),
  );

  // The flat() method creates a new array with all sub-array elements concatenated into it recursively up to the specified depth.
  const concatenatedQuestionGroup = questionGroup.flat().flat();

  const questionSelected = concatenatedQuestionGroup.map((question) => ({
    refQuestionId: question.refQuestionId,
    score: question.score,
  }));

  return questionSelected;
};

export const getSearchTags = (
  searchParams: URLSearchParams,
  difficultyTags: QBTag[],
  subjectTag: string,
  gradeTag: string,
) => {
  const searchTags: QuestionSearchTagInput[] = [];

  // Get search params
  const searchLearningUnit = searchParams.get('learningUnit');
  const searchIndicator = searchParams.get('indicator');
  const searchBlooms = searchParams.get('blooms');
  const searchLevelEasy = searchParams.get('easy') === 'true';
  const searchLevelMedium = searchParams.get('medium') === 'true';
  const searchLevelHard = searchParams.get('hard') === 'true';

  if (searchLearningUnit) {
    searchTags.push({ id: searchLearningUnit, category: QBTagCategory.Subsection });
  }
  if (searchIndicator) {
    searchTags.push({ id: searchIndicator, category: QBTagCategory.Indicator });
  }
  if (searchBlooms) {
    const blooms = searchBlooms.split(',');
    const bloomTags = blooms.map((bloom) => ({
      id: bloom,
      category: QBTagCategory.Bloom,
    }));

    searchTags.push(...bloomTags);
  }

  // Create array of difficulty search values
  const difficultySearch: string[] = [];
  if (searchLevelEasy) difficultySearch.push('ง่าย');
  if (searchLevelMedium) difficultySearch.push('ปานกลาง');
  if (searchLevelHard) difficultySearch.push('ยาก');

  // Get search difficulty tags data and mapping to search tag input type
  const searchDifficultyTags = difficultyTags
    .filter((difficulty) => difficultySearch.includes(difficulty.name))
    .map(({ id, category }) => ({ id, category }));

  searchTags.push(...searchDifficultyTags);

  // searchTags below not include to queryString, these tags must inject by business requirement
  searchTags.push({ id: subjectTag, category: QBTagCategory.Subject });
  searchTags.push({ id: gradeTag, category: QBTagCategory.Grade });

  const searchTagsQueryString = queryString.stringify({
    learningUnit: searchLearningUnit,
    indicator: searchIndicator,
    blooms: searchBlooms,
    easy: searchLevelEasy,
    medium: searchLevelMedium,
    hard: searchLevelHard,
  });

  return { data: searchTags as QuestionSearchTagInput[], queryString: searchTagsQueryString };
};

export const updateQuestionSelected = (
  prevQuestionSelected: Question[],
  questions: QBQuestion[],
  selectionModel: string[],
) => {
  const questionSelected = selectionModel.map((id) => {
    const question = questions.find((item) => item.id === id);

    return {
      id: id,
      tags: question?.tags.map((tag) => ({
        category: tag.category,
        id: tag.id,
        name: tag.name,
        slug: tag.slug,
      })),
      point: 1,
    };
  });

  const questionUpdated = [...prevQuestionSelected, ...questionSelected];
  const questionIds = new Set(prevQuestionSelected.map((question) => question.id));
  const uniqueQuestions = [
    ...prevQuestionSelected,
    ...questionUpdated.filter((question) => !questionIds.has(question.id)),
  ];

  const formattedUniqueQuestions = formatExaminationItemToQuestionSelected(uniqueQuestions);
  const questionGroupBySection = groupQuestionBySection(formattedUniqueQuestions);

  const questionsSelected: Question[] = [];
  questionGroupBySection.forEach((section) =>
    section.items.forEach((indicator) =>
      indicator.items.forEach((question) => {
        const qbQuestion = uniqueQuestions.find((item) => question.id === item.id);
        questionsSelected.push({
          id: question.id,
          tags: qbQuestion?.tags ?? [],
          order: question.order,
          point: question.point,
        });
      }),
    ),
  );

  return questionsSelected;
};

export const getTagIds = (tags: ExaminationTag[]) => {
  const tagIds: string[] = [];

  const subjectTag = tags?.find((value) => value.category === QMSTagCategory.Subject)?.id ?? '';
  const gradeTag = tags?.find((value) => value.category === QMSTagCategory.Grade)?.id ?? '';

  tagIds.push(subjectTag);
  tagIds.push(gradeTag);

  return tagIds;
};
