import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';

import { Button } from '../../../Components/Base';
import ArrowForwardIcon from '../../../Components/Icons/ArrowForwardIcon';
import ExaminationEventDetailsForm, {
  ExaminationEventDetailsProps,
} from '../../../Components/ExaminationEventDetailsForm';
import { EXAMINATION_EVENT_DETAIL_PATH } from '../../../Domains/ExaminationManagement/constant';
import { CLASS_LIST, EXAMINATION_LIST, SEMESTER_LIST } from '../constants';

const CreateExamButton: ExaminationEventDetailsProps['submitComponent'] = (formContext) => {
  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Button
        sx={{ ml: 'auto', display: 'flex' }}
        type="submit"
        disabled={formContext.formState.isSubmitting || !formContext.formState.isValid}
        endIcon={<ArrowForwardIcon />}
      >
        {formContext.formState.isSubmitting ? 'กำลังบันทึก..' : 'ต่อไป'}
      </Button>
    </>
  );
};

const initialExaminationEventValues = {
  examEventName: '',
  semester: undefined,
  class: undefined,
  dateRange: ['', ''],
  endTime: '',
  startTime: '',
  examination: undefined,
  randomQuestion: false,
  randomAnswer: false,
  shouldShowPoints: false,
  instruction: '',
};

export default function ExaminationEventDetailsCreation() {
  const navigate = useNavigate();

  return (
    <ExaminationEventDetailsForm
      classList={CLASS_LIST}
      examinationList={EXAMINATION_LIST}
      semesterList={SEMESTER_LIST}
      initialFormValues={initialExaminationEventValues}
      //TODO: call mutation
      onSubmit={(formContext) => (formValues) =>
        new Promise(() =>
          setTimeout(() => {
            console.log(formContext, formValues);
            const eventId = Math.floor(Math.random() * (9999999 - 1000000) + 1000000) + '';
            if (!eventId) return;
            navigate(EXAMINATION_EVENT_DETAIL_PATH.replace(':eventId', eventId));
          }, 3000),
        )}
      submitComponent={CreateExamButton}
    />
  );
}
