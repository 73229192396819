import { User } from '../../Domains/User/useGetMyProfile';

export const formatUserProfile = (data: User) => {
  const userProfile = {
    id: data.id,
    displayName: `${data.firstname} ${data.lastname}`,
    schoolName: data.additional8 ?? '',
    schoolCode: data.additional7 ?? '',
    image: data.image ?? '',
    realm: data.realm ?? '',
    username: data.username,
    email: data.email ?? '',
    newEmail: data.newEmail ?? '',
    organizationId: data.organizationId ?? '',
    referenceId: data.referenceId ?? '',
    lastLoginAt: data.lastLoginAt ?? '',
    emailVerified: data.emailVerified ?? false,
    isPassportUser: data.isPassportUser,
    isPasswordCreatedByUser: data.isPasswordCreatedByUser,
    isDisabled: data.isDisabled,
    isVerified: data.isVerified,
    createdAt: data.createdAt,
    roles: data.roles,
    appRoles: data.appRoles,
    appPermissions: data.appPermissions,
    teamIds: data.teamIds,
  };

  return userProfile;
};
