import { Box, BoxProps, Text } from '../../Base';

export type ExaminationInstructionProps = {
  children?: React.ReactNode | string;
} & BoxProps;

const ExaminationInstruction = (props: ExaminationInstructionProps) => {
  const { children, ...restProps } = props;

  return (
    <Box {...restProps}>
      {typeof children === 'string' ? (
        <Text variant="caption" fontWeight="600" color="grey.900">
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default ExaminationInstruction;
