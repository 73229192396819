import { Box, BoxProps } from '../Box';

type ImageProps = {
  src?: string;
  alt?: string;
  srcSet?: string;
} & BoxProps;

const Image = (props: ImageProps) => <Box component={'img'} {...props} />;

export { Image };
export type { ImageProps };
