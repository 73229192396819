import { IconButton, IconButtonProps, ButtonProps } from '../../../Components/Base/Button';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import useDisclosure from '../../../Utils/useDisclosure';
import useDeleteExamination from '../../../Domains/ExaminationManagement/useDeleteExamination';
import { ResponseType } from '../../../Domains/ExaminationManagement/constant';
import ActionConfirmationModal from '../../../Components/ActionConfirmModal';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';

type RemoveExaminationButtonProps = {
  examId: string;
  onCompleted?: (() => void) | (() => Promise<void>);
} & IconButtonProps;

const RESPONSE_MESSAGE = {
  EXAM_IS_PUBLISHED: 'ดำเนินการไม่สำเร็จ ข้อสอบที่ทำการเผยแพร่ไปแล้วจะไม่สามารถลบได้',
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการลบ',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'ลบฉบับร่างเรียบร้อยแล้ว',
};

const RemoveExaminationButton = (props: RemoveExaminationButtonProps) => {
  const { examId, onCompleted, ...buttonProps } = props;
  const { isOpen, open, close } = useDisclosure();
  const { open: openSnackbar } = useSnackbar();
  const [deleteExamMutation, { loading }] = useDeleteExamination();

  const handleRemoveExamination: ButtonProps['onClick'] = async (e) => {
    const target = e.target as HTMLButtonElement;
    target.innerText = 'กำลังลบชุดข้อสอบ ...';
    await deleteExamMutation({
      variables: { id: examId },
      onCompleted: () => {
        openSnackbar(RESPONSE_MESSAGE.SUCCESS, 'success');
        close();
        onCompleted?.();
      },
      onError: (error) => {
        console.error(error);

        target.innerText = 'ลบชุดข้อสอบ';
        target.disabled = false;
        target.className = target.className.replace(' Mui-disabled', '');

        //TODO: handle all error message after connect learn auth
        let message = RESPONSE_MESSAGE.UNEXPECTED;
        if (error.message.includes('Not found Exam')) {
          message = RESPONSE_MESSAGE.NOT_FOUND;
        } else if (error.message.includes('Cannot delete Published Exam')) {
          message = RESPONSE_MESSAGE.EXAM_IS_PUBLISHED;
        } else if (error.message === 'Failed to fetch') {
          message = RESPONSE_MESSAGE.NETWORK_ERROR;
        }
        openSnackbar(message, 'error');
      },
    });
  };

  return (
    <>
      <IconButton data-testid="remove-exam" {...buttonProps} onClick={open}>
        <DeleteIcon />
      </IconButton>
      <ActionConfirmationModal
        open={isOpen}
        onDiscard={loading ? undefined : close}
        onClose={loading ? undefined : close}
        onAccept={handleRemoveExamination}
        title="ลบชุดข้อสอบนี้?"
        subtitle="คุณแน่ใจหรือไม่ ว่าต้องการที่จะลบชุดข้อสอบนี้?"
        acceptText="ลบชุดข้อสอบ"
      />
    </>
  );
};

export default RemoveExaminationButton;
