import { useNavigate } from 'react-router-dom';
import { Divider, Skeleton } from '@mui/material';

import ArrowForwardIcon from '../../../Components/Icons/ArrowForwardIcon';
import { Alert, Text, Button, TextProps, Box, Flex } from '../../../Components/Base';
import ExamInformationForm, {
  ExaminationInformationFormProps,
  ExaminationInformationLoading,
} from '../../../Components/ExaminationInformationForm';
import {
  ExaminationGenerator,
  EXAMINATION_EXAMINATION_QUESTIONS_PATH,
  ResponseType,
} from '../../../Domains/ExaminationManagement/constant';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import { useCreateExamination } from '../../../Domains/ExaminationManagement/useCreateExamination';
import { getExaminationDocument } from '../../../Domains/ExaminationManagement/useGetExamination';
import {
  QMSTagCategory,
  useGetQMSGradeTags,
  useGetQMSSubjectTags,
} from '../../../Domains/ExaminationManagement/useGetQMSTags';
import { getTagOptions } from '../../../Domains/ExaminationManagement/utils';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';
import { useAuthentication } from '../../../Contexts/AuthenticationProvider';
import useGetQMSLeSchoolGrades from '../../../Domains/ExaminationManagement/useGetQMSLeSchoolGrades';

const CreateExamButton: ExaminationInformationFormProps['submitComponent'] = (formContext) => {
  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Button
        data-testid="saving-and-continue-button"
        sx={{ ml: 'auto', display: 'flex' }}
        type="submit"
        disabled={formContext.formState.isSubmitting || !formContext.formState.isValid}
        size="large"
        endIcon={<ArrowForwardIcon />}
      >
        {formContext.formState.isSubmitting ? 'กำลังบันทึก..' : 'บันทึกและดำเนินการต่อ'}
      </Button>
    </>
  );
};

const TextAlert = (props: TextProps) => <Text component="span" color={'info.darker'} {...props} />;

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'บันทึกฉบับร่างเรียบร้อยแล้ว',
};

export default function ExaminationInformationCreation() {
  const navigate = useNavigate();
  const { open } = useSnackbar();
  const { userProfile } = useAuthentication();
  const [createExamMutation] = useCreateExamination({
    onCompleted: (data) => {
      open(RESPONSE_MESSAGE.SUCCESS, 'success');
      const examId = data?.qmsCreateExam?.id;
      if (!examId) return;
      navigate(EXAMINATION_EXAMINATION_QUESTIONS_PATH.replace(':examId', examId));
    },
    onError: (error) => {
      console.error(error);
      //TODO: handle all error message after connect learn auth
      let message = RESPONSE_MESSAGE.UNEXPECTED;
      if (error.message === 'Failed to fetch') message = RESPONSE_MESSAGE.NETWORK_ERROR;
      open(message, 'error');
    },
    update: (cache, { data }) => {
      const qmsExam = data?.qmsCreateExam;
      cache.writeQuery({
        query: getExaminationDocument,
        variables: { id: qmsExam?.id },
        data: { qmsExam },
      });
    },
  });

  const { data: gradeTags, loading: gradeTagsLoading } = useGetQMSGradeTags();
  const { data: subjectTags, loading: subjectTagsLoading } = useGetQMSSubjectTags();

  const schoolCode = userProfile?.schoolCode ?? '';
  const { data, loading: schoolGradesLoading } = useGetQMSLeSchoolGrades({
    variables: { schoolCode },
  });

  const qmsGradeTags = gradeTags?.tags ?? [];
  const qmsSchoolGrades = data?.qmsLeSchoolGrades.schoolGrades ?? [];

  const schoolGrades = qmsGradeTags.filter((grade) =>
    qmsSchoolGrades.find((schoolGrade) => grade.name === schoolGrade.name),
  );

  const subjectOptions = getTagOptions(subjectTags?.tags) ?? [];
  const gradeOptions = getTagOptions(schoolGrades) ?? [];

  const initialSubject =
    subjectOptions.find((item) => item.label === 'คณิตศาสตร์')?.value?.toString() ?? '';
  const initialGrade = gradeOptions.find((item) => item.label === 'ม.1')?.value?.toString() ?? '';

  const createExam: ExaminationInformationFormProps['onSubmit'] = () => async (formValues) => {
    const { examName, subject, grade } = formValues;
    await createExamMutation({
      variables: {
        input: {
          title: examName,
          defaultGeneratorType: ExaminationGenerator.Fixed,
          tags: [
            {
              slug: subject,
              category: QMSTagCategory.Subject,
            },
            {
              slug: grade,
              category: QMSTagCategory.Grade,
            },
          ],
        },
      },
    });
  };

  return (
    <Box data-testid="examination-information-creation">
      <Text data-testid="title" variant="h4">
        {'รายละเอียดชุดข้อสอบ'}
      </Text>
      <Box mt={2.5} />
      <Alert
        data-testid="information-alert-notice"
        icon={<InfoIcon fontSize="small" sx={{ color: 'info.main' }} />}
        color="info"
      >
        <TextAlert>
          เมื่อบันทึกข้อมูลแล้ว
          <TextAlert fontWeight="600"> วิชาและระดับชั้น</TextAlert>
          จะไม่สามารถแก้ไขข้อมูลได้ในภายหลัง
        </TextAlert>
      </Alert>
      <Box mt={2.5} />
      {gradeTagsLoading || subjectTagsLoading || schoolGradesLoading ? (
        <>
          <ExaminationInformationLoading />
          <Divider sx={{ my: 4 }} />
          <Flex justifyContent={'flex-end'}>
            <Skeleton variant="rectangular" width={200} height={44} sx={{ borderRadius: '8px' }} />
          </Flex>
        </>
      ) : (
        <ExamInformationForm
          initialFormValues={{
            examName: '',
            subject: initialSubject,
            grade: initialGrade,
          }}
          onSubmit={createExam}
          submitComponent={CreateExamButton}
          gradeOptions={gradeOptions}
          subjectOptions={subjectOptions}
        />
      )}
    </Box>
  );
}
