import UserButtonView from '../../Components/UserButton';
import { useAuthentication } from '../../Contexts/AuthenticationProvider';

const UserButton = () => {
  const { userProfile, logout } = useAuthentication();
  const { displayName, schoolName } = userProfile ?? {};

  return (
    <UserButtonView
      displayName={displayName ?? '-'}
      schoolName={schoolName ?? '-'}
      onLogoutClick={logout}
    />
  );
};

export default UserButton;
