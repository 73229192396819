import { Divider, styled } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ExaminationQuestionSummaryList from '../ExaminationQuestionSummaryList';
import { Box, BoxProps, Flex, Text } from '../../../../Components/Base';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { formatExaminationItemToQuestions } from '../../../../Domains/ExaminationManagement/utils';

const SummaryWrapper = styled(Box)(({ theme }) => ({
  boxShadow: '0px 2px 15px hsla(206, 13%, 25%, 0.1)',
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
}));

const ExamSummary = (props: BoxProps) => {
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });
  const examItems = examination?.qmsExam.examItems ?? [];
  const totalQuestions = examination?.qmsExam.totalQuestions ?? 0;
  const questions = formatExaminationItemToQuestions(examItems);

  const totalExaminationPoint = useMemo(() => {
    let totalPoint = 0;
    questions.map((question) => {
      totalPoint = totalPoint + question.point;
    });
    return totalPoint;
  }, [questions]);

  const totalQuestionCount = useMemo(() => totalQuestions, [totalQuestions]);

  return (
    <Box data-testid="examination-summary" {...props}>
      <SummaryWrapper>
        <Flex
          data-testid="total-point"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          width={1}
        >
          <Text data-testid="label" variant="subtitle1">
            {'คะแนนรวม:'}
          </Text>
          <Text data-testid="value" variant="subtitle1">
            {totalExaminationPoint}
          </Text>
        </Flex>
        <Divider sx={{ mt: 1.25, mb: 2 }} />

        <Flex
          data-testid="question-count"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          width={1}
        >
          <Text data-testid="label" variant="subtitle1">
            {'จำนวนข้อ:'}
          </Text>
          <Text data-testid="value" variant="subtitle1">
            {totalQuestionCount}
          </Text>
        </Flex>
        <Divider sx={{ mt: 1.25, mb: 2 }} />
        <ExaminationQuestionSummaryList />
      </SummaryWrapper>
    </Box>
  );
};

export default ExamSummary;
