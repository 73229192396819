import { Color, Flex, Label, LabelProps, Text } from '../Base';
import { ExaminationStatus } from '../../Domains/ExaminationManagement/constant';

export type HeaderTitleProps = {
  children: React.ReactChild;
  variant?: ExaminationStatus;
};

const ExaminationLabel = ({
  variant,
  ...props
}: Omit<LabelProps, 'color' | 'children' | 'variant'> & { variant: ExaminationStatus }) => {
  switch (variant) {
    case ExaminationStatus.Published: {
      return (
        <Label data-testid="published-status" color={Color.SUCCESS} {...props}>
          {'เผยแพร่'}
        </Label>
      );
    }
    case ExaminationStatus.Deprecated: {
      return (
        <Label data-testid="deprecated-status" color={Color.NEUTRAL} {...props}>
          {'เลิกใช้แล้ว'}
        </Label>
      );
    }
    case ExaminationStatus.Draft:
    default: {
      return (
        <Label data-testid="draft-status" color={Color.PRIMARY} {...props}>
          {'ฉบับร่าง'}
        </Label>
      );
    }
  }
};

const HeaderTitle = (props: HeaderTitleProps) => {
  const { children, variant = ExaminationStatus.Draft } = props;
  return (
    <Flex data-testid="header-title" alignItems={'center'} minWidth={0}>
      {typeof children === 'string' ? (
        <Text
          data-testid="title"
          variant="body1"
          fontWeight={600}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {children}
        </Text>
      ) : (
        children
      )}
      <ExaminationLabel mx={2} flex="0 0 auto" variant={variant} />
    </Flex>
  );
};

export default HeaderTitle;
