import orderBy from 'lodash/orderBy';

import { Question } from '../../../Contexts/ExaminationProvider';
import { groupQuestionBySection } from '../../../Domains/ExaminationManagement/utils';

export type QuestionItemType = {
  id: string;
  bloom: string;
  choices: string[];
  correctAnswer: number;
  indicator: string;
  level: string;
  order: number;
  question: string;
  section: string;
  point: number;
};

export const combineExaminationQuestionWithStore = (
  examQuestions: QuestionItemType[],
  questionSelected: Question[],
): QuestionItemType[] => {
  const combinedQuestions: QuestionItemType[] = [];

  examQuestions.forEach((question) => {
    const matchQuestion = questionSelected.find((item) => question.id === item.id);
    if (!matchQuestion) return;
    combinedQuestions.push({ ...question, ...matchQuestion });
  });

  const orderedQuestions = orderBy(combinedQuestions, 'order') ?? [];
  return orderedQuestions;
};

export const formatQuestionGroupBySection = (questionList: QuestionItemType[]) => {
  const questionGroupBySection = groupQuestionBySection(questionList);
  const questionGroup = questionGroupBySection.map((section) =>
    section.items.map((indicator) =>
      indicator.items.map((question) => ({
        id: question.id,
        order: question.order,
        point: question.point,
      })),
    ),
  );

  // The flat() method creates a new array with all sub-array elements concatenated into it recursively up to the specified depth.
  const concatenatedQuestionGroup = questionGroup.flat().flat();

  const questions = concatenatedQuestionGroup.map((question) => ({
    id: question.id,
    order: question.order,
    point: question.point,
  }));

  return questions;
};
