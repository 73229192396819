import { useState, useCallback, useRef } from 'react';
import { matchPath, useParams } from 'react-router-dom';

import {
  EXAMINATION_EXAMINATIONS_PATH,
  EXAMINATION_EXAMINATION_FINALIZATION_PATH,
} from '../../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';
import { Examination, useExaminationContext } from '../../../Contexts/ExaminationProvider';
import { isExaminationEqualWithStore } from '../utils';

import EnsureLeavingModal from './EnsureLeavingModal';
import { Transition, PromptCallback, usePrompt } from './usePrompt';

const EnsureLeavingListener = () => {
  const { examId = '' } = useParams();
  const transitionRef = useRef<Transition | null>(null);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const { reset, defaultState, ...editableExamination } = useExaminationContext();

  const { data: examination } = useGetExamination({
    skip: !examId,
    variables: { id: examId },
  });

  const handleShowPrompt: PromptCallback = useCallback((location, nextTransition, unblock) => {
    const targetPath = nextTransition.location.pathname;
    const examinationModuleRegExpString = `^${EXAMINATION_EXAMINATIONS_PATH}.+`;
    const isInExaminationDetail = new RegExp(examinationModuleRegExpString).test(targetPath);
    const finalizationMatch = matchPath(
      EXAMINATION_EXAMINATION_FINALIZATION_PATH,
      location.pathname,
    );

    if (isInExaminationDetail && !finalizationMatch) {
      unblock();
      nextTransition.retry();
      return;
    }

    transitionRef.current = nextTransition;
    setIsShowConfirmation(true);
  }, []);

  const isExamDirty =
    !!examination?.qmsExam &&
    !isExaminationEqualWithStore(examination?.qmsExam, editableExamination);
  const unblock = usePrompt(isExamDirty, handleShowPrompt);

  const handleStayThisPage = useCallback(() => {
    transitionRef.current = null;
    setIsShowConfirmation(false);
  }, []);

  const handleResetAndLeavePage = () => {
    if (!transitionRef.current) return;
    reset(defaultState as Examination);
    unblock();
    transitionRef.current.retry();
    transitionRef.current = null;
    setIsShowConfirmation(false);
  };

  const handleLeavePage = useCallback(() => {
    if (!transitionRef.current) return;
    unblock();
    transitionRef.current.retry();
    transitionRef.current = null;
    setIsShowConfirmation(false);
  }, [unblock]);

  return (
    <EnsureLeavingModal
      open={isShowConfirmation}
      onStayThisPage={handleStayThisPage}
      onLeavePage={handleResetAndLeavePage}
      onSaveDraftCompleted={handleLeavePage}
    />
  );
};

export default EnsureLeavingListener;
