import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { Box, BoxProps, Flex, TablePagination, Text } from '../../../../Components/Base';
import ExaminationQuestionGroups from '../../../../Components/ExaminationQuestionGroups';
import { ExaminationStatus } from '../../../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestions,
} from '../../../../Domains/ExaminationManagement/utils';
import usePagination from '../../../../Domains/Pagination';

const ExaminationQuestionList = (props: BoxProps) => {
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({ variables: { id: examId } });
  const examItems = examination?.qmsExam.examItems ?? [];
  const orderedExamItems = orderBy(examItems, 'order') ?? [];
  const questions = formatExaminationItemToQuestions(orderedExamItems);
  const { paging, rowsPerPage, changePage, changeRowsPerPage } = usePagination();

  const splitQuestionList = useMemo(() => {
    const startQuestionListInPage = paging * rowsPerPage;
    const endQuestionListInPage = (paging + 1) * rowsPerPage;

    return questions.slice(startQuestionListInPage, endQuestionListInPage);
  }, [questions, paging, rowsPerPage]);

  const questionGroupBySection = groupQuestionBySection(splitQuestionList);

  return (
    <Box data-testid="examination-question-list" {...props}>
      {questionGroupBySection.map((question, questionGroupIndex) => {
        return (
          <Box data-testid={`section-${questionGroupIndex + 1}`} key={question.section}>
            <Flex flexDirection={'row'}>
              <Box
                px={0.6}
                py={2}
                bgcolor={'primary.main'}
                borderRadius={'8px 0px 0px 8px'}
                boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
              />
              <Box
                flexGrow={1}
                px={3}
                py={2}
                bgcolor={'common.white'}
                borderRadius={'0px 8px 8px 0px'}
                boxShadow={'0px 2px 15px rgba(55, 64, 71, 0.1)'}
              >
                <Text data-testid="section-title" variant="h4">
                  {question.section}
                </Text>
              </Box>
            </Flex>

            {question.items.map((item, indicatorIndex) => {
              const title = item.indicator.substring(0, 11).trim();
              const description = item.indicator.substring(12).trim();
              const questionItems = item.items;
              return (
                <ExaminationQuestionGroups
                  data-testid={`indicator-${indicatorIndex + 1}`}
                  key={item.indicator}
                  title={title}
                  description={description}
                  questionItems={questionItems}
                  readonly={examination?.qmsExam?.status !== ExaminationStatus.Draft}
                />
              );
            })}
          </Box>
        );
      })}
      <TablePagination
        count={examination?.qmsExam?.totalQuestions ?? 0}
        page={paging}
        onPageChange={changePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={changeRowsPerPage}
      />
    </Box>
  );
};

export default ExaminationQuestionList;
