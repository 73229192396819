import { Box, Flex, Text } from '../../Components/Base';
import { LinkButton } from '../../Components/LinkButton';
import AddIcon from '../../Components/Icons/AddIcon';
import { EXAMINATION_EXAMINATION_CREATION_PATH } from '../../Domains/ExaminationManagement/constant';

import ExaminationList from './Containers/ExaminationList';

const Examinations = () => {
  return (
    <Box data-testid="examination-page">
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={3}>
        <Text data-testid="title" variant={'h2'}>
          ชุดข้อสอบ
        </Text>
        <LinkButton
          data-testid="create-examination-button"
          to={EXAMINATION_EXAMINATION_CREATION_PATH}
          size={'large'}
          startIcon={<AddIcon />}
        >
          จัดชุดข้อสอบ
        </LinkButton>
      </Flex>
      <ExaminationList />
    </Box>
  );
};

export default Examinations;
