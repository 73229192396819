import { Fragment, HTMLAttributes, useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import DOMPurify from 'dompurify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import katex from 'katex/dist/contrib/auto-render.min';

import { Box, BoxProps } from '../Base';

const isAllowedIframeSource = (srcAttribute: string | null) => !!srcAttribute;

DOMPurify.addHook('beforeSanitizeElements', (currentNode: Element) => {
  if (currentNode.tagName === 'IFRAME') {
    const srcAttribute = currentNode.getAttribute('src');
    if (isAllowedIframeSource(srcAttribute)) {
      return currentNode;
    }
    currentNode.remove();
  }
});

type HTMLViewProps = {
  rawContent?: string;
  children?: string;
} & HTMLAttributes<HTMLDivElement> &
  Omit<BoxProps, 'children'>;

const HTMLView = styled((props: HTMLViewProps) => {
  const { rawContent = '', children = '', ...restProps } = props;
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const sanitized = DOMPurify.sanitize(rawContent || children, {
    ADD_TAGS: ['iframe'],
  });

  useEffect(() => {
    // This is an extension to automatically render all of the math inside of text.
    // Ref: https://katex.org/docs/autorender.html
    katex(ref.current, {
      delimiters: [
        { left: '$$', right: '$$', display: true },
        { left: '$', right: '$', display: false },
        { left: '\\(', right: '\\)', display: false },
        { left: '\\[', right: '\\]', display: true },
      ],
    });
  }, []);

  return (
    <Fragment>
      <Box dangerouslySetInnerHTML={{ __html: sanitized }} {...restProps} ref={ref} />
    </Fragment>
  );
})(({ theme }) => ({
  p: {
    margin: theme.spacing(0),
    display: 'inline',
  },
}));

export default HTMLView;
