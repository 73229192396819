import { styled } from '@mui/material';

import { Box, Text, TextProps, Flex } from '../../Base';

const ExaminationHeaderInfoWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '6px',
}));

export type ExaminationHeaderInfoProps = {
  questionName: string;
  subject: string;
  grade: string;
  questionCount: number | string;
  totalExaminationPoint: number | string;
};

const Title = (props: TextProps) => <Text fontWeight={'600'} variant={'caption'} {...props} />;
const Description = (props: TextProps) => <Text variant={'caption'} {...props} />;

const ExaminationHeaderInfo = (props: ExaminationHeaderInfoProps) => {
  const { questionName, subject, grade, questionCount, totalExaminationPoint } = props;

  return (
    <ExaminationHeaderInfoWrapper data-testid="examination-header-info">
      <Box sx={{ display: 'grid', gridTemplateColumns: '75px 1fr' }} mb={1}>
        <Title mr={0}>ชื่อชุดข้อสอบ</Title>
        <Description data-testid="examination-name">{questionName}</Description>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${subject.length > 15 ? 2 : 4}, 1fr)`,
          gap: 1,
        }}
      >
        <Flex>
          <Title mr={1.25}>วิชา</Title>
          <Description data-testid="subject">{subject}</Description>
        </Flex>
        <Flex>
          <Title mr={1.25}>ระดับชั้น</Title>
          <Description data-testid="grade">{grade}</Description>
        </Flex>
        <Flex>
          <Title mr={1.25}>จำนวนข้อ</Title>
          <Description data-testid="question-count">{questionCount}</Description>
        </Flex>
        <Flex>
          <Title mr={1.25}>คะแนนรวม</Title>
          <Description data-testid="total-point">{totalExaminationPoint}</Description>
        </Flex>
      </Box>
    </ExaminationHeaderInfoWrapper>
  );
};

export default ExaminationHeaderInfo;
