import { styled, Divider } from '@mui/material';

import { Box, Text, TextProps } from '../../Components/Base';
import { useExaminationParticipant } from '../../Contexts/ExaminationEventStoreProvider';

import {
  getAllowClassesString,
  getExceptCandidatesString,
  formatDisplayExaminationParticipant,
} from './utils';

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 3.5fr',
  gap: theme.spacing(2),
}));

const Title = (props: TextProps) => <Text color={'text.secondary'} fontWeight={'600'} {...props} />;
const Description = (props: TextProps) => <Text fontWeight={'600'} {...props} />;

const ExaminationEventParticipantSummary = () => {
  const [examinationParticipant] = useExaminationParticipant();

  const { candidateByClass, candidateList, candidateExcepted } =
    formatDisplayExaminationParticipant(examinationParticipant);

  const allowClasses = getAllowClassesString(candidateByClass);
  const candidates = getExceptCandidatesString(candidateList);
  const exceptCandidates = getExceptCandidatesString(candidateExcepted);

  return (
    <>
      <Text variant="subtitle1">ผู้มีสิทธิ์เข้าสอบ</Text>
      <Divider sx={{ mt: 2 }} />
      {candidateList.length > 0 ? (
        <ContentWrapper mt={2.5}>
          <Title>แบบรายบุคคล</Title>
          <Description>{candidates}</Description>
        </ContentWrapper>
      ) : (
        <>
          <ContentWrapper mt={2.5}>
            <Title>แบบรายห้อง</Title>
            <Description>{allowClasses}</Description>
          </ContentWrapper>
          <ContentWrapper mt={2.5}>
            <Title>ผู้ที่ไม่มีสิทธิ์เข้าสอบ</Title>
            <Description>{exceptCandidates}</Description>
          </ContentWrapper>
        </>
      )}
    </>
  );
};

export default ExaminationEventParticipantSummary;
