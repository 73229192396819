import { forwardRef, Ref } from 'react';
import { alpha, styled, TextField, TextFieldProps } from '@mui/material';

type TextInputProps = Omit<TextFieldProps, 'variant'>;

const StyledMUITextField = styled(TextField)(({ theme, label }) => ({
  width: '100%',
  '& .MuiFormHelperText-root': {
    //matching with design
    margin: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    '&.Mui-error': {
      color: `${theme.palette.error.main}`,
    },
  },
  '& .MuiInputLabel-shrink': {
    color: theme.palette.grey['800'],
    fontSize: 14,
    fontWeight: 600,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  '& .MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
  },
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    //16px as input padding and additional 12px for center label inside input
    transform: 'translate(16px, 12px) scale(1)',
    //label act as placeholder first then as solid label when shrink active
    color: theme.palette.grey['600'],
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.grey['900'],
    background: theme.palette.common.white,
    height: '44px',
    border: '1px solid',
    borderColor: theme.palette.grey['400'],
    borderRadius: '8px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    marginTop: label ? theme.spacing(3.5) : 'inherit',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
      minHeight: '44px',
      boxSizing: 'border-box',
    },

    //hover case
    '&:hover': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },

    //active case
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none',
      borderColor: 'transparent',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 3px`,
    },

    //error case
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
      '&.Mui-focused': {
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 3px`,
      },
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    // disabled case
    '&.Mui-disabled': {
      color: theme.palette.grey['500'],
      // prevent safari show wrong color in shadow dom ref: https://github.com/mui-org/material-ui/issues/23332
      WebkitTextFillColor: theme.palette.grey['600'],
      backgroundColor: theme.palette.grey['100'],
      '&:hover': {
        borderColor: theme.palette.grey['400'],
      },
      '.MuiSelect-icon': {
        color: theme.palette.grey['500'],
      },
    },
  },
}));

const BaseTextInput = (props: TextInputProps, ref: Ref<HTMLElement>): JSX.Element => {
  return <StyledMUITextField inputRef={ref} InputLabelProps={{ shrink: true }} {...props} />;
};

const TextInput = forwardRef(BaseTextInput);

export type { TextInputProps };
export default TextInput;
