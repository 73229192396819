import { HTMLAttributes } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { styled } from '@mui/material';

import { Box, BoxProps } from '../Base';

export enum Accept {
  SECTION = 'SECTION',
  QUESTION = 'QUESTION',
}

const ACCEPTS = [Accept.SECTION, Accept.QUESTION];

const StyledDropzone = styled(Box)<BoxProps & { $isActive?: boolean; $isLast?: boolean }>(
  ({ theme, $isActive, $isLast }) => {
    const isActive = $isActive || ($isActive && $isLast);
    return {
      display: 'flex',
      flex: '0 0 auto',
      height: theme.spacing(0.5),
      background: isActive ? theme.palette.info.main : 'transparent',
      transition: isActive ? '500ms all' : '200ms all',
      '&:nth-of-type(2n)': {
        display: 'none',
      },
    };
  },
);

export type DropzoneProps = {
  data: {
    path: string;
    childrenCount: number;
  };
  onDropItem: (data: any, item: any) => void;
  isLast?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Dropzone = (props: DropzoneProps) => {
  const { data, onDropItem, isLast, ...restProps } = props;
  const [{ isOver, canDrop }, drop] = useDrop(() => {
    return {
      accept: ACCEPTS,
      drop: (item: any) => {
        onDropItem(data, item);
      },
      canDrop: (item: any) => {
        const dropZonePath = data.path;
        const splitDropZonePath = dropZonePath.split('-');
        const itemPath = item.path;

        if (!itemPath) {
          return true;
        }

        const splitItemPath = itemPath.split('-');

        // Current item can't possible move to it's own location
        if (itemPath === dropZonePath) return false;

        // Current area
        if (splitItemPath.length === splitDropZonePath.length) {
          const pathToItem = splitItemPath.slice(0, -1).join('-');
          const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

          const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
          const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

          if (pathToItem === pathToDropZone) {
            const nextDropZoneIndex = currentItemIndex + 1;
            if (nextDropZoneIndex === currentDropZoneIndex) return false;
          }
        }

        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    };
  }, [onDropItem]);

  const isActive = isOver && canDrop;

  return <StyledDropzone $isActive={isActive} $isLast={isLast} ref={drop} {...restProps} />;
};

export default Dropzone;
