import { useForm, SubmitHandler } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

import FormTextInput from '../../Components/Form/FormTextInput';
import SearchIcon from '../../Components/Icons/SearchIcon';

type SearchDataType = {
  search: string;
};

type SearchBoxProps = {
  placeholder?: string;
};

type SearchBoxFormSubmission = SubmitHandler<SearchDataType>;

const SearchBox = (props: SearchBoxProps) => {
  const { placeholder } = props;
  const [searchParams, setSearchParams] = useSearchParams({});
  const queryParams = searchParams.get('q');

  const { watch, control, handleSubmit } = useForm<SearchDataType>({
    defaultValues: { search: queryParams ?? '' },
  });

  const navigateWithSearchQuery: SearchBoxFormSubmission = useCallback(
    (formValues) => {
      const { search } = formValues;
      setSearchParams(search ? `?q=${search.trim()}` : {});
    },
    [setSearchParams],
  );

  useEffect(() => {
    const subscription = watch(
      debounce((formValues) => navigateWithSearchQuery(formValues as SearchDataType), 700),
    );
    return subscription.unsubscribe;
  }, [watch, navigateWithSearchQuery]);

  return (
    <form onSubmit={handleSubmit(navigateWithSearchQuery)}>
      <FormTextInput
        control={control}
        name="search"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <SearchIcon sx={{ fontSize: 24 }} />
            </InputAdornment>
          ),
        }}
        sx={{ width: 290 }}
      />
    </form>
  );
};

export default SearchBox;
