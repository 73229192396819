import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddIcon from '../../../../Components/Icons/AddIcon';
import { Button } from '../../../../Components/Base';
import { useExaminationContext } from '../../../../Contexts/ExaminationProvider';
import { useSnackbar } from '../../../../Contexts/SnackbarProvider';
import {
  EXAMINATION_EXAMINATION_DETAIL_PATH,
  ResponseType,
} from '../../../../Domains/ExaminationManagement/constant';
import useUpdateExamination from '../../../../Domains/ExaminationManagement/useUpdateExamination';
import { formatExaminationItemToQuestionSelected } from '../../../../Domains/ExaminationManagement/utils';
import { formatQuestionSelectedGroupBySection } from '../utils';

const RESPONSE_MESSAGE = {
  EXAM_NAME_IS_EMPTY: 'กรุณากรอกชื่อชุดข้อสอบ',
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการเพิ่มคำถาม',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'เพิ่มคำถามเรียบร้อยแล้ว',
};

const ExaminationAddQuestionButton = () => {
  const { examId = '' } = useParams();
  const { open } = useSnackbar();
  const navigate = useNavigate();
  const [addQuestionToExam, { loading }] = useUpdateExamination({
    onCompleted: () => open(RESPONSE_MESSAGE.SUCCESS, 'success'),
    onError: (error) => {
      console.error(error);
      //TODO: handle all error message after connect learn auth
      let message = RESPONSE_MESSAGE.UNEXPECTED;
      if (error.message.includes('Not found Exam')) {
        message = RESPONSE_MESSAGE.NOT_FOUND;
      } else if (error.message === 'Failed to fetch') {
        message = RESPONSE_MESSAGE.NETWORK_ERROR;
      }
      open(message, 'error');
    },
  });

  const handleAddExam = () => {
    const examinationStore = useExaminationContext.getState();
    const examName = examinationStore.examinationInformation?.examName;
    const qbQuestions = examinationStore.questionSelected ?? [];
    const questionSelected = formatExaminationItemToQuestionSelected(qbQuestions);
    const questions = formatQuestionSelectedGroupBySection(questionSelected);
    if (examName) {
      addQuestionToExam({ variables: { id: examId, input: { title: examName, questions } } });
    } else {
      open(RESPONSE_MESSAGE.EXAM_NAME_IS_EMPTY, 'warning');
      navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
    }
  };

  return (
    <Button
      data-testid="add-question-selected-to-examination-button"
      variant="text"
      size="large"
      color="info"
      onClick={handleAddExam}
      disabled={loading}
      sx={{ height: 32, py: 0, alignItems: 'center' }}
      startIcon={<AddIcon />}
    >
      {loading ? 'กำลังเพิ่มไปยังชุดข้อสอบ...' : 'เพิ่มไปยังชุดข้อสอบ'}
    </Button>
  );
};

export default memo(ExaminationAddQuestionButton);
