import { styled } from '@mui/material';
import { GridOverlay as MUIGridOverlay } from '@mui/x-data-grid';

import { Text } from '../Base';
import SearchExamIcon from '../Icons/SearchExamIcon';

export type EmptySearchResultProps = {
  title: string;
  description: string;
};

const GridOverlay = styled(MUIGridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.palette.common.white,
}));

const EmptySearchResult = (props: EmptySearchResultProps) => {
  const { title, description } = props;
  return (
    <GridOverlay>
      <SearchExamIcon color="primary" />
      <Text variant={'h4'} mt={2}>
        {title}
      </Text>
      <Text mt={0.5}>{description}</Text>
    </GridOverlay>
  );
};

export default EmptySearchResult;
