import { styled } from '@mui/material';

import { Flex, FlexProps } from '../Flex';
import { Text } from '../Text';
import LoadingIcon from '../../Icons/LoadingIcon';
import { Modal as BaseModal, ModalContent } from '../Modal';

const Modal = styled(BaseModal)({
  '& .MuiDialog-paper': {
    maxWidth: '180px',
    width: '100%',
    borderRadius: '8px',
    boxShadow: 'none',
  },
});

export enum LoadingState {
  FLAT = 'flat',
  MODAL = 'modal',
}

export type LoadingProps = {
  open?: boolean;
  variant?: LoadingState;
} & FlexProps;

const LoadingContent = (props: FlexProps) => {
  return (
    <Flex alignItems={'center'} flexDirection={'column'} justifyContent={'center'} {...props}>
      <LoadingIcon color="primary" sx={{ fontSize: 48 }} />
      <Text fontFamily={'inherit'} mt={2}>
        {'กำลังดาวน์โหลด...'}
      </Text>
    </Flex>
  );
};

const Loading = (props: LoadingProps) => {
  const { open = false, variant = LoadingState.MODAL, ...restProps } = props;

  if (variant === LoadingState.FLAT) return <LoadingContent {...restProps} />;

  return (
    <Modal open={open}>
      <ModalContent sx={{ p: 3 }}>
        <LoadingContent {...restProps} />
      </ModalContent>
    </Modal>
  );
};

export default Loading;
