import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { styled, Tooltip } from '@mui/material';
import {
  GridRowParams,
  GridRenderCellParams,
  GridColumns,
  GridCellParams,
  GridSortDirection,
  GridOverlay,
} from '@mui/x-data-grid';
import { format } from 'date-fns';

import ExaminationListEmpty from '../Components/ExaminationListEmpty';
import { Box, Color, DataGrid, Label, Text } from '../../../Components/Base';
import EmptySearchResult from '../../../Components/EmptySearchResult';
import SearchBox from '../../../Containers/SearchBox';
import {
  EXAMINATION_EXAMINATION_CREATION_PATH,
  EXAMINATION_EXAMINATION_DETAIL_PATH,
  ExaminationStatus,
  ExaminationOrder,
  QueryParamsDataGrid,
} from '../../../Domains/ExaminationManagement/constant';
import useGetExaminations from '../../../Domains/ExaminationManagement/useGetExaminations';
import { QMSTagCategory, QMSTag } from '../../../Domains/ExaminationManagement/useGetQMSTags';
import Loading, { LoadingState } from '../../../Components/Base/Loading/Loading';

import DuplicateExaminationButton from './DuplicateExaminationButton';
import RemoveExaminationButton from './RemoveExaminationButton';
import DeprecateExaminationButton from './DeprecateExaminationButton';
import EditExaminationButton from './EditExaminationButton';
import ViewExaminationButton from './ViewExaminationButton';

const TableWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const initialQueryString = queryString.stringify({
  q: undefined,
  field: 'updatedAt',
  sort: 'desc',
  page: 0,
  pageSize: 10,
});

const ExaminationList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(initialQueryString);
  const searchKey = searchParams.get('q') ?? undefined;
  const fieldKey = searchParams.get('field') ?? undefined;
  const sortKey = (searchParams.get('sort') ?? null) as GridSortDirection;
  const pageKey = searchParams.get('page') ?? 0;
  const pageSizeKey = searchParams.get('pageSize') ?? 10;
  const currentPageSize = Number(pageSizeKey);
  const currentPage = Number(pageKey);
  const currentSort = sortKey === 'asc' ? 'Asc' : 'Desc';

  const {
    data: examinations,
    loading: examinationsLoading,
    refetch,
  } = useGetExaminations({
    fetchPolicy: 'cache-and-network',
    variables: {
      search: {
        title: searchKey,
        tagName: searchKey,
      },
      paging: {
        pageSize: currentPageSize,
        currentPage: currentPage,
      },
      order: fieldKey
        ? {
            field: fieldKey,
            type: ExaminationOrder[currentSort],
          }
        : undefined,
    },
    errorPolicy: 'all',
  });

  const examinationRows = examinations?.qmsExams?.exams ?? [];
  const examinationTotal = examinations?.qmsExams?.total ?? 0;

  const setSearchQueryParams = (dataQuery: QueryParamsDataGrid) => {
    const pageValue = dataQuery.currentPage;
    const pageSizeValue = dataQuery.currentPageSize;
    const fieldValue = dataQuery.sortField;
    const sortValue = dataQuery.sortOrder;

    const page = pageValue ?? currentPage;
    const pageSize = pageSizeValue ?? currentPageSize;
    const field = pageValue || pageValue === 0 || pageSizeValue ? fieldKey : fieldValue ?? '';
    const sort = pageValue || pageValue === 0 || pageSizeValue ? sortKey : sortValue ?? '';
    const q = !!searchKey ? searchKey : undefined;

    const stringify = queryString.stringify({ q, page, pageSize, field, sort });
    setSearchParams(stringify);
  };

  const handleCellClick = (cell: GridCellParams) => {
    if (cell.field !== 'actions') {
      navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', cell.id.toString()));
    }
  };

  const columnTable = useMemo<GridColumns>(
    () => [
      { field: 'id', hide: true },
      {
        field: 'updatedAt',
        type: 'date',
        headerName: 'วันที่แก้ไข',
        width: 120,
        renderCell: (item: GridRenderCellParams) => format(new Date(item.value), 'dd/MM/yyyy'),
      },
      {
        field: 'title',
        headerName: 'ชื่อชุดข้อสอบ',
        minWidth: 200,
        flex: 1,
        hideable: false,
        renderCell: (item: GridRenderCellParams) => (
          <Tooltip title={item.value} arrow>
            <Text
              variant={'body1'}
              fontWeight={600}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {item.value}
            </Text>
          </Tooltip>
        ),
      },
      {
        field: 'subject',
        headerName: 'วิชา',
        width: 115,
        sortable: false,
        renderCell: (item: GridRenderCellParams) => {
          const tags: QMSTag[] = item.row.tags;
          const subject =
            tags.find((value) => value.category === QMSTagCategory.Subject)?.name ?? '-';
          return (
            <Tooltip title={subject} arrow>
              <Text
                variant={'body1'}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                overflow={'hidden'}
              >
                {subject}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        field: 'grade',
        headerName: 'ระดับชั้น',
        headerAlign: 'center',
        align: 'center',
        width: 110,
        sortable: false,
        renderCell: (item: GridRenderCellParams) => {
          const tags: QMSTag[] = item.row.tags;
          const grade = tags.find((value) => value.category === QMSTagCategory.Grade)?.name ?? '-';
          return (
            <Text
              variant={'body1'}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >
              {grade}
            </Text>
          );
        },
      },
      {
        field: 'totalQuestions',
        headerName: 'จำนวนข้อ',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: (item: GridRenderCellParams) => {
          return <Text variant={'body1'}>{item.value ?? '-'}</Text>;
        },
      },
      {
        field: 'status',
        headerName: 'สถานะ',
        width: 115,
        renderCell: (item: GridRenderCellParams) => {
          let label: { color: Color; message: string } = { color: Color.INFO, message: 'ฉบับร่าง' };
          if (item.value === ExaminationStatus.Published) {
            label = { color: Color.SUCCESS, message: 'เผยแพร่' };
          } else if (item.value === ExaminationStatus.Deprecated) {
            label = { color: Color.NEUTRAL, message: 'เลิกใช้แล้ว' };
          }
          return (
            <Label color={label.color} px={label.message === 'เลิกใช้แล้ว' ? 1.4 : 2}>
              {label.message}
            </Label>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'left',
        width: 146,
        getActions: (item: GridRowParams) => {
          const actions = [
            <DuplicateExaminationButton examId={item.id as string} onCompleted={refetch} />,
          ];

          if (item.row.status === ExaminationStatus.Draft) {
            actions.unshift(<EditExaminationButton examId={item.id as string} />);
            actions.push(
              <RemoveExaminationButton examId={item.id as string} onCompleted={refetch} />,
            );
          } else {
            actions.unshift(<ViewExaminationButton examId={item.id as string} />);
          }

          if (item.row.status === ExaminationStatus.Published) {
            actions.push(<DeprecateExaminationButton examId={item.id} />);
          }

          return actions;
        },
      },
    ],
    [refetch],
  );

  const isExaminationListEmpty = examinationTotal === 0 && !searchKey && !examinationsLoading;

  if (isExaminationListEmpty) {
    return <ExaminationListEmpty redirectUrl={EXAMINATION_EXAMINATION_CREATION_PATH} />;
  }

  return (
    <Box
      p={3}
      pb={1}
      borderRadius={1.875}
      boxShadow={'0px 2px 15px hsla(206, 13%, 25%, 0.1)'}
      bgcolor={'common.white'}
    >
      <SearchBox placeholder="ค้นหาชุดข้อสอบ/วิชา" />
      <TableWrapper height={examinationTotal === 0 ? '700px' : 'auto'}>
        <DataGrid
          rows={examinationRows}
          columns={columnTable}
          initialState={{
            sorting: {
              sortModel: fieldKey ? [{ field: fieldKey ?? '', sort: sortKey }] : undefined,
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <EmptySearchResult
                title="ไม่พบชุดข้อสอบที่คุณค้นหา"
                description="กรุณาค้นหาอีกครั้ง"
              />
            ),
            LoadingOverlay: () => (
              <GridOverlay>
                <Loading variant={LoadingState.FLAT} mb={6} />
              </GridOverlay>
            ),
          }}
          loading={!examinations && examinationsLoading}
          autoHeight={examinationTotal > 0}
          hideFooter={examinationTotal === 0}
          disableSelectionOnClick
          disableColumnFilter
          sortingMode="server"
          paginationMode="server"
          onCellClick={handleCellClick}
          rowCount={examinationTotal}
          page={currentPage}
          pageSize={currentPageSize}
          onPageChange={(page) => setSearchQueryParams({ currentPage: page })}
          onPageSizeChange={(pageSize) => setSearchQueryParams({ currentPageSize: pageSize })}
          onSortModelChange={(sorting) => {
            const field = sorting[0]?.field;
            const sort = sorting[0]?.sort ?? undefined;
            setSearchQueryParams({ sortField: field, sortOrder: sort });
          }}
        />
      </TableWrapper>
    </Box>
  );
};

export default ExaminationList;
