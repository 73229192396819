import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';

import { Box, Flex, Button } from '../../../Components/Base';
import SearchIcon from '../../../Components/Icons/SearchIcon';
import SearchExaminationResultsForm, {
  SearchExaminationResultFormProps,
} from '../Components/SearchExaminationResultsForm';
import { SEMESTER_LIST } from '../constants';

const initialFormValues = {
  examEventName: '',
  semester: '',
};

const ExaminationResultSubmissionBlock: SearchExaminationResultFormProps['submitComponent'] = (
  formContext,
) => {
  return (
    <>
      <Box pl={2} mt={{ xs: 2, md: 0 }} />
      <Flex flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: 1, md: 'auto' }}>
        <Button type="submit" size={'large'} startIcon={<SearchIcon />}>
          {'ค้นหา'}
        </Button>
        <Box pl={2} mt={{ xs: 2, md: 0 }} />
        <Button
          variant={'text'}
          size={'large'}
          onClick={() => {
            formContext.reset(initialFormValues);
          }}
        >
          {'ล้างทั้งหมด'}
        </Button>
      </Flex>
    </>
  );
};

const queryStringFormat = (data: SearchExaminationResultFormProps['initialFormValues']) => {
  const examEventNameValue = data?.examEventName;
  const semesterValue = data?.semester;

  const examEventName = examEventNameValue ? examEventNameValue : undefined;
  const semester = semesterValue ? semesterValue : undefined;

  const stringify = queryString.stringify({ examEventName, semester });

  return stringify;
};

const SearchExaminationResult = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const examEventNameParams = searchParams.get('examEventName');
  const semesterParams = searchParams.get('semester');

  return (
    <>
      <SearchExaminationResultsForm
        initialFormValues={{
          examEventName: examEventNameParams ?? '',
          semester: semesterParams ?? '',
        }}
        semesterList={SEMESTER_LIST}
        onSubmit={() => (formValues) => {
          const examEventName = formValues.examEventName;
          const semester = formValues.semester;
          if (!examEventName && !semester) return setSearchParams({});
          const query = queryStringFormat(formValues);
          setSearchParams(query);
        }}
        submitComponent={ExaminationResultSubmissionBlock}
      />
    </>
  );
};

export default SearchExaminationResult;
