import { styled, Drawer as MUIDrawer, List } from '@mui/material';

import { Flex, IconButton, Image } from '../../Components/Base';
import MenuIcon from '../../Components/Icons/MenuIcon';
import { MENU_DRAWER_WIDTH } from '../../Components/Layout';
import { useConfig } from '../../Contexts/ConfigProvider';
import useAppMenu from '../../Domains/useAppMenu';
import useDisclosure from '../../Utils/useDisclosure';
import MenuItem from '../MenuItem';

const Drawer = styled(MUIDrawer)(({ theme }) => ({
  display: 'block',
  '& .MuiPaper-root': {
    boxSizing: 'border-box',
    width: MENU_DRAWER_WIDTH,
    padding: theme.spacing(2),
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const HamburgerMenu = () => {
  const { logoUrl } = useConfig();
  const { appMenu } = useAppMenu();
  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={open}
        sx={{ mr: 2, display: { md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={close}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {logoUrl && (
          <Flex px={2} justifyContent={'center'}>
            <Image src={logoUrl} alt={'app-logo'} width={1} />
          </Flex>
        )}
        <List sx={{ marginTop: 2 }}>
          {appMenu.map((menu) => (
            <MenuItem key={menu.label} {...menu} />
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
