import React from 'react';
import { styled, AppBar as MUIAppBar, Toolbar } from '@mui/material';

import { MENU_DRAWER_WIDTH } from '../constants';

export type AppBarProps = {
  children: React.ReactChild[];
};

const StyledAppBar = styled(MUIAppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: 'none',

  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${MENU_DRAWER_WIDTH}px)`,
    marginLeft: `${MENU_DRAWER_WIDTH}px`,
  },
}));

const AppBar = ({ children }: AppBarProps) => {
  return (
    <StyledAppBar>
      <Toolbar>{children}</Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;
