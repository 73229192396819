export const SEMESTER_LIST = [
  { label: 'เทอม 1 ปีการศึกษา 2564', value: 'semester1' },
  { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
  { label: 'เทอม 1 ปีการศึกษา 2565', value: 'semester3' },
];

export const CLASS_LIST = [
  { label: 'ม.1', value: 'm1' },
  { label: 'ม.2', value: 'm2' },
  { label: 'ม.3', value: 'm3' },
];

export const CANDIDATE_BY_CLASS = [
  { id: 'm1', class: 'ม.1/1', students: 30 },
  { id: 'm2', class: 'ม.1/2', students: 32 },
  { id: 'm3', class: 'ม.1/3', students: 30 },
];

export const CANDIDATES = [
  { id: 'candidates1', firstName: 'ศุภกร', lastName: 'วงษ์ขจร', class: 'ม.1/1' },
  { id: 'candidates2', firstName: 'วงษ์ขจร', lastName: 'มากสุข', class: 'ม.1/2' },
  { id: 'candidates3', firstName: 'ศุภกร', lastName: 'สุขใจ', class: 'ม.1/3' },
];

export const CANDIDATE_EXCEPTED = [
  { id: 'candidates1', firstName: 'ศุภกร', lastName: 'วงษ์ขจร', class: 'ม.1/1' },
  { id: 'candidates2', firstName: 'วงษ์ขจร', lastName: 'มากสุข', class: 'ม.1/2' },
  { id: 'candidates3', firstName: 'ศุภกร', lastName: 'สุขใจ', class: 'ม.1/3' },
];
