import { useController } from 'react-hook-form';

import { Textarea } from '../../Base';

import { FormTextareaProps, FieldValues } from './types';

const FormTextarea = <TFieldValues extends FieldValues = FieldValues>(
  props: FormTextareaProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });

  return (
    <Textarea
      {...field}
      {...props}
      error={isTouched ? invalid : false}
      helperText={error?.message ?? null}
    />
  );
};

export default FormTextarea;
