import { useParams } from 'react-router-dom';

import { Box, Text } from '../../Components/Base';
import ExaminationEventSummary from '../../Containers/ExaminationEventSummary';
import {
  useExamEventDetails,
  ExaminationEventStatus,
} from '../../Domains/ExaminationManagement/useExamEventDetails';

import ExaminationEventDetailsReformation from './Containers/ExaminationEventDetailsReformation';

const ExaminationEventDetails = () => {
  const { eventId = '' } = useParams();
  const { data } = useExamEventDetails({ id: eventId });

  return (
    <Box
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px hsla(206, 13%, 25%, 0.1)'}
      bgcolor={'common.white'}
    >
      {data.status === ExaminationEventStatus.PUBLISH && <ExaminationEventSummary />}
      {data.status === ExaminationEventStatus.DRAFT && (
        <>
          <Text variant="h4">{'รายละเอียดการจัดสอบ'}</Text>
          <ExaminationEventDetailsReformation />
        </>
      )}
    </Box>
  );
};

export default ExaminationEventDetails;
