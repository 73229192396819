import { styled } from '@mui/material';

import {
  Box,
  Color,
  Flex,
  Label,
  Modal as BaseModal,
  ModalContent,
  ModalTitle,
  Text,
} from '../../../Components/Base';
import HTMLView from '../../../Components/HTMLView';
import CheckRoundedIcon from '../../../Components/Icons/CheckRoundedIcon';
import { QBQuestion } from '../Domains/useGetPublishedQuestions';
import { QUESTION_CHOICES } from '../../../Domains/ExaminationManagement/constant';

const Modal = styled(BaseModal)({
  '& .MuiDialog-paper': {
    maxWidth: '825px',
  },
});

export type QuestionDetailsProps = {
  bloom: QBQuestion['tags'][0];
  difficulty: QBQuestion['tags'][0];
  indicator: QBQuestion['tags'][0];
} & QBQuestion;

type QuestionDetailsModalProps = {
  onClose: () => void;
  open: boolean;
  question?: QuestionDetailsProps;
};

const QuestionDetailsModal = (props: QuestionDetailsModalProps) => {
  const { question: questionDetails, onClose, open } = props;
  const {
    question = '',
    metadata = {},
    correctAnswer,
    indicator,
    bloom,
    difficulty,
  } = questionDetails ?? {};

  const choices = metadata?.choices ?? [];

  return (
    <Modal data-testid="question-details-modal" open={open} onClose={onClose}>
      <ModalTitle
        sx={{
          pt: 3,
          pb: 0,
          mb: 3,
        }}
        onClose={onClose}
      >
        <Flex>
          <Label data-testid="indicator" color={Color.PRIMARY} variant={'capsule'}>
            <Text variant={'body2'}>
              {'ตัวชี้วัด: '}
              <Text
                data-testid="indicator-value"
                component={'span'}
                variant={'body2'}
                fontWeight={'600'}
              >
                {indicator?.name.substring(0, 11).trim() ?? '-'}
              </Text>
            </Text>
          </Label>
          <Label data-testid="bloom" color={Color.PRIMARY} variant={'capsule'} ml={1}>
            <Text variant={'body2'}>
              {'Bloom: '}
              <Text
                data-testid="bloom-value"
                component={'span'}
                variant={'body2'}
                fontWeight={'600'}
              >
                {bloom?.name ?? '-'}
              </Text>
            </Text>
          </Label>
          <Label data-testid="difficulty" color={Color.PRIMARY} variant={'capsule'} ml={1}>
            <Text variant={'body2'}>
              {'ระดับความยาก: '}
              <Text
                data-testid="difficulty-value"
                component={'span'}
                variant={'body2'}
                fontWeight={'600'}
              >
                {difficulty?.name ?? '-'}
              </Text>
            </Text>
          </Label>
        </Flex>
      </ModalTitle>
      <ModalContent sx={{ p: 3 }}>
        <HTMLView data-testid="question" rawContent={question} />
        <Box pt={3} />
        {choices.map((choice: { label: string; value: string }, index: number) => {
          return (
            <Flex
              data-testid={`choice-${index + 1}`}
              mt={0.25}
              px={2}
              py={1.25}
              key={index}
              bgcolor={correctAnswer?.value === choice.value ? 'success.lighter' : 'transparent'}
            >
              {correctAnswer?.value === choice.value && (
                <CheckRoundedIcon
                  data-testid="correct-answer"
                  color={'success'}
                  sx={{ fontSize: 22 }}
                />
              )}
              <Flex>
                <Text
                  data-testid="badge"
                  ml={correctAnswer?.value === choice.value ? 2.25 : 5}
                  mr={index < 8 ? '1ch' : 'unset'}
                >
                  {QUESTION_CHOICES[index]}
                </Text>
                <HTMLView data-testid="label" rawContent={choice.label} />
              </Flex>
            </Flex>
          );
        })}
      </ModalContent>
    </Modal>
  );
};

export default QuestionDetailsModal;
