import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';

import { Box } from '../../Components/Base';
import BackToTop from '../../Components/BackToTop';

const ScrollToTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        sx={{ position: 'fixed', bottom: { xs: 16, sm: 25 }, right: { xs: 16, sm: 25 } }}
      >
        <BackToTop />
      </Box>
    </Zoom>
  );
};

export default ScrollToTop;
