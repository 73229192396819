import TagManager from 'react-gtm-module';

interface GoogleTagManagerConfig {
  id: string;
  authToken?: string;
  previewId?: string;
}

export const initialize = (gtmConfig?: GoogleTagManagerConfig) => {
  if (!gtmConfig?.id) return;

  try {
    TagManager.initialize({
      gtmId: gtmConfig?.id,
      auth: gtmConfig?.authToken,
      preview: gtmConfig?.previewId,
    });
  } catch (e) {
    console.error(e);
  }
};

export const sendDataLayer = (dataLayer: Record<string, unknown>) => {
  try {
    TagManager.dataLayer({ dataLayer });
  } catch (e) {
    console.error(e);
  }
};
