import { useController } from 'react-hook-form';

import TextInput from '../../Base/Input/TextInput';

import { FormTextInputProps, FieldValues } from './types';

const FormTextInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormTextInputProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });
  return (
    <TextInput
      {...field}
      {...props}
      type={props?.type ?? 'text'}
      error={isTouched ? invalid : false}
      helperText={error?.message ?? null}
    />
  );
};

export type { FormTextInputProps };
export default FormTextInput;
