import React from 'react';
import { styled, Divider } from '@mui/material';

import { Box, BoxProps, Flex, Text, Label, LabelProps, IconButton, IconButtonProps } from '../Base';
import CheckRoundedIcon from '../Icons/CheckRoundedIcon';
import DeleteIcon from '../Icons/DeleteIcon';
import FormTextInput from '../Form/FormTextInput';
import HTMLView from '../../Components/HTMLView';
import ActionConfirmationModal from '../../Components/ActionConfirmModal';
import { QUESTION_CHOICES } from '../../Domains/ExaminationManagement/constant';
import useDisclosure from '../../Utils/useDisclosure';

export type QuestionItemType = {
  id: string;
  bloom: string;
  choices: string[];
  correctAnswer: number;
  order: number;
  question: string;
  point: number;
};

type QuestionItemProps = {
  question: QuestionItemType;
  onDelete?: () => void;
  readonly?: boolean;
} & BoxProps;

const TextInput = styled(FormTextInput)({
  '& .MuiOutlinedInput-root': {
    fontSize: 14,
    fontWeight: '600',
    maxWidth: '60px',
    borderRadius: '4px',
    padding: '2px',
    paddingLeft: '4px',
    '& .MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center',
      minHeight: '20px',
      boxSizing: 'border-box',
    },
  },

  // Make step up/down button always show
  // Ref: https://stackoverflow.com/questions/25194631/is-it-possible-to-always-show-up-down-arrows-for-input-number
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button ': {
    opacity: 1,
  },
});

const ExaminationQuestionItemDeleteButton = ({ onClick, ...props }: IconButtonProps) => {
  const { isOpen, open, close } = useDisclosure();
  return (
    <>
      <IconButton {...props} onClick={open}>
        <DeleteIcon />
      </IconButton>
      <ActionConfirmationModal
        open={isOpen}
        onDiscard={close}
        onClose={close}
        onAccept={(e) => {
          onClick?.(e);
          e.currentTarget.innerText = 'กำลังลบคำถาม ...';
          close();
        }}
        title="ต้องการลบคำถามนี้ใช่ไหม"
        subtitle="หากลบคำถามแล้ว คุณสามารถเพิ่มใหม่ได้ที่เมนูเลือกคำถาม"
      />
    </>
  );
};

const ExaminationQuestionItem = (props: QuestionItemProps) => {
  const { question: questionItem, onDelete, readonly, ...restProps } = props;
  const { id, question, order, choices, correctAnswer, bloom, point } = questionItem;
  const minPoint = 0;
  const maxPoint = 100;

  return (
    <Box data-testid="question" {...restProps}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text data-testid="order" sx={{ flexGrow: 1 }} fontWeight={'600'}>
          ข้อที่ {order}
        </Text>
        <Label data-testid="bloom" flex={'0 0 auto'} variant={'capsule' as LabelProps['variant']}>
          {bloom}
        </Label>
        <Flex mx={2} alignItems={'center'}>
          {readonly ? (
            <Text data-testid="point" variant={'body2'}>
              {point}
            </Text>
          ) : (
            <TextInput
              data-testid="point"
              name={id}
              type={'number'}
              defaultValue={point}
              InputProps={{ inputProps: { min: minPoint, max: maxPoint } }}
              sx={{ height: '28px' }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                const integerValue = parseInt(e.target.value);
                const isNumber = !isNaN(integerValue);
                if (isNumber) {
                  if (integerValue >= 0 && integerValue <= 100) {
                    e.target.value = integerValue.toString();
                  } else {
                    e.target.value = integerValue < 0 ? '0' : '100';
                  }
                } else {
                  e.target.value = '0';
                }
              }}
            />
          )}
          <Text ml={1} variant={'body2'} fontWeight={'600'}>
            {'คะแนน'}
          </Text>
        </Flex>
        {!readonly && typeof onDelete === 'function' && (
          <ExaminationQuestionItemDeleteButton
            data-testid="delete-question-button"
            onClick={onDelete}
          />
        )}
      </Flex>
      <Divider sx={{ mt: 2 }} />
      <Box mt={3} />
      <Text data-testid="label">
        <HTMLView rawContent={question} />
      </Text>
      <Box mt={3} />
      {choices.map((choice, index) => {
        return (
          <Flex
            data-testid={`choice-${index + 1}`}
            mt={0.25}
            px={2}
            py={1.5}
            key={`${index}-${choice}`}
            bgcolor={correctAnswer === index + 1 ? 'success.lighter' : 'transparent'}
          >
            {correctAnswer === index + 1 && (
              <CheckRoundedIcon
                data-testid="correct-answer"
                color={'success'}
                sx={{ fontSize: 22, position: 'absolute' }}
              />
            )}
            <Flex>
              <Text data-testid="badge" ml={5} mr={index < 8 ? '1ch' : 'unset'}>
                {QUESTION_CHOICES[index]}
              </Text>
              <Text data-testid="label">
                <HTMLView rawContent={choice} />
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default ExaminationQuestionItem;
