import { set, isEqual, format } from 'date-fns';

import { DateTimeType, ExaminationEvent } from '../../Contexts/ExaminationEventStoreProvider';

import {
  SEMESTER_LIST,
  CLASS_LIST,
  CANDIDATE_BY_CLASS,
  CANDIDATES,
  CANDIDATE_EXCEPTED,
} from './constants';

export const getDateTimeString = (startDate?: DateTimeType, endDate?: DateTimeType) => {
  if (!startDate || !endDate || startDate === '' || endDate === '') return '-';

  let dateTimeString = '';
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startDateWithoutTime = set(start, { hours: 0, minutes: 0, seconds: 0 });
  const endDateWithoutTime = set(end, { hours: 0, minutes: 0, seconds: 0 });

  if (isEqual(startDateWithoutTime, endDateWithoutTime)) {
    dateTimeString = `${format(start, 'dd/MM/yyyy, HH:mm')} - ${format(end, 'HH:mm')} น.`;
  } else {
    dateTimeString = `${format(start, 'dd/MM/yyyy, HH:mm น.')} - ${format(
      end,
      'dd/MM/yyyy, HH:mm น.',
    )}`;
  }

  return dateTimeString;
};

export const getAllowClassesString = (data: { class: string; students: number }[]) => {
  if (data.length === 0) return '-';
  const allowCandidate: string[] = [];

  data.map((classroom) => {
    const { class: classNo, students } = classroom;
    allowCandidate.push(`${classNo} (${students})`);
  });
  const allowCandidateString = allowCandidate.join(', ');

  return allowCandidateString.trim();
};

export const getExceptCandidatesString = (
  data: {
    id: string;
    firstName: string;
    lastName: string;
    class: string;
  }[],
) => {
  if (data.length === 0) return '-';
  const exceptStudents: string[] = [];

  data.map((student) => {
    const { firstName, lastName, class: classNo } = student;
    exceptStudents.push(`${firstName} ${lastName} (${classNo})`);
  });

  return exceptStudents.join(', ');
};

export const formatDisplayExaminationEventInformation = (
  eventInformation: ExaminationEvent['examinationEventInformation'],
) => {
  const {
    examEventName,
    semester,
    class: classNo,
    examination,
    startTime,
    endTime,
    randomQuestion,
    randomAnswer,
    shouldShowPoints,
    instruction,
  } = eventInformation ?? {};

  const semesterOption = SEMESTER_LIST.find((option) => option.value === semester);
  const gradeOption = CLASS_LIST.find((option) => option.value === classNo);

  const displayExamEventName = examEventName ?? '-';
  const displaySemester = semesterOption?.label ?? '-';
  const displayGrade = gradeOption?.label ?? '-';

  const examinationLabel = examination?.label !== '' ? examination?.label : null;
  const displayExamination = examinationLabel ?? '-';

  // Check Invalid Date when date is undefined
  // Ref: https://stackoverflow.com/questions/10589732/checking-if-a-date-is-valid-in-javascript
  const displayStartTime =
    startTime instanceof Date && !isNaN(startTime.valueOf()) ? startTime : '';
  const displayEndTime = endTime instanceof Date && !isNaN(endTime.valueOf()) ? endTime : '';

  const displayRandomQuestion = randomQuestion ? 'เปิด' : 'ปิด';
  const displayRandomAnswer = randomAnswer ? 'เปิด' : 'ปิด';
  const displayShouldShowPoints = shouldShowPoints ? 'เปิด' : 'ปิด';
  const displayInstruction = instruction !== '' ? instruction : '-';

  return {
    examEventName: displayExamEventName,
    semester: displaySemester,
    grade: displayGrade,
    examination: displayExamination,
    startTime: displayStartTime,
    endTime: displayEndTime,
    randomQuestion: displayRandomQuestion,
    randomAnswer: displayRandomAnswer,
    shouldShowPoints: displayShouldShowPoints,
    instruction: displayInstruction,
  };
};

export const formatDisplayExaminationParticipant = (
  examParticipant: ExaminationEvent['examinationParticipant'],
) => {
  const rooms = examParticipant?.rooms ?? [];
  const candidates = examParticipant?.candidateList ?? [];
  const exceptCandidates = examParticipant?.exceptCandidates ?? [];

  const candidateByClass = CANDIDATE_BY_CLASS.filter((option) =>
    rooms?.find((room) => option.id === room?.value),
  );

  const candidateList = CANDIDATES.filter((option) =>
    candidates?.find((candidate) => option.id === candidate?.value),
  );

  const candidateExcepted = CANDIDATE_EXCEPTED.filter((option) =>
    exceptCandidates?.find((candidate) => option.id === candidate?.value),
  );

  return {
    candidateByClass,
    candidateList,
    candidateExcepted,
  };
};
