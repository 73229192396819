import { useParams } from 'react-router-dom';
import { Divider, styled } from '@mui/material';

import { Box, BoxProps, Flex, Text } from '../../../Components/Base';
import AddIcon from '../../../Components/Icons/AddIcon';
import { LinkButton } from '../../../Components/LinkButton';
import { useExaminationQuestion } from '../../../Contexts/ExaminationProvider';
import { EXAMINATION_EXAMINATIONS_PATH } from '../../../Domains/ExaminationManagement/constant';

import QuestionManagement from './QuestionManagement';

const SummaryWrapper = styled(Box)(({ theme }) => ({
  boxShadow: '0px 2px 15px hsla(206, 13%, 25%, 0.1)',
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
}));

const ExamSummary = (props: BoxProps) => {
  const { examId } = useParams();
  const [draftSummary] = useExaminationQuestion();

  const examinationSummary = draftSummary ?? [];
  const totalQuestionCount = examinationSummary.length;
  const points = examinationSummary.map((question) => question.point).filter((point) => !!point);
  const totalExaminationPoint = points.reduce((prev, current) => prev + current, 0);

  return (
    <Box data-testid="examination-summary" {...props}>
      <SummaryWrapper>
        <LinkButton
          data-testid="back-to-select-question-button"
          to={`${EXAMINATION_EXAMINATIONS_PATH}/${examId}/questions`}
          sx={{ width: 1 }}
          size="large"
          color="neutral"
          startIcon={<AddIcon />}
        >
          {'เลือกคำถามเพิ่ม'}
        </LinkButton>
        <Flex
          data-testid="total-point"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          width={1}
          mt={3}
        >
          <Text data-testid="label" variant="subtitle1">
            {'คะแนนรวม:'}
          </Text>
          <Text data-testid="value" variant="subtitle1">
            {totalExaminationPoint}
          </Text>
        </Flex>
        <Divider sx={{ mt: 1.25, mb: 2 }} />

        <Flex
          data-testid="question-count"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          width={1}
        >
          <Text data-testid="label" variant="subtitle1">
            {'จำนวนข้อ:'}
          </Text>
          <Text data-testid="value" variant="subtitle1">
            {totalQuestionCount}
          </Text>
        </Flex>
        <Divider sx={{ mt: 1.25, mb: 2 }} />

        <QuestionManagement />
      </SummaryWrapper>
    </Box>
  );
};

export default ExamSummary;
