import { ReactNode } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useExaminationManagement } from '../../Contexts/ExaminationProvider';
import { useSnackbar } from '../../Contexts/SnackbarProvider';
import {
  ResponseType,
  ExaminationStatus,
  EXAMINATION_EXAMINATION_DETAIL_PATH,
} from '../../Domains/ExaminationManagement/constant';
import { useGetExamination } from '../../Domains/ExaminationManagement/useGetExamination';
import { formatExaminationToStore } from '../../Domains/ExaminationManagement/utils';

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.UNEXPECTED]: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'บันทึกฉบับร่างเรียบร้อยแล้ว',
};

const ExaminationPreloader = ({ children }: { children?: ReactNode }) => {
  const { examId = '' } = useParams();
  const navigate = useNavigate();
  const { open } = useSnackbar();
  const { reset } = useExaminationManagement();

  useGetExamination({
    skip: !examId,
    variables: { id: examId },
    onCompleted: (data) => {
      if (!examId) return;
      reset();
      const exam = formatExaminationToStore(data?.qmsExam);
      reset(exam);
      if (data?.qmsExam.status === ExaminationStatus.Published) {
        navigate(EXAMINATION_EXAMINATION_DETAIL_PATH.replace(':examId', examId));
      }
    },
    onError: async (error) => {
      console.error(error);
      //TODO: handle all error message after connect learn auth
      if (error.message === 'Failed to fetch') {
        open(RESPONSE_MESSAGE.NETWORK_ERROR, 'error');
      } else {
        open(RESPONSE_MESSAGE.UNEXPECTED, 'error');
      }
    },
  });

  return <>{children}</>;
};

export default ExaminationPreloader;
