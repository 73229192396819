import { Flex, Text } from '../../Components/Base';
import { LinkButton } from '../../Components/LinkButton';
import AddIcon from '../../Components/Icons/AddIcon';
import { EXAMINATION_EVENT_CREATION_PATH } from '../../Domains/ExaminationManagement/constant';

import ExaminationEventList from './Containers/ExaminationEventList';

const ExaminationEvents = () => {
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={3}>
        <Text variant={'h2'}>{'การจัดสอบ'}</Text>
        <LinkButton to={EXAMINATION_EVENT_CREATION_PATH} variant={'contained'} size="large">
          <AddIcon sx={{ fontSize: 24 }} />
          <Text ml={0.5} fontWeight={600}>
            สร้างการสอบ
          </Text>
        </LinkButton>
      </Flex>
      <ExaminationEventList />
    </>
  );
};

export default ExaminationEvents;
