import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import FormSearchableMultipleSelectionInput from '../../../../Components/Form/FormSearchableMultipleSelectionInput';
import useGetQBRemainTags, {
  QBTagCategory,
} from '../../../../Domains/ExaminationManagement/useGetQBRemainTags';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { getTagIds, getTagOptions } from '../utils';

const BloomsSelector = () => {
  const formContext = useFormContext();
  const { examId = '' } = useParams();
  const { data } = useGetExamination({ variables: { id: examId } });
  const tagIdsWithIndicator: string[] = [];

  const learningUnit = formContext.watch('learningUnit.value');
  const indicator = formContext.watch('indicator.value');

  const isLearningUnitEmptyState = !learningUnit;
  const searchLearningUnit = learningUnit ?? '';
  const searchIndicator = indicator ?? '';

  const tags = data?.qmsExam.tags ?? [];
  const tagIds = getTagIds(tags);

  if (searchLearningUnit) {
    tagIdsWithIndicator.push(...tagIds, searchLearningUnit);
  }
  if (searchIndicator) {
    tagIdsWithIndicator.push(searchIndicator);
  }

  const {
    data: bloomTags,
    loading,
    error,
  } = useGetQBRemainTags({
    skip: !searchLearningUnit,
    variables: {
      search: { tagIds: tagIdsWithIndicator, category: QBTagCategory.Bloom },
    },
  });

  const bloomRemainTags = bloomTags?.qbGetRemainTags ?? [];
  const bloomOptions = getTagOptions(bloomRemainTags);

  let noOptionsText = 'ไม่พบผลการค้นหา กรุณาลองใช้คำค้นหาอื่นๆ';
  if (loading) noOptionsText = 'กำลังค้นหาความรู้และทักษะตามตัวชี้วัด (Bloom)...';
  if (error && !bloomTags) noOptionsText = 'ไม่สามารถดึงข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง';

  return (
    <FormSearchableMultipleSelectionInput
      name={'blooms'}
      label={'ความรู้และทักษะตามตัวชี้วัด (Bloom)'}
      InputProps={{ placeholder: 'Blooms' }}
      noOptionsText={noOptionsText}
      options={bloomOptions}
      disabled={isLearningUnitEmptyState}
    />
  );
};

export default BloomsSelector;
