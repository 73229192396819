import { createElement, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { styled } from '@mui/material';

import {
  Box,
  BoxProps,
  Text,
  OptionSearchableMultipleSelectionProps,
  Tabs as BaseTabs,
  TabsProps,
  Tab,
} from '../../../../Components/Base';
import FormSearchableMultipleSelectionInput from '../../../../Components/Form/FormSearchableMultipleSelectionInput';
import PeopleIcon from '../../../../Components/Icons/PeopleIcon';
import PersonIcon from '../../../../Components/Icons/PersonIcon';
import {
  PARTICIPANT_TAB_LISTS,
  ROOM_OPTIONS,
  CANDIDATE_ROOM_EXCEPT_OPTIONS,
} from '../../constants';

const Tabs = styled(BaseTabs)(({ theme }) => ({
  minHeight: 40,
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.grey[300],
}));

const FormWrapper = (props: BoxProps) => (
  <Box p={3} borderRadius={2} border={'1px solid hsla(210, 14%, 89%, 1)'} {...props} />
);

export enum ParticipantTab {
  CANDIDATE_BY_CLASS_TYPE = 'candidateByClassType',
  CANDIDATETYPE_TYPE = 'candidateType',
}

interface ExaminationParticipantFormInput {
  rooms?: OptionSearchableMultipleSelectionProps[];
  exceptCandidates?: OptionSearchableMultipleSelectionProps[];
  candidateList?: OptionSearchableMultipleSelectionProps[];
}

type ExaminationParticipantFormContext = UseFormReturn<ExaminationParticipantFormInput>;

type ExaminationParticipantFormSubmission = (
  formUtils: ExaminationParticipantFormContext,
  participantTab: string,
) => SubmitHandler<ExaminationParticipantFormInput>;

export type ExaminationParticipantFormProps = {
  initialFormValues: ExaminationParticipantFormInput;
  onSubmit: ExaminationParticipantFormSubmission;
  submitComponent?: (formContext: ExaminationParticipantFormContext) => JSX.Element;
};

const ExaminationParticipantForm = ({
  initialFormValues,
  onSubmit,
  submitComponent,
}: ExaminationParticipantFormProps) => {
  const formContext = useForm<ExaminationParticipantFormInput>({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });

  const [activeTab, setActiveTab] = useState<string>(() => {
    const isCandidateList =
      Array.isArray(initialFormValues.candidateList) && initialFormValues.candidateList.length;
    if (isCandidateList) return ParticipantTab.CANDIDATETYPE_TYPE;
    return ParticipantTab.CANDIDATE_BY_CLASS_TYPE;
  });

  const handleChange: TabsProps['onChange'] = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit(formContext, activeTab))}>
          <FormWrapper>
            <Text variant="subtitle1" mb={2}>
              {'เลือกประเภทผู้เข้าสอบ'}
            </Text>
            <Tabs onChange={handleChange} value={activeTab}>
              {PARTICIPANT_TAB_LISTS?.map((item, index): JSX.Element => {
                const Icon = item.icon === 'PeopleIcon' ? PeopleIcon : PersonIcon;
                return (
                  <Tab
                    key={index}
                    value={item.value}
                    label={item.label}
                    icon={<Icon sx={{ fontSize: 16 }} />}
                    iconPosition="start"
                  />
                );
              })}
            </Tabs>
            <Box width={2 / 3} mt={2}>
              {activeTab === ParticipantTab.CANDIDATE_BY_CLASS_TYPE && (
                <>
                  <FormSearchableMultipleSelectionInput
                    name="rooms"
                    label="เลือกห้อง"
                    options={ROOM_OPTIONS}
                    InputProps={{ placeholder: 'กรุณาเลือกห้องที่ต้องการจัดสอบ' }}
                  />
                  <FormSearchableMultipleSelectionInput
                    name="exceptCandidates"
                    label="ผู้เข้าสอบในห้องทั้งหมดยกเว้น..."
                    options={CANDIDATE_ROOM_EXCEPT_OPTIONS}
                    InputProps={{ placeholder: 'ค้นหารายชื่อ...' }}
                    sx={{ mt: 3 }}
                  />
                </>
              )}
              {activeTab === ParticipantTab.CANDIDATETYPE_TYPE && (
                <FormSearchableMultipleSelectionInput
                  name="candidateList"
                  label="เลือกรายชื่อผู้เข้าสอบ"
                  options={CANDIDATE_ROOM_EXCEPT_OPTIONS}
                  InputProps={{ placeholder: 'กรุณาเลือกรายชื่อผู้เข้าสอบ' }}
                />
              )}
            </Box>
          </FormWrapper>
          {submitComponent && createElement(submitComponent, formContext)}
        </form>
      </FormProvider>
    </>
  );
};

export default ExaminationParticipantForm;
