import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Image } from '../../../Components/Base';
import { ExaminationHeaderInfo, ExaminationUserInfo } from '../../../Components/ExaminationPrint';
import { useConfig } from '../../../Contexts/ConfigProvider';
import { useGetExamination } from '../../../Domains/ExaminationManagement/useGetExamination';
import { QMSTagCategory } from '../../../Domains/ExaminationManagement/useGetQMSTags';

const ExaminationHeader = () => {
  const { logoUrl } = useConfig();
  const { examId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const typeKey = searchParams.get('type') ?? 'teacher';
  const isStudent = typeKey === 'student';

  const { data: examination } = useGetExamination({ variables: { id: examId } });
  const { title, tags, fullScore, totalQuestions } = examination?.qmsExam ?? {};
  const grade = tags?.find((value) => value.category === QMSTagCategory.Grade)?.name ?? '';
  const subject = tags?.find((value) => value.category === QMSTagCategory.Subject)?.name ?? '';

  return (
    <Box data-testid="examination-header">
      {logoUrl && (
        <Image data-testid="logo" src={logoUrl} alt={'logo'} height={30} display="flex" mb={2} />
      )}
      {isStudent && <ExaminationUserInfo mb={2} />}
      <ExaminationHeaderInfo
        questionName={title ?? ''}
        subject={subject}
        grade={grade}
        questionCount={totalQuestions ?? ''}
        totalExaminationPoint={fullScore ?? ''}
      />
    </Box>
  );
};

export default ExaminationHeader;
