import { Skeleton } from '@mui/material';

import { Box } from '../Base';
const FormLoading = () => {
  return (
    <Box width={1} mb={3}>
      <Skeleton variant="text" width={'15%'} />
      <Skeleton variant="rectangular" height={44} sx={{ borderRadius: '8px', maxWidth: 500 }} />
    </Box>
  );
};

const ExaminationInformationLoading = () => {
  return (
    <Box>
      <FormLoading />
      <FormLoading />
      <FormLoading />
    </Box>
  );
};

export default ExaminationInformationLoading;
