import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Box, Flex } from '../../Base';
import FormTimePicker from '../../Form/FormTimePicker';
import { FormTimePickerProps, FieldValues } from '../../Form/FormTimePicker/types';
import FormDateRangePicker from '../../Form/FormDateRangePicker';

type BaseExaminationEventDateTimeFormProps = {
  startTimeProps?: Omit<FormTimePickerProps<FieldValues>, 'name'>;
  endTimeProps?: Omit<FormTimePickerProps<FieldValues>, 'name'>;
};

const ExaminationEventDateTimeFields = (props: BaseExaminationEventDateTimeFormProps) => {
  const { startTimeProps, endTimeProps } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <FormDateRangePicker name={'dateRange'} disablePast />
      </Box>
      <Flex width={1} mt={2}>
        <Box flex={1}>
          <FormTimePicker
            label={'เวลาเริ่ม'}
            name={'startTime'}
            disableIgnoringDatePartForTimeValidation
            {...startTimeProps}
          />
        </Box>
        <Box flex={1} ml={2}>
          <FormTimePicker
            label={'เวลาสิ้นสุด'}
            name={'endTime'}
            disableIgnoringDatePartForTimeValidation
            {...endTimeProps}
          />
        </Box>
      </Flex>
    </LocalizationProvider>
  );
};

export default ExaminationEventDateTimeFields;
