// TODO: Improve performance this examination event details form

import { createElement, useEffect } from 'react';
import { FormProvider, useForm, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { set, add, isAfter } from 'date-fns';

import { Box, BoxProps, Text, OptionSelectionProps, OptionSearchSelectionProps } from '../Base';

import ExaminationEventInformationFields from './ExaminationEventInformationFields';
import ExaminationEventSettingFields from './ExaminationEventSettingFields';
import ExaminationEventDateTimeFields from './ExaminationEventDateTimeFields';

const FormWrapper = (props: BoxProps) => (
  <Box p={3} borderRadius={2} border={'1px solid hsla(210, 14%, 89%, 1)'} {...props} />
);

type DateTimeType = Date | number | string;

interface ExaminationEventDetailsInput {
  examEventName?: string;
  semester?: string;
  class?: string;
  dateRange?: Array<DateTimeType>;
  endTime?: DateTimeType;
  startTime?: DateTimeType;
  examination?: OptionSearchSelectionProps;
  randomQuestion?: boolean;
  randomAnswer?: boolean;
  shouldShowPoints?: boolean;
  instruction?: string;
}

type ExaminationEventDetailsContext = UseFormReturn<ExaminationEventDetailsInput>;

type ExaminationEventDetailsSubmission = (
  formUtils: ExaminationEventDetailsContext,
) => SubmitHandler<ExaminationEventDetailsInput>;

export type ExaminationEventDetailsProps = {
  classList: OptionSelectionProps[];
  examinationList: OptionSearchSelectionProps[];
  initialFormValues: ExaminationEventDetailsInput;
  onSubmit: ExaminationEventDetailsSubmission;
  semesterList: OptionSelectionProps[];
  submitComponent?: (formContext: ExaminationEventDetailsContext) => JSX.Element;
};

const getDateTime = (
  dateRange?: Array<DateTimeType>,
  startTime?: DateTimeType,
  endTime?: DateTimeType,
) => {
  const formDateRange = (dateRange as ExaminationEventDetailsInput['dateRange']) ?? ['', ''];

  const now = new Date();
  const baseStartDate = new Date(formDateRange[0]);
  const baseEndDate = new Date(formDateRange[1]);

  const startDate = isAfter(baseStartDate, now)
    ? baseStartDate
    : set(baseStartDate, {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      });

  const endDate = set(baseEndDate, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  });

  const timeInStartDate = startTime ? new Date(startTime) : startDate;
  const timeInEndDate = endTime ? new Date(endTime) : endDate;

  const formStartTime = set(startDate, {
    hours: timeInStartDate.getHours(),
    minutes: timeInStartDate.getMinutes(),
  });

  const formEndTime = set(endDate, {
    hours: timeInEndDate.getHours(),
    minutes: timeInEndDate.getMinutes(),
  });

  return { startDate, endDate, startTime: formStartTime, endTime: formEndTime };
};

const ExaminationEventDetailsForm = (props: ExaminationEventDetailsProps) => {
  const { initialFormValues, onSubmit, submitComponent, classList, examinationList, semesterList } =
    props;

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });
  const { startTime, endTime, dateRange } = formContext?.watch() ?? {};

  const {
    startDate: formStartDate,
    endDate: formEndDate,
    startTime: formStartTime,
    endTime: formEndTime,
  } = getDateTime(dateRange, startTime, endTime);

  useEffect(() => {
    const subscription = formContext.watch((formValue, { name = '' }) => {
      if (name !== 'dateRange') return;
      const {
        dateRange: dateRangeValue,
        startTime: startTimeValue,
        endTime: endTimeValue,
      } = formValue;

      const { startTime: newStartTime, endTime: newEndTime } = getDateTime(
        dateRangeValue as Array<DateTimeType>,
        startTimeValue,
        endTimeValue,
      );

      formContext.reset({
        ...formValue,
        startTime: newStartTime,
        endTime: newEndTime,
      });
    });

    return () => subscription.unsubscribe();
  }, [formContext]);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit(formContext))}>
        <FormWrapper mt={3}>
          <Text variant="subtitle1">{'ข้อมูลการสอบ'}</Text>
          <Box width={2 / 3} mt={2}>
            <ExaminationEventInformationFields classList={classList} semesterList={semesterList} />
          </Box>
        </FormWrapper>
        <FormWrapper mt={2}>
          <Text variant="subtitle1">{'วันที่และเวลาสอบ'}</Text>
          <Box width={1 / 2} mt={2}>
            <ExaminationEventDateTimeFields
              startTimeProps={{
                minTime: formStartDate,
                maxTime: formEndTime,
                disabled: !formContext.watch().dateRange?.every((date) => !!date),
              }}
              endTimeProps={{
                minTime: add(formStartTime, { minutes: 5 }),
                maxTime: formEndDate,
                disabled: !formContext.watch().startTime,
              }}
            />
          </Box>
        </FormWrapper>
        <FormWrapper mt={2}>
          <Text variant="subtitle1">{'ตั้งค่าชุดข้อสอบ'}</Text>
          <Box width={2 / 3} mt={2}>
            <ExaminationEventSettingFields examinationList={examinationList} />
          </Box>
        </FormWrapper>
        {submitComponent && createElement(submitComponent, formContext)}
      </form>
    </FormProvider>
  );
};

export default ExaminationEventDetailsForm;
