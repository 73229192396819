import TimePicker from '@mui/lab/TimePicker';
import { styled } from '@mui/material';
import { useController } from 'react-hook-form';

import { TextInput } from '../../Base';
import AccessTimeRoundedIcon from '../../Icons/AccessTimeRoundedIcon';

import { FormTimePickerProps, FieldValues } from './types';

const ClockIcon = styled(AccessTimeRoundedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '20px',
}));

const FormTimePicker = <TFieldValues extends FieldValues = FieldValues>(
  props: FormTimePickerProps<TFieldValues>,
): JSX.Element => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController({
    ...props,
    name: props.name,
  });

  return (
    <TimePicker
      {...field}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={isTouched ? invalid : false}
          helperText={error?.message ?? null}
        />
      )}
      components={{
        OpenPickerIcon: ClockIcon,
      }}
      ampm={false}
      {...props}
    />
  );
};

export default FormTimePicker;
