import { groupBy, n, sum, summarize, tidy } from '@tidyjs/tidy';
import orderBy from 'lodash/orderBy';

import { OptionSelectionProps } from '../../Components/Base';
import {
  Examination,
  ExaminationItem,
  ExaminationQuestion,
} from '../../Domains/ExaminationManagement/useGetExamination';
import { Question } from '../../Contexts/ExaminationProvider';

import { QuestionItemType } from './constant';
import { QMSTag, QMSTagCategory } from './useGetQMSTags';

type UnOrderedQuestion = Omit<Question, 'order'> & { order?: number };

export const groupQuestionBySection = (examQuestions: QuestionItemType[]) => {
  const dataQuestions = orderBy(examQuestions, 'order') ?? [];
  const questionGroupBySectionAndIndicator = tidy(
    dataQuestions,
    groupBy(
      ['section', 'indicator'],
      summarize({
        points: sum('point'),
        questionCount: n(),
        items: (items) => items,
      }),
    ),
  );

  return tidy(
    questionGroupBySectionAndIndicator,
    groupBy(
      ['section'],
      summarize({
        points: sum('points'),
        allQuestionCount: sum('questionCount'),
        items: (items) =>
          items.map((item) => ({
            points: item.points,
            questionCount: item.questionCount,
            indicator: item.indicator,
            items: item.items,
          })),
      }),
    ),
  );
};

export const getTagOptions = (tags: QMSTag[]) => {
  const tagOptions: OptionSelectionProps[] = tags?.map(({ name, slug }) => ({
    label: name,
    value: slug,
  }));

  return tagOptions;
};

export const formatExaminationItemToQuestions = (data: ExaminationItem[]): QuestionItemType[] => {
  const questions = data.map((item: ExaminationItem) => {
    const question = item.question; // QMSQuestion object
    const metadataChoices = question.metadata.choices ?? [];
    const tags = question.tags;

    const subsection = tags.find((tag) => tag.category.includes(QMSTagCategory.Subsection));
    const indicator = tags.find((tag) => tag.category.includes(QMSTagCategory.Indicator));
    const bloom = tags.find((tag) => tag.category.includes(QMSTagCategory.Bloom));
    const difficulty = tags.find((tag) => tag.category.includes(QMSTagCategory.Difficulty));
    const choices = metadataChoices.map((choice: ExaminationQuestion['metadata']) => choice.label);

    const baseIndicatorName = indicator?.name;
    const indicatorName = baseIndicatorName ?? '-';
    const indicatorDescription = !baseIndicatorName ? '' : indicator?.description ?? '-';

    return {
      id: item.question.refQuestionId,
      question: question.question, //Question text
      correctAnswer: Number(question.correctAnswer.value),
      section: subsection?.name,
      indicator: `${indicatorName} ${indicatorDescription}`,
      bloom: bloom?.name,
      level: difficulty?.name,
      point: item.score,
      order: item.order,
      choices,
    } as QuestionItemType;
  });

  return questions;
};

export const formatExaminationToStore = (qmsExam: Examination) => {
  const questions = qmsExam?.examItems ?? [];
  const subject = qmsExam?.tags?.find((value) => value.category === QMSTagCategory.Subject)?.name;
  const grade = qmsExam?.tags?.find((value) => value.category === QMSTagCategory.Grade)?.name;

  const questionSelected = questions.map(({ question, order, score }) => ({
    id: question.refQuestionId,
    tags: question.tags.map((tag) => ({
      category: tag.category,
      id: tag.id,
      name: tag.name,
      slug: tag.slug,
    })),
    order: order ?? 1,
    point: score ?? 1,
  }));

  return {
    examinationInformation: {
      examName: qmsExam?.title,
      subject: subject,
      grade: grade,
    },
    questionSelected: questionSelected ?? [],
  };
};

export const formatExaminationItemToQuestionSelected = (data: UnOrderedQuestion[]) => {
  const questions: QuestionItemType[] = data.map((item: UnOrderedQuestion) => {
    const tags = item.tags;
    const subsection = tags?.find((tag) => tag.category.includes(QMSTagCategory.Subsection));
    const indicator = tags?.find((tag) => tag.category.includes(QMSTagCategory.Indicator));

    return {
      id: item.id,
      section: subsection?.name ?? '',
      indicator: indicator?.name ?? '',
      order: item.order,
      point: item.point,
    } as QuestionItemType;
  });

  return questions;
};
