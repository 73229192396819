import { useParams } from 'react-router-dom';

import FormSearchSelectionInput from '../../../../Components/Form/FormSearchSelectionInput';
import useGetQBRemainTags, {
  QBTagCategory,
} from '../../../../Domains/ExaminationManagement/useGetQBRemainTags';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { getTagIds, getTagOptions } from '../utils';

const LearningUnitSelector = () => {
  const { examId = '' } = useParams();
  const { data } = useGetExamination({ variables: { id: examId } });

  const tags = data?.qmsExam.tags ?? [];
  const tagIds = getTagIds(tags);

  const { data: subsectionTags, error } = useGetQBRemainTags({
    variables: {
      search: { tagIds, category: QBTagCategory.Subsection },
    },
  });

  const subsectionRemainTags = subsectionTags?.qbGetRemainTags ?? [];
  const subsectionOptions = getTagOptions(subsectionRemainTags);

  let noOptionsText = 'ไม่พบผลการค้นหา กรุณาลองใช้คำค้นหาอื่นๆ';
  if (error && !subsectionTags)
    noOptionsText = 'ไม่สามารถดึงข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง';

  return (
    <FormSearchSelectionInput
      name={'learningUnit'}
      label={'หน่วยการเรียนรู้'}
      InputProps={{ placeholder: 'เลือกหน่วยการเรียนรู้' }}
      noOptionsText={noOptionsText}
      options={subsectionOptions}
      rules={{ required: true }}
    />
  );
};

export default LearningUnitSelector;
