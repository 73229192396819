import { Box, Text } from '../../../../Components/Base';
import DragAndDrop from '../../../../Components/DragAndDrop';

const questionItems = [
  {
    id: '1',
    question: 'อัตราส่วนในข้อใดเป็นอัตราส่วนที่เท่ากับอัตราส่วน 9 : 1',
    order: 1,
    choices: ['1 : 9', '7 : 3', '10 : 2', '18 : 2'],
    correctAnswer: 2,
    indicator:
      'ค 1.1 ม.1/3 เข้าใจ และประยุกต์ใช้อัตราส่วน สัดส่วน และร้อยละในการแก้ปัญหาคณิตศาสตร์ และปัญหาในชีวิตจริง',
    bloom: 'ความเข้าใจ',
    level: 'ยาก',
    point: 2,
  },
  {
    id: '2',
    question:
      'มีบัตรจำนวนหนึ่งในกล่อง บนบัตรแต่ละใบเขียนตัวเลข 0-9 กำกับไว้ใบละ 1 ตัว อัตราส่วนของจำนวนบัตรที่เขียนจำนวนเฉพาะต่อจำนวนบัตรที่เขียนจำนวนคู่ต่อจำนวนบัตรทั้งหมดเป็นเท่าใด',
    order: 2,
    choices: ['4 : 5 : 9', '4 : 5 : 10', '5 : 4 : 9', '5 : 4 : 10'],
    correctAnswer: 4,
    indicator:
      'ค 1.3 ม.1/6 เข้าใจ และประยุกต์ใช้อัตราส่วน สัดส่วน และร้อยละในการแก้ปัญหาคณิตศาสตร์ และปัญหาในชีวิตจริง',
    bloom: 'การนำไปใช้',
    level: 'ง่าย',
    point: 100,
  },
];

const DragAndDrops = () => {
  return (
    <Box>
      <Text variant="h6">Drag And Drop</Text>
      {questionItems.map((item) => {
        return (
          <Box key={item.id}>
            <DragAndDrop>{item.question}</DragAndDrop>
          </Box>
        );
      })}
    </Box>
  );
};

export default DragAndDrops;
