import React, { useState } from 'react';
import { styled, Menu, MenuItem } from '@mui/material';

import { Flex, Text } from '../Base';
import KeyboardArrowDownIcon from '../Icons/KeyboardArrowDownIcon';

const UserButtonWrapper = styled((props) => (
  <Flex px={1} py={0.5} alignItems={'center'} {...props} />
))(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey['100'],
    borderRadius: 12,
  },
}));

type UserButtonProps = {
  displayName: string;
  schoolName: string;
  onLogoutClick: () => void;
};

const UserButton = (props: UserButtonProps) => {
  const { displayName, schoolName, onLogoutClick } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserButtonWrapper
        data-testid="user-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <Text data-testid="schoolName" variant={'subtitle1'} color={'primary.main'}>
            {schoolName}
          </Text>
          <Text data-testid="displayName" variant={'body2'} color={'text.secondary'}>
            {displayName}
          </Text>
        </Flex>
        <KeyboardArrowDownIcon color={'primary'} sx={{ ml: 1.5 }} />
      </UserButtonWrapper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        autoFocus={false}
      >
        <MenuItem data-testid="logout-button" onClick={onLogoutClick} sx={{ color: 'error.main' }}>
          {'ออกจากระบบ'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserButton;
