import { FormProvider, useForm } from 'react-hook-form';

import { Box, Text } from '../../../../Components/Base';
import BaseFormTextInput from '../../../../Components/Form/FormTextInput';

type PointsType = {
  points: number;
};

const Form = () => {
  const formContext = useForm<PointsType>({ defaultValues: { points: 0 } });
  const minPoint = 0;
  const maxPoint = 100;

  const onSubmit = (data: PointsType) => {
    console.log('points', Number(data.points));
  };

  return (
    <Box>
      <Text variant={'h6'}>Form Text Input</Text>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <BaseFormTextInput
            name={'points'}
            type="number"
            label={'Points'}
            InputProps={{ inputProps: { min: minPoint, max: maxPoint } }}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default Form;
