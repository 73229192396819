import { Box, BoxProps } from '../Box';
import { Color } from '../Theme/constants';
import { Text } from '../Text';

enum Variant {
  ROUNDED = 'rounded',
  CAPSULE = 'capsule',
}

type LabelProps = {
  children?: React.ReactNode | string;
  color?: Color;
  variant?: 'rounded' | 'capsule';
} & BoxProps;

const getLabelStyle = (variant: string) => {
  switch (variant) {
    case Variant.CAPSULE:
      return { px: 1.5, py: 0.5, borderRadius: 25 };
    case Variant.ROUNDED:
    default:
      return {
        px: 2,
        py: 0.5,
        borderRadius: 1.5,
      };
  }
};

const Label = (props: LabelProps) => {
  const { children, color = Color.PRIMARY, variant = Variant.ROUNDED, ...restProps } = props;
  const labelStyle = getLabelStyle(variant);
  return (
    <Box
      width={'fit-content'}
      border={'1px solid'}
      borderColor={`${color}.light`}
      bgcolor={`${color}.lighter`}
      color={`${color}.main`}
      {...labelStyle}
      {...restProps}
    >
      {typeof children === 'string' ? (
        <Text variant="body2" fontWeight={'600'}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default Label;
export type { LabelProps };
