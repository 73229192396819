import { useMatch, useParams, useResolvedPath } from 'react-router-dom';

import { HeaderTitle } from '../../Components/HeaderTitle';
import {
  EXAMINATION_EVENT_CREATION_PATH,
  ExaminationStatus,
} from '../../Domains/ExaminationManagement/constant';
import { useExamEventDetails } from '../../Domains/ExaminationManagement/useExamEventDetails';

const Header = () => {
  const resolved = useResolvedPath(EXAMINATION_EVENT_CREATION_PATH);
  const match = useMatch({ path: resolved.pathname });
  const { eventId = '' } = useParams();
  const { data } = useExamEventDetails({ id: eventId });

  // TODO: change variant from event status
  const variant = ExaminationStatus.Draft;

  if (match) {
    return <HeaderTitle>การสอบใหม่</HeaderTitle>;
  }
  return <HeaderTitle variant={variant}>{data?.title}</HeaderTitle>;
};

export default Header;
