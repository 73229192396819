import { createElement } from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import {
  Box,
  OptionSearchSelectionProps,
  OptionSearchableMultipleSelectionProps,
} from '../../../../Components/Base';

import LearningUnitSelector from './LearningUnitSelector';
import IndicatorSelector from './IndicatorSelector';
import BloomsSelector from './BloomsSelector';
import DifficultyCheckbox from './DifficultyCheckbox';

type LevelProps = {
  easy: boolean;
  medium: boolean;
  hard: boolean;
};

interface ExaminationQuestionSearchFilterFormInput {
  learningUnit: OptionSearchSelectionProps;
  blooms: OptionSearchableMultipleSelectionProps[];
  indicator: OptionSearchSelectionProps;
  level: LevelProps;
}

type ExaminationQuestionSearchFilterFormContext =
  UseFormReturn<ExaminationQuestionSearchFilterFormInput>;

type ExaminationQuestionSearchFilterFormSubmission = (
  formUtils: ExaminationQuestionSearchFilterFormContext,
) => SubmitHandler<ExaminationQuestionSearchFilterFormInput>;

export type ExaminationQuestionSearchFilterFormProps = {
  initialFormValues: ExaminationQuestionSearchFilterFormInput;
  onSubmit: ExaminationQuestionSearchFilterFormSubmission;
  submitComponent?: (formContext: ExaminationQuestionSearchFilterFormContext) => JSX.Element;
};

const ExaminationQuestionSearchFilterForm = (props: ExaminationQuestionSearchFilterFormProps) => {
  const { initialFormValues, onSubmit, submitComponent } = props;
  const formContext = useForm<ExaminationQuestionSearchFilterFormInput>({
    mode: 'onTouched',
    defaultValues: initialFormValues,
  });

  return (
    <FormProvider {...formContext}>
      <Box
        data-testid="examination-question-search-filter-form"
        component="form"
        onSubmit={formContext.handleSubmit(onSubmit(formContext))}
      >
        <Box
          display={'grid'}
          gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
          rowGap={1}
          columnGap={2}
        >
          <LearningUnitSelector />
          <IndicatorSelector />
          <BloomsSelector />
          <DifficultyCheckbox />
        </Box>
        {submitComponent && createElement(submitComponent, formContext)}
      </Box>
    </FormProvider>
  );
};

export default ExaminationQuestionSearchFilterForm;
