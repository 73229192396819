import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import defaultsDeep from 'lodash/defaultsDeep';

import { useConfig } from './Contexts/ConfigProvider';
import AppRoute from './Routes/routes';
import createApolloClient from './Libs/GraphQL/ApolloClient';
import { AuthenticationProvider } from './Contexts/AuthenticationProvider';
import { SnackbarProvider } from './Contexts/SnackbarProvider';
import * as GTM from './Utils/analytics/gtm';

const App = () => {
  const { graphqlURL, gtm: gtmConfig, theme: themeConfig } = useConfig();

  useEffect(() => {
    if (!gtmConfig) return;
    GTM.initialize(gtmConfig);
  }, [gtmConfig]);

  const client = createApolloClient(graphqlURL);
  const mergedTheme = (base: Theme) => createTheme(defaultsDeep(themeConfig, base));

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <ThemeProvider theme={mergedTheme}>
          <SnackbarProvider>
            <Router>
              <CssBaseline />
              <AppRoute />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthenticationProvider>
    </ApolloProvider>
  );
};

export default App;
