import { Fragment, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { Divider, styled } from '@mui/material';

import { Box, BoxProps, Flex, Text } from '../../../../Components/Base';
import { Dropzone, AcceptType } from '../../../../Components/Dropzone';
import DragAndDrop from '../../../../Components/DragAndDrop';

import QuestionDnd from './QuestionDnd';

type RowProps = {
  data: any;
  onDropItem: (data: any, item: any) => void;
  path: string;
} & Omit<BoxProps, 'color'>;

const SectionWrapper = styled(Box)<BoxProps & { $isDragging: boolean }>(({ $isDragging }) => ({
  opacity: $isDragging ? 0 : 1,
  transform: 'translateZ(0)',
}));

const SectionTitle = styled(Text)({
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const SectionRowGroup = (props: RowProps) => {
  const { data, onDropItem, path, ...restProps } = props;
  const indicatorGroupBySection = data.items;

  const rowRef = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: AcceptType.SECTION,
    item: {
      type: AcceptType.SECTION,
      section: data.section,
      items: data.items,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(rowRef);

  return (
    <SectionWrapper ref={preview} $isDragging={isDragging} {...restProps}>
      <Box ref={rowRef}>
        <DragAndDrop width={1}>
          <Flex justifyContent={'space-between'} width={1}>
            <SectionTitle data-testid="title" variant={'body1'} fontWeight={600}>
              {data.section}
            </SectionTitle>
            <Text
              data-testid="question-count"
              variant={'body1'}
              fontWeight={600}
              ml={1}
              flex={'0 0 auto'}
            >
              {`${data.allQuestionCount} ข้อ`}
            </Text>
          </Flex>
        </DragAndDrop>
      </Box>
      {/* Number of 2.5 is a space of drag icon */}
      <Box pl={2.5}>
        {indicatorGroupBySection.map((indicator: any, indicatorIndex: number) => {
          const { questionCount } = indicator;
          const indicatorName = indicator.indicator;
          const questionGroupByIndicator = indicator.items;

          return (
            <Box
              data-testid={`indicator-${indicatorIndex + 1}`}
              key={`indicator-${indicatorIndex}`}
            >
              <Flex justifyContent={'space-between'} width={1} mt={indicatorIndex === 0 ? 1 : 2}>
                <Text data-testid="title" variant={'body2'} fontWeight={600}>
                  {indicatorName.substring(0, 11).trim()}
                </Text>
                <Text data-testid="question-count" variant={'body2'} fontWeight={600}>
                  {`${questionCount} ข้อ`}
                </Text>
              </Flex>
              <Divider sx={{ my: 1 }} />
              <Box>
                {questionGroupByIndicator.map((question: any, questionIndex: number) => {
                  const currentPath = `${path}-${indicatorIndex}-${questionIndex}`;

                  return (
                    <Fragment key={`indicator-${questionIndex}`}>
                      <Dropzone
                        data={{
                          path: currentPath,
                          childrenCount: indicator.items.length,
                        }}
                        onDropItem={onDropItem}
                      />
                      <QuestionDnd
                        data-testid={`question-${questionIndex + 1}`}
                        key={question.id}
                        data={question}
                        path={currentPath}
                        my={1}
                      />
                    </Fragment>
                  );
                })}
                <Dropzone
                  data={{
                    path: `${path}-${indicatorIndex}-${indicator.items.length}`,
                    childrenCount: indicator.items.length,
                  }}
                  isLast
                  onDropItem={onDropItem}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </SectionWrapper>
  );
};

export default SectionRowGroup;
