import { Divider, Skeleton } from '@mui/material';

import { Box, Flex } from '../../../Components/Base';

const FormLoading = () => {
  return (
    <Box width={1}>
      <Skeleton variant="text" width={'50%'} />
      <Skeleton variant="rectangular" height={44} sx={{ borderRadius: '8px' }} />
    </Box>
  );
};

const SearchFilterLoading = () => {
  return (
    <>
      <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} width={1} gap={'16px 8px'}>
        <FormLoading />
        <FormLoading />
        <FormLoading />
        <FormLoading />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Flex justifyContent={'flex-end'}>
        <Skeleton variant="rectangular" width={100} height={44} sx={{ borderRadius: '8px' }} />
        <Skeleton
          variant="rectangular"
          width={100}
          height={44}
          sx={{ ml: 2, borderRadius: '8px' }}
        />
      </Flex>
    </>
  );
};

export default SearchFilterLoading;
