import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';

import { Button } from '../../../Components/Base';
import ExaminationEventDetailsForm, {
  ExaminationEventDetailsProps,
} from '../../../Components/ExaminationEventDetailsForm';
import ArrowForwardIcon from '../../../Components/Icons/ArrowForwardIcon';
import { EXAMINATION_EVENT_POLICIES_PATH } from '../../../Domains/ExaminationManagement/constant';
import { CLASS_LIST, EXAMINATION_LIST, SEMESTER_LIST } from '../constants';
import { useExaminationEventInformation } from '../../../Contexts/ExaminationEventStoreProvider';

const ExaminationEventSubmissionDetails: ExaminationEventDetailsProps['submitComponent'] = (
  formContext,
) => {
  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Button
        sx={{ ml: 'auto', display: 'flex' }}
        type="submit"
        disabled={formContext.formState.isSubmitting || !formContext.formState.isValid}
        size="large"
        endIcon={<ArrowForwardIcon />}
      >
        {formContext.formState.isSubmitting ? 'กำลังบันทึก..' : 'ต่อไป'}
      </Button>
    </>
  );
};

const ExaminationEventDetailsReformation = () => {
  const { eventId = '' } = useParams();
  const navigate = useNavigate();
  const [examinationEventInformation, setExaminationEventInformation] =
    useExaminationEventInformation();

  return (
    <ExaminationEventDetailsForm
      classList={CLASS_LIST}
      examinationList={EXAMINATION_LIST}
      semesterList={SEMESTER_LIST}
      initialFormValues={{
        examEventName: examinationEventInformation?.examEventName,
        semester: examinationEventInformation?.semester,
        class: examinationEventInformation?.class,
        dateRange: examinationEventInformation?.dateRange,
        endTime: examinationEventInformation?.endTime,
        startTime: examinationEventInformation?.startTime,
        examination: examinationEventInformation?.examination,
        randomQuestion: examinationEventInformation?.randomQuestion,
        randomAnswer: examinationEventInformation?.randomAnswer,
        shouldShowPoints: examinationEventInformation?.shouldShowPoints,
        instruction: examinationEventInformation?.instruction,
      }}
      onSubmit={() => (formValues) => {
        setExaminationEventInformation(formValues);
        if (!eventId) return;
        navigate(EXAMINATION_EVENT_POLICIES_PATH.replace(':eventId', eventId));
      }}
      submitComponent={ExaminationEventSubmissionDetails}
    />
  );
};

export default ExaminationEventDetailsReformation;
