export const EXAMINATION_LIST = [
  {
    label: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
    value: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
  },
  {
    label: 'ข้อสอบคณิตศาสตร์ ม.3 ภาคเรียนที่ 1/2564',
    value: 'ข้อสอบคณิตศาสตร์ ม.3 ภาคเรียนที่ 1/2564',
  },
];

export const SEMESTER_LIST = [
  { label: 'เทอม 1 ปีการศึกษา 2564', value: 'semester1' },
  { label: 'เทอม 2 ปีการศึกษา 2564', value: 'semester2' },
  { label: 'เทอม 1 ปีการศึกษา 2565', value: 'semester3' },
];

export const CLASS_LIST = [
  { label: 'ม.1', value: 'm1' },
  { label: 'ม.2', value: 'm2' },
  { label: 'ม.3', value: 'm3' },
];
