import {
  Button,
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  ModalProps,
  ButtonProps,
} from '../Base';

type ActionConfirmationModalProps = ModalProps & {
  onAccept: ButtonProps['onClick'];
  onDiscard: ButtonProps['onClick'];
  acceptText?: string;
  discardText?: string;
  title: string;
  subtitle: string;
};

const ActionConfirmationModal = (props: ActionConfirmationModalProps) => {
  const { title, subtitle, acceptText, discardText, onAccept, onDiscard, ...modalProps } = props;

  const handleAccept: ButtonProps['onClick'] = (e) => {
    e.currentTarget.disabled = true;
    e.currentTarget.className += ' Mui-disabled';
    onAccept?.(e);
  };

  return (
    <Modal {...modalProps}>
      <ModalTitle sx={{ pt: 3, pb: 4 }} onClose={(e) => modalProps.onClose?.(e, 'backdropClick')}>
        {title}
      </ModalTitle>
      <ModalContent
        sx={{
          pb: 0,
          minHeight: '46px', //workaround prevent scroll shown on first mounted,
        }}
      >
        {subtitle}
      </ModalContent>
      <ModalFooter sx={{ justifyContent: 'flex-end', px: 3, pt: 4, pb: 3 }}>
        <Button
          data-testid="cancel-button"
          onClick={onDiscard}
          variant={'outlined'}
          sx={{ mr: 2 }}
          size="large"
          color="neutral"
        >
          {discardText ?? 'ยกเลิก'}
        </Button>
        <Button data-testid="confirm-button" onClick={handleAccept} size="large">
          {acceptText ?? 'ยืนยัน'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export type { ActionConfirmationModalProps };
export default ActionConfirmationModal;
