import { Fragment } from 'react';

import {
  Box,
  Button as BaseButton,
  ButtonProps,
  Color,
  Flex,
  FlexProps,
  Text,
} from '../../../../Components/Base';

const ButtonWrapper = (props: FlexProps) => (
  <Flex alignItems={'center'} flexWrap={'wrap'} gap={2} {...props} />
);

const COLOR: ButtonProps['color'][] = Object.values(Color);

const Button = () => {
  return (
    <Flex flexDirection={'column'} justifyContent={'space-around'}>
      {/* Variant */}
      <Box>
        <Text variant="body2" fontWeight={600}>
          Button Variant
        </Text>
        <ButtonWrapper>
          <BaseButton variant={'contained'}>Test</BaseButton>
          <BaseButton variant={'outlined'}>Test</BaseButton>
          <BaseButton>Test</BaseButton>
        </ButtonWrapper>
      </Box>
      {/* Size */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Size
        </Text>
        <ButtonWrapper>
          <BaseButton variant={'contained'} size={'large'}>
            Test
          </BaseButton>
          <BaseButton variant={'contained'}>Test</BaseButton>
          <BaseButton variant={'contained'} size={'small'}>
            Test
          </BaseButton>
        </ButtonWrapper>
      </Box>
      {/* Disabled */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Disabled
        </Text>

        <ButtonWrapper>
          <BaseButton variant={'contained'} color="neutral">
            Test
          </BaseButton>
          <BaseButton variant={'contained'} disabled>
            Test
          </BaseButton>
          <BaseButton variant={'outlined'} disabled>
            Test
          </BaseButton>
          <BaseButton disabled>Test</BaseButton>
        </ButtonWrapper>
      </Box>
      {/* Color */}
      <Box mt={2}>
        <Text variant="body2" fontWeight={600}>
          Button Color
        </Text>
        <ButtonWrapper>
          {COLOR.map((color, index) => (
            <Fragment key={index}>
              <BaseButton variant={'contained'} color={color}>
                Test
              </BaseButton>
              <BaseButton variant={'outlined'} color={color}>
                Test
              </BaseButton>
            </Fragment>
          ))}
        </ButtonWrapper>
      </Box>
    </Flex>
  );
};

export default Button;
