import { styled } from '@mui/material';

import TextInput, { TextInputProps } from '../TextInput';

type TextareaProps = TextInputProps;

const Textarea = styled(TextInput)(({ theme }) => ({
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    padding: 0,
  },
  '& .MuiInputBase-multiline': {
    padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  },
}));

Textarea.defaultProps = {
  multiline: true,
  rows: 3,
};

export type { TextareaProps };
export default Textarea;
