import create from 'zustand';
import shallow from 'zustand/shallow';

import {
  OptionSearchSelectionProps,
  OptionSearchableMultipleSelectionProps,
} from '../../Components/Base';

export type DateTimeType = Date | number | string;

export type ExaminationEventInformation = {
  examEventName?: string;
  semester?: string;
  class?: string;
  dateRange?: Array<DateTimeType>;
  endTime?: DateTimeType;
  startTime?: DateTimeType;
  examination?: OptionSearchSelectionProps;
  randomQuestion?: boolean;
  randomAnswer?: boolean;
  shouldShowPoints?: boolean;
  instruction?: string;
};

export interface ExaminationEvent {
  examinationEventInformation?: ExaminationEventInformation;
  examinationParticipant?: {
    rooms: OptionSearchableMultipleSelectionProps[];
    exceptCandidates: OptionSearchableMultipleSelectionProps[];
    candidateList: OptionSearchableMultipleSelectionProps[];
  };
}

type ExaminationEventInformationSelector = (
  state: ExaminationEventContext,
) => [
  ExaminationEventContext['examinationEventInformation'],
  ExaminationEventContext['setExaminationEventInformation'],
];

type ExaminationParticipantSelector = (
  state: ExaminationEventContext,
) => [
  ExaminationEventContext['examinationParticipant'],
  ExaminationEventContext['setExaminationParticipant'],
];

export type ExaminationEventContext = {
  reset: (incomingState?: ExaminationEvent) => void;
  examinationEventInformation: ExaminationEvent['examinationEventInformation'];
  setExaminationEventInformation: (data: ExaminationEvent['examinationEventInformation']) => void;
  examinationParticipant: ExaminationEvent['examinationParticipant'];
  setExaminationParticipant: (data: ExaminationEvent['examinationParticipant']) => void;
};

const useStore = create<ExaminationEventContext>((set) => ({
  reset: (incomingInitialState) =>
    set(() =>
      !incomingInitialState
        ? {
            examinationEventInformation: undefined,
            examinationParticipant: undefined,
          }
        : {
            examinationEventInformation: incomingInitialState?.examinationEventInformation,
            examinationParticipant: incomingInitialState?.examinationParticipant,
          },
    ),
  // Examination event information store
  examinationEventInformation: undefined,
  setExaminationEventInformation: (examinationEventInformation) =>
    set(() => ({ examinationEventInformation })),

  // Examination participant store
  examinationParticipant: undefined,
  setExaminationParticipant: (examinationParticipant) =>
    set(() => ({
      examinationParticipant: {
        rooms: examinationParticipant?.rooms ?? [],
        exceptCandidates: examinationParticipant?.exceptCandidates ?? [],
        candidateList: examinationParticipant?.candidateList ?? [],
      },
    })),
}));

const examinationEventInformationSelector: ExaminationEventInformationSelector = (state) => [
  state.examinationEventInformation,
  state.setExaminationEventInformation,
];
const useExaminationEventInformation = () => useStore(examinationEventInformationSelector, shallow);

const examinationParticipantSelector: ExaminationParticipantSelector = (state) => [
  state.examinationParticipant,
  state.setExaminationParticipant,
];
const useExaminationParticipant = () => useStore(examinationParticipantSelector, shallow);

export { useExaminationEventInformation, useExaminationParticipant };
export { useStore as useExaminationEventContext };
