import { IconButton, IconButtonProps } from '../../../Components/Base/Button';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import useDisclosure from '../../../Utils/useDisclosure';
import { ButtonProps } from '../../../Components/Base';
import { ResponseType } from '../../../Domains/ExaminationManagement/constant';
import useDeprecateExamination from '../../../Domains/ExaminationManagement/useDeprecateExamination';
import ActionConfirmationModal from '../../../Components/ActionConfirmModal';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';

type DeprecateExaminationButtonProps = {
  examId: string | number;
} & IconButtonProps;

const RESPONSE_MESSAGE = {
  [ResponseType.UNAUTHENTICATED]: 'ดำเนินการไม่สำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  [ResponseType.FORBIDDEN]: 'ดำเนินการไม่สำเร็จ เนื่องจากบัญชีนี้ไม่มีสิทธิ์การใช้งาน',
  [ResponseType.NOT_FOUND]: 'ดำเนินการไม่สำเร็จ เนื่องจากไม่พบชุดข้อสอบที่ต้องการเลิกใช้งาน',
  [ResponseType.UNEXPECTED]: 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
  [ResponseType.NETWORK_ERROR]: 'อินเทอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง',
  [ResponseType.SUCCESS]: 'เลิกใช้งานชุดข้อสอบเรียบร้อยแล้ว',
};

const DeprecateExaminationButton = (props: DeprecateExaminationButtonProps) => {
  const { examId, ...buttonProps } = props;
  const { isOpen, open, close } = useDisclosure();
  const { open: openSnackbar } = useSnackbar();
  const [deprecateExamMutation, { loading }] = useDeprecateExamination();

  const handleDeprecateExamination: ButtonProps['onClick'] = async (e) => {
    const target = e.target as HTMLButtonElement;
    target.innerText = 'กำลังยกเลิกชุดข้อสอบ...';
    await deprecateExamMutation({
      variables: { id: examId.toString() },
      onCompleted: () => {
        openSnackbar(RESPONSE_MESSAGE.SUCCESS, 'success');
        close();
      },
      onError: (error) => {
        console.error(error);

        target.innerText = 'เลิกใช้ชุดข้อสอบ';
        target.disabled = false;
        target.className = target.className.replace(' Mui-disabled', '');

        //TODO: handle all error message after connect learn auth
        let message = RESPONSE_MESSAGE.UNEXPECTED;
        if (error.message.includes('Not found Exam')) {
          message = RESPONSE_MESSAGE.NOT_FOUND;
        } else if (error.message === 'Failed to fetch') {
          message = RESPONSE_MESSAGE.NETWORK_ERROR;
        }
        openSnackbar(message, 'error');
      },
    });
  };

  return (
    <>
      <IconButton data-testid="deprecate-exam" {...buttonProps} onClick={open}>
        <DeleteIcon />
      </IconButton>
      <ActionConfirmationModal
        open={isOpen}
        onDiscard={loading ? undefined : close}
        onClose={loading ? undefined : close}
        onAccept={handleDeprecateExamination}
        title="ต้องการเลิกใช้ชุดข้อสอบนี้ใช่ไหม"
        subtitle="การยกเลิกชุดข้อสอบทำให้ไม่สามารถนำชุดข้อสอบไปจัดสอบได้อีก และไม่สามารถยกเลิกการกระทำนี้ได้"
        acceptText="เลิกใช้ชุดข้อสอบ"
      />
    </>
  );
};

export default DeprecateExaminationButton;
