import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Box } from '../../../../Components/Base';
import { Dropzone, AcceptType, DropzoneProps } from '../../../../Components/Dropzone';
import SectionDnd from '../../Components/SectionDnd';
import { useExaminationQuestion } from '../../../../Contexts/ExaminationProvider';
import {
  groupQuestionBySection,
  formatExaminationItemToQuestions,
} from '../../../../Domains/ExaminationManagement/utils';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { combineExaminationQuestionWithStore } from '../utils';

import { moveWithinParent, mapQuestionOrder } from './dndUtils';

const QuestionManagement = () => {
  const { examId = '' } = useParams();
  const { data: examination } = useGetExamination({
    variables: { id: examId },
  });
  const examItems = examination?.qmsExam.examItems ?? [];
  const questions = formatExaminationItemToQuestions(examItems);

  const [questionSelected, setExaminationQuestionSelected] = useExaminationQuestion();

  const examinationSummaryManagement = combineExaminationQuestionWithStore(
    questions ?? [],
    questionSelected ?? [],
  );

  const questionGroupBySection = groupQuestionBySection(examinationSummaryManagement);

  const handleDropItem: DropzoneProps['onDropItem'] = (dropZone, item) => {
    const splitDropZonePath = dropZone.path.split('-');
    const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');

    const newItem = {
      id: item.id,
      section: item.section,
      items: item.items,
      type: item.type,
    };

    // Drop Section & Question
    if (item.type === AcceptType.SECTION || item.type === AcceptType.QUESTION) {
      newItem.items = item.items;
    }

    const splitItemPath = item.path.split('-');
    const pathToItem = splitItemPath.slice(0, -1).join('-');

    // Move item to dropzone
    if (splitItemPath.length === splitDropZonePath.length) {
      if (pathToItem === pathToDropZone) {
        const reorderQuestionGroupBySection = moveWithinParent(
          questionGroupBySection,
          splitDropZonePath,
          splitItemPath,
        );

        setExaminationQuestionSelected((prev) => {
          const updateQuestionOrder = mapQuestionOrder(prev, reorderQuestionGroupBySection);

          return updateQuestionOrder;
        });

        return;
      }
    }
    return;
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box data-testid="examination-question-management">
        {questionGroupBySection.map((section, sectionIndex) => {
          const currentPath = `${sectionIndex}`;

          return (
            <Fragment key={`section-${section.section}`}>
              <Dropzone
                data={{
                  path: currentPath,
                  childrenCount: questionGroupBySection.length,
                }}
                onDropItem={handleDropItem}
              />
              <SectionDnd
                data-testid={`section-${sectionIndex + 1}`}
                key={sectionIndex}
                data={section}
                onDropItem={handleDropItem}
                path={currentPath}
                my={1}
              />
            </Fragment>
          );
        })}
        <Dropzone
          data={{
            path: `${questionGroupBySection.length}`,
            childrenCount: questionGroupBySection.length,
          }}
          isLast
          onDropItem={handleDropItem}
        />
      </Box>
    </DndProvider>
  );
};

export default QuestionManagement;
