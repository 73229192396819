import { Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Box, Flex } from '../../Components/Base';
import { AppBar, MENU_DRAWER_WIDTH, APP_BAR_HEIGHT } from '../../Components/Layout';
import UserButton from '../UserButton';
import SideBar from '../SideBar';
import HamburgerMenu from '../HamburgerMenu';

const BaseLayout = ({ children }: { children?: React.ReactChild }) => {
  return (
    <>
      <AppBar>
        <HamburgerMenu />
        <Flex width={1} justifyContent={'flex-end'}>
          <UserButton />
          {/* TODO: Info icon */}
        </Flex>
      </AppBar>
      <SideBar />
      <Toolbar />
      <Flex bgcolor={'grey.50'}>
        <Box width={`${MENU_DRAWER_WIDTH}px`} display={{ xs: 'none', md: 'block' }} />
        <Flex
          flexDirection={'column'}
          component="main"
          p={3}
          minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
          width={{ md: `calc(100% - ${MENU_DRAWER_WIDTH}px)` }}
          maxWidth={1440}
          m={'auto'}
          sx={{ flexGrow: 1 }}
        >
          {children ?? <Outlet />}
        </Flex>
      </Flex>
    </>
  );
};

export default BaseLayout;
