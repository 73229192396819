export enum ExaminationEventStatus {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
}

export type ExaminationEventItemType = {
  id: string;
  title: string;
  examDate: string;
  status: ExaminationEventStatus;
};

const mockUpData = [
  {
    id: '33c6fe9d-72c6-4066-8ba1-d8ee87df0088',
    title: 'ภาษาไทย ม.1 (ข้อสอบกลางภาคเรียนที่ 2/2564)',
    examDate: '23-03-2022 14:30-15:30',
    status: 'DRAFT',
  },
  {
    id: 'eff0bde4-a98a-4a75-ae01-28ed58b9a913',
    title: 'วิทยาศาสตร์ ม.1 (ข้อสอบกลางภาคภาคเรียนที่ 2/2564)',
    examDate: '24-01-2022 13:00-14:00',
    status: 'PUBLISH',
  },
  {
    id: '20254095-03d4-4505-8f11-128872ed04a0',
    title: 'คณิตศาสตร์ ม.1 (ข้อสอบกลางภาคเรียนที่ 2/2564)',
    examDate: '24-01-2022 09:00-10:00',
    status: 'DRAFT',
  },
];

const useExaminationEvents = () => {
  const data = mockUpData as ExaminationEventItemType[];

  return { data };
};

export default useExaminationEvents;
