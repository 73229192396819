import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExaminationEventEmptyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 180 180" sx={{ width: 180, height: 180 }} {...props}>
    <g clipPath="url(#clip0_2055_69413)">
      <path
        d="M90 160C128.66 160 160 128.66 160 90C160 51.3401 128.66 20 90 20C51.3401 20 20 51.3401 20 90C20 128.66 51.3401 160 90 160Z"
        fill="#E9ECEF"
      />
      <path
        d="M17.92 146.18C22.4929 146.18 26.2 142.473 26.2 137.9C26.2 133.327 22.4929 129.62 17.92 129.62C13.3471 129.62 9.64001 133.327 9.64001 137.9C9.64001 142.473 13.3471 146.18 17.92 146.18Z"
        fill="#E9ECEF"
      />
      <path
        d="M156.47 36.85C159.8 36.85 162.5 34.1503 162.5 30.82C162.5 27.4898 159.8 24.79 156.47 24.79C153.14 24.79 150.44 27.4898 150.44 30.82C150.44 34.1503 153.14 36.85 156.47 36.85Z"
        fill="#E9ECEF"
      />
      <path
        d="M160 17.01C162.496 17.01 164.52 14.9863 164.52 12.49C164.52 9.99364 162.496 7.96997 160 7.96997C157.504 7.96997 155.48 9.99364 155.48 12.49C155.48 14.9863 157.504 17.01 160 17.01Z"
        fill="#E9ECEF"
      />
      <g filter="url(#filter0_d_2055_69413)">
        <path
          d="M90.0532 25.6113L24.0218 43.3785C19.3179 44.6441 16.5307 49.4834 17.7964 54.1872L43.3975 149.333C44.6632 154.037 49.5024 156.824 54.2063 155.559L120.238 137.791C124.942 136.526 127.729 131.686 126.463 126.983L100.862 31.8366C99.5963 27.1328 94.7571 24.3456 90.0532 25.6113Z"
          fill="url(#paint0_linear_2055_69413)"
        />
      </g>
      <g filter="url(#filter1_d_2055_69413)">
        <path
          d="M79.4288 30.0982L53.811 125.672C52.5445 130.397 55.3482 135.254 60.0732 136.521L126.402 154.3C131.127 155.566 135.984 152.762 137.25 148.037L162.868 52.4634C164.135 47.7384 161.331 42.8813 156.606 41.6148L90.2774 23.836C85.5524 22.5695 80.6953 25.3732 79.4288 30.0982Z"
          fill="url(#paint1_linear_2055_69413)"
        />
      </g>
      <g filter="url(#filter2_d_2055_69413)">
        <path
          d="M138.77 62.7V145.86C138.77 148.512 137.716 151.056 135.841 152.931C133.966 154.806 131.422 155.86 128.77 155.86H51.23C48.5778 155.86 46.0343 154.806 44.1589 152.931C42.2835 151.056 41.23 148.512 41.23 145.86V34.14C41.23 31.4878 42.2835 28.9443 44.1589 27.0689C46.0343 25.1936 48.5778 24.14 51.23 24.14H100.37C101.649 24.1408 102.914 24.3939 104.095 24.8846C105.275 25.3754 106.348 26.0942 107.25 27L135.82 55.58C137.709 57.4683 138.77 60.0294 138.77 62.7V62.7Z"
          fill="url(#paint2_linear_2055_69413)"
        />
        <path
          d="M138.43 60.13C138.176 59.6442 137.794 59.2374 137.325 58.9535C136.856 58.6697 136.318 58.5198 135.77 58.52H114.4C111.748 58.52 109.204 57.4664 107.329 55.5911C105.453 53.7157 104.4 51.1722 104.4 48.52V28.14C104.4 27.0791 103.978 26.0617 103.228 25.3116C102.478 24.5614 101.461 24.14 100.4 24.14C102.972 24.1474 105.436 25.1762 107.25 27L135.82 55.58C137.079 56.8374 137.98 58.4081 138.43 60.13V60.13Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M95.65 48.8401H54C53.1716 48.8401 52.5 49.5117 52.5 50.3401C52.5 51.1685 53.1716 51.8401 54 51.8401H95.65C96.4784 51.8401 97.15 51.1685 97.15 50.3401C97.15 49.5117 96.4784 48.8401 95.65 48.8401Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M83.45 57.13H54C53.1716 57.13 52.5 57.8016 52.5 58.63C52.5 59.4584 53.1716 60.13 54 60.13H83.45C84.2784 60.13 84.95 59.4584 84.95 58.63C84.95 57.8016 84.2784 57.13 83.45 57.13Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 73H70.19C69.3781 73 68.72 73.6581 68.72 74.47V74.53C68.72 75.3419 69.3781 76 70.19 76H126.03C126.842 76 127.5 75.3419 127.5 74.53V74.47C127.5 73.6581 126.842 73 126.03 73Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M60 79.5H56C55.2044 79.5 54.4413 79.1839 53.8787 78.6213C53.3161 78.0587 53 77.2956 53 76.5V72.5C53 71.7044 53.3161 70.9413 53.8787 70.3787C54.4413 69.8161 55.2044 69.5 56 69.5H60C60.7956 69.5 61.5587 69.8161 62.1213 70.3787C62.6839 70.9413 63 71.7044 63 72.5V76.5C63 77.2956 62.6839 78.0587 62.1213 78.6213C61.5587 79.1839 60.7956 79.5 60 79.5ZM56 71.5C55.7348 71.5 55.4804 71.6054 55.2929 71.7929C55.1054 71.9804 55 72.2348 55 72.5V76.5C55 76.7652 55.1054 77.0196 55.2929 77.2071C55.4804 77.3946 55.7348 77.5 56 77.5H60C60.2652 77.5 60.5196 77.3946 60.7071 77.2071C60.8946 77.0196 61 76.7652 61 76.5V72.5C61 72.2348 60.8946 71.9804 60.7071 71.7929C60.5196 71.6054 60.2652 71.5 60 71.5H56Z"
          fill="currentColor"
        />
        <path
          d="M58.72 77H58.64C58.5067 76.9892 58.377 76.9517 58.2585 76.8899C58.14 76.8281 58.0351 76.7431 57.95 76.64L55.6 73.78C55.4316 73.5745 55.3517 73.3105 55.378 73.046C55.4042 72.7816 55.5344 72.5384 55.74 72.37C55.9455 72.2016 56.2095 72.1217 56.474 72.148C56.7384 72.1743 56.9816 72.3045 57.15 72.51L58.83 74.51L65.83 68.27C65.9278 68.182 66.042 68.1142 66.1661 68.0703C66.2901 68.0265 66.4216 68.0075 66.553 68.0145C66.6844 68.0214 66.8131 68.0542 66.9319 68.1109C67.0506 68.1676 67.157 68.2472 67.245 68.345C67.333 68.4429 67.4008 68.5571 67.4447 68.6811C67.4885 68.8052 67.5075 68.9367 67.5005 69.0681C67.4936 69.1994 67.4608 69.3282 67.4041 69.4469C67.3474 69.5656 67.2678 69.672 67.17 69.76L59.41 76.7C59.2278 76.886 58.9803 76.9937 58.72 77V77Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 128.1H70.19C69.3781 128.1 68.72 128.758 68.72 129.57V129.63C68.72 130.442 69.3781 131.1 70.19 131.1H126.03C126.842 131.1 127.5 130.442 127.5 129.63V129.57C127.5 128.758 126.842 128.1 126.03 128.1Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M60 134.6H56C55.2044 134.6 54.4413 134.284 53.8787 133.721C53.3161 133.159 53 132.396 53 131.6V127.6C53 126.804 53.3161 126.041 53.8787 125.479C54.4413 124.916 55.2044 124.6 56 124.6H60C60.7956 124.6 61.5587 124.916 62.1213 125.479C62.6839 126.041 63 126.804 63 127.6V131.6C63 132.396 62.6839 133.159 62.1213 133.721C61.5587 134.284 60.7956 134.6 60 134.6V134.6ZM56 126.6C55.7348 126.6 55.4804 126.705 55.2929 126.893C55.1054 127.08 55 127.335 55 127.6V131.6C55 131.865 55.1054 132.12 55.2929 132.307C55.4804 132.495 55.7348 132.6 56 132.6H60C60.2652 132.6 60.5196 132.495 60.7071 132.307C60.8946 132.12 61 131.865 61 131.6V127.6C61 127.335 60.8946 127.08 60.7071 126.893C60.5196 126.705 60.2652 126.6 60 126.6H56Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 109.74H70.19C69.3781 109.74 68.72 110.398 68.72 111.21V111.27C68.72 112.082 69.3781 112.74 70.19 112.74H126.03C126.842 112.74 127.5 112.082 127.5 111.27V111.21C127.5 110.398 126.842 109.74 126.03 109.74Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M60 116.24H56C55.2044 116.24 54.4413 115.924 53.8787 115.361C53.3161 114.799 53 114.036 53 113.24V109.24C53 108.444 53.3161 107.681 53.8787 107.119C54.4413 106.556 55.2044 106.24 56 106.24H60C60.7956 106.24 61.5587 106.556 62.1213 107.119C62.6839 107.681 63 108.444 63 109.24V113.24C63 114.036 62.6839 114.799 62.1213 115.361C61.5587 115.924 60.7956 116.24 60 116.24V116.24ZM56 108.24C55.7348 108.24 55.4804 108.345 55.2929 108.533C55.1054 108.72 55 108.975 55 109.24V113.24C55 113.505 55.1054 113.76 55.2929 113.947C55.4804 114.135 55.7348 114.24 56 114.24H60C60.2652 114.24 60.5196 114.135 60.7071 113.947C60.8946 113.76 61 113.505 61 113.24V109.24C61 108.975 60.8946 108.72 60.7071 108.533C60.5196 108.345 60.2652 108.24 60 108.24H56Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M126.03 91.37H70.19C69.3781 91.37 68.72 92.0281 68.72 92.84V92.9C68.72 93.7119 69.3781 94.37 70.19 94.37H126.03C126.842 94.37 127.5 93.7119 127.5 92.9V92.84C127.5 92.0281 126.842 91.37 126.03 91.37Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M60 97.87H56C55.2044 97.87 54.4413 97.5539 53.8787 96.9913C53.3161 96.4287 53 95.6656 53 94.87V90.87C53 90.0743 53.3161 89.3113 53.8787 88.7487C54.4413 88.1861 55.2044 87.87 56 87.87H60C60.7956 87.87 61.5587 88.1861 62.1213 88.7487C62.6839 89.3113 63 90.0743 63 90.87V94.87C63 95.6656 62.6839 96.4287 62.1213 96.9913C61.5587 97.5539 60.7956 97.87 60 97.87ZM56 89.87C55.7348 89.87 55.4804 89.9754 55.2929 90.1629C55.1054 90.3504 55 90.6048 55 90.87V94.87C55 95.1352 55.1054 95.3896 55.2929 95.5771C55.4804 95.7646 55.7348 95.87 56 95.87H60C60.2652 95.87 60.5196 95.7646 60.7071 95.5771C60.8946 95.3896 61 95.1352 61 94.87V90.87C61 90.6048 60.8946 90.3504 60.7071 90.1629C60.5196 89.9754 60.2652 89.87 60 89.87H56Z"
          fill="currentColor"
        />
        <path
          d="M58.72 95.3701H58.64C58.3872 95.333 58.1582 95.2006 58 95.0001L55.6 92.1501C55.4316 91.9445 55.3517 91.6805 55.378 91.4161C55.4042 91.1516 55.5344 90.9085 55.74 90.7401C55.9455 90.5716 56.2095 90.4918 56.474 90.518C56.7384 90.5443 56.9816 90.6745 57.15 90.8801L58.83 92.9301L65.83 86.6801C65.9278 86.5921 66.042 86.5242 66.1661 86.4804C66.2901 86.4365 66.4216 86.4175 66.553 86.4245C66.6844 86.4315 66.8131 86.4642 66.9319 86.521C67.0506 86.5777 67.157 86.6572 67.245 86.7551C67.333 86.8529 67.4008 86.9671 67.4447 87.0912C67.4885 87.2152 67.5075 87.3467 67.5005 87.4781C67.4936 87.6095 67.4608 87.7382 67.4041 87.8569C67.3474 87.9757 67.2678 88.0821 67.17 88.1701L59.41 95.1701C59.2127 95.3199 58.9668 95.3912 58.72 95.3701V95.3701Z"
          fill="currentColor"
        />
      </g>
      <g filter="url(#filter3_d_2055_69413)">
        <path
          d="M123.9 144L113.37 149C113.171 149.095 112.95 149.134 112.731 149.115C112.511 149.095 112.301 149.017 112.122 148.888C111.943 148.759 111.802 148.584 111.714 148.383C111.626 148.181 111.593 147.959 111.62 147.74L113.08 136.18C113.133 135.739 113.298 135.319 113.56 134.96L161.83 68.2399C162.918 66.7368 164.558 65.7271 166.39 65.4327C168.222 65.1383 170.096 65.5833 171.6 66.6699C172.346 67.2087 172.978 67.8891 173.46 68.6723C173.943 69.4555 174.266 70.3262 174.412 71.2345C174.558 72.1428 174.523 73.0709 174.31 73.9658C174.097 74.8607 173.71 75.7049 173.17 76.4499L124.9 143.19C124.64 143.539 124.296 143.818 123.9 144V144Z"
          fill="currentColor"
        />
        <path
          d="M169.347 81.9394L158.007 73.6794L156.337 75.9717L167.677 84.2317L169.347 81.9394Z"
          fill="white"
        />
        <path
          d="M127.104 140.166L115.761 131.961L114.935 133.103L126.278 141.309L127.104 140.166Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2055_69413"
        x="2.49129"
        y="22.3062"
        width="139.277"
        height="160.557"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69413" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69413"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2055_69413"
        x="38.5068"
        y="20.5317"
        width="139.666"
        height="161.072"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69413" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69413"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2055_69413"
        x="26.23"
        y="21.14"
        width="127.54"
        height="161.72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69413" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69413"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_2055_69413"
        x="96.6108"
        y="62.344"
        width="92.8898"
        height="113.776"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.870588 0 0 0 0 0.886275 0 0 0 0 0.901961 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2055_69413" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2055_69413"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2055_69413"
        x1="8238.78"
        y1="811.693"
        x2="12214.7"
        y2="15588.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F7F9" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2055_69413"
        x1="4835.86"
        y1="-9453.65"
        x2="15826.5"
        y2="-6507.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F7F9" />
        <stop offset="1" stopColor="#F1F3F5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2055_69413"
        x1="8819.83"
        y1="3285.53"
        x2="8819.83"
        y2="20635.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_2055_69413">
        <rect width="180" height="180" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ExaminationEventEmptyIcon;
