import { Link as BaseLink } from 'react-router-dom';
import { styled } from '@mui/material';

import { Button, ButtonProps } from '../Base';

const Link = styled(BaseLink)`
  text-decoration: none;
`;

type LinkButtonProps = {
  to: string;
  target?: string;
} & Omit<ButtonProps, 'onClick' | 'href'>;

const LinkButton = (props: LinkButtonProps) => {
  const { to, target = '_self', ...restProps } = props;
  return (
    <Link to={to} target={target}>
      <Button {...restProps} />
    </Link>
  );
};

export default LinkButton;
