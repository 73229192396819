import { Flex, Text } from '../../../Components/Base';
import { LinkButton } from '../../../Components/LinkButton';
import AddIcon from '../../../Components/Icons/AddIcon';
import ExamPaperIcon from '../../../Components/Icons/ExamPaperIcon';

const ExaminationListEmpty = ({ redirectUrl }: { redirectUrl: string }) => {
  return (
    <Flex
      data-testid="examination-list-empty"
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'60vh'}
    >
      <ExamPaperIcon color="primary" />
      <Text variant={'h4'} mt={2}>
        คุณยังไม่มีชุดข้อสอบ
      </Text>
      <Text mt={0.5}>
        คลิกที่{' '}
        <Text component={'span'} fontWeight={600}>
          “จัดชุดข้อสอบ”
        </Text>{' '}
        เพื่อสร้างชุดข้อสอบแรกของคุณ
      </Text>
      <LinkButton
        data-testid="go-to-create-examination-button"
        to={redirectUrl}
        color="neutral"
        sx={{ mt: 2 }}
      >
        <AddIcon sx={{ fontSize: 24 }} />
        <Text ml={0.5} fontWeight={600}>
          จัดชุดข้อสอบ
        </Text>
      </LinkButton>
    </Flex>
  );
};

export default ExaminationListEmpty;
