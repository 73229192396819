import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Text } from '../../Components/Base';
import ArrowBackIcon from '../../Components/Icons/ArrowBackIcon';
import ExaminationEventSummary from '../../Containers/ExaminationEventSummary';

const ExaminationEventFinalization = () => {
  const navigate = useNavigate();

  return (
    <Box
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px hsla(206, 13%, 25%, 0.1)'}
      bgcolor={'common.white'}
    >
      <Text variant="h4">สรุปรายละเอียดการจัดสอบ</Text>
      <Box id={'exam-event-error'} />
      <Box mt={2.5}>
        <ExaminationEventSummary />
      </Box>
      <Divider sx={{ my: 4 }} />
      <Button
        variant={'outlined'}
        onClick={() => navigate(-1)}
        size="large"
        color="neutral"
        startIcon={<ArrowBackIcon />}
      >
        {'ก่อนหน้า'}
      </Button>
    </Box>
  );
};

export default ExaminationEventFinalization;
