import { styled } from '@mui/material';

import { Box, BoxProps } from './Box';

type ImageProps = BoxProps<'img'>;

const Image = styled(Box)<ImageProps>``;
Image.defaultProps = { component: 'img' };

export { Image };
export type { ImageProps };
