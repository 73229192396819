import { Box, Label as BaseLabel, LabelProps, Flex, Text } from '../../../../Components/Base';

const Label = () => {
  return (
    <Flex>
      <Box>
        <Text variant="body2" fontWeight={600}>
          Rounded Label
        </Text>
        <Flex mt={2}>
          <BaseLabel color={'info' as LabelProps['color']}>ฉบับร่าง</BaseLabel>
          <Box pl={2} />
          <BaseLabel color={'success' as LabelProps['color']}>เผยแพร่</BaseLabel>
        </Flex>
      </Box>
      <Box pl={2} />
      <Box>
        <Text variant="body2" fontWeight={600}>
          Capsule Label
        </Text>
        <Flex mt={2}>
          <BaseLabel variant={'capsule' as LabelProps['variant']}>ความเข้าใจ</BaseLabel>
          <Box pl={2} />
          <BaseLabel variant={'capsule' as LabelProps['variant']}>การนำไปใช้</BaseLabel>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Label;
