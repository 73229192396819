import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Portal from '@mui/material/Portal';

import {
  Box,
  Button,
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  Alert,
  Text,
  TextProps,
} from '../../../Components/Base';
import ErrorIcon from '../../../Components/Icons/ErrorIcon';
import {
  useExaminationEventContext,
  ExaminationEvent,
} from '../../../Contexts/ExaminationEventStoreProvider';
import {
  useExamEventDetails,
  ExaminationEventItemType,
} from '../../../Domains/ExaminationManagement/useExamEventDetails';
import { EXAMINATION_EVENT_DETAIL_PATH } from '../../../Domains/ExaminationManagement/constant';
import useDisclosure from '../../../Utils/useDisclosure';
import { useSnackbar } from '../../../Contexts/SnackbarProvider';

import { isExaminationEventEqualWithStore } from './utils';

const ErrorText = (props: TextProps) => <Text color={'error.darker'} {...props} />;

const mergedExaminationEvent = (
  remoteExaminationEvent: ExaminationEventItemType,
  editableExaminationEvent: ExaminationEvent,
) => {
  const { examinationEventInformation, examinationParticipant } = editableExaminationEvent;

  const remoteExaminationEvents = {
    ...remoteExaminationEvent,
    title: examinationEventInformation?.examEventName,
    semester: examinationEventInformation?.semester,
    grade: examinationEventInformation?.class,
    startDate: examinationEventInformation?.startTime,
    endDate: examinationEventInformation?.endTime,
    examination: examinationEventInformation?.examination,
    randomQuestion: examinationEventInformation?.randomQuestion,
    randomAnswer: examinationEventInformation?.randomAnswer,
    shouldShowPoints: examinationEventInformation?.shouldShowPoints,
    instruction: examinationEventInformation?.instruction,
    policies: {
      candidateByClass: examinationParticipant?.rooms,
      candidateExcepted: examinationParticipant?.exceptCandidates,
      candidateList: examinationParticipant?.candidateList,
    },
  };

  return remoteExaminationEvents;
};

const PublishExaminationEventButton = () => {
  const navigate = useNavigate();
  const { eventId = '' } = useParams();
  const { open } = useSnackbar();

  // TODO: Get data from examination event provider
  const { data: remoteExaminationEvent } = useExamEventDetails({ id: eventId });

  // FIXME: Will be remove after api integration
  const localExaminationEvent = localStorage.getItem('examinationEvent');
  const examinationEvents = JSON.parse(localExaminationEvent ?? '{}');
  const examinationEvent = mergedExaminationEvent(remoteExaminationEvent, examinationEvents);

  // Check examination event equal with store for disable/enable publish button
  const editableExaminationEvent = useExaminationEventContext();
  const isExaminationEventUpToDated = isExaminationEventEqualWithStore(
    examinationEvents,
    editableExaminationEvent,
  );

  const {
    isOpen: isConfirmModalOpen,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useDisclosure();
  const [isError, setIsError] = useState(false);

  const examinations = examinationEvent?.examination;
  const startDateTime = examinationEvent?.startDate;
  const endDateTime = examinationEvent?.endDate;
  const candidateByClassCount = examinationEvent?.policies?.candidateByClass?.length;
  const candidateListCount = examinationEvent?.policies?.candidateList?.length;

  const isInvalidExamination = !examinations || examinations?.label === '';
  const isInvalidDateTime = !startDateTime || !endDateTime;
  const isInvalidCandidates = candidateByClassCount === 0 && candidateListCount === 0;

  const isValidForPublish = !isInvalidExamination && !isInvalidDateTime && !isInvalidCandidates;

  const ErrorBox = document.getElementById('exam-event-error');

  const handleValidation = () => {
    if (!isValidForPublish) return setIsError(true);
    openConfirmModal();
  };

  const handlePublish = () => {
    // TODO: Waiting integrate publish exam function
    console.log('PublishExaminationEventButton', { examinationEvent });
    closeConfirmModal();
    open('เผยแพร่เรียบร้อยแล้ว', 'success');

    // TODO: After published success it should redirect to published page follow eventId
    setTimeout(() => {
      navigate(
        EXAMINATION_EVENT_DETAIL_PATH.replace(':eventId', 'eff0bde4-a98a-4a75-ae01-28ed58b9a913'),
      );
    }, 2000);
  };

  return (
    <>
      <Button
        color={'success'}
        onClick={handleValidation}
        sx={{ minWidth: '130px' }}
        disabled={!isExaminationEventUpToDated}
      >
        {'เผยแพร่'}
      </Button>
      <Modal open={isConfirmModalOpen}>
        <ModalTitle sx={{ pb: 0, mb: 3 }}>{'เผยแพร่การสอบ'}</ModalTitle>
        <ModalContent sx={{ pb: 0, mb: 3 }}>
          {'หากกดเผยแพร่การสอบแล้ว จะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก'}
        </ModalContent>
        <ModalFooter sx={{ justifyContent: 'flex-end', px: 3, mb: 2 }}>
          <Button
            onClick={closeConfirmModal}
            variant={'outlined'}
            color="neutral"
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            {'ยกเลิก'}
          </Button>
          <Button onClick={handlePublish} sx={{ width: { xs: 1, sm: 'auto' }, ml: 2 }}>
            {'เผยแพร่'}
          </Button>
        </ModalFooter>
      </Modal>

      {isError && (
        <>
          {/* Error Validation Alert */}
          <Portal container={ErrorBox}>
            <Box mt={2.5}>
              <Alert color={'error'} icon={<ErrorIcon fontSize="small" color="error" />}>
                <ErrorText>{'ไม่สามารถเผยแพร่ชุดข้อสอบได้ เนื่องจากขาดข้อมูลดังนี้:'}</ErrorText>
                {isInvalidDateTime && <ErrorText>{'- วันที่และเวลาสอบ'}</ErrorText>}
                {isInvalidExamination && <ErrorText>{'- ชื่อชุดข้อสอบ'}</ErrorText>}
                {isInvalidCandidates && <ErrorText>{'- ผู้มีสิทธิ์เข้าสอบ'}</ErrorText>}
              </Alert>
            </Box>
          </Portal>
        </>
      )}
    </>
  );
};

export default PublishExaminationEventButton;
