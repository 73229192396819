import {
  Button,
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  ModalProps,
  ButtonProps,
} from '../../../Components/Base';
import SaveExaminationAsDraftButton, {
  SaveExaminationAsDraftButtonProps,
} from '../SaveExaminationAsDraftButton';

type EnsureLeavingModalProps = ModalProps & {
  onStayThisPage: ButtonProps['onClick'];
  onLeavePage: ButtonProps['onClick'];
  onSaveDraftCompleted: SaveExaminationAsDraftButtonProps['onCompleted'];
};

const EnsureLeavingModal = (props: EnsureLeavingModalProps) => {
  const { onStayThisPage, onLeavePage, onSaveDraftCompleted, ...modalProps } = props;
  return (
    <Modal {...modalProps}>
      <ModalTitle sx={{ p: 3, pb: 4 }} onClose={onStayThisPage}>
        {'ต้องการออกจากหน้านี้ใช่ไหม'}
      </ModalTitle>
      <ModalContent sx={{ pb: 0 }}>
        {'คุณยังไม่ได้บันทึกข้อมูล หากคุณออกตอนนี้ ชุดข้อสอบนี้จะหายไป'}
      </ModalContent>
      <ModalFooter sx={{ justifyContent: 'flex-end', p: 3, pt: 4 }}>
        <Button
          data-testid="leaving-without-save-as-draft-button"
          onClick={onLeavePage}
          color="neutral"
          variant={'outlined'}
          sx={{ mr: 2 }}
          size="large"
        >
          {'ออกและไม่บันทึก'}
        </Button>
        <SaveExaminationAsDraftButton
          data-testid="leaving-with-save-as-draft-button"
          onCompleted={onSaveDraftCompleted}
          size="large"
        >
          {'ออกและบันทึกฉบับร่าง'}
        </SaveExaminationAsDraftButton>
      </ModalFooter>
    </Modal>
  );
};

export default EnsureLeavingModal;
