export enum ExaminationEventStatus {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
}

export type CandidateByClassType = {
  id: string;
  class: string;
  students: number;
};

export type CandidateExceptedType = {
  id: string;
  firstName: string;
  lastName: string;
  class: string;
};

export type CandidateListType = {
  id: string;
  firstName: string;
  lastName: string;
  class: string;
};

export type ExaminationEventItemType = {
  id: string;
  title: string;
  examDate: string;
  status: ExaminationEventStatus;
  semester: {
    label: string;
    value: string;
  };
  grade: {
    label: string;
    value: string;
  };
  startDate: string;
  endDate: string;
  examination: {
    label: string;
    value: string;
  };
  randomQuestion: boolean;
  randomAnswer: boolean;
  shouldShowPoints: boolean;
  instruction: string;
  policies: {
    candidateByClass?: CandidateByClassType[];
    candidateExcepted?: CandidateExceptedType[];
    candidateList?: CandidateListType[];
  };
};
