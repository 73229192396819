import { useMemo } from 'react';
import { Tooltip } from '@mui/material';

import { Box, BoxProps, Flex, OptionSearchSelectionProps, Text } from '../../Base';
import FormSearchSelectionInput from '../../Form/FormSearchSelectionInput';
import FormSwitch from '../../Form/FormSwitch';
import FormTextarea from '../../Form/FormTextarea';
import InfoIcon from '../../Icons/InfoIcon';
import { useConfig } from '../../../Contexts/ConfigProvider';

const InstructionLabel = () => (
  <Flex alignItems={'center'}>
    คำชี้แจงในการทำข้อสอบ
    <Box ml={1.25}>
      <Tooltip title={'ข้อความนี้จะแสดงบนหน้าคำชีแจ้งการสอบของผู้เข้าสอบ'} placement={'top'}>
        <InfoIcon sx={{ fontSize: 20, color: 'text.primary' }} />
      </Tooltip>
    </Box>
  </Flex>
);

const SwitchWrapper = (props: BoxProps) => <Box width={'fit-content'} {...props} />;

type ExaminationSettingProps = {
  examinationList: OptionSearchSelectionProps[];
};

const getRandomMessage = (enableRandomQuestions: boolean, enableRandomChoices: boolean) => {
  if (enableRandomQuestions && enableRandomChoices) return 'คำถามและตัวเลือก';
  if (enableRandomQuestions) return 'คำถาม';
  if (enableRandomChoices) return 'ตัวเลือก';
};

const ExaminationEventSettingFields = (props: ExaminationSettingProps) => {
  const { examinationList = [] } = props;

  const { exam: examConfig } = useConfig();
  const enableRandomQuestions = examConfig?.examinationEvent?.enableRandomQuestions ?? false;
  const enableRandomChoices = examConfig?.examinationEvent?.enableRandomChoices ?? false;

  const messageRandom = useMemo(
    () => getRandomMessage(enableRandomQuestions, enableRandomChoices),
    [enableRandomChoices, enableRandomQuestions],
  );

  return (
    <>
      <FormSearchSelectionInput
        name={'examination'}
        label={'เลือกชุดข้อสอบ'}
        InputProps={{ placeholder: 'กรุณาเลือกชุดข้อสอบ' }}
        options={examinationList}
      />

      {(enableRandomQuestions || enableRandomChoices) && (
        <>
          <Text variant={'body2'} fontWeight={600} mt={2}>
            การเรียงลำดับของคำถาม
          </Text>
          <Text variant={'body2'} mt={1}>
            {`หากเปิดใช้งานจะเป็นการสุ่มลำดับของ${messageRandom}`}
          </Text>
        </>
      )}

      {enableRandomQuestions && (
        <SwitchWrapper>
          <FormSwitch name={'randomQuestion'} label={'สุ่มลำดับของคำถาม (ภายในตัวชี้วัด)'} />
        </SwitchWrapper>
      )}
      {enableRandomChoices && (
        <SwitchWrapper>
          <FormSwitch name={'randomAnswer'} label={'สุ่มลำดับของตัวเลือก'} />
        </SwitchWrapper>
      )}

      <Text variant={'body2'} fontWeight={600} mt={1}>
        การแสดงคะแนนรายข้อ
      </Text>
      <SwitchWrapper mt={1}>
        <FormSwitch name={'shouldShowPoints'} label={'ผู้เข้าสอบสามารถเห็นคะแนนรายข้อ'} />
      </SwitchWrapper>
      <FormTextarea
        name={'instruction'}
        label={<InstructionLabel />}
        placeholder={'ระบุคำชี้แจงในการทำข้อสอบ...'}
        sx={{ mt: 2 }}
      />
    </>
  );
};

export default ExaminationEventSettingFields;
