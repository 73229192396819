import { Routes, Route, Navigate } from 'react-router-dom';

import { useConfig } from '../Contexts/ConfigProvider';
// Layout
import BaseLayout from '../Containers/BaseLayout';
import ExaminationEventLayout from '../Containers/ExaminationEventLayout';
import ExaminationLayout from '../Containers/ExaminationLayout';

import ProtectedRoute from './Containers/ProtectedRoute';
// Page
import ExaminationCreation from './ExaminationCreation';
import ExaminationDetail from './ExaminationDetail';
import ExaminationEventCreation from './ExaminationEventCreation';
import ExaminationEventDetails from './ExaminationEventDetails';
import ExaminationEventFinalization from './ExaminationEventFinalization';
import ExaminationEventPolicies from './ExaminationEventPolicies';
import ExaminationEvents from './ExaminationEvents';
import ExaminationFinalization from './ExaminationFinalization';
import ExaminationPrint from './ExaminationPrint';
import ExaminationQuestions from './ExaminationQuestions';
import ExaminationResults from './ExaminationResults';
import Examinations from './Examinations';
import NotFound from './NotFound';
import TestUI from './TestUI';
import Login from './Login';

const AppRoute = () => {
  const { routing } = useConfig();

  const enableExaminationModule =
    routing.examination.enable ||
    routing.examinationEvent.enable ||
    routing.examinationResult.enable;

  return (
    <Routes>
      {enableExaminationModule && (
        <Route path="exam">
          {routing.examination.enable && (
            <>
              <Route index element={<Navigate to={'examinations'} replace />} />

              <Route
                path="examinations"
                element={
                  <ProtectedRoute>
                    <BaseLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Examinations />} />
              </Route>

              <Route
                path="examinations"
                element={
                  <ProtectedRoute>
                    <ExaminationLayout />
                  </ProtectedRoute>
                }
              >
                <Route path="create" element={<ExaminationCreation />} />
                <Route path=":examId" element={<ExaminationDetail />} />
                <Route path=":examId/questions" element={<ExaminationQuestions />} />
                <Route path=":examId/finalize" element={<ExaminationFinalization />} />
              </Route>

              <Route path={'examinations'}>
                <Route
                  path=":examId/print"
                  element={
                    <ProtectedRoute>
                      <ExaminationPrint />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </>
          )}

          {routing.examinationEvent.enable && (
            <>
              <Route
                path="events"
                element={
                  <ProtectedRoute>
                    <BaseLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<ExaminationEvents />} />
              </Route>

              <Route
                path="events"
                element={
                  <ProtectedRoute>
                    <ExaminationEventLayout />
                  </ProtectedRoute>
                }
              >
                <Route path="create" element={<ExaminationEventCreation />} />
                <Route path=":eventId" element={<ExaminationEventDetails />} />
                <Route path=":eventId/policies" element={<ExaminationEventPolicies />} />
                <Route path=":eventId/finalize" element={<ExaminationEventFinalization />} />
              </Route>
            </>
          )}

          {routing.examinationResult.enable && (
            <>
              <Route
                path="results"
                element={
                  <ProtectedRoute>
                    <BaseLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<ExaminationResults />} />
                <Route path=":resultId" />
              </Route>
            </>
          )}
        </Route>
      )}

      {routing.components.enable && (
        <Route path="components" element={<BaseLayout />}>
          <Route index element={<TestUI />} />
        </Route>
      )}

      {routing.login.enable && <Route path="/login" element={<Login />} />}

      {routing.landing.enable && routing.landing.path !== '/' && (
        <Route path="/" element={<Navigate to={routing.landing.path} replace />} />
      )}

      <Route
        path="*"
        element={
          <BaseLayout>
            <NotFound />
          </BaseLayout>
        }
      />
    </Routes>
  );
};

export default AppRoute;
