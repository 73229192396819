import DescriptionIcon from '../../Components/Icons/DescriptionIcon';
import InsertInvitationRoundedIcon from '../../Components/Icons/InsertInvitationRoundedIcon';
import LeaderboardRoundedIcon from '../../Components/Icons/LeaderboardRoundedIcon';
import BrushIcon from '../../Components/Icons/BrushIcon';

enum MenuIcon {
  DESCRIPTION_ICON = 'DESCRIPTION_ICON',
  INSERT_INVITE_ROUND_ICON = 'INSERT_INVITE_ROUND_ICON',
  LEADER_BOARD_ROUND_ICON = 'LEADER_BOARD_ROUND_ICON',
  UI_COMPONENTS = 'UI_COMPONENTS',
}

export const getMenuIcon = (icon: MenuIcon | string) => {
  switch (icon) {
    case MenuIcon.DESCRIPTION_ICON: {
      return <DescriptionIcon />;
    }
    case MenuIcon.INSERT_INVITE_ROUND_ICON: {
      return <InsertInvitationRoundedIcon />;
    }
    case MenuIcon.LEADER_BOARD_ROUND_ICON: {
      return <LeaderboardRoundedIcon />;
    }
    case MenuIcon.UI_COMPONENTS: {
      return <BrushIcon />;
    }
    default: {
      return null;
    }
  }
};
