import Stepper, { StepperProps } from '@mui/material/Stepper';
import { styled, Step, StepProps, StepLabel as MUIStepLabel } from '@mui/material';

const StepLabel = styled(MUIStepLabel)(({ theme }) => ({
  cursor: 'pointer',
  '.MuiStepLabel-label': {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: '400',
  },
  '&.Mui-active .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled .MuiStepIcon-root.Mui-completed': {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

export { Stepper, Step, StepLabel };
export type { StepperProps, StepProps };
