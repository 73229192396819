import create from 'zustand';
import shallow from 'zustand/shallow';

import { SnackbarProps } from '../../Components/Base';

export type Snackbar = {
  message: string;
  type?: 'success' | 'info' | 'error' | 'warning';
  options?: SnackbarProps;
  id: number;
};

export type SnackbarContext = {
  snackbar?: Snackbar;
  show: boolean;
  open: (
    message: Snackbar['message'],
    type?: Snackbar['type'],
    options?: Snackbar['options'],
  ) => void;
  close: () => void;
};

type SnackbarSelector = (state: SnackbarContext) => SnackbarContext;

const createStore = () =>
  create<SnackbarContext>((set) => ({
    show: false,
    snackbar: undefined,
    open: (message, type, options) =>
      set({ snackbar: { message, type, options, id: new Date().getTime() }, show: true }),
    close: () => set({ show: false }),
  }));

const useStore = createStore();

const snackbarSelector: SnackbarSelector = ({ show, snackbar, open, close }) => ({
  show,
  snackbar,
  open,
  close,
});

const useSnackbarSelector = () => useStore(snackbarSelector, shallow);

export { useSnackbarSelector as useSnackbar };
