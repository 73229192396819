import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthentication, LoginState } from '../../Contexts/AuthenticationProvider';
import { Loading } from '../../Components/Base';
import { useConfig } from '../../Contexts/ConfigProvider';
import { EXAMINATION_BASE_PATH } from '../../Domains/ExaminationManagement/constant';

const Login = () => {
  const { loginState } = useAuthentication();
  const { routing } = useConfig();
  const navigate = useNavigate();

  useEffect(() => {
    const loginPath = routing?.login?.path;

    if (loginState === LoginState.LOGGED_IN) {
      navigate(EXAMINATION_BASE_PATH, { replace: true });
      return;
    }

    if (loginState === LoginState.NOT_LOGGED_IN) {
      if (loginPath && loginPath.startsWith('/')) {
        navigate(loginPath, { replace: true });
      } else {
        window.location.href = loginPath ?? '/';
      }
      return;
    }
  }, [loginState, navigate, routing?.login?.path]);

  return <Loading open />;
};

export default Login;
