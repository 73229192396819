import { QueryHookOptions } from '@apollo/client';

import {
  useGetQMSTags,
  QMSTagCategory,
  QMSTagsResult,
  QMSTagQueryArgs,
  QMSTagOrder,
} from './useGetQMSTag';

type SubjectTagVariables = {
  order?: QMSTagQueryArgs['order'];
} & QueryHookOptions;

const useGetQMSSubjectTags = (props?: SubjectTagVariables) => {
  const { order, ...apolloOption } = props ?? {};
  const { data, loading, error, ...apolloUtils } = useGetQMSTags({
    variables: {
      search: {
        category: QMSTagCategory.Subject,
      },
      paging: {
        pageSize: 100,
        currentPage: 0,
      },
      order: {
        field: 'name',
        type: QMSTagOrder.Asc,
        ...order,
      },
    },
    ...apolloOption,
  });

  return { data: data?.qmsTags as QMSTagsResult, loading, error, ...apolloUtils };
};

export default useGetQMSSubjectTags;
