import { tidy, groupBy, summarize } from '@tidyjs/tidy';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Text } from '../../../../Components/Base';
import ExaminationQuestionGroups from '../../../../Components/ExaminationQuestionGroups';

const questionItems = [
  {
    bloom: 'ความเข้าใจ',
    choices: ['1 : 9', '7 : 3', '10 : 2', '18 : 2'],
    correctAnswer: 2,
    id: '1',
    indicator:
      'ค 1.1 ม.1/3 เข้าใจ และประยุกต์ใช้อัตราส่วน สัดส่วน และร้อยละในการแก้ปัญหาคณิตศาสตร์ และปัญหาในชีวิตจริง',
    level: 'ยาก',
    order: 1,
    point: 2,
    question: 'อัตราส่วนในข้อใดเป็นอัตราส่วนที่เท่ากับอัตราส่วน 9 : 1',
  },
  {
    bloom: 'การนำไปใช้',
    choices: ['4 : 5 : 9', '4 : 5 : 10', '5 : 4 : 9', '5 : 4 : 10'],
    correctAnswer: 4,
    id: '2',
    indicator:
      'ค 1.3 ม.1/6 เข้าใจ และประยุกต์ใช้อัตราส่วน สัดส่วน และร้อยละในการแก้ปัญหาคณิตศาสตร์ และปัญหาในชีวิตจริง',
    level: 'ง่าย',
    order: 2,
    point: 100,
    question:
      'มีบัตรจำนวนหนึ่งในกล่อง บนบัตรแต่ละใบเขียนตัวเลข 0-9 กำกับไว้ใบละ 1 ตัว อัตราส่วนของจำนวนบัตรที่เขียนจำนวนเฉพาะต่อจำนวนบัตรที่เขียนจำนวนคู่ต่อจำนวนบัตรทั้งหมดเป็นเท่าใด',
  },
];

interface ExaminationPointInput {
  [key: string]: string;
}

const QuestionGroups = () => {
  const formContext = useForm<ExaminationPointInput>({
    mode: 'onTouched',
    defaultValues: {},
  });

  const questionGroupByIndicators = tidy(
    questionItems,
    groupBy('indicator', summarize({ items: (items) => items })),
  );

  return (
    <Box>
      <Text variant="h6">Question Group</Text>
      <FormProvider {...formContext}>
        <form>
          {questionGroupByIndicators.map((item) => {
            const title = item.indicator.substring(0, 11).trim();
            const description = item.indicator.substring(12).trim();
            const questions = item.items;
            return (
              <Box key={item.indicator}>
                <ExaminationQuestionGroups
                  title={title}
                  description={description}
                  questionItems={questions}
                  onDelete={() => {
                    console.log('Delete question');
                  }}
                />
              </Box>
            );
          })}
        </form>
      </FormProvider>
    </Box>
  );
};

export default QuestionGroups;
