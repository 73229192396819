import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import FormSearchSelectionInput from '../../../../Components/Form/FormSearchSelectionInput';
import useGetQBRemainTags, {
  QBTagCategory,
} from '../../../../Domains/ExaminationManagement/useGetQBRemainTags';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import { getTagIds, getIndicatorOptions } from '../utils';

type SearchBloom = { label: string; value: string };

const IndicatorSelector = () => {
  const formContext = useFormContext();
  const { examId = '' } = useParams();
  const { data } = useGetExamination({ variables: { id: examId } });
  const tagIdsWithBloom: string[] = [];

  const learningUnit = formContext.watch('learningUnit.value');
  const blooms = formContext.watch('blooms');

  const isLearningUnitEmptyState = !learningUnit;
  const searchLearningUnit = learningUnit ?? '';
  const searchBlooms = blooms.map((item: SearchBloom) => item.value) ?? [];

  const tags = data?.qmsExam.tags ?? [];
  const tagIds = getTagIds(tags);

  if (searchLearningUnit) {
    tagIdsWithBloom.push(...tagIds, searchLearningUnit);
  }
  if (searchBlooms) {
    tagIdsWithBloom.push(...searchBlooms);
  }

  const {
    data: indicatorTags,
    loading,
    error,
  } = useGetQBRemainTags({
    skip: !searchLearningUnit,
    variables: {
      search: { tagIds: tagIdsWithBloom, category: QBTagCategory.Indicator },
    },
  });

  const indicatorRemainTags = indicatorTags?.qbGetRemainTags ?? [];
  const indicatorOptions = getIndicatorOptions(indicatorRemainTags);

  let noOptionsText = 'ไม่พบผลการค้นหา กรุณาลองใช้คำค้นหาอื่นๆ';
  if (loading) noOptionsText = 'กำลังค้นหาตัวชี้วัด...';
  if (error && !indicatorTags) noOptionsText = 'ไม่สามารถดึงข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง';

  return (
    <FormSearchSelectionInput
      name="indicator"
      label={'ตัวชี้วัด'}
      InputProps={{ placeholder: 'เลือกตัวชี้วัด' }}
      noOptionsText={noOptionsText}
      options={indicatorOptions}
      disabled={isLearningUnitEmptyState}
    />
  );
};

export default IndicatorSelector;
