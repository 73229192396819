import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';

import { DataGrid as BaseDataGrid, Label, Text, Color } from '../../../../Components/Base';
import ContentCopyRoundedIcon from '../../../../Components/Icons/ContentCopyRoundedIcon';
import DeleteIcon from '../../../../Components/Icons/DeleteIcon';
import EditIcon from '../../../../Components/Icons/EditIcon';

const rows = [
  {
    id: 1,
    createdAt: '08-12-2021',
    questionName: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 2/2564',
    subject: 'คณิตศาสตร์',
    grade: 'ม.1',
    questionCount: 20,
    status: 'DRAFT',
  },
  {
    id: 2,
    createdAt: '20-09-2021',
    questionName: 'ข้อสอบคณิตศาสตร์ ม.1 ภาคเรียนที่ 1/2564',
    subject: 'คณิตศาสตร์',
    grade: 'ม.1',
    questionCount: 30,
    status: 'PUBLISH',
  },
];

const DataGrid = () => {
  const columns = [
    { field: 'id', hide: true },
    { field: 'createdAt', headerName: 'วันที่สร้าง', width: 120 },
    {
      field: 'questionName',
      headerName: 'ชื่อชุดข้อสอบ',
      flex: 1,
      renderCell: (item: GridRenderCellParams) => (
        <Text variant={'body1'} fontWeight={600}>
          {item.value}
        </Text>
      ),
    },
    { field: 'subject', headerName: 'วิชา', width: 115 },
    { field: 'grade', headerName: 'ระดับชั้น', width: 115 },
    { field: 'questionCount', headerName: 'จำนวนข้อ', width: 100 },
    {
      field: 'status',
      headerName: 'สถานะ',
      width: 115,
      renderCell: (item: GridRenderCellParams) => (
        <Label color={item.value === 'DRAFT' ? Color.INFO : Color.SUCCESS}>{item.value}</Label>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 120,
      getActions: () => [
        <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => {}} />,
        <GridActionsCellItem
          icon={<ContentCopyRoundedIcon />}
          label="Duplicate"
          onClick={() => {}}
        />,
        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => {}} />,
      ],
    },
  ];

  return (
    <>
      <Text variant="body2" fontWeight={600}>
        Data Grid Without Pagination
      </Text>
      <BaseDataGrid rows={rows} columns={columns} hideFooter disableSelectionOnClick />
    </>
  );
};

export default DataGrid;
