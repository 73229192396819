import { styled, Drawer as MUIDrawer, List } from '@mui/material';

import { Box, Flex, Image } from '../../Components/Base';
import { MENU_DRAWER_WIDTH } from '../../Components/Layout';
import MenuItem from '../MenuItem';
import { useConfig } from '../../Contexts/ConfigProvider';
import useAppMenu from '../../Domains/useAppMenu';

const Drawer = styled(MUIDrawer)(({ theme }) => ({
  display: 'none',
  '& .MuiPaper-root': {
    boxSizing: 'border-box',
    width: MENU_DRAWER_WIDTH,
    padding: theme.spacing(2),
  },

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const SideBar = () => {
  const { logoUrl } = useConfig();
  const { appMenu } = useAppMenu();

  return (
    <Box component="nav" sx={{ width: { sm: MENU_DRAWER_WIDTH }, flexShrink: { sm: 0 } }}>
      <Drawer variant="permanent" open>
        {logoUrl && (
          <Flex px={2} justifyContent={'center'}>
            <Image src={logoUrl} alt={'app-logo'} width={1} />
          </Flex>
        )}
        <List sx={{ marginTop: 2 }}>
          {appMenu.map((menu) => (
            <MenuItem key={menu.label} {...menu} />
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default SideBar;
