import { ComponentsPropsList, createTheme, experimental_sx as sx } from '@mui/material/styles';

const buttonTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          minWidth: '100px',
          fontWeight: 600,
          borderRadius: '8px',
          boxShadow: 'none',
          textTransform: 'initial',
          alignItems: 'stretch',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: ({ ownerState, theme }) => {
          const color = ownerState?.color as ComponentsPropsList['MuiButton']['color'];
          if (color !== 'inherit') {
            return { color: theme.palette[color ?? 'primary'].dark };
          } else {
            return {};
          }
        },
        outlined: sx({ bgcolor: 'common.white' }),
        sizeSmall: sx({ fontSize: 14, height: 32, py: 0.75, px: 1.5 }),
        sizeMedium: sx({ fontSize: 14, height: 36, py: 1, px: 1.5 }),
        sizeLarge: sx({ fontSize: 16, height: 44, py: 1.25, px: 1.5 }),
        startIcon: sx({ mr: 0.5, ml: 0 }),
        endIcon: sx({ ml: 0.5, mr: 0 }),
        iconSizeSmall: { '.MuiSvgIcon-root': { fontSize: 20 } },
        iconSizeMedium: { '.MuiSvgIcon-root': { fontSize: 20 } },
        iconSizeLarge: { '.MuiSvgIcon-root': { fontSize: 24 } },
      },
      variants: [
        {
          props: { color: 'neutral' },
          style: sx({
            color: 'text.primary',
            borderColor: 'grey.400',
            ':hover': {
              bgcolor: 'hsla(210, 10%, 23%, 0.04)',
              borderColor: 'grey.800',
            },
          }),
        },
        {
          props: { color: 'neutral', variant: 'contained' },
          style: sx({
            bgcolor: 'grey.200',
            ':hover': {
              bgcolor: 'grey.300',
            },
          }),
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: sx({ '.MuiSvgIcon-root': { fontSize: 24 } }),
        sizeSmall: sx({ p: 0.5 }),
        sizeMedium: sx({ p: 0.75 }),
        sizeLarge: sx({ p: 1 }),
      },
      variants: [
        {
          props: { color: 'default' },
          style: sx({ color: 'text.primary' }),
        },
      ],
    },
  },
});

export default buttonTheme.components;
