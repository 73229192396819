import isEqualDate from 'date-fns/isEqual';
import isEqual from 'lodash/isEqual';

import { ExaminationEvent } from '../../../Contexts/ExaminationEventStoreProvider';

const isExamEventInformationEqualWithStore = (
  examEventInformationDomain: ExaminationEvent['examinationEventInformation'],
  examEventInformationStore: ExaminationEvent['examinationEventInformation'],
) => {
  const examEventNameDomain = examEventInformationDomain?.examEventName;
  const semesterDomain = examEventInformationDomain?.semester;
  const gradeDomain = examEventInformationDomain?.class;
  const startDateDomain = examEventInformationDomain?.startTime ?? 0;
  const endDateDomain = examEventInformationDomain?.endTime ?? 0;
  const examinationDomain = examEventInformationDomain?.examination?.label ?? '';
  const shouldShowPointsDomain = examEventInformationDomain?.shouldShowPoints;
  const instructionDomain = examEventInformationDomain?.instruction;

  const examEventNameStore = examEventInformationStore?.examEventName;
  const semesterStore = examEventInformationStore?.semester;
  const gradeStore = examEventInformationStore?.class;
  const startDateStore = examEventInformationStore?.startTime ?? 0;
  const endDateStore = examEventInformationStore?.endTime ?? 0;
  const examinationStore = examEventInformationStore?.examination?.label ?? '';
  const shouldShowPointsStore = examEventInformationStore?.shouldShowPoints;
  const instructionStore = examEventInformationStore?.instruction;

  // Convert date from string to Date
  const startDateDomainFormat = new Date(startDateDomain);
  const startDateStoreFormat = new Date(startDateStore);
  const endDateDomainFormat = new Date(endDateDomain);
  const endDateStoreFormat = new Date(endDateStore);

  // Check Invalid Date when date is undefined and return default 0 becuase type of date is Date | number
  // Ref: https://stackoverflow.com/questions/10589732/checking-if-a-date-is-valid-in-javascript
  const displayStartTimeDomain =
    startDateDomainFormat instanceof Date && !isNaN(startDateDomainFormat.valueOf())
      ? startDateDomainFormat
      : 0;
  const displayStartTimeStore =
    startDateStoreFormat instanceof Date && !isNaN(startDateStoreFormat.valueOf())
      ? startDateStoreFormat
      : 0;
  const displayEndTimeDomain =
    endDateDomainFormat instanceof Date && !isNaN(endDateDomainFormat.valueOf())
      ? endDateDomainFormat
      : 0;
  const displayEndTimeStore =
    endDateStoreFormat instanceof Date && !isNaN(endDateStoreFormat.valueOf())
      ? endDateStoreFormat
      : 0;

  const isEqualExamEventName = examEventNameDomain === examEventNameStore;
  const isEqualSemester = semesterDomain === semesterStore;
  const isEqualGrade = gradeDomain === gradeStore;
  const isEqualStartDate = isEqualDate(displayStartTimeDomain, displayStartTimeStore);
  const isEqualEndDate = isEqualDate(displayEndTimeDomain, displayEndTimeStore);
  const isEqualExamination = examinationDomain === examinationStore;
  const isEqualShouldShowPoints = shouldShowPointsDomain === shouldShowPointsStore;
  const isEqualInstruction = instructionDomain === instructionStore;

  const isEqualExamEventInformation =
    isEqualExamEventName &&
    isEqualSemester &&
    isEqualGrade &&
    isEqualStartDate &&
    isEqualEndDate &&
    isEqualExamination &&
    isEqualShouldShowPoints &&
    isEqualInstruction;

  return isEqualExamEventInformation;
};

const isExamParticipantEqualWithStore = (
  examParticipantDomain: ExaminationEvent['examinationParticipant'],
  examParticipantStore: ExaminationEvent['examinationParticipant'],
) => {
  const roomsDomain = examParticipantDomain?.rooms;
  const exceptCandidatesDomain = examParticipantDomain?.exceptCandidates;
  const candidateListDomain = examParticipantDomain?.candidateList;

  const roomsStore = examParticipantStore?.rooms;
  const exceptCandidatesStore = examParticipantStore?.exceptCandidates;
  const candidateListStore = examParticipantStore?.candidateList;

  const isEqualRooms = isEqual(roomsDomain, roomsStore);
  const isEqualExceptCandidates = isEqual(exceptCandidatesDomain, exceptCandidatesStore);
  const isEqualCandidateList = isEqual(candidateListDomain, candidateListStore);

  const isEqualExaminationParticipant =
    isEqualRooms && isEqualExceptCandidates && isEqualCandidateList;

  return isEqualExaminationParticipant;
};

export const isExaminationEventEqualWithStore = (
  examinationEventDomain: ExaminationEvent,
  examinationEventStore: ExaminationEvent,
) => {
  const {
    examinationEventInformation: examEventInformationDomain,
    examinationParticipant: examParticipantDomain,
  } = examinationEventDomain;
  const { examinationEventInformation, examinationParticipant } = examinationEventStore;

  const isEqualExamEventInformation = isExamEventInformationEqualWithStore(
    examEventInformationDomain,
    examinationEventInformation,
  );

  const isEqualExaminationParticipant = isExamParticipantEqualWithStore(
    examParticipantDomain,
    examinationParticipant,
  );

  return isEqualExamEventInformation && isEqualExaminationParticipant;
};
