import { DialogTitle, DialogTitleProps } from '@mui/material';

import { IconButton, IconButtonProps } from '../../Button';
import CloseIcon from '../../../Icons/CloseIcon';
import { Text } from '../../Text';

type ModalTitleProps = {
  onClose?: IconButtonProps['onClick'];
} & DialogTitleProps;

const modalTitleLayout = { display: 'grid', gridTemplateColumns: '1fr 20px', alignItems: 'center' };

const ModalTitle = (props: ModalTitleProps) => {
  const { children, onClose, sx, ...restProps } = props;
  const showCloseIcon = typeof onClose === 'function';
  return (
    <DialogTitle {...restProps} sx={showCloseIcon ? { ...modalTitleLayout, ...sx } : sx}>
      {typeof children === 'string' ? (
        <Text component="p" variant="h5">
          {children}
        </Text>
      ) : (
        children
      )}
      {showCloseIcon && (
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 16, top: 16 }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default ModalTitle;
export type { ModalTitleProps };
