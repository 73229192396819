import create from 'zustand';
import shallow from 'zustand/shallow';

import { QMSTagCategory } from '../../Domains/ExaminationManagement/useGetQMSTags';
import { QBTagCategory } from '../../Domains/ExaminationManagement/useGetQBTags';

export type Question = {
  id: string;
  tags?: {
    category: QMSTagCategory | QBTagCategory; // Add TagCategory or QuestionTagCategory for support enum of question tags category not same
    id: string;
    name: string;
    slug: string;
  }[];
  order: number;
  point: number;
};

export interface Examination {
  examinationInformation: {
    examName?: string;
    subject?: string;
    grade?: string;
  };
  questionSelected: Question[];
}

// Type of memoizing selectors
type ExaminationInformationSelector = (
  state: ExaminationContext,
) => [
  ExaminationContext['examinationInformation'],
  ExaminationContext['setExaminationInformation'],
  ExaminationContext['defaultState'],
];

type ExaminationQuestionSelector = (
  state: ExaminationContext,
) => [
  ExaminationContext['questionSelected'],
  ExaminationContext['setExaminationQuestionSelected'],
  ExaminationContext['defaultState'],
];

type ExaminationManagementSelector = (state: ExaminationContext) => {
  reset: ExaminationContext['reset'];
  setExaminationInformation: ExaminationContext['setExaminationInformation'];
  setExaminationQuestionSelected: ExaminationContext['setExaminationQuestionSelected'];
  defaultState: ExaminationContext['defaultState'];
};

type EditableExaminationSelector = (state: ExaminationContext) => {
  examinationInformation: ExaminationContext['examinationInformation'];
  questionSelected: ExaminationContext['questionSelected'];
};

export type ExaminationContext = {
  reset: (incomingState?: Examination) => void;
  examinationInformation?: {
    examName: Examination['examinationInformation']['examName'];
    subject: Examination['examinationInformation']['subject'];
    grade: Examination['examinationInformation']['grade'];
  };
  setExaminationInformation: (data: Examination['examinationInformation']) => void;
  questionSelected?: Examination['questionSelected'];
  setExaminationQuestionSelected: (
    dataOrCallback:
      | Examination['questionSelected']
      | ((prevData: Examination['questionSelected']) => Examination['questionSelected']),
  ) => void;
  defaultState?: Partial<Examination>;
};

const initialState = {
  examinationInformation: undefined,
  questionSelected: undefined,
};

const createStore = () =>
  create<ExaminationContext>((set) => ({
    reset: (incomingInitialState) =>
      set(() =>
        !incomingInitialState
          ? { ...initialState, defaultState: initialState }
          : {
              examinationInformation: {
                examName: incomingInitialState?.examinationInformation?.examName,
                subject: incomingInitialState?.examinationInformation?.subject,
                grade: incomingInitialState?.examinationInformation?.grade,
              },
              questionSelected: incomingInitialState?.questionSelected,
              defaultState: incomingInitialState,
            },
      ),

    // Examination information store
    examinationInformation: undefined,
    setExaminationInformation: (examinationInformation) =>
      set(() => ({
        examinationInformation: {
          examName: examinationInformation.examName,
          subject: examinationInformation.subject,
          grade: examinationInformation.grade,
        },
      })),

    // Examination selected question store
    questionSelected: undefined,
    setExaminationQuestionSelected: (questionSelectedOrCallback) =>
      set((prev) => {
        if (typeof questionSelectedOrCallback === 'function') {
          return {
            questionSelected: questionSelectedOrCallback(prev?.questionSelected ?? []),
          };
        }
        return { questionSelected: questionSelectedOrCallback };
      }),
  }));

const useStore = createStore();

// Selecting multiple state slices
// Memoizing selectors and Array pick, re-renders the component when either state change
const examinationInformationSelector: ExaminationInformationSelector = (state) => [
  state.examinationInformation,
  state.setExaminationInformation,
  state.defaultState,
];
const useExaminationInformation = () => useStore(examinationInformationSelector, shallow);

const examinationQuestionSelector: ExaminationQuestionSelector = (state) => [
  state.questionSelected,
  state.setExaminationQuestionSelected,
  state.defaultState,
];
const useExaminationQuestion = () => useStore(examinationQuestionSelector, shallow);

const examinationManagementSelector: ExaminationManagementSelector = (state) => ({
  reset: state.reset,
  setExaminationInformation: state.setExaminationInformation,
  setExaminationQuestionSelected: state.setExaminationQuestionSelected,
  defaultState: state.defaultState,
});
const useExaminationManagement = () => useStore(examinationManagementSelector, shallow);

const editableExaminationSelector: EditableExaminationSelector = (state) => ({
  examinationInformation: state.examinationInformation,
  questionSelected: state.questionSelected,
});
const useEditableExamination = () => useStore(editableExaminationSelector, shallow);

export {
  useStore as useExaminationContext,
  useEditableExamination,
  useExaminationManagement,
  useExaminationInformation,
  useExaminationQuestion,
};
