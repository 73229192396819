import { forwardRef } from 'react';
import {
  styled,
  FormControlLabel as MUIFormControlLabel,
  Checkbox as MUICheckbox,
  CheckboxProps,
} from '@mui/material';

const FormControlLabel = styled(MUIFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label ,.MuiFormControlLabel-label.Mui-disabled': {
    fontWeight: '600',
    color: theme.palette.text.primary,
  },
  '& .Mui-checked.Mui-disabled': {
    color: theme.palette.grey[500],
  },
  '&.Mui-error .MuiSvgIcon-root': {
    color: theme.palette.error.main,
  },
}));

type CheckboxWithLabelProps = {
  label?: string;
} & CheckboxProps;

const Checkbox = forwardRef((props: CheckboxWithLabelProps, ref) => {
  const { label = '', value, ...restProps } = props;
  return (
    <FormControlLabel
      ref={ref}
      control={<MUICheckbox checked={!!value} value={value} {...restProps} />}
      label={label}
    />
  );
});

export type { CheckboxWithLabelProps };
export default Checkbox;
