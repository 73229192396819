import { useParams, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { Divider } from '@mui/material';

import { Flex, Box, Button } from '../../../../Components/Base';
import useGetQBRemainTags, {
  QBTagCategory,
} from '../../../../Domains/ExaminationManagement/useGetQBRemainTags';
import useGetQBDifficultyTags from '../../../../Domains/ExaminationManagement/useGetQBTags/useGetQBDifficultyTags';
import { useGetExamination } from '../../../../Domains/ExaminationManagement/useGetExamination';
import SearchFilterLoading from '../../Components/SearchFilterLoading';
import ExaminationQuestionSearchFilterForm, {
  ExaminationQuestionSearchFilterFormProps,
} from '../ExaminationQuestionSearchFilterForm';
import { getTagIds } from '../utils';

const LEVEL_DEFAULT = {
  easy: true,
  medium: true,
  hard: true,
};

const SEARCH_SELECTION_DEFAULT = { label: '', value: '' };

const initialFormValues = {
  learningUnit: SEARCH_SELECTION_DEFAULT,
  blooms: [],
  indicator: SEARCH_SELECTION_DEFAULT,
  level: LEVEL_DEFAULT,
};

const ApplyAndResetFilterButton: ExaminationQuestionSearchFilterFormProps['submitComponent'] = (
  formContext,
) => {
  const isLearningUnitEmptyState = !formContext.watch().learningUnit?.value;
  const isSubmitting = formContext.formState.isSubmitting;
  return (
    <>
      <Divider sx={{ mt: 2 }} />
      <Flex mt={2} justifyContent={'flex-end'}>
        <Button
          data-testid="reset-filter-button"
          color="neutral"
          onClick={() => {
            formContext.reset(initialFormValues);
          }}
          disabled={isLearningUnitEmptyState}
          size="large"
        >
          {'Reset'}
        </Button>
        <Box pl={2} />
        <Button
          data-testid="apply-filter-button"
          type="submit"
          disabled={isSubmitting || isLearningUnitEmptyState}
          size="large"
        >
          {'Apply'}
        </Button>
      </Flex>
    </>
  );
};

const queryStringFormat = (data: ExaminationQuestionSearchFilterFormProps['initialFormValues']) => {
  const blooms = data?.blooms?.map((item) => item?.value);
  const learningUnitValue = data?.learningUnit?.value;
  const indicatorValue = data?.indicator?.value;
  const learningUnit = !!learningUnitValue ? learningUnitValue : undefined;
  const indicator = !!indicatorValue ? indicatorValue : undefined;
  const easy = data?.level?.easy;
  const medium = data?.level?.medium;
  const hard = data?.level?.hard;
  const stringify = queryString.stringify(
    { blooms, indicator, learningUnit, easy, medium, hard },
    { arrayFormat: 'comma' },
  );
  return stringify;
};

const SearchExaminationQuestionForm = () => {
  const [, setSearchParams] = useSearchParams({});
  const { examId = '' } = useParams();
  const { data } = useGetExamination({ variables: { id: examId } });

  const tags = data?.qmsExam.tags ?? [];
  const tagIds = getTagIds(tags);

  const { loading: subsectionTagsLoading } = useGetQBRemainTags({
    variables: {
      search: { tagIds, category: QBTagCategory.Subsection },
    },
  });
  const { loading: difficultyTagsLoading } = useGetQBDifficultyTags();

  if (subsectionTagsLoading || difficultyTagsLoading) return <SearchFilterLoading />;

  return (
    <>
      <ExaminationQuestionSearchFilterForm
        initialFormValues={initialFormValues}
        onSubmit={() => (formValues) => {
          const query = queryStringFormat(formValues);
          setSearchParams(query);
        }}
        submitComponent={ApplyAndResetFilterButton}
      />
    </>
  );
};

export default SearchExaminationQuestionForm;
