import { Box, Text } from '../../Components/Base';

import ExaminationEventDetailsCreation from './Containers/ExaminationEventDetailsCreation';

export default function ExaminationEventCreation() {
  return (
    <Box
      p={3}
      pb={5}
      borderRadius={2}
      boxShadow={'0px 2px 15px hsla(206, 13%, 25%, 0.1)'}
      bgcolor={'common.white'}
    >
      <Text variant="h4">{'รายละเอียดการจัดสอบ'}</Text>
      <ExaminationEventDetailsCreation />
    </Box>
  );
}
