import {
  ExaminationEventItemType,
  CandidateByClassType,
  CandidateExceptedType,
  CandidateListType,
} from '../../../Domains/ExaminationManagement/useExamEventDetails';

const formatExaminationEventInformation = (eventInformation: ExaminationEventItemType) => {
  const {
    title,
    semester,
    grade,
    startDate,
    endDate,
    examination,
    randomQuestion,
    randomAnswer,
    shouldShowPoints,
    instruction,
  } = eventInformation;

  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);

  return {
    examEventName: title,
    semester: semester?.value,
    class: grade?.value,
    dateRange: [newStartDate, newEndDate],
    endTime: newEndDate,
    startTime: newStartDate,
    examination,
    randomQuestion,
    randomAnswer,
    shouldShowPoints,
    instruction,
  };
};

const getRoomValue = (candidatesByClass: CandidateByClassType[]) => {
  const rooms = candidatesByClass.map(({ id, class: classNo, students }) => ({
    label: `${classNo} (${students})`,
    value: id,
  }));

  return rooms;
};

const getExceptCandidateValue = (candidatesExcepted: CandidateExceptedType[]) => {
  const exceptCandidates = candidatesExcepted.map(
    ({ id, firstName, lastName, class: classNo }) => ({
      label: `${firstName} ${lastName} (${classNo})`,
      value: id,
    }),
  );

  return exceptCandidates;
};

const getCandidateListValue = (candidates: CandidateListType[]) => {
  const candidateList = candidates.map(({ id, firstName, lastName, class: classNo }) => ({
    label: `${firstName} ${lastName} (${classNo})`,
    value: id,
  }));

  return candidateList;
};

const formatExaminationParticipant = (eventPolicies: ExaminationEventItemType['policies']) => {
  const rooms = getRoomValue(eventPolicies?.candidateByClass ?? []);
  const exceptCandidates = getExceptCandidateValue(eventPolicies?.candidateExcepted ?? []);
  const candidateList = getCandidateListValue(eventPolicies?.candidateList ?? []);

  const examinationParticipant = {
    rooms: rooms,
    exceptCandidates: exceptCandidates,
    candidateList: candidateList,
  };

  return examinationParticipant;
};

export const formatInitialExaminationEventValues = (examinationEvent: ExaminationEventItemType) => {
  const examinationEventInformation = formatExaminationEventInformation(examinationEvent ?? {});
  const examinationParticipant = formatExaminationParticipant(examinationEvent?.policies ?? {});
  return {
    examinationEventInformation,
    examinationParticipant,
  };
};
