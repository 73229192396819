import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { styled } from '@mui/material';

import { Box, BoxProps } from '../../../../Components/Base';
import DragAndDrop from '../../../../Components/DragAndDrop';
import { AcceptType } from '../../../../Components/Dropzone';
import BaseHTMLView from '../../../../Components/HTMLView';

const HTMLView = styled(BaseHTMLView)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

type RowProps = {
  data: any;
  path: string;
} & Omit<BoxProps, 'color'>;

const adjustImageSpacingInQuestion = (question: string) => {
  return question.replaceAll('<figure>', '<figure style="margin: 8px 0px;">');
};

const insertQuestionOrder = (questionOrder: number, question: string) => {
  return `<p style="display: grid;">${questionOrder}. ${question}</p>`;
};

const QuestionDnd = (props: RowProps) => {
  const { data, path, ...restProps } = props;
  const questionOrder = data.order;
  let formattedQuestion = data.question;
  formattedQuestion = adjustImageSpacingInQuestion(formattedQuestion);
  formattedQuestion = insertQuestionOrder(questionOrder, formattedQuestion);

  const rowRef = useRef<HTMLDivElement>(null);

  const [{ opacity }, drag] = useDrag({
    type: AcceptType.QUESTION,
    item: {
      type: AcceptType.QUESTION,
      id: data.id,
      path,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  });

  useEffect(() => {
    drag(rowRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      ref={rowRef}
      key={`question-${questionOrder}`}
      style={{ opacity }}
      minHeight={40}
      maxHeight={120}
      overflow={'hidden'}
      sx={{ transform: 'translateZ(0)' }}
      {...restProps}
    >
      <DragAndDrop variant="small">
        <HTMLView rawContent={formattedQuestion} />
      </DragAndDrop>
    </Box>
  );
};

export default QuestionDnd;
